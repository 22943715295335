<template>
    <div v-if="initiated">
        <UserData
            v-if="showUserTab && activeTab === 'user'"
            is-data-check
            @on-saved="nextForm"
        />
        <ClientData
            v-if="showClientTab && activeTab === 'client'"
            is-data-check
            :show-back-btn="showUserTab"
            @on-back="previousForm"
            @on-saved="nextForm"
        />
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import UserData from "../myAccount/UserData";
    import ClientData from "../myAccount/ClientData";
    import { getRouteAfterDataCheck } from "../../router";

    export default {
        name: 'DataCheck',
        components: {ClientData, UserData},
        computed: {
            ...mapState({
                initiated: state => state.myAccount.initiated,
                activeTab: state => state.myAccount.activeTab
            }),
            ...mapGetters('myAccount', [
                'showUserTab',
                'showClientTab'
            ])
        },
        created() {
            this.$store.dispatch('myAccount/getDataCheckConfig')
                .then(() => {
                    if (this.showUserTab) {
                        this.$store.dispatch('myAccount/setActiveTabUser');
                    } else {
                        this.$store.dispatch('myAccount/setActiveTabClient');
                    }
                });
        },
        methods: {
            previousForm() {
                this.$store.dispatch('myAccount/setActiveTabUser');
            },
            nextForm() {
                if (this.activeTab === 'user' && this.showClientTab) {
                    this.$store.dispatch('myAccount/setActiveTabClient');
                } else {
                    Promise.all([
                        this.$store.dispatch('shop/loadMenu'),
                        this.$store.dispatch('session/reloadPortalCustomization'),
                    ]).finally(() =>
                        this.$store.dispatch('user/logDataCheckAction')
                            .then(() => {
                                this.$router.push(getRouteAfterDataCheck());
                            })
                            .catch((error) => {
                                console.error(error);
                            })
                    );
                }
            }
        }
    };
</script>
