<template>
    <NdxPageFrame>
        <InstanceDetail
            v-if="timelineData !== null"
            :timeline-data="timelineData"
        />
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import InstanceDetail from "../../components/workflows/InstanceDetail.vue";

    export default {
        name: 'WorkflowsInstanceCompletedDetail',
        components: {InstanceDetail, NdxPageFrame},
        data() {
            return {
                timelineData: null
            };
        },
        watch: {
            '$route.params': {
                handler: 'loadTimeline',
                deep: true,
                immediate: true
            }
        },
        methods: {
            loadTimeline() {
                let snapshotId = null;
                if (this.$route.params && 'snapshotId' in this.$route.params) {
                    snapshotId = this.$route.params.snapshotId;
                }

                if (snapshotId === null) {
                    this.timelineData = null;
                    return;
                }

                this.$store.dispatch('workflowInstanceSnapshot/find', {
                    id: snapshotId
                }).then((result) => {
                    this.timelineData = result.timeline;
                }).catch((error) => {
                    console.error(error);
                });
            }
        }
    };
</script>
