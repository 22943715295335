<template>
    <NdxPageFrame :key="$route.params.id">
        <template #center-headline>
            <NdxPageHeader
                hide-filter
                hide-view-mode
                :nav-name="navName"
            />
        </template>
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import NdxPageHeader from "../../components/library/NdxPageHeader.vue";
    export default {
        name: 'Custom',
        components: {NdxPageHeader, NdxPageFrame},
        computed: {
            navName() {
                return this.$store.getters['shop/getWgc'](+this.$route.params.id).label;
            }
        }
    };
</script>
