<template>
    <div class="w-100 ndx-form-element ndx-select secondary">
        <div class="dropdown">
            <div
                v-if="availableBudgets.length"
                class="dropdown-toggle d-flex justify-content-between align-items-center"
                :id="dropdownId"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-boundary="body"
                data-bs-display="dynamic"
            >
                <template v-if="usedBudget">
                    <div class="budgetInfo">
                        {{
                            $t(
                                'message.budgetAvailable',
                                {budgetName: usedBudget.name, value: formatPrice(usedBudget.availableAmount)}
                            )
                        }}
                        <div class="highlight">
                            <template v-if="usedBudget.availableAmount < costs">
                                {{ $t('message.budgetToLow') }}
                            </template>
                            <template v-else>
                                {{ $t('message.budgetCharge', {value: formattedCosts}) }}
                            </template>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="selectedValue">{{ $t('label.ownCosts') }}</div>
                </template>
                <NdxIcon icon="drop-down" size="s"/>
            </div>
            <ul
                v-if="!readonly"
                class="dropdown-menu dropdown-menu-end"
                :aria-labelledby="dropdownId"
            >
                <li
                    class="w-100 px-3 pb-3"
                    v-if="availableBudgets.length + 1 >= searchFieldLength"
                >
                    <NdxSearchInput
                        class="w-100"
                        v-model="searchText"
                    />
                </li>
                <li
                    class="dropdown-item"
                    :class="{active: !usedBudget}"
                    v-if="supportNoBudgetUse && matchesSearchText($t('label.ownCosts'))"
                    @click="usedBudget ? setBudget(null) : () => {}"
                >
                    {{ $t('label.ownCosts') }}
                </li>
                <template v-for="(budget, idx) in availableBudgets" :key="idx">
                    <li
                        v-show="matchesSearchText(budget.name)"
                        class="budgetInfo dropdown-item"
                        :class="{
                            active: budget.id === usedBudget?.id,
                            disabled: budget.availableAmount < costs
                        }"
                        @click="budget.availableAmount < costs || budget.id === usedBudget?.id
                            ? () => {} : setBudget(budget.id)"
                    >
                        {{
                            $t(
                                'message.budgetAvailable',
                                {budgetName: budget.name, value: formatPrice(budget.availableAmount)}
                            )
                        }}
                        <div class="note">
                            <template v-if="budget.availableAmount < costs">
                                {{ $t('message.budgetToLow') }}
                            </template>
                            <template v-else>
                                {{ $t('message.budgetCharge', {value: formattedCosts}) }}
                            </template>
                        </div>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import NdxIcon from "../../library/NdxIcon";
    import { PriceFormatter } from "../../../plugins/formatter";
    import NdxSearchInput from "../../library/formElements/NdxSearchInput.vue";

    export default {
        name: "BudgetSelect",
        components: {NdxSearchInput, NdxIcon},
        emits: ['change-budget'],
        props: {
            costs: {
                type: Number,
                required: true
            },
            availableBudgets: {
                type: Array,
                required: true
            },
            selectedBudget: {
                type: [Number, null],
                required: true
            },
            readonly: {
                type: Boolean
            },
            searchFieldLength: {
                type: Number,
                default: 10
            }
        },
        data() {
            return {
                dropdownId: 'dropdownMenuButton_' + this.ndxUuid,
                searchText: '',
            };
        },
        computed: {
            ...mapGetters('shop', [
                'currency'
            ]),
            usedBudget() {
                return this.availableBudgets.find(budget => budget.id === this.selectedBudget);
            },
            supportNoBudgetUse() {
                return this.availableBudgets.reduce((prevVal, budget) => prevVal || !budget.restrictedProducts, false);
            },
            formattedCosts() {
                return this.formatPrice(this.costs);
            }
        },
        mounted() {
            let dropDownEl = document.getElementById(this.dropdownId);
            if (dropDownEl) {
                let me = this;
                dropDownEl.addEventListener('hidden.bs.dropdown', function () {
                    me.searchText = '';
                });
            }
        },
        methods: {
            setBudget(budgetId) {
                this.$emit('change-budget', budgetId);
            },
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },
            matchesSearchText(text) {
                return text.toLowerCase().includes((this.searchText || '').toLowerCase());
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '../../../style/variables_ndx';

    .dropdown-toggle,
    .dropdown-item {
        white-space: normal;
    }

    .budgetInfo {
        @extend %font-body2;
        padding-top: 4px;
        padding-bottom: 4px;

        .highlight {
            @extend %font-caption;
            color: var(--bs-danger);
        }

        .note {
            @extend %font-caption;
        }

        &.active {
            .note {
                color: var(--bs-gray-middle);
            }
        }
    }
</style>
