<template>
    <ul class="instance-timeline">
        <li
            v-for="(item, idx) of timelineData.timeline"
            :key="idx"

            class="event"
            :class="{['status_' + getItemStatusClass(item)]: true}"
            :data-date="$d(dateConverter(item.date), 'long')"
        >
            <div class="headline">{{ $t('label.workflow.timeline.' + item.oid.type + '.' + item.type) }}</div>
            <div class="subHeadline">
                <template v-if="item.oid.type === 'task'">
                    {{ getTaskName(item.oid.id) }}
                </template>
                <template v-if="item.oid.type === 'incident'">
                    {{ $t('label.workflow.incidentType.' + item.data.incidentType) }}
                    {{ getIncidentTaskKey(item) }}
                </template>
            </div>
            <div class="content">
                <template v-if="item.oid.type === 'instance'">
                    <template v-if="item.type === 'created'">
                        {{ $t('label.requestedBy') }}:
                        <b>{{ timelineData.businessObject.createdBy.username }}</b>
                    </template>
                </template>

                <template v-if="item.oid.type === 'incident'">
                    <template v-if="item.type === 'created'">
                        <div>
                            {{ $t('label.message') }}:
                            <div class="info_message">{{ getIncidentMessage(item.data) }}</div>
                        </div>
                    </template>
                </template>

                <template v-if="item.oid.type === 'task'">
                    <template v-if="item.type === 'ended'">
                        <div v-if="item.data.userId !== null">
                            {{ $t('label.assignee') }}:
                            <b>{{ getUserName(item.data.userId) }}</b>
                        </div>
                        <div class="info_message" v-if="item.data.verdict !== null">
                            {{ getVerdictMessage(item.data.verdict) }}
                        </div>
                    </template>
                    <template v-if="['claim', 'unclaim'].includes(item.type)">
                        {{ $t('label.workflow.' + item.type + 'edBy') }}:
                        <b>{{ getUserName(item.data.userId) }}</b>
                    </template>
                    <template v-if="['candidate_add', 'candidate_delete'].includes(item.type)">
                        {{ $t('label.workflow.taskCandidate') }}:
                        <b>{{ getUserName(item.data.userId) }}</b>
                    </template>
                </template>

                <template v-if="item.type === 'comment'">
                    {{ $t('label.commentedBy') }}: <b>{{ getUserName(item.data.userId) }}</b>
                    <div class="info_message preserveLineBreaks">{{ item.data.message }}</div>

                    <div class="comment_files" v-if="item.data.files && item.data.files.length > 0">
                        <div class="comment_files-headline">{{ $t('label.files') }}</div>
                        <div class="file-grid">
                            <template
                                v-for="file in item.data.files"
                                :key="file.id"
                            >
                                <div class="action-data" @click="downloadFile(file)">
                                    <div class="d-flex align-items-center gap-2">
                                        <component :is="ndxIcon" icon="export" size="s"/>
                                        <span>{{ file.originalFilename }}</span>
                                    </div>
                                </div>
                                <div class="size-data">{{ fileSize(file.size) }}</div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </li>
    </ul>
</template>

<script>
    import NdxWorkflowMixin from "./NdxWorkflowMixin";

    const extendedInfoTypes = [
        'created', 'unclaim', 'candidate_add', 'candidate_delete'
    ];

    const DECISIONS = {
        approve: 'approve',
        reject: 'reject',
        rejectWithRevision: 'rejectWithRevision'
    };

    export { DECISIONS };

    export default {
        name: "NdxWorkflowTimeline",
        mixins: [NdxWorkflowMixin],
        data() {
            return {
                extendedInfoTypes
            };
        },
        methods: {
            getTaskName(taskId) {
                for (let task of this.timelineData.tasks) {
                    if (task.id === taskId) {
                        return task.name;
                    }
                }
                return taskId;
            },
            getItemStatusClass(item) {
                let status = '';

                if (item.oid.type === 'instance' && item.type === 'created') {
                    const instanceVerdict = item.data && 'verdict' in item.data ? item.data.verdict : '';
                    status = this.getVerdictColorType(instanceVerdict);
                } else if (item.oid.type === 'task' && ['created', 'ended'].includes(item.type)) {
                    const taskVerdict = item.data && 'verdict' in item.data ? item.data.verdict : '';
                    status = this.getVerdictColorType(taskVerdict);
                } else if (item.oid.type === 'incident') {
                    status = item.type === 'created' ? 'error' : 'success';
                } else if (!this.extendedInfoTypes.includes(item.type)) {
                    status = 'info'
                }

                return status;
            },
            getVerdictColorType(verdict) {
                switch (verdict) {
                    case 'approve':
                        return 'success';
                    case 'revision':
                        return 'warning';
                    case 'reject':
                        return 'error';
                    default:
                        return 'info';
                }
            },
            getIncidentMessage(incident) {
                return this.$t(this.$store.getters['workflowIncidents/getIncidentCauseDictEntry'](incident));
            },
            getIncidentTaskKey(item) {
                const incidentMessage = item.data?.incidentMessage ?? '';
                const taskKey = incidentMessage.match(/taskKey: ([^,]+)/);
                return Array.isArray(taskKey) && taskKey.length === 2 ? taskKey[1] : '';
            }
        }
    };
</script>

<style scoped lang="scss">
    .instance-timeline {
        --gray-middle: var(--bs-gray-middle, #e6e6e6);
        --gray-dimmer: var(--bs-gray-dimmer, #ccc);
        --info: var(--bs-info, #03a9f4);
        --success: var(--bs-success, #259b24);
        --danger: var(--bs-danger, red);
        --warning: var(--bs-warning, #ffc107);


        margin: 0 0 0 150px;
        position: relative;
        list-style: none;
        text-align: left;
        max-width: calc(100% - 300px);

        .event {
            border-bottom: 1px dashed var(--gray-middle);
            padding: 25px 0 25px 50px;
            position: relative;
            border-left: 3px solid var(--gray-dimmer);

            .headline {
                @extend %font-body1 !optional;
                font-weight: bold;
                margin-bottom: 5px;
            }

            .subHeadline {
                @extend %font-caption !optional;
                margin-bottom: 10px;

                &:empty {
                    display: none;
                }
            }

            .content {
                @extend %font-body2 !optional;

                &:empty {
                    display: none;
                }

                .info_message {
                    @extend %font-caption !optional;
                    margin-top: 10px;
                    border-left: 2px solid var(--gray-middle);
                    padding: 10px;
                }
            }

            &:before,
            &:after {
                position: absolute;
                display: block;
                top: 0;
            }

            &:before {
                @extend %font-caption !optional;
                line-height: 1.8em;
                left: -145px;
                content: attr(data-date);
                text-align: right;
                min-width: 120px;
                top: 25px;
            }

            &:after {
                -webkit-box-shadow: 0 0 0 3px var(--gray-dimmer);
                box-shadow: 0 0 0 3px var(--gray-dimmer);
                left: -6.2px;
                background: #fff;
                border-radius: 50%;
                height: 9px;
                width: 9px;
                content: "";
                top: 32px;
            }

            &.status_info {
                border-left-color: var(--info);

                &:after {
                    -webkit-box-shadow: 0 0 0 3px var(--info);
                    box-shadow: 0 0 0 3px var(--info);
                }
            }

            &.status_success {
                border-left-color: var(--success);

                &:after {
                    -webkit-box-shadow: 0 0 0 3px var(--success);
                    box-shadow: 0 0 0 3px var(--success);
                }
            }

            &.status_error {
                border-left-color: var(--danger);

                &:after {
                    -webkit-box-shadow: 0 0 0 3px var(--danger);
                    box-shadow: 0 0 0 3px var(--danger);
                }
            }

            &.status_warning {
                border-left-color: var(--warning);

                &:after {
                    -webkit-box-shadow: 0 0 0 3px var(--warning);
                    box-shadow: 0 0 0 3px var(--warning);
                }
            }
        }

        .file-grid {
            display: grid;
            column-gap: 8px;
            grid-template-columns: auto auto;
            max-width: 100%;
            word-wrap: anywhere;
        }
    }
</style>
