<template>
    <NdxPageFrame>
        <TaskDetail
            v-if="task !== null"
            :task="task"
            @update-data-model="updateDataModel"
        />
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import TaskDetail from "../../components/workflows/taskDetail/TaskDetail.vue";

    export default {
        name: 'WorkflowsTaskDetail',
        components: {TaskDetail, NdxPageFrame},
        data() {
            return {
                task: null
            };
        },
        watch: {
            '$route.params': {
                handler: 'loadTask',
                deep: true,
                immediate: true
            }
        },
        methods: {
            updateDataModel(updatedModel) {
                this.task = updatedModel;
            },
            loadTask() {
                let taskId = null;
                if (this.$route.params && 'taskId' in this.$route.params) {
                    taskId = this.$route.params.taskId;
                }

                if (taskId === null) {
                    return;
                }

                this.$store.dispatch('workflowTask/findTask', {
                    taskId: taskId,
                    historyFallback: true
                }).then((result) => {
                    this.task = result;
                }).catch((error) => {
                    console.error(error);
                });
            }
        }
    };
</script>
