<template>
    <div class="table-responsive">
        <table class="table" v-if="product.quantityStrategy.type === 'MinMaxStep'">
            <thead>
                <tr>
                    <th class="min-width text-nowrap">{{ $t('label.minOrderQuantity') }}</th>
                    <th class="min-width text-nowrap">{{ $t('label.maxOrderQuantity') }}</th>
                    <th class="min-width text-nowrap">{{ $t('label.stepOrderQuantity') }}</th>
                    <th class="text-nowrap" :class="{'min-width': showAdditionalPrices}">
                        <template v-if="displayQuantity === 1">
                            {{ $t('label.priceUnit') }}
                        </template>
                        <template v-else>
                            {{ $t('label.priceXUnit', {x: displayQuantity}) }}
                        </template>
                    </th>
                    <th class="text-nowrap" v-if="showAdditionalPrices">{{ additionalPriceText }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(item, idx) in getDisplayList()"
                    :key="idx"
                >
                    <td class="min-width text-nowrap">
                        {{ Math.max(1, item.min) }} {{ quantityUnit }}
                    </td>
                    <td class="min-width text-nowrap">
                        {{ item.max === 0 ? $t('label.inifinty') : item.max + ' ' + quantityUnit }}
                    </td>
                    <td class="min-width text-nowrap">
                        {{ item.step }} {{ quantityUnit }}
                    </td>
                    <td class="text-nowrap" :class="{'min-width': showAdditionalPrices}">
                        <template v-if="displayQuantity === 1">
                            {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-else>
                            {{ formatPrice(item.displayPrice) }} / {{displayQuantity}} {{quantityUnit}}
                        </template>
                    </td>
                    <td class="text-nowrap" v-if="showAdditionalPrices">
                        {{ formatPrice(item.additionalPrice) }}
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table" v-else-if="product.quantityStrategy.type === 'Discrete'">
            <thead>
                <tr>
                    <th class="min-width text-nowrap">{{ $t('label.quantity') }}</th>
                    <th class="min-width text-nowrap">
                        <template v-if="displayQuantity === 1">
                            {{ $t('label.priceUnit') }}
                        </template>
                        <template v-else>
                            {{ $t('label.priceXUnit', {x: displayQuantity}) }}
                        </template>
                    </th>
                    <th class="min-width text-nowrap" v-if="showAdditionalPrices">{{ additionalPriceText }}</th>
                    <th class="text-nowrap">{{ $t('label.price') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(item, idx) in getDisplayListConcrete()"
                    :key="idx"
                >
                    <td class="min-width text-nowrap">
                        {{ item.quantity }} {{ quantityUnit }}
                    </td>
                    <td class="min-width text-nowrap">
                        <template v-if="displayQuantity === 1">
                            {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-else>
                            {{ formatPrice(item.displayPrice) }} / {{displayQuantity}} {{quantityUnit}}
                        </template>
                    </td>
                    <td class="min-width text-nowrap" v-if="showAdditionalPrices">
                        {{ formatPrice(item.additionalPrice) }}
                    </td>
                    <td class="text-nowrap">
                        {{ formatPrice(item.price) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import {PriceFormatter} from "../../../plugins/formatter";

    export default {
        name: 'QuantityStrategy',
        data() {
            return {
                priceList: {}
            };
        },
        computed: {
            ...mapState({
                product: state => state.productDetail.product,
                currency: state => state.shop.currency,
                shopAdditionalPriceText: state => state.shop.additionalPriceText
            }),
            displayQuantity() {
                return this.product.displayQuantity || 1;
            },
            quantityUnit() {
                return this.product?.quantityUnit?.shortName ?? this.$t('units.short.pieces');
            },
            showAdditionalPrices() {
                for (let i in this.priceList) {
                    if (this.priceList[i].additionalPrice > 0) {
                        return true;
                    }
                }
                return false;
            },
            additionalPriceText() {
                if (this.product.additionalCostsText && this.product.additionalCostsText.length > 0) {
                    return this.product.additionalCostsText;
                }
                if (this.shopAdditionalPriceText && this.shopAdditionalPriceText.length > 0) {
                    return this.shopAdditionalPriceText;
                }
                return this.$t('label.additionalPrice');
            }
        },
        watch: {
            product: {
                immediate: true,
                handler: 'getPrices'
            }
        },
        methods: {
            getPrices() {
                if (this.product) {
                    this.$store.dispatch('products/getPrices', {
                        pciId: this.product.id
                    }).then((priceList) => {
                        this.priceList = priceList;
                    }).catch((error) => {
                        console.error(error);
                        this.priceList = {};
                    });
                } else {
                    this.priceList = {};
                }
            },
            formatPrice(value) {
                if (!isNaN(parseFloat(value))) {
                    return PriceFormatter(value, this.currency, this);
                }

                return '';
            },
            getDisplayList() {
                let list = [];

                for (const quantity in this.priceList) {
                    const orderScaleEntry1 = this.product.quantityStrategy.data.find((entry) => {
                        return quantity > 0 && (
                            entry.min <= quantity && entry.max >= quantity ||
                            entry.min <= quantity && entry.max === 0
                        );
                    });
                    const orderScaleEntry2 = this.product.quantityStrategy.data.find((entry) => {
                        return quantity > 0 && (
                            entry.min < quantity && entry.max >= quantity ||
                            entry.min < quantity && entry.max === 0
                        );
                    });

                    const orderScaleEntry = orderScaleEntry1 || orderScaleEntry2;

                    if (orderScaleEntry) {
                        const x = Math.ceil((quantity - orderScaleEntry.min) / orderScaleEntry.step);
                        const minQuantity = orderScaleEntry.min + (x * orderScaleEntry.step);

                        list.push({
                            orderScaleEntry: orderScaleEntry,
                            min: minQuantity,
                            max: orderScaleEntry.max,
                            step: orderScaleEntry.step,
                            price: this.priceList[quantity].unitPrice * minQuantity,
                            unitPrice: this.priceList[quantity].unitPrice,
                            displayPrice: this.priceList[quantity].unitPrice * this.displayQuantity,
                            additionalPrice: this.priceList[quantity].additionalPrice
                        });
                    }
                }

                list.forEach((entry, idx) => {
                    const nextEntry = list.length > idx + 1 ? list[idx + 1] : null;

                    if (nextEntry) {
                        if (entry.orderScaleEntry.min === nextEntry.orderScaleEntry.min) {
                            entry.max = Math.max(1, nextEntry.min - nextEntry.step);
                        } else {
                            entry.max = entry.orderScaleEntry.max;
                        }
                    }

                });

                return list;
            },
            getDisplayListConcrete() {
                let list = [];

                for (const quantity in this.priceList) {
                    if (quantity > 0) {
                        let price = (this.priceList[quantity].unitPrice * quantity) ;
                        price += this.priceList[quantity].additionalPrice;

                        list.push({
                            quantity: quantity,
                            price: price,
                            unitPrice: this.priceList[quantity].unitPrice,
                            displayPrice: this.priceList[quantity].unitPrice * this.displayQuantity,
                            additionalPrice: this.priceList[quantity].additionalPrice
                        });
                    }
                }

                return list;
            }
        }
    };
</script>
