<template>
    <div>
        <div>
            <span class="key">{{ $t('label.type') }}</span>
            <span class="value">{{ businessObject.businessKey.namespace }}</span>
        </div>
        <div>
            <span class="key">{{ $t('label.' + businessObject.businessKey.type) }}:</span>
            <span class="value">{{ $t('label.id') }} {{ businessObject.businessKey.id }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MissingBo',
        props: {
            businessObject: {
                type: Object
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../../../style/variables_ndx";

    .key {
        @extend %font-caption;
    }

    .value {
        @extend %font-h3;
        padding-left: 8px;
    }
</style>
