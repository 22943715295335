<template>
    <div :class="{'all-white': allWhite}">
        <div>{{ $t('message.passwordChangeCriteria') }}</div>
        <br />
        <div :class="{error: form.newPassword.hasAlpha.$invalid}">
            <NdxIcon icon="done-circle" size="s" v-if="!form.newPassword.hasAlpha.$invalid"/>
            <NdxIcon icon="error-filled" size="s" v-else/>
            <span>{{ $t('label.passwordChange.char') }}</span>
        </div>
        <div :class="{error: form.newPassword.hasNumeric.$invalid}">
            <NdxIcon icon="done-circle" size="s" v-if="!form.newPassword.hasNumeric.$invalid"/>
            <NdxIcon icon="error-filled" size="s" v-else/>
            <span>{{ $t('label.passwordChange.num') }}</span>
        </div>
        <div :class="{error: form.newPassword.hasSpecialChar.$invalid}">
            <NdxIcon icon="done-circle" size="s" v-if="!form.newPassword.hasSpecialChar.$invalid"/>
            <NdxIcon icon="error-filled" size="s" v-else/>
            <span>{{ $t('label.passwordChange.special') }}</span>
        </div>
        <div :class="{error: !hasValue || form.newPassword.minLength.$invalid}">
            <NdxIcon icon="done-circle" size="s" v-if="hasValue && !form.newPassword.minLength.$invalid"/>
            <NdxIcon icon="error-filled" size="s" v-else/>
            <span>{{ $t('label.passwordChange.length') }}</span>
        </div>
        <div :class="{error: !hasValue || form.newPassword.hasNoWhiteSpace.$invalid}">
            <NdxIcon icon="done-circle" size="s" v-if="hasValue && !form.newPassword.hasNoWhiteSpace.$invalid"/>
            <NdxIcon icon="error-filled" size="s" v-else/>
            <span>{{ $t('label.passwordChange.noWhite') }}</span>
        </div>
        <div :class="{error: !hasValue || form.newPassword.notSameAs.$invalid}">
            <NdxIcon icon="done-circle" size="s" v-if="hasValue && !form.newPassword.notSameAs.$invalid"/>
            <NdxIcon icon="error-filled" size="s" v-else/>
            <span>{{ $t('label.passwordChange.diffPassword') }}</span>
        </div>
        <div :class="{error: !hasValue || form.confirmPassword.sameAs.$invalid}">
            <NdxIcon icon="done-circle" size="s" v-if="hasValue && !form.confirmPassword.sameAs.$invalid"/>
            <NdxIcon icon="error-filled" size="s" v-else/>
            <span>{{ $t('label.passwordChange.passwordRepeat') }}</span>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "../library/NdxIcon";
    export default {
        name: 'PasswordChangeCriteria',
        components: {NdxIcon},
        props: ['form', 'allWhite'],
        computed: {
            hasValue() {
                return !this.form.newPassword.required.$invalid;
            }
        }
    };
</script>

<style scoped lang="scss">
    .all-white {
        .bi {
            color: white !important;
        }
    }
    .bi {
        margin-right: 8px;
        color: var(--bs-success);
    }
    .error {
        font-weight: 800;
        .bi {
            color: var(--bs-danger);
        }
    }
</style>
