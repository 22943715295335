<template>
    <NdxPageHeader
        hide-filter
        v-model:view-mode="viewMode"
        view-mode-storage-key="favoritesViewMode"
        :nav-name="$store.getters['shop/getWgcsByType']('favorites')[0].label"
    />
    <template v-if="products.length">
        <div class="card-container" :class="{['variant-' + viewMode]: true}">
            <NdxProductCard
                v-for="product in products"
                :key="product.id"
                :card-img="product.image"
                :card-img-background-color="product.imageBgColor"
                :title="product.name"
                :tags="getTags(product.id)"
                :description="product.listingDescription"
                :price="product.minBasePrice"
                :price-tag="product.priceTag"
                :list-price="product.listPrice"
                :currency="currency"
                :quantity="product.displayQuantity || 1"
                :quantityUnit="product.quantityUnit"
                :label="product.label"
                :actions="getCardActions(product)"
                :variant="viewMode"
                :bundleParent="product.bundleParent"
                @card-click="() => gotoDetailPage(product)"
            />
        </div>
    </template>
    <div v-else-if="!isInitializing" class="d-flex justify-content-center">
        <NdxNotification
            variant="info"
            :duration="0"
            :modelValue="true"
            :message="$t('message.noFavorites')"
        />
    </div>

    <div class="notificationWrapper">
        <NdxNotification
            variant="info"
            :duration="5"
            v-model="addToCartSucceeded"
            :message="$t('message.addToCartSucceeded', {url: getBasketUrl()})"
        />
        <NdxNotification
            variant="success"
            :duration="5"
            v-model="addToWatchlistSucceeded"
            :message="$t('message.addToWatchlistSucceeded', {url: getWatchlistUrl()})"
        />
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { stripHtmlTags } from "@utilities/ndxText";
    import NdxProductCard from '../library/NdxProductCard';
    import NdxNotification from "../library/NdxNotification";
    import TagsForProduct from "../../mixins/TagsForProduct";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: "FavoritesList",
        components: {NdxPageHeader, NdxNotification, NdxProductCard},
        mixins: [TagsForProduct],
        data() {
            return {
                products: [],
                viewMode: 'card',
                isInitializing: true,
                addToCartSucceeded: false,
                addToWatchlistSucceeded: false
            };
        },
        computed: {
            ...mapGetters('shop', [
                'currency'
            ]),
        },
        watch: {
            viewMode(mode) {
                if (mode === 'list') {
                    this.updateTags(this.products);
                }
            }
        },
        created() {
            this.getList();
        },
        methods: {
            stripHtmlTags,
            getBasketUrl() {
                const data = this.$store.getters['shop/getRouteByType']('basket');
                return data ? this.$router.resolve(data).href : '';
            },
            getWatchlistUrl() {
                const data = this.$store.getters['shop/getRouteByType']('watchlist');
                return data ? this.$router.resolve(data).href : '';
            },
            getList() {
                this.$store.dispatch('products/getFavorites').then((list) => {
                    this.products = list;
                    this.isInitializing = false;
                });
            },
            getCardActions(product) {
                let actions = [
                    {
                        icon: 'heart-filled',
                        title: this.$t('label.defavorizeProduct'),
                        action: () => this.defavorizeProduct(product)
                    }
                ];

                if (product.isDirectlyOrderable) {
                    actions.push({
                        icon: 'basket-add',
                        title: this.$t('label.addToBasket'),
                        action: () => this.addToBasket(product)
                    });
                }

                return actions;
            },
            defavorizeProduct(product) {
                this.$store.dispatch('products/toggleFavorite', {
                    pciId: product.id,
                    favorize: false
                }).then(() => this.getList());
            },
            gotoDetailPage(product) {
                this.$router.push({name: 'Product', params: {pid: product.id}});
            },
            addToBasket(product) {
                this.$store.dispatch('basket/addToBasket', {
                    productId: product.id,
                    quantity: 1
                }).then((result) => {
                    if (result.campaignRunId) {
                        this.addToWatchlistSucceeded = true;
                    } else {
                        this.addToCartSucceeded = true;
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~bootstrap/scss/bootstrap-utilities";

    .card-container {
        gap: 16px;
    }

    .card-container.variant-card {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }

    @include media-breakpoint-up(md) {
        .card-container.variant-card {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        }
    }
</style>
