import shopApi from "../../api/shop";

//state
const state = {
    widgetCache: {}
};

// getters
const getters = {};

// actions
const actions = {
    getWidgets({state, commit}, params) {
        return new Promise((resolve, reject) => {
            const key = JSON.stringify(params.parentOid);
            if (key in state.widgetCache) {
                resolve(state.widgetCache[key]);
            } else {
                shopApi.getWidgets(params.parentOid)
                    .then((data) => {
                        commit('ADD_WIDGET_CACHE_ENTRY', {
                            key: key,
                            widgets: data
                        });
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    },
    clearWidgetCache({commit}) {
        commit('RESET');
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.widgetCache = {};
    },
    ADD_WIDGET_CACHE_ENTRY(state, data) {
        state.widgetCache[data.key] = data.widgets;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
