<template>
    <NdxPageFrame>
        <NewsContent />
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import NewsContent from "../../components/news/News";

    export default {
        name: 'News',
        components: {NdxPageFrame, NewsContent}
    };
</script>
