<template>
    <DataCheckWireframe>
        <NdxPageFrame>
            <template #pageTop>
                <DataCheckInfos />
            </template>
            <DataCheckForm />
        </NdxPageFrame>
    </DataCheckWireframe>
</template>

<script>
    import DataCheckWireframe from '../../wireframes/NdxDataCheck';
    import DataCheckForm from "../../components/dataCheck/DataCheck";
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import DataCheckInfos from "../../components/dataCheck/DataCheckInfos";

    export default {
        name: 'DataCheck',
        components: {DataCheckInfos, NdxPageFrame, DataCheckForm, DataCheckWireframe}
    };
</script>
