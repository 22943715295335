<template>
    <div class="view-wrapper">
        <div v-if="viewMode === 'card'" @click="() => switchMode('list')" class="viewToggle pt-3 pb-3">
            <NdxIcon icon="view-preview-filled"/>
            <NdxIcon icon="view-list"/>
        </div>
        <div v-else @click="() => switchMode('card')" class="viewToggle pt-3 pb-3">
            <NdxIcon icon="view-preview"/>
            <NdxIcon icon="view-list-filled"/>
        </div>
    </div>
</template>

<script>
import NdxIcon from './NdxIcon';

export default {
    name: "NdxListHeader",
    components: {NdxIcon},
    emits: ['update:viewMode'],
    props: {
        viewMode: {
            Type: String,
            default: 'card',
            validator(value) {
                return ['card', 'list'].includes(value);
            }
        }
    },
    methods: {
        switchMode(newMode) {
            this.$emit('update:viewMode', newMode);
        }
    }
};
</script>

<style scoped>
    .view-wrapper {
        width: 100%;
        display: block;
        padding-bottom: 32px;
    }
    .viewToggle {
        cursor: pointer;
        display: flex;
        gap: 8px;
    }
</style>
