import VueSocketIO from 'socket.io-client';

const options = {
    'reconnection': true,
    'reconnectionDelay': 1000,
    'reconnectionDelayMax' : 5000,
    'reconnectionAttempts': Infinity,
    'withCredentials': false
};
const namespace = 'jobQueue';

let socket = null;
let socketUrl = null;
let subdomain = null;

export default function createJobQueueSocket() {
    return (store) => {
        store.subscribe(() => {
            if (socket === null &&
                store.getters['session/isLoggedIn'] === true &&
                store.getters['session/getWebSocketUrl'] !== null
            ) {
                console.log('init ' + namespace + '  websocket');

                subdomain = store.getters['session/getSubdomain'];
                socketUrl = store.getters['session/getWebSocketUrl'] + namespace;
                socket = VueSocketIO(socketUrl, options);

                socket.on('connect', function () {
                    console.log('connected: ' + subdomain);
                    console.log('socketUrl: ' + socketUrl);
                    if (subdomain) {
                        socket.emit('subdomain', subdomain);
                    }
                });
                socket.on('reconnect_attempt', function () {
                    console.log('websocket reconnect attempt');
                    console.log('socketUrl: ' + socketUrl);
                });

                socket.on('progress', (data) => {
                    console.log(namespace + ' - progress');
                    console.log(data);
                    if ('jobInfo' in data) {
                        store.dispatch('jobQueue/progress', data.jobInfo);
                    }
                });
            }
        });
    };
}
