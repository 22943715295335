<template>
    <NdxPageFrame>
        <FavoritesList />
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import FavoritesList from "../../components/favorites/FavoritesList.vue";

    export default {
        name: 'Favorites',
        components: {FavoritesList, NdxPageFrame},
    };
</script>
