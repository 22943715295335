/**
 * convert cmyk color to rgb for simple preview use
 *
 * @param value cmyk(0.1, 0.03, 0.45, 0.23)
 * @param normalized bool true := values between 0 and 255 are returned otherwise between 0 and 1
 * @returns {{r: number, b: number, g: number}}
 */
export function cmyk2rgb(value, normalized = false) {
    let color = value
        .replace('cmyk', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .split(',');
    color = color.map(v => parseInt((parseFloat(v) * 100), 10));

    let c = color[0];
    let m = color[1];
    let y = color[2];
    let k = color[3];

    c = (c / 100) * (1 - (k / 100)) + (k / 100);
    m = (m / 100) * (1 - (k / 100)) + (k / 100);
    y = (y / 100) * (1 - (k / 100)) + (k / 100);

    let r = 1 - c;
    let g = 1 - m;
    let b = 1 - y;

    if (!normalized) {
        r = Math.round(255 * r);
        g = Math.round(255 * g);
        b = Math.round(255 * b);
    }
    return {r: r, g: g, b: b};
}

/**
 *
 * @param hex e.g. #70ab37
 * @returns {{h: number, s: number, l: number}} useable for "hsl(" + h + "," + s + "%," + l + "%)";
 */
export function hex2hsl(hex) {
    const splittedHexValue = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const r = parseInt(splittedHexValue[1], 16) / 255;
    const g = parseInt(splittedHexValue[2], 16) / 255;
    const b = parseInt(splittedHexValue[3], 16) / 255;
    const cmax = Math.max(r, g, b);
    const cmin = Math.min(r, g, b);
    const delta = cmax - cmin;

    let h = 0,
        s = 0,
        l = 0;

    // Calculate hue
    // No difference
    if (delta === 0)
        h = 0;
    // Red is max
    else if (cmax === r)
        h = ((g - b) / delta) % 6;
    // Green is max
    else if (cmax === g)
        h = (b - r) / delta + 2;
    // Blue is max
    else
        h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    // Make negative hues positive behind 360°
    if (h < 0)
        h += 360;


    // Calculate lightness
    l = (cmax + cmin) / 2;

    // Calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return {h, s, l};
}
