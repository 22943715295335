<template>
    <div
        v-for="newsItem in news"
        :key="newsItem.id"
        class="d-flex gap-3 pt-3 pb-3 border-bottom item"
        @click="() => gotoNewsItemDetail(newsItem.id)"
    >
        <div class="avatar">
            <NdxAvatar
                :src="newsItem.avatar ? '/ndx/file/downloadResized/100/100/' + newsItem.avatar.filename : null"
            />
        </div>
        <div :class="{read: newsItem.readByLoggedUser}" class="flex-grow-1">
            <div class="d-flex justify-content-between mb-2 gap-2 headline">
                <div>{{ $t('label.news.type_' + newsItem.type) }}</div>
                <div>
                    {{ $d(new Date(newsItem.createdAt), 'long') }}
                    <NdxIcon
                        v-if="newsItem.attachments?.length"
                        icon="attachment"
                    />
                </div>
            </div>
            <div class="d-grid">
                <div class="title">{{ newsItem.title }}</div>
                <div class="content">{{ newsItem.description }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "../../library/NdxIcon";
    import NdxAvatar from "../../library/NdxAvatar";

    export default {
        name: "NewsOverview",
        components: {NdxAvatar, NdxIcon},
        props: {
            news: {
                type: Array,
                required: true
            }
        },
        methods: {
            gotoNewsItemDetail(newsItemId) {
                this.$router.push({
                    name: 'NewsDetail',
                    params: {
                        newsId: newsItemId
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../style/variables_ndx";

    .item {
        cursor: pointer;

        &:hover .title {
            color: var(--bs-primary) !important;
        }
    }

    .headline {
        @extend %font-h4;
    }

    .title {
        @extend %font-h2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .content {
        @extend %font-body2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .avatar {
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .read * {
        color: var(--bs-disabled);
    }
</style>
