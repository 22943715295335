<template>
    <div v-swap="html"></div>
</template>

<script>
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'Vhtml',
        directives: {
            swap: {
                mounted(el, binding) {
                    // createContextualFragment allows script execution
                    // why you would probably want to sanitize the html
                    // e.g. using https://github.com/cure53/DOMPurify
                    //let safe = DOMPurify.sanitize(binding.value);
                    let safe = binding.value;

                    let frag = document.createRange().createContextualFragment(safe);
                    el.replaceWith(frag);
                }
            }
        },
        props: {
            html: {
                type: String,
                required: true
            }
        }
    });
</script>
