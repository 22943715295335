import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'order';

export default {
    getOrdersForProduct(productId, offset, limit) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getOrdersForProduct', [+productId, +offset, +limit], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    filterBy(criteria, offset, limit) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'filterBy', [criteria, offset, limit], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    find(orderId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'find', [+orderId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getDownloadableFilesBulk(orderItemIds) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getDownloadableFilesBulk', [orderItemIds], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getOrderHistoryByProductAndDateRange(list) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getOrderHistoryByProductAndDateRange', [list], throbberRules.NONE)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
