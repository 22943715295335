<template>
    <template v-if="!hideChild">
        <BundleTree
            v-if="child.bundleProduct"
            ref="bundleTree"
            in-tree
            :type="type"
            :bundle="child"
            :order-items="orderItems"
            :level="level+1"
            @refresh-list="$emit('refresh-list')"
            @open-project-run="(item) => $emit('open-project-run', item)"
            @goto-detailpage="(item) => $emit('goto-detailpage', item)"
            @action-required="(prop) => handleActionRequired('tree', prop)"
        />
        <BasketGroupItem
            v-else-if="(type === 'basket' || type === 'configurator') && child.orderItemId"
            :type="type"
            :item="findOrderItem(child.orderItemId)"
            in-tree
            @action-required="(prop) => handleActionRequired('item', prop)"
            @refresh-basket="$emit('refresh-list')"
        />
        <WatchlistItem
            v-else-if="type === 'watchlist' && child.orderItemId"
            :item="findOrderItem(child.orderItemId)"
            :bundle-id="child.bundleId"
            in-tree
            @open-project-run="(item) => $emit('open-project-run', item)"
            @goto-detailpage="(item) => $emit('goto-detailpage', item)"
            @action-required="(prop) => handleActionRequired('item', prop)"
        />
    </template>
</template>

<script>
    import { defineAsyncComponent } from 'vue';
    import WatchlistItem from "../../../watchlist/WatchlistItem.vue";
    import BasketGroupItem from "../../../basket/parts/BasketGroupItem.vue";

    export default {
        name: 'BundleChild',
        components: {
            BasketGroupItem,
            WatchlistItem,
            BundleTree: defineAsyncComponent(() => import("./BundleTree.vue")),
        },
        emits: ['refresh-list', 'action-required', 'open-project-run', 'goto-detailpage'],
        props: {
            child: {
                type: Object,
                required: true
            },
            type: {
                type: String,
                validator: function (value) {
                    return ['watchlist', 'basket', 'configurator'].indexOf(value) !== -1;
                }
            },
            orderItems: {
                type: Array
            },
            level: {
                type: Number,
                default: function () {
                    return 0;
                }
            }
        },
        data() {
            return {
                actionRequiredTree: false,
                actionRequiredItem: false
            };
        },
        computed: {
            hideChild() {
                // in watchlist there is no quantity information
                const quantity = this.$store.getters['basket/getOrderItemById'](this.child.orderItemId)?.quantity;
                const isBundleDetailPage = ['BundleItemDetail', 'ProductsInCategory', 'Product'].includes(
                    this.$route.name
                );

                return typeof quantity === 'number' && +quantity === 0 && !isBundleDetailPage;
            }
        },
        methods: {
            findOrderItem(orderItemId) {
                return this.orderItems.find(item => item.id === orderItemId || item.orderItemId === orderItemId);
            },
            handleActionRequired(type, value) {
                if (type === 'tree') {
                    this.actionRequiredTree = value;
                } else {
                    this.actionRequiredItem = value;
                }
                this.sendActionRequiredEvent();
            },
            sendActionRequiredEvent() {
                const actionRequired = this.actionRequiredTree || this.actionRequiredItem;
                this.$emit('action-required', actionRequired);
            },

            toggleExpandAll(expand) {
                if (this.$refs.bundleTree) {
                    this.$refs.bundleTree.toggleExpandAll(expand);
                }
            }
        }
    };
</script>
