<template>
    <div class="ndx-form-element ndx-search">
        <div class="d-flex align-items-center">
            <NdxIcon icon="search" size="m"/>
            <input
                class="flex-grow-1"
                type="text"
                :placeholder="$t('label.doSearch')"
                :value="modelValue"
                :readonly="disabled"
                :disabled="disabled"
                @input="(evt) => $emit('update:model-value', evt.target.value)"
                @keyup.enter="(evt) => $emit('on-key-enter', evt.target.value)"
            />
            <span
                v-if="modelValue && removable"
                class="removable"
                @click="$emit('on-remove-value')">x</span>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "../NdxIcon";

    export default {
        name: 'NdxSearchInput',
        components: {NdxIcon},
        emits: ['on-key-enter', 'update:model-value', 'on-remove-value'],
        props: {
            modelValue: {
                type: String
            },
            disabled: {
                type: Boolean
            },
            removable: {
                type: Boolean
            }
        }
    };
</script>

<style lang="scss">
.removable {
    cursor: pointer;
    border-radius: 50%;
    background-color: darkgrey;
    color: white;
    width: 16px;
    height: 16px;
    line-height: 14px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
}
</style>/
