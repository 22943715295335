<template>
    <div
        class="w-100 ndx-form-element ndx-date-picker"
        :class="{
            'error': invalid,
            'disabled': readonly,
            'focused': focused,
            'right': alignRight,
            [variant]: true
        }"
    >
        <div v-if="label && label.length > 0" class="label">{{ label }}</div>
        <div v-if="description && description.length" class="description">{{ description }}</div>
        <div class="datePreviewWrapper d-flex align-items-center">
            <Datepicker
                v-model="compSelected"
                :week-starts-on="1"
                input-class="ndxDatePickerInput"
                :upper-limit="disabledDates.upperLimit"
                :lower-limit="disabledDates.lowerLimit"
                :disabled="readonly"
                :locale="language"
                :input-format="dateFormat"
                :typeable="false"
                @focusin="focused = true"
                @focusout="focused = false"
            />
            <span class="actions" v-show="!readonly && clearButton && selected !== null">
                <NdxIcon icon="close" @click="clearDate" is-action/>
            </span>
        </div>
    </div>
</template>

<script>
    /** https://icehaunter.github.io/vue3-datepicker/ */
    import Datepicker from 'vue3-datepicker';
    import { enUS, de } from 'date-fns/locale';
    import { ndxDateConvert } from "@utilities/ndxDate";
    import NdxIcon from "../NdxIcon";

    export default {
        name: 'NdxDatePicker',
        components: {NdxIcon, Datepicker},
        props: {
            readonly: {
                type: Boolean,
                default: false
            },
            label: {
                type: String
            },
            description: {
                type: String
            },
            modelValue: {
                type: [String, Date, Object],
                required: false
            },
            minDate: {
                type: [String, Date, Object],
                required: false
            },
            maxDate: {
                type: [String, Date, Object],
                required: false
            },
            invalid: {
                type: Boolean,
                default: false
            },
            clearButton: {
                type: Boolean,
                default: false
            },
            setNoon: {
                type: Boolean,
                default: true
            },
            variant: {
                type: String,
                default: 'primary',
                validator: function (value) {
                    return ['primary', 'secondary'].indexOf(value) !== -1;
                }
            }
        },
        emits: ['update:modelValue'],
        data() {
            return {
                languages: {
                    en: enUS,
                    de: de
                },
                selected: this.modelValue,
                focused: false,
                alignRight: false
            };
        },
        computed: {
            compSelected: {
                get() {
                    return ndxDateConvert(this.selected);
                },
                set(newVal) {
                    if (newVal && this.setNoon) {
                        newVal.setHours(12);
                    }
                    this.selected = newVal;
                    this.$emit('update:modelValue', this.selected);
                }
            },
            disabledDates: function () {
                let ret = {};

                if (this.minDate) {
                    ret.lowerLimit = ndxDateConvert(this.minDate);
                }

                if (this.maxDate) {
                    ret.upperLimit = ndxDateConvert(this.maxDate);
                }

                return ret;
            },
            language: function () {
                if (this.$i18n &&
                    'locale' in this.$i18n &&
                    this.$i18n.locale in this.languages
                ) {
                    return this.languages[this.$i18n.locale];
                }
                return this.languages.de;
            },
            dateFormat: function () {
                let dateFormat = 'dd.MM.yyyy';

                if (this.$i18n && this.$i18n.locale === 'en') {
                    dateFormat = 'yyyy-MM-dd';
                }

                return dateFormat;
            }
        },
        watch: {
            modelValue(newVal) {
                this.selected = newVal;
            }
        },
        updated() {
            // calculate with to position the dropdown left or right
            // the datePicker dropdown has a width of 280px
            this.alignRight = this.$el.offsetWidth < 281;
        },
        methods: {
            clearDate() {
                this.compSelected = null;
            }
        }
    };
</script>
