function _getParentListKey(categoryId, rootCategoryId) {
    return rootCategoryId + '_' + categoryId;
}

function _getParentList(state, categoryId, rootCategoryId) {
    // categoryId and rootCategoryId are equal -> list is empty
    if (+categoryId === +rootCategoryId) {
        return [];
    }

    // list already in parentListCache
    const key = _getParentListKey(categoryId, rootCategoryId);
    if (key in state.parentListCache) {
        return state.parentListCache[key];
    }

    // list can be calculated of existing parentListCache
    for (let key in state.parentListCache) {
        if (+key.split('_')[0] === +rootCategoryId) {
            const list = _getParentSubList(state.parentListCache[key], categoryId);
            if (list !== null) {
                return list;
            }
        }
    }

    // list can be calculated of existing category cache
    if (rootCategoryId in state.cache) {
        const list = _getCacheSublist(state.cache[rootCategoryId]['children'], categoryId);
        if (list !== null) {
            return list;
        }
    }

    return null;
}

function _getParentSubList(list, categoryId) {
    let subList = [];
    for (let item of list) {
        subList.push(item);
        if (+item.id === +categoryId) {
            return subList;
        }
    }
    return null;
}

function _getCacheSublist(list, categoryId) {
    for (let item of list) {
        let list = [{
            id: item.id,
            name: item.name
        }];
        if (+item.id === +categoryId) {
            return list;
        }
        let subList = _getCacheSublist(item.children, categoryId);
        if (subList !== null) {
            return [...list, ...subList];
        }
    }
    return null;
}

function _getCachedParentCategoryId(state, categoryId) {
    // get parent id by existing category cache
    let parentId = _getParentCategoryIdByCache({id: null, children: state.cache}, categoryId);
    if (parentId !== null) {
        return parentId;
    }

    // get parentId by existing parentListCache
    for (let key in state.parentListCache) {
        const list = _getParentSubList(state.parentListCache[key], categoryId);
        if (list !== null && list.length > 1) {
            return list[list.length - 2].id;
        } else if (list !== null && list.length === 1) {
            return +key.split('_')[0];
        }
    }

    return null;
}

function _getParentCategoryIdByCache(item, categoryId) {
    const children = Object.values(item.children);
    for (const child of children) {
        if (child.id === categoryId) {
            return item.id;
        }
        if (child.children?.length) {
            const result = _getParentCategoryIdByCache(child, categoryId);
            if (result !== null) {
                return result;
            }
        }
    }

    return null;
}

export const state = () => ({
    cache: {},
    parentListCache: {}
});

export const getters = {
    getCachedSubtree: (state) => (rootId) => {
        if (rootId in state.cache) {
            return state.cache[rootId];
        }
        return null;
    },
    getParentList: (state) => (categoryId, rootCategoryId) => {
        return _getParentList(state, categoryId, rootCategoryId) || [];
    }
};

export const buildActions = (api) => {
    return {
        getSubtree({state, commit}, data) {
            return new Promise((resolve, reject) => {
                const rootId = data.rootId;
                const depth = data.depth || 3;

                if (rootId in state.cache) {
                    resolve(state.cache[rootId]);
                } else {
                    api.getSubtree(rootId, depth)
                        .then((subTree) => {
                            commit('ADD_CACHE_ENTRY', {
                                rootId: rootId,
                                subTree: subTree
                            });
                            resolve(subTree);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }
            });
        },
        getSubtreeBulk({state, commit}, data) {
            return new Promise((resolve, reject) => {
                let result = [];
                let ids = [];

                for (let id of data.idList) {
                    if (id in state.cache) {
                        result.push(state.cache[id]);
                    } else {
                        ids.push(id);
                    }
                }

                if (ids.length > 0) {
                    api.getSubtreeBulk(ids)
                        .then((list) => {
                            for (let rootId in list) {
                                commit('ADD_CACHE_ENTRY', {
                                    rootId: rootId,
                                    subTree: list[rootId]
                                });
                                result.push(list[rootId]);
                            }
                            resolve(result);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                } else {
                    resolve(result);
                }
            });
        },
        getParentList({state, commit}, {categoryId, rootCategoryId}) {
            return new Promise((resolve, reject) => {
                const list = _getParentList(state, categoryId, rootCategoryId);
                const key = _getParentListKey(categoryId, rootCategoryId);

                if (list !== null) {
                    commit('ADD_PARENT_LISTCACHE_ENTRY', {
                        key: key,
                        list: list
                    });
                    resolve();
                } else {
                    api.getParentList(categoryId, rootCategoryId)
                        .then((list) => {
                            commit('ADD_PARENT_LISTCACHE_ENTRY', {
                                key: key,
                                list: list
                            });
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }
            });
        },
        getParentCategoryId({state, commit}, {currentCategoryId}) {
            return new Promise((resolve, reject) => {
                let parentCategoryId = _getCachedParentCategoryId(state, currentCategoryId);

                if (parentCategoryId !== null) {
                    resolve(parentCategoryId);
                } else {
                    api.getParentId(currentCategoryId)
                        .then((parentCategoryTree) => {
                            parentCategoryId = null;
                            if (parentCategoryTree) {
                                parentCategoryId = parentCategoryTree.id;
                                commit('ADD_CACHE_ENTRY', {
                                    rootId: parentCategoryId,
                                    subTree: parentCategoryTree
                                });
                            }
                            resolve(parentCategoryId);
                        })
                        .catch((error) => {
                            reject(error);
                            console.log(error);
                        });
                }
            });
        }
    };
};

export const mutations = {
    RESET() {
        state.cache = {};
        state.parentListCache = {};
    },
    ADD_CACHE_ENTRY(state, data) {
        state.cache[data.rootId] = data.subTree;
    },
    ADD_PARENT_LISTCACHE_ENTRY(state, data) {
        state.parentListCache[data.key] = data.list;
    }
};
