import fileApi from "../../api/file.js";

//state
const state = {};

// getters
const getters = {};

// actions
const actions = {
    find(ctx, data) {
        return new Promise((resolve, reject) => {
            fileApi.find(
                data.id
            ).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    generateSecureDownloadLink(ctx, data) {
        return new Promise((resolve, reject) => {
            fileApi.generateSecureDownloadLink(
                data.filename, data.expirationTime
            ).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
};

// mutations
const mutations = {
    RESET () {
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
