<template>
    <div class="ndx-throbber" :class="{showSpinner: hasSpinner}">
        <div v-if="hasSpinner">
            <img
                :src="logo"
                alt="logo"
                class="img-fluid main-logo"
            >
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";

    export default {
        name: 'NdxThrobber',
        props: {
            showSpinner: {
                type: Boolean
            }
        },
        computed: {
            ...mapState({
                logo: state => state.shop.logo
            }),
            hasSpinner() {
                return this.showSpinner && this.logo && this.logo.length > 0;
            }
        }
    };
</script>
