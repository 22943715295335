<template>
    <NdxWorkflowTimeline
        :timeline-data="getFilteredTimelineData"
        :ndx-icon="ndxIconComponent"
    />
</template>

<script>
    import NdxWorkflowTimeline from "@library/NdxWorkflowTimeline.vue";
    import NdxIcon from "../library/NdxIcon.vue";

    export default {
        name: "WorkflowInstanceTimeline",
        components: {NdxWorkflowTimeline},
        props: {
            timelineData: {
                type: Object
            }
        },
        data() {
            return {
                ndxIconComponent: NdxIcon
            };
        },
        computed: {
            getFilteredTimelineData() {
                return {...this.timelineData, ...{timeline: this.getFilteredTimeline()}};
            }
        },
        methods: {
            getFilteredTimeline() {
                return this.timelineData.timeline.filter((item) => {
                    return item.oid.type !== 'incident';
                });
            }
        }
    };
</script>
