<template>
    <NdxPageFrame>
        <SearchResultsView />
    </NdxPageFrame>
</template>

<script>
    import SearchResultsView from '../../components/search/SearchResults';
    import NdxPageFrame from "../../components/library/NdxPageFrame";

    export default {
        name: "SearchResults",
        components: {NdxPageFrame, SearchResultsView}
    };
</script>
