<template>
    <TagList
        v-if="activeTags.length > 0"
        class="activeTagContainer"
        :tags="activeTags"
        removable
        @remove-tag="toggleTag"
    />
    <TagList
        v-if="inactiveTags.length > 0"
        class="inactiveTagContainer"
        :tags="inactiveTags"
        @open-tag="toggleTag"
    />
</template>

<script>
    import { mapGetters } from "vuex";
    import tagApi from "../../api/tags";
    import TagList from "../library/TagList.vue";

    export default {
        name: 'TagsWidget',
        components: {TagList},
        props: {
            widget: Object
        },
        data() {
            return {
                tags: []
            };
        },
        computed: {
            ...mapGetters('search', [
                'tagIds'
            ]),
            activeTags() {
                return this.tags.filter((tag) => {
                    return this.tagIds.length > 0 && this.tagIds.includes(tag.id);
                });
            },
            inactiveTags() {
                return this.tags.filter((tag) => {
                    return this.tagIds.length === 0 || !this.tagIds.includes(tag.id);
                });
            }
        },
        mounted() {
            this.$store.dispatch('tags/getTags', {
                type: tagApi.FQCN.product
            }).then((result) => {
                if (!this.widget.config.preselectTags) {
                    this.tags = result;
                } else {
                    // show only tags configured by admin
                    let selectedTags = [];
                    result.forEach((tag) => {
                        let idx = this.widget.config.tagIds.findIndex((id) => {
                            return id === tag.id;
                        });
                        if (idx >= 0) {
                            selectedTags.push(tag);
                        }
                    });

                    this.tags = selectedTags;
                }
            }).catch((error) => {
                console.error(error);
            });
        },
        methods: {
            toggleTag(tag) {
                if (this.tagIds.includes(tag.id)) {
                    this.$store.dispatch('search/removeTagId', tag.id);
                } else {
                    this.$store.dispatch('search/addTagId', tag.id);
                    this.$router.push({name: 'SearchResults'});
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .activeTagContainer + .inactiveTagContainer {
        border-top: 1px solid var(--bs-gray-middle);
        margin-top: 16px;
        padding-top: 16px;
    }
</style>
