<template>
    <NdxPageFrame>
        <Component :is="activeComponent"/>
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import MyAccountComponent from "../../components/myAccount/MyAccount";
    import MyAccountUser from "../../components/myAccount/UserData";
    import MyAccountClient from "../../components/myAccount/ClientData";
    import MyAccountAddresses from "../../components/myAccount/Addresses";
    import MyAccountUserSettings from "../../components/myAccount/UserSettings";
    import MyAccountSubstitutes from "../../components/myAccount/Substitutes";
    import MyAccountAbsence from "../../components/myAccount/Absence";

    export default {
        name: 'MyAccount',
        components: {NdxPageFrame},
        computed: {
            activeComponent() {
                if (this.$route.name === 'MyAccountUser') {
                    return MyAccountUser;
                }
                if (this.$route.name === 'MyAccountClient') {
                    return MyAccountClient;
                }
                if (this.$route.name === 'MyAccountAddresses') {
                    return MyAccountAddresses;
                }
                if (this.$route.name === 'MyAccountUserSettings') {
                    return MyAccountUserSettings;
                }
                if (this.$route.name === 'MyAccountSubstitutes') {
                    return MyAccountSubstitutes;
                }
                if (this.$route.name === 'MyAccountAbsence') {
                    return MyAccountAbsence;
                }
                return MyAccountComponent;
            }
        },
        beforeUnmount() {
            // menu and widgets may contain text variables referencing user and client values
            this.$store.dispatch('shop/loadMenu');
            this.$store.dispatch('session/reloadPortalCustomization');
            this.$store.dispatch('widgets/clearWidgetCache');
        }
    };
</script>
