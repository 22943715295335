import { createApp } from 'vue';
import PortalApp from './PortalApp.vue';
import router from './router';
import store from './store';
import i18n from './plugins/i18n';

import 'bootstrap/dist/js/bootstrap.esm.js';

const app = createApp(PortalApp);

// /////////////////////////////////////////////////////////////////////////////////////////
// Directives
import { ObserveVisibility } from 'vue-observe-visibility';
import WindowResize from '@directives/window-resize';
import clickOutside from '@directives/click-outside';
import scrollIntoView from "@directives/scroll-into-view";

app.directive('observe-visibility', {
    beforeMount: (el, binding, vnode) => {
        vnode.context = binding.instance;
        ObserveVisibility.bind(el, binding, vnode);
    },
    update: ObserveVisibility.update,
    unmounted: ObserveVisibility.unbind
});

app.directive('window-resize', WindowResize);
app.directive('click-outside', clickOutside);
app.directive('scroll-into-view', scrollIntoView);

// Directives
// /////////////////////////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////////////////////////
// appTracking

import VueGtag from "vue-gtag";

app.use(VueGtag, {
    bootstrap: false
});

// appTracking
// /////////////////////////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////////////////////////
// Global Components

// todo

// Global Components
// /////////////////////////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////////////////////////
// Global Mixins

// mixin to get rid of Vues private _uid @see https://github.com/vuejs/vue/issues/5886#issuecomment-308647738
let ndxUuid = 1;
app.mixin({
    data() {
        return {};
    },
    beforeCreate() {
        this.ndxUuid = ndxUuid.toString();
        ndxUuid += 1;
    }
});
// Global Mixins
// /////////////////////////////////////////////////////////////////////////////////////////

app.use(store)
    .use(router(store))
    .use(i18n);

app.mount('#app');
