<template>
    <table v-bind="$attrs" class="list-key-table">
        <tbody>
            <tr
                v-for="(productFeature, idx) in productFeatures"
                :key="idx"
            >
                <th class="listKey align-top">{{ productFeature.name }}:</th>
                <td class="listValue align-top">
                    <template v-if="productFeature.value">
                        <template v-if="['input'].includes(productFeature.type)">
                            {{ productFeature.valuesTranslated || productFeature.value }}
                        </template>
                        <template v-if="['text'].includes(productFeature.type)">
                            <div
                                v-for='(line, idx) in
                                    (productFeature.valuesTranslated || productFeature.value).split("\n")'
                                :key="idx"
                            >{{ line }}</div>
                        </template>
                        <template v-if="['select'].includes(productFeature.type)">
                            {{ productFeature.valuesTranslated ||
                                productFeature.availableValues[productFeature.value]
                            }}
                        </template>
                        <template v-else-if="['selectMulti'].includes(productFeature.type)">
                            {{ productFeature.value.map(val => productFeature.availableValues[val]).join(', ') }}
                        </template>
                        <template v-else-if="['checkbox'].includes(productFeature.type)">
                            <NdxIcon v-if="productFeature.value === '1'" icon="done"/>
                            <NdxIcon v-else icon="close"/>
                        </template>
                        <template v-else-if="['date'].includes(productFeature.type)">
                            {{ $d(new Date(productFeature.value), 'short') }}
                        </template>
                        <span
                            v-else-if="['file'].includes(productFeature.type)"
                            @click="() => showPreview(productFeature)"
                            :class="{previewable: previewType(productFeature)}"
                        >
                            {{ productFeature.value.originalFilename }}
                            <NdxIcon icon="preview" v-if="previewType(productFeature)" size="s"/>
                        </span>
                    </template>
                    <!-- orderDetail view -->
                    <template v-if="productFeature.valuesTranslated">
                        <template v-if="['selectMulti'].includes(productFeature.type)">
                            <template v-if="Array.isArray(productFeature.valuesTranslated.value)">
                                {{ productFeature.valuesTranslated.value.join(', ') }}
                            </template>
                            <template v-else>
                                {{ productFeature.valuesTranslated.value }}
                            </template>
                        </template>
                    </template>
                    <template v-else-if="productFeature.values">
                        <template v-if="['selectMulti'].includes(productFeature.type)">
                            {{ productFeature.values.value.join(', ') }}
                        </template>
                    </template>
                </td>
            </tr>
        </tbody>
    </table>
    <NdxImageOverlay v-model="imagePreviewFile"/>
    <NdxFileOverlay v-model="pdfPreviewFile"/>
</template>

<script>
    import NdxImageOverlay from "../../library/NdxImageOverlay";
    import NdxFileOverlay from "../../library/NdxFileOverlay";
    import NdxIcon from "../../library/NdxIcon";
    export default {
        name: "ProductFeaturesList",
        components: {NdxIcon, NdxFileOverlay, NdxImageOverlay},
        props: {
            productFeatures: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                imagePreviewFile: null,
                pdfPreviewFile: null,
            };
        },
        methods: {
            previewType(productFeature) {
                // eslint-disable-next-line no-prototype-builtins
                if (!productFeature.value.hasOwnProperty('originalFilename')) {
                    return null;
                }
                const fileEnding = productFeature.value.originalFilename.substr(
                    productFeature.value.originalFilename.lastIndexOf('.') + 1
                );
                const file = productFeature.value.filename;
                const imageFormats = ['bmp', 'svg', 'png', 'jpeg', 'jpg', 'gif', 'tif', 'tiff'];
                const pdfFormats = ['pdf'];
                const supportPreviewFormats = imageFormats.concat(pdfFormats);

                if (file && supportPreviewFormats.includes(fileEnding)) {
                    if (pdfFormats.includes(fileEnding)) {
                        return 'pdf';
                    } else if (imageFormats.includes(fileEnding)) {
                        return 'image';
                    }
                }
            },
            showPreview(productFeature) {
                const file = productFeature.value.filename;

                switch (this.previewType(productFeature)) {
                    case 'pdf':
                        this.pdfPreviewFile = file;
                        break;
                    case 'image':
                        this.imagePreviewFile = {filename: file};
                        break;
                }
            }
        }
    };
</script>

<style scoped>
    .previewable {
        cursor: pointer;
    }
</style>
