import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'tag';

const FQCN = {
    product: 'products'
};

export default {
    find(id, throbberType = throbberRules.CONTENTAREA) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'find', [+id], throbberType)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getTagsByIdsOfSameType(ids, type, throbberType = throbberRules.CONTENTAREA) {
        return new Promise((resolve, reject) => {
            if (ids.length > 0) {
                ndxRpc(service, 'getTagsByIdsOfSameType', [ids, type], throbberType)
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                resolve([]);
            }
        });
    },
    getReferencedTags(type, throbberType = throbberRules.CONTENTAREA) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getReferencedTags', [type], throbberType)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    FQCN: FQCN
};
