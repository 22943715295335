import workflowInstanceHistoryApi from "../../api/workflowInstanceHistory";

//state
const state = {};

// getters
const getters = {};

// actions
const actions = {
    filterBy(ctx, {filterCriteria, offset, limit, orderBy}) {
        return new Promise((resolve, reject) => {
            workflowInstanceHistoryApi.filterBy(
                filterCriteria.filterDescription,
                orderBy.sortBy,
                orderBy.sortAsc,
                filterCriteria.searchText,
                offset,
                limit
            ).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    find(ctx, data) {
        return new Promise((resolve, reject) => {
            workflowInstanceHistoryApi.find(data.id)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// mutations
const mutations = {
    RESET() {
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
