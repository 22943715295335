<template>
    <NdxPageHeader
        hide-view-mode
        :breadcrumbs="breadcrumbs"
        :active-route-callback="() => $router.push({name: 'Budgets'})"
        :nav-name="$t('label.budget_products')"
        :active-filter="activeFilter"
        v-model:show-filter="showFilter"
    />

    <NdxFlyIn v-if="showFilter" class="limit500">
        <template #title>
            {{ $t('label.filterHeadline') }}
        </template>
        <template #default>
            <div class="d-flex gap-3 flex-column">
                <NdxSearchInput
                    class="w-100"
                    v-model="searchStr"
                    icon="search"
                    variant="secondary"
                    @on-key-enter="reloadProducts"
                />
                <NdxSelect
                    :options="budgetOptions"
                    :text-wrap="true"
                    v-model="selectedBudget"
                    variant="secondary"
                />
            </div>
        </template>
        <template #buttons>
            <NdxButtonLink
                class="btnFlex"
                @click="clearFilter"
            >
                {{ $t('btn.reset') }}
            </NdxButtonLink>
            <NdxButton class="btnFlex" @click="showFilter = false">
                {{ $t('btn.close') }}
            </NdxButton>
        </template>
    </NdxFlyIn>

    <div v-if="!loading && list.length === 0" class="empty-result">
        {{ $t('message.noSearchHits') }}
    </div>

    <div
        v-if="!loading && list.length > 0"
        class="card-container viewmode-card"
    >
        <NdxProductCard
            v-for="product in list"
            :key="product.id"
            :card-img="product.image"
            :card-img-background-color="product.imageBgColor"
            :title="product.name"
            :description="product.listingDescription"
            :price="product.minBasePrice"
            :price-tag="product.priceTag"
            :list-price="product.listPrice"
            :currency="currency"
            :quantity="product.displayQuantity || 1"
            :quantityUnit="product.quantityUnit"
            :label="product.label"
            :bundleParent="product.bundleParent"
            @card-click="() => gotoDetailPage(product)"
        />
    </div>
    <div
        v-if="listCount > limit"
        class="d-flex justify-content-center align-items-center mt-5"
    >
        <NdxButcon icon="drop-left" :disabled="offset === 0" @click="previousPage"/>
        <NdxButcon
            icon="drop-right"
            class="ms-5"
            :disabled="(offset + 1) * limit >= listCount"
            @click="nextPage"
        />
    </div>
</template>

<script>
    import NdxSelect from "../library/formElements/NdxSelect";
    import NdxSearchInput from "../library/formElements/NdxSearchInput";
    import NdxProductCard from "../library/NdxProductCard";
    import { mapGetters } from "vuex";
    import NdxButcon from "../library/NdxButcon";
    import NdxFlyIn from "../library/NdxFlyIn.vue";
    import NdxButtonLink from "../library/NdxButtonLink.vue";
    import NdxButton from "../library/NdxButton.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: 'BudgetProducts',
        components: {
            NdxPageHeader, NdxButton, NdxButtonLink, NdxFlyIn, NdxButcon, NdxProductCard,
            NdxSearchInput, NdxSelect
        },
        data() {
            return {
                showFilter: false,

                loading: true,

                searchStr: '',

                limit: 12,
                offset: 0,
                list: [],
                listCount: 0,

                budgetList: []
            };
        },
        computed: {
            ...mapGetters('shop', [
                'currency'
            ]),
            breadcrumbs() {
                return [{
                    label: this.$t('label.budget_products'),
                    click: this.activeFilter ? () => this.clearFilter() : null
                }];
            },
            activeFilter() {
                return this.searchStr.length > 0 || this.selectedBudget > 0;
            },
            selectedBudget: {
                get() {
                    return +this.$route.params.budgetId > 0 ? +this.$route.params.budgetId : -1;
                },
                set(newId) {
                    this.$router.push({
                        name: 'BudgetProducts',
                        params: {
                            id: this.$route.params.id,
                            budgetId: newId > 0 ? newId : ''
                        }
                    });
                }
            },
            budgetOptions() {
                let options = [{
                    value: -1, text: this.$t('label.budgets_all')
                }];

                for (let idx in this.budgetList) {
                    options.push({
                        value: this.budgetList[idx].id,
                        text: this.budgetList[idx].name
                    });
                }

                return options;
            }
        },
        watch: {
            selectedBudget() {
                this.reloadProducts();
            }
        },
        mounted() {
            this.loadBudgets();
            this.loadProducts();
        },
        methods: {
            loadBudgets() {
                this.$store.dispatch('budgets/getBudgets', {
                    type: 'active',
                    limit: 999999,
                    offset: 0
                }).then((result) => {
                    this.budgetList = result;
                }).catch((error) => {
                    console.error(error);
                });
            },
            reloadProducts() {
                this.offset = 0;
                this.loadProducts();
            },
            loadProducts() {
                this.loading = true;

                let criteria = {};
                if (this.selectedBudget > 0) {
                    criteria['budgetId'] = this.selectedBudget;
                }
                if (this.searchStr.length > 0) {
                    criteria['searchStr'] = this.searchStr;
                }

                this.$store.dispatch('budgets/getProducts', {
                    criteria: criteria,
                    limit: this.limit,
                    offset: this.offset
                }).then((result) => {
                    this.list = result.list;
                    this.listCount = result.count;
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    this.loading = false;
                });
            },
            gotoDetailPage(product) {
                this.$router.push({name: 'Product', params: {pid: product.id}});
            },
            previousPage() {
                this.offset = this.offset - this.limit;
                if (this.offset < 0) {
                    this.offset = 0;
                }
                this.loadProducts();
            },
            nextPage() {
                this.offset = this.offset + this.limit;
                this.loadProducts();
            },

            clearFilter() {
                this.showFilter = false;
                this.searchStr = '';
                this.selectedBudget = -1;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_bootstrap";
    @import "../../style/variables_ndx";
    @import "~bootstrap/scss/mixins/breakpoints";

    .form-wrapper {
        display: flex;
        align-items: center;

        .ndx-form-element + .ndx-form-element {
            margin-left: 8px;
        }
    }

    .empty-result {
        @extend %font-caption;
        margin-top: 32px;
    }

    .card-container {
        margin-top: 32px;
    }

    .card-container.viewmode-card {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 16px;
    }

    @include media-breakpoint-up(md) {
        .card-container.viewmode-card {
            grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
        }
    }
</style>
