<template>
    <NdxPageFrame>
        <DocumentsList />
    </NdxPageFrame>
</template>

<script>
import NdxPageFrame from "../../components/library/NdxPageFrame";
import DocumentsList from "../../components/documents/DocumentList.vue";

export default {
    name: 'Documents',
    components: {DocumentsList, NdxPageFrame},
};
</script>
