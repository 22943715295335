<template>
    <div
        class="widgetWrapper"
        v-observe-visibility="{
            callback: function (isVisible) {
                visibilityChanged(isVisible)
            },
            intersection: {
                threshold: .9
            }
        }"
    >
        <div
            v-if="showWidgetLabel(widget)"
            class="widgetLabel"
            :class="{toggleable: toggleable}"
            @click="toggleOpenState"
        >
            {{ widget.name }}
            <NdxIcon v-if="toggleable" icon="drop-down" :flip-v="isOpen" class="pt-1" />
        </div>
        <div
            v-if="renderWidget || noObserver"
            v-show="isOpen"
            class="widgetContent"
            :style="computedStyle"
        >
            <component
                :widget="widget"
                v-bind="dynamicProps"
                :is="componentMap[widget.type]"
            />
        </div>
    </div>
</template>

<script>
    import { shallowRef } from "vue";

    // user widgets
    import HtmlFragmentWidget from "./HtmlFragmentWidget";
    import ProductListWidget from "./ProductListWidget";
    import CategoriesWidget from "./CategoriesWidget";
    import NotificationsWidget from "./NotificationsWidget";
    import BricksWidget from "./BricksWidget";
    import AssetsWidget from "./AssetsWidget";
    import SliderWidget from "./SliderWidget";
    import TagsWidget from "./TagsWidget";
    import MessageWidget from "./MessageWidget";
    import ForumWidget from "./ForumWidget";
    import VueAppWidget from "./VueAppWidget";
    import OutputHandler from "./OutputHandler";

    // system widgets
    import BudgetSummary from "./system/BudgetWidget";
    import ProductCategoryTreeWidget from "./system/ProductCategoryTreeWidget";
    import Orders from "./system/OrdersWidget";
    import Favorites from "./system/FavoritesWidget";
    import Watchlist from "./system/WatchlistWidget";
    import AssetCategoryTreeWidget from "./system/AssetCategoryTreeWidget.vue";
    import MyAccountNavWidget from "./system/MyAccountNavWidget";
    import NewsCategoryWidget from "./system/NewsCategoryWidget";
    import BudgetsNavigation from "./system/BudgetsNavigation";
    import BackBtn from "./system/BackBtn.vue";

    import BasketSummary from "../basket/parts/BasketSummary";
    import CheckoutSummary from "../checkout/steps/parts/CheckoutSummary";
    import SearchResultWidget from "./system/SearchResultWidget";
    import OrderDetailWidget from "../orders/detail/OrderDetailWidget";
    import NdxIcon from "../library/NdxIcon.vue";
    import WorkflowNavWidget from "./system/WorkflowNavWidget.vue";

    export default {
        name: 'BaseWidget',
        components: {NdxIcon},
        props: {
            widget: Object,
            parentWidth: Number,
            position: String,
            // if set, the widget is always rendered. The visibilityObserver has no effect
            noObserver: Boolean
        },
        data() {
            return {
                renderWidget: false,
                componentMap: {
                    // user widgets
                    'Categories': shallowRef(CategoriesWidget),
                    'ProductList': shallowRef(ProductListWidget),
                    'HtmlFragment': shallowRef(HtmlFragmentWidget),
                    'Notifications': shallowRef(NotificationsWidget),
                    'Bricks': shallowRef(BricksWidget),
                    'Styleguides': shallowRef(AssetsWidget),
                    'Slider': shallowRef(SliderWidget),
                    'Tags': shallowRef(TagsWidget),
                    'Message': shallowRef(MessageWidget),
                    'Forum': shallowRef(ForumWidget),
                    'VueApp': shallowRef(VueAppWidget),
                    'OutputHandler': shallowRef(OutputHandler),

                    // system widgets
                    'BasketBudgetSummary': shallowRef(BudgetSummary),
                    'ProductCategoryTreeWidget': shallowRef(ProductCategoryTreeWidget),
                    'Orders': shallowRef(Orders),
                    'Favorites': shallowRef(Favorites),
                    'Watchlist': shallowRef(Watchlist),
                    'AssetCategoryTreeWidget': shallowRef(AssetCategoryTreeWidget),
                    'MyAccountNavWidget': shallowRef(MyAccountNavWidget),
                    'NewsNavWidget': shallowRef(NewsCategoryWidget),
                    'SearchResultWidget': shallowRef(SearchResultWidget),
                    'BudgetsNavigation': shallowRef(BudgetsNavigation),
                    'BackBtn': shallowRef(BackBtn),
                    'WorkflowNavWidget': shallowRef(WorkflowNavWidget),

                    'BasketSummary': shallowRef(BasketSummary),
                    'checkoutSummary': shallowRef(CheckoutSummary),
                    'OrderSummary': shallowRef(OrderDetailWidget),
                },
                computedStyle: '',
                isOpen: true
            };
        },
        computed: {
            toggleable() {
                return ['Tags'].includes(this.widget.type);
            },
            dynamicProps() {
                let props = {};
                const parentWidth = ['OutputHandler'];

                // ATTENTION: There are edge cases where widgets trigger a re-rendering inside the browser which
                // causes a change of the width
                if (parentWidth.indexOf(this.widget.type) > -1) {
                    props['parent-width'] = this.parentWidth;
                }

                const positionProp = ['Notifications', 'OutputHandler', 'Styleguides'];
                if (positionProp.indexOf(this.widget.type) > -1) {
                    props['position'] = this.position;
                }

                return props;
            }
        },
        watch: {
            parentWidth: {
                immediate: true,
                handler: 'setComputedStyle'
            }
        },
        created() {
            this.isOpen = this.widget.initiallyOpen || !this.showWidgetLabel(this.widget);
        },
        methods: {
            setComputedStyle() {
                let styles = [];

                // sliders and carousels needs a fixed width, so they don't overflow
                const widthRelevant = ['top', 'center', 'bottom'];
                if (this.parentWidth && this.parentWidth > 0 && widthRelevant.indexOf(this.position) > -1) {
                    styles.push('width: ' + this.parentWidth + 'px;');
                }

                this.computedStyle = styles.join(', ');
            },
            // load widget component only if widget is visible!
            visibilityChanged(isVisible) {
                if (isVisible) {
                    this.renderWidget = true;
                }
            },
            showWidgetLabel(widget) {
                let blackList = [];

                if (['Categories', 'ProductList'].indexOf(widget.type) > -1 && widget.config.version === 1) {
                    blackList.push(widget.type);
                }

                return widget.displayName && blackList.indexOf(widget.type) < 0;
            },
            toggleOpenState() {
                this.isOpen = !this.isOpen;
            }
        }
    };
</script>
