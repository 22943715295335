<template>
    <div class="ndx-tree-nav-widget">
        <div
            class="rootNode"
            @click="onItemClick(null)"
        >
            <span class="node-text">{{ wgc.label }}</span>
        </div>

        <template
            v-for="type in searchables"
            :key="type"
        >
            <div
                v-if="isAllowed(type)"
                class="childNode"
                @click="onItemClick(type)"
                :class="{active: filter === type}"
            >
                <span>{{ $t('label.' + type) }}</span>
                <NdxIcon
                    v-if="isRunning(type)"
                    icon="search"
                />
                <NdxBadge v-else>{{ resultCount(type) }}<span v-if="loadMore(type)">+</span></NdxBadge>
            </div>
        </template>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import NdxIcon from "../../library/NdxIcon";
    import NdxBadge from "../../library/NdxBadge";

export default {
    name: "SearchResultWidget",
    components: {NdxBadge, NdxIcon},
    data() {
        return {
            searchables: ['product', 'productCategory', 'assetLibrary', 'assetEntry']
        };
    },
    computed: {
        ...mapGetters('search', [
            'allowProductCategorySearch',
            'allowProductSearch',
            'allowAssetLibrarySearch',
            'allowAssetEntrySearch',
            'assetLibraryResult',
            'assetGroupResult',
            'productCategoryLoadMore',
            'productLoadMore',
            'assetEntryLoadMore'
        ]),
        ...mapState({
            productCategorySearchRunning: state => state.search.productCategorySearchRunning,
            productCategoryCount: state => state.search.productCategoryCount,
            productCount: state => state.search.productCount,
            assetLibraryCount: state => state.search.assetLibraryCount,
            assetEntryCount: state => state.search.assetEntryCount,
            productSearchRunning: state => state.search.productSearchRunning,
            assetLibrarySearchRunning: state => state.search.assetLibrarySearchRunning,
            assetEntrySearchRunning: state => state.search.assetEntrySearchRunning,
            filter: state => state.search.resultDisplayFilter,
            wgc: state => state.shop.systemPages?.find(entry => entry.type === 'search') ?? {}
        }),
    },
    methods: {
        isAllowed(type) {
            return this['allow' + type.substr(0, 1).toUpperCase() + type.substr(1) + 'Search'];
        },
        isRunning(type) {
            return this[type + 'SearchRunning'];
        },
        resultCount(type) {
            return this[type + 'Count'] || 0;
        },
        loadMore(type) {
            const key = type + 'LoadMore';
            return key in this ? this[key] : false;
        },
        onItemClick(type) {
            this.$store.dispatch('search/setResultFilter', type);
        }
    }
};
</script>

<style scoped lang="scss">
    @import "../../../style/variables_bootstrap";
    @import "../../../style/variables_ndx";
    @import "~bootstrap/scss/mixins/breakpoints";

    @include media-breakpoint-down(md) {
        .ndx-tree-nav-widget {
            display: grid;
            white-space: nowrap;
            grid-template-columns: repeat(2, auto);
            gap: 12px;
        }
        .rootNode {
            display: none
        }
        .childNode {
            border-left: none;
            height: auto;
            margin: 0;
            padding: 8px;
        }
        .childNode:hover {
            border-bottom-width: 4px;
            padding-bottom: 5px;
        }
        .childNode.active {
            border-bottom: 4px var(--bs-gray-dark) solid;
            padding-bottom: 5px;
        }
    }
</style>
