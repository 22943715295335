<template>
    <div class="ndx-tree-nav-widget">
        <div
            class="startNode"
            @click="onClick"
        >
            <NdxIcon icon="arrow-left"/>
            <span class="node-text">
                {{ $t('btn.back') }}
            </span>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "../../library/NdxIcon.vue";

    export default {
        name: 'BackBtn',
        components: {NdxIcon},
        methods: {
            onClick() {
                if (this.$route.name === 'OrderItemDetail') {
                    this.$router.push(this.$store.getters['shop/getRouteByType']('basket'));
                } else {
                    this.$router.go(-1);
                }
            }
        }
    };
</script>
