<template>
    <NdxPageFrame>
        <NdxButton @click="gotoOrders" class="mb-3 d-md-none">{{ $t('btn.openOrders') }}</NdxButton>
        <OrderDetail :orderId="+$route.params.orderId">
            <template #message>
                <div class="success-label my-3">{{ $t('label.orderSuccess') }}</div>
            </template>
        </OrderDetail>
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import OrderDetail from "../../components/orders/detail/OrderDetail";
    import NdxButton from "../../components/library/NdxButton";

    export default {
        name: 'OrderSuccess',
        components: {NdxButton, OrderDetail, NdxPageFrame},
        methods: {
            gotoOrders() {
                const route = this.$store.getters['shop/getRouteByType']('orders');

                this.$router.push(route);
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .success-label {
        @extend %font-h2;
    }
</style>
