<template>
    <template v-if="Object.values(additionalCostList).length > 0">
        <div
            v-for="(cost, hash) in additionalCostList"
            :key="hash"
        >
            <template v-if="isSummary">
                {{ $t('label.plusAdditionalCosts', {txt: cost.txt}) }}
            </template>
            <template v-else>
                {{ $t('label.additionalCosts', {txt: cost.txt, value: formatPrice(cost.value)}) }}
            </template>
        </div>
    </template>

    <template v-if="displayDeliveryCosts">
        <template v-if="showDeliveryCosts">
            <div v-if="deliveryCosts > 0">
                {{ $t('label.deliveryCosts', {value: formatPrice(deliveryCosts)}) }}
            </div>
            <div v-else-if="deliveryCosts === null">
                {{ $t('label.plus_delivery') }}
            </div>
            <div v-if="priceTags.length">
                <div v-for="(priceTag, idx) in priceTags" :key="idx">
                    {{ priceTag }}
                </div>
            </div>
        </template>
        <div v-else-if="deliveryCostsAltText?.length">
            {{ deliveryCostsAltText }}
        </div>
    </template>

    <div
        v-for="budget in relevantBudgets"
        :key="budget.id"
    >
        <template v-if="isSummary">
            {{ $t('label.lessBudget', {txt: budget.name}) }}
        </template>
        <template v-else>
            {{ $t('label.lessBudgetCosts', {txt: budget.name, value: formatPrice(parseFloat(budget.value))}) }}
        </template>
    </div>
</template>

<script>
    import { PriceFormatter } from "../../../../plugins/formatter";
    import { mapGetters, mapState } from "vuex";

    export default {
        name: 'SubPriceInfo',
        props: {
            group: {
                type: Object
            },
            items: {
                type: Array
            },
            budgets: {
                type: Array
            },
            isSummary: {
                type: Boolean
            },
            forcedCurrency: {
                type: String,
                default: null
            }
        },
        computed: {
            ...mapState({
                showDeliveryCosts: state => state.shop.deliveryCosts?.show,
                deliveryCostsAltText: state => state.shop.deliveryCosts?.alternativeText,
                shopAdditionalPriceText: state => state.shop.additionalPriceText
            }),
            ...mapGetters('shop', {
                currency: 'currency',
            }),
            additionalCostList() {
                let list = {};

                for (let item of this.items) {
                    if (item.additionalPriceNet && parseFloat(item.additionalPriceNet) > 0) {
                        let value = parseFloat(item.additionalPriceNet);

                        let txt = this.$t('label.additionalPrice');
                        if (item.additionalCostsText && item.additionalCostsText.length > 0) {
                            txt = item.additionalCostsText;
                        } else if (this.shopAdditionalPriceText && this.shopAdditionalPriceText.length > 0) {
                            txt = this.shopAdditionalPriceText;
                        }

                        let txtHash = this.hashCode(txt);

                        if (!(txtHash in list)) {
                            list[txtHash] = {
                                txt: txt,
                                value: 0
                            };
                        }

                        list[txtHash].value += value;
                    }
                }

                return list;
            },
            relevantBudgets() {
                return this.budgets.filter((budget) => {
                    return ('isGift' in budget && budget.isGift)
                        || ('modus' in budget && budget.modus === 'gift'); // orderDetails
                });
            },
            displayDeliveryCosts() {
                if ('deliveryCost' in this.group) { // orderDetails
                    return this.showDeliveryCosts && this.group.items.reduce((show, item) => {
                            const product = this.$store.getters['orders/getProduct'](item.productId);
                            return show || !('hideDelivery' in product);
                        },
                        false
                    );
                }

                return this.showDeliveryCosts &&
                    this.group.items.reduce((show, item) => show || !item.hideDelivery, false);
            },
            deliveryCosts() {
                if ('deliveryOption' in this.group) {
                    return this.group.deliveryOption.costs;
                } else if ('deliveryCost' in this.group) { // orderDetails
                    return parseFloat(this.group.deliveryCost);
                } else if ('summary' in this.group) {
                    return this.group.summary.deliveryCosts;
                }
                return 0;
            },
            priceTags() {
                if ('deliveryOption' in this.group) {
                    return this.group?.deliveryOption?.priceTag !== null ? [this.group.deliveryOption.priceTag] : [];
                } else if ('deliveryCost' in this.group) { // orderDetails
                    const deliveryOption = this.$store.getters['orders/getDeliveryOptionByPricelistId'](
                        this.group.deliveryOptionPricelistId
                    );
                    const priceList = deliveryOption.pricelists.find(
                        pl => pl.deliveryOptionPriceListId == this.group.deliveryOptionPricelistId
                    );

                    return 'priceTag' in priceList ? [priceList.priceTag] : [];
                } else if ('summary' in this.group) {
                    return this.group?.summary?.deliveryOptionPriceTags ?? [];
                }
                return (this.group?.summary?.deliveryOptionPriceTags || this.group?.deliveryOption?.priceTag ) ?? [];
            }
        },
        methods: {
            hashCode(str) {
                let hash = 0;
                if (str.length === 0) {
                    return hash;
                }
                for (let i = 0; i < str.length; i++) {
                    let chr   = str[i].charCodeAt();
                    hash  = ((hash << 5) - hash) + chr;
                    hash |= 0; // Convert to 32bit integer
                }
                return hash;
            },
            formatPrice(value) {
                const _currency = this.forcedCurrency ? this.forcedCurrency : this.currency;
                return PriceFormatter(value, _currency, this);
            }
        }
    };
</script>
