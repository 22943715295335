<template>
    <NdxPageHeader
        v-if="!isDataCheck"
        hide-filter
        hide-view-mode
        :nav-name="getLabel('Client', this)"
        :breadcrumbs="breadcrumbs"
    />
    <div class="myAccount-data-container" v-if="initiated && showClientTab && client !== null">
        <div v-if="isDataCheck" class="page-label">{{ getLabel('Client', this) }}</div>

        <div class="myAccount-data">
            <template
                v-for="item of list"
                :key="item.id"
            >
                <div v-if="item.type === 'headline'" class="myAccount-data-headline">
                    {{ item.label }}
                </div>

                <template v-else-if="item.type === 'NDX_ADDRESS'">
                    <div class="myAccount-data-item">
                        <div
                            class="control has-editor"
                            @click="onEditAddress(item.userDefinedName, clientMainAddress)"
                        >
                            <div class="key">
                                {{ item.userDefinedName }}
                                <div v-if="!isAddressValid(clientMainAddress)" class="key invalid p-0 pt-2">
                                    {{ $t('message.invalidAddressError') }}
                                </div>
                            </div>
                            <div class="value-wrapper">
                                <div class="value">
                                    <div
                                        class="text-end preserveLineBreaks"
                                    >
                                        {{ formatAddress(clientMainAddress) }}
                                    </div>
                                </div>
                                <NdxIcon class="editor-toggle" icon="drop-right"/>
                            </div>
                        </div>
                    </div>
                </template>
                <DataItem
                    v-else
                    :item="item"
                    type="client"
                    v-model="client"
                    @is-valid="(val) => validate(item.id,val)"
                />
            </template>
            <template v-if="showClientAttributes">
                <div class="myAccount-data-headline">
                    {{ getLabelClientAttributes }}
                </div>
                <DataItem
                    v-for="item of client.attributes"
                    :key="item.attributeDef.id"
                    :item="item"
                    type="clientAttribute"
                    v-model="client"
                    @is-valid="(val) => validate(item.key,val)"
                />
            </template>
        </div>

        <div class="form-actions">
            <NdxButtonLink
                v-if="showBackBtn"
                @click="$emit('on-back')"
            >
                {{ $t('btn.back') }}
            </NdxButtonLink>
            <NdxButtonLink
                v-if="hasEditableItems('client')"
                @click="resetData"
                :disabled="!isDirty"
            >
                {{ $t('btn.cancel') }}
            </NdxButtonLink>
            <NdxButton
                v-if="isDataCheck || hasEditableItems('client')"
                @click="submitClientForm"
                :disabled="submitBtnDisabled"
            >
                {{ isDataCheck ? $t('btn.save_continue') : $t('btn.save') }}
            </NdxButton>
        </div>
    </div>

    <NdxFlyIn v-if="showAddressEditor">
        <template #title>{{ editAddressTitle }}</template>
        <template #default>
            <AddressForm
                v-model:address="editAddressModel"
                address-book="client"
                @is-valid="isValid => editAddressValid = isValid"
            />
        </template>
        <template #buttons>
            <NdxButtonLink @click="closeEditor">
                {{ isMainAddressEditingAllowed ? $t('btn.cancel') : $t('btn.close') }}
            </NdxButtonLink>
            <NdxButton
                v-if="isMainAddressEditingAllowed"
                :disabled="!editAddressValid"
                @click="saveAddress"
            >
                {{ $t('btn.save') }}
            </NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import DataItem from "./dataItem/DataItem";
    import lodashClone from "lodash/cloneDeep";
    import NdxButton from "../library/NdxButton";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxIcon from "../library/NdxIcon";
    import { AddressFormatter } from "../../plugins/formatter";
    import NdxFlyIn from "../library/NdxFlyIn";
    import AddressForm from "../checkout/AddressForm";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    const PROPS = {
        'data': ['name', 'orgId', 'description', 'website'],
        'address': ['NDX_ADDRESS'],
        'contact': ['phone', 'phoneBusiness', 'mobile', 'fax', 'faxBusiness', 'email']
    };

    export default {
        name: 'ClientData',
        components: {NdxPageHeader, AddressForm, NdxFlyIn, NdxIcon, NdxButtonLink, NdxButton, DataItem},
        props: {
            isDataCheck: {
                type: Boolean
            },
            showBackBtn: {
                type: Boolean
            }
        },
        emits: ['on-saved', 'on-back'],
        data() {
            return {
                client: null,

                showAddressEditor: false,
                editAddressTitle: '',
                editAddressModel: null,
                editAddressValid: false,

                invalidItems: []
            };
        },
        computed: {
            ...mapState({
                storeClient: state => state.user.client,
                initiated: state => state.myAccount.initiated,
                labelClient: state => state.myAccount.labelClient,
                labelClientData: state => state.myAccount.labelClientData,
                labelClientAddress: state => state.myAccount.labelClientAddress,
                labelClientContact: state => state.myAccount.labelClientContact,
                isMainAddressEditingAllowed: state => state.shop.isMainAddressEditingAllowed
            }),
            ...mapGetters('myAccount', [
                'getItem',
                'showClientTab',
                'showClientAttributes',
                'hasOneItem',
                'hasEditableItems',
                'getLabel',
                'addressProps',
                'invalidAddressItems'
            ]),
            getLabelClientData() {
                return this.labelClientData && this.labelClientData.length
                    ? this.labelClientData
                    : this.$t('contact.label.client_data');
            },
            getLabelClientContact() {
                return this.labelClientContact && this.labelClientContact.length
                    ? this.labelClientContact
                    : this.$t('contact.label.client_contact');
            },
            getLabelClientAddress() {
                return this.labelClientAddress && this.labelClientAddress.length
                    ? this.labelClientAddress
                    : this.$t('contact.label.client_address');
            },
            getLabelClientAttributes() {
                return this.$t('contact.label.client_attributes');
            },
            list() {
                let list = [];

                for (let key in PROPS) {
                    if (key === 'address') {
                        if (this.clientMainAddress && this.getAddressProps().length > 0) {
                            list.push({
                                label: this.getLabelClientAddress,
                                type: 'headline'
                            });
                            list.push({
                                userDefinedName: this.getLabelClientAddress,
                                type: 'NDX_ADDRESS'
                            });
                        }
                    } else if (['contact', 'data'].includes(key)) {
                        if (this.hasOneItem('client', 'contact', PROPS[key]) ||
                            this.hasOneItem('client', 'entity', PROPS[key])
                        ) {
                            list.push({
                                label: key === 'data' ? this.getLabelClientData : this.getLabelClientContact,
                                type: 'headline'
                            });
                            for (let item of PROPS[key]) {
                                let tmp = this.getItem('client', 'contact', item) ||
                                    this.getItem('client', 'entity', item);
                                if (tmp) {
                                    list.push(tmp);
                                }
                            }
                        }
                    }
                }

                return list;
            },
            isDirty() {
                const local = JSON.stringify(this.client);
                const org = JSON.stringify(this.storeClient);

                return local !== org;
            },
            clientMainAddress() {
                let address = this.client.contact.addresses.find((address) => {
                    return address.addressTypes
                        .find(type => type.isDefault && type.addressType === 'main') !== undefined;
                });

                return address !== undefined ? address : null;
            },
            submitBtnDisabled() {
                return (!this.isDirty && !this.isDataCheck) ||
                    this.invalidItems.length > 0 ||
                    !this.isAddressValid(this.clientMainAddress);
            },
            breadcrumbs() {
                return [{
                    label: this.$t('label.myAccount'),
                    click: () => this.$router.push({name: 'MyAccount'})
                }, {
                    label: this.getLabel('Client', this)
                }];
            }
        },
        created() {
            this.$store.dispatch('myAccount/getDataCheckConfig');
        },
        mounted() {
            this.resetData();
        },
        methods: {
            isAddressValid(address) {
                if (address) {
                    return this.invalidAddressItems(
                        address,
                        address.addressTypes.map(item => item.addressType),
                        'client'
                    ).length === 0;
                }

                return address === undefined || address === null;
            },
            getAddressProps() {
                return this.addressProps('client', ['main']);
            },
            resetData() {
                this.client = lodashClone(this.storeClient);
            },
            submitClientForm() {
                this.$store.dispatch('user/updateClient', this.client)
                    .then(() => {
                        this.resetData();
                        this.$emit('on-saved');
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            formatAddress(address) {
                return AddressFormatter(address, this, "\n");
            },
            onEditAddress(title, address) {
                this.editAddressValid = false;
                this.editAddressTitle = title;
                this.editAddressModel = address;
                this.showAddressEditor = true;
            },
            closeEditor() {
                this.showAddressEditor = false;
                this.editAddressTitle = '';
                this.editAddressModel = null;
                this.resetData();
            },
            saveAddress() {
                if (this.editAddressValid === false) {
                    return;
                }
                this.$store.dispatch('user/updateClientAddress', {
                    addressData: this.editAddressModel,
                    addressType: 'main'
                }).then(() => {
                    this.resetData();
                    this.closeEditor();
                }).catch((error) => {
                    console.error(error);
                });
            },

            validate(key, isValid) {
                let idx = this.invalidItems.indexOf(key);
                if (isValid && idx > -1) {
                    this.invalidItems.splice(idx, 1);
                } else if (!isValid && idx < 0) {
                    this.invalidItems.push(key);
                }
            }
        }
    };
</script>
