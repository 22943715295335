<template>
    <SummaryWidget :widget="widget" v-bind="$attrs">
        <template #actions>
            <div class="actionsWrapper">
                <NdxButton
                    @click="nextStep"
                    :disabled="!isValid || ordering"
                >
                    <div class="d-flex justify-content-between">
                        {{ nextBtnText }}
                        <NdxIcon icon="arrow-left" flip-h/>
                    </div>
                </NdxButton>

                <NdxButton
                    variant="secondary"
                    class="text-start"
                    @click="previousStep"
                >{{ previousBtnText }}</NdxButton>
            </div>
        </template>
    </SummaryWidget>
</template>

<script>
    import NdxButton from "../../../library/NdxButton";
    import {mapGetters, mapState} from "vuex";
    import NdxIcon from "../../../library/NdxIcon";
    import SummaryWidget from "./SummaryWidget";

    export default {
        name: 'CheckoutSummary',
        components: {SummaryWidget, NdxIcon, NdxButton},
        props: {
            widget: {
                type: Object
            }
        },
        data() {
            return {
                ordering: false
            };
        },
        computed: {
            ...mapState({
                activeStep: state => state.checkout.activeStep,
                isValid: state => state.checkout.isValid
            }),
            ...mapGetters('checkout', [
                'checkoutStepsCount'
            ]),
            previousBtnText() {
                return this.activeStep > 1 ? this.$t('btn.back') : this.$t('btn.cancel');
            },
            nextBtnText() {
                return this.activeStep < this.checkoutStepsCount ?
                    this.$t('btn.continue') :
                    this.$t('btn.quickCheckout');
            }
        },
        methods: {
            previousStep() {
                if (this.activeStep > 1) {
                    this.$store.dispatch('checkout/previousStep');
                } else {
                    this.$router.push(this.$store.getters['shop/getRouteByType']('basket'));
                }
            },
            nextStep() {
                if (this.activeStep < this.checkoutStepsCount) {
                    this.$store.dispatch('checkout/nextStep');
                } else {
                    this.ordering = true;
                    this.$store.dispatch('basket/order')
                        .then((orderId) => {
                            const route = JSON.parse(JSON.stringify(
                                this.$store.getters['shop/getRouteByType']('orderSuccess')
                            ));
                            route.params.orderId = orderId;

                            this.$router.push(route);
                        })
                        .catch((error) => {
                            console.error('Basket ordering failure', error);
                        })
                        .finally(() => {
                            this.ordering = false;
                        });
                }
            }
        }
    };
</script>
