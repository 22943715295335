<template>
    <NdxPageHeader
        :nav-name="navName"
        :breadcrumbs="breadcrumbs"
        :active-route-callback="goToTasks"
        v-model:show-filter="showFilter"
        :active-filter="activeFilter"
        hide-view-mode
    />

    <NdxListFilter
        v-if="filterData !== null"
        v-model:show-filter="showFilter"
        :search-text="filterData.searchText"
        :states-selected="filterData.stateList"
        :state-list-options="stateListOptions"
        :start-date="filterData.startDate"
        :end-date="filterData.endDate"
        @filter-triggered="updateFilterData"
        @clear-filter="clearFilter"
    />

    <TasklistActive
        v-if="type === 'active'"
        :filter-data="filterData"
    />
    <TaskListCompleted
        v-else
        :filter-data="filterData"
    />
</template>

<script>
    import TasklistActive from "./TaskListActive.vue";
    import TaskListCompleted from "./TaskListCompleted.vue";
    import NdxListFilter from "../library/NdxListFilter.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    const LISTTYPE_ACTIVE = 'active';
    const LISTTYPE_COMPLETED = 'completed';

    export default {
        name: "Tasklist",
        components: {
            NdxPageHeader, NdxListFilter, TaskListCompleted, TasklistActive
        },
        data() {
            return {
                type: LISTTYPE_ACTIVE,
                filterData: null,
                showFilter: false
            };
        },
        computed: {
            stateListOptions() {
                if (this.type === LISTTYPE_COMPLETED) {
                    return [
                        {
                            value: 'assignedToCurrentUser',
                            text: this.$t('label.workflow.tasksAssignedToCurrentUser')
                        },
                    ];
                }
                return [
                    {
                        value: 'assignedToCurrentUser',
                        text: this.$t('label.workflow.tasksAssignedToCurrentUser')
                    }, {
                        value: 'notAssigned',
                        text: this.$t('label.workflow.tasksNotAssigned')
                    },
                ];
            },
            navName() {
                return this.type === LISTTYPE_ACTIVE
                    ? this.$t('label.workflow.workflowTasksActive')
                    : this.$t('label.workflow.workflowTasksCompleted');
            },
            breadcrumbs() {
                return [{
                    label: this.navName,
                    click: this.activeFilter ? () => this.clearFilter() : null
                }];
            },
            activeFilter() {
                const dirtyStateList = this.type !== LISTTYPE_COMPLETED
                    ? this.filterData.stateList.length !== 2
                    : this.filterData.stateList.length !== 1 ||
                        !this.filterData.stateList.includes('assignedToCurrentUser');

                return this.filterData.searchText.length > 0 ||
                    dirtyStateList ||
                    ![null, undefined].includes(this.filterData?.startDate?.from) ||
                    ![null, undefined].includes(this.filterData?.startDate?.to) ||
                    ![null, undefined].includes(this.filterData?.endDate?.from) ||
                    ![null, undefined].includes(this.filterData?.endDate?.to);
            }
        },
        watch: {
            '$route.params': {
                immediate: true,
                handler: function (params) {
                    const routeParam = params.taskType;
                    const isValidRoutParam = [LISTTYPE_ACTIVE, LISTTYPE_COMPLETED].includes(routeParam);
                    this.type = isValidRoutParam ? routeParam : LISTTYPE_ACTIVE;
                    this.clearFilter();
                }
            }
        },
        methods: {
            goToTasks() {
                this.$router.push(
                    this.$store.getters['shop/getWgcsByType']('workflowsTasks')[0].route
                );
            },
            clearFilter() {
                this.showFilter = false;
                this.filterData = {
                    stateList: this.type !== LISTTYPE_COMPLETED
                        ? ['notAssigned', 'assignedToCurrentUser']
                        : ['assignedToCurrentUser'],
                    searchText: '',
                    startDate: {from: null, to: null},
                    endDate: this.type === LISTTYPE_COMPLETED
                        ? {from: null, to: null}
                        : null
                };
            },
            updateFilterData(filterValues) {
                this.filterData = {
                    stateList: filterValues.stateList,
                    searchText: filterValues.searchText,
                    startDate: {
                        from: filterValues.startDateFrom,
                        to: filterValues.startDateTo
                    },
                    endDate: this.type === LISTTYPE_COMPLETED
                        ? {
                            from: filterValues.endDateFrom,
                            to: filterValues.endDateTo
                        }
                        : null
                };
            }
        }
    };
</script>
