<template>
    <NdxPageHeader
        v-model:view-mode="viewMode"
        view-mode-storage-key="documentsViewMode"
        :nav-name="navName"
        hide-filter
    />
    <NdxListing
        ref="listing"
        store-action="document/filterBy"
        :order-by="{id: 'desc'}"
        @items-changed="updateItems"
    >
        <template #default>
            <div class="card-container" :class="{['viewmode-' + viewMode]: true}">
                <NdxDocumentCard
                    v-for="(document, idx) in list"
                    :key="idx"
                    :document-set="document"
                    :variant="viewMode"
                    @card-click="openEditor"
                />
            </div>
        </template>

        <template #emptyList>
            <NdxNotification
                variant="info"
                :duration="0"
                :modelValue="true"
                :message="$t('message.noDocuments')"
            />
        </template>

        <template #loading>
            <NdxNotification
                variant="info"
                :model-value="true"
                :duration="0"
                :message="$t('message.documentsAreLoading')"
            />
        </template>
    </NdxListing>

    <NdxOverlay
        v-show="pickedDocument && pickedDocumentSet && pickedDocumentContext"
        title=""
        variant="fullscreen"
    >
        <template #content>
            <iframe id="le"></iframe>
        </template>
    </NdxOverlay>
</template>

<script>
    import NdxDocumentCard from "../library/NdxDocumentCard.vue";
    import NdxOverlay from "../library/NdxOverlay.vue";
    import NdxNotification from "../library/NdxNotification.vue";
    import NdxListing from "../library/NdxListing.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: "DocumentList",
        components: {NdxPageHeader, NdxListing, NdxNotification, NdxOverlay, NdxDocumentCard},
        data() {
            return {
                list: null,

                viewMode: 'card',

                layoutEditorApp: null,
                pickedDocumentSet: null,
                pickedDocument: null,
            };
        },
        computed: {
            pickedDocumentContext() {
                return this.pickedDocument ? {id: this.pickedDocument.id, type: 'layout'} : null;
            },
            navName() {
                const wgc = this.$store.getters['shop/getWgc'](+this.$route.params.id);
                return wgc ? wgc.label : '';
            }
        },
        created() {
            window.addEventListener('message', this.onDocumentEditorClose);
        },
        beforeUnmount() {
            window.removeEventListener('message', this.onDocumentEditorClose);
        },
        methods: {
            updateItems(items) {
                this.list = items;
            },
            openEditor(documentSet) {
                this.pickedDocumentSet = documentSet;
                this.pickedDocument = documentSet.documents[0];

                const layoutInitData = {
                    context: this.pickedDocumentContext,
                    documentId: this.pickedDocument.id,
                    documentSetId: this.pickedDocumentSet.id,
                    documentType: 'layout',
                    shopId: this.$store.getters['session/getShopId'],
                    csrfToken: this.$store.getters['session/getSecret'],
                    brandColor: window.getComputedStyle(window.parent.document.body).getPropertyValue('--bs-primary')
                };

                const wrapper = document.querySelector('#le');
                let urlParts = {
                    host: '//' + window.location.hostname,
                    envPart: ':8099',
                    file: '/index.html?cfg=' + encodeURIComponent(JSON.stringify(layoutInitData))
                };
                if (parseInt(window.location.port, 10) !== 8088) {
                    urlParts.envPart = '/js/apps/layout-editor/dist';
                }
                wrapper.setAttribute('src', urlParts.host + urlParts.envPart + urlParts.file);
                // manipulate surounding html to avoid scrollbars from underlying content
                document.querySelector('html').style.overflow = 'hidden';
            },
            /**@params {MessageEvent} evt */
            onDocumentEditorClose(evt) {
                if (typeof evt.data === "string" && evt.data === 'closeLayoutEditor') {
                    // close editor
                    this.pickedDocumentSet = null;
                    this.pickedDocument = null;

                    // refreshList
                    this.$refs.listing.fetchItems();

                    // revert css manipulation
                    document.querySelector('html').style.overflow = '';
                }
                if (typeof evt.data === "string" && evt.data === 'sessionTimedOut') {
                    // revert css manipulation
                    document.querySelector('html').style.overflow = '';

                    this.$store.dispatch('session/forcedLogout');
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~bootstrap/scss/bootstrap-utilities";
    @import "../../style/variables_ndx";

    .card-container.viewmode-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .card-container.viewmode-card {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 16px;
    }

    @include media-breakpoint-up(md) {
        .card-container.viewmode-card {
            grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
        }
    }

    #le {
        width: 100%;
        height: 100%;
    }

    :deep(.ndx-overlay) {
        padding: 0 !important;

        .ndx-overlay-header {
            display: none;
        }

        .ndx-overlay-content {
            padding: 0 !important;
        }

        .ndx-overlay-footer {
            display: none;
        }
    }
</style>
