import productApi from '../../api/product';
import { ndxDateConvert } from "@utilities/ndxDate";

//state
const state = {
    similarProductsHeadline: ''
};

// getters
const getters = {
    cardActions(state, getters, rootState, rootGetters) {
        return (product, favoriteCb, buyCb, $t, $d, $router) => {
            let actions = [];

            if (rootGetters['shop/getRouteByType']('favorites')) {
                if (product.isFavorite) {
                    actions.push({
                        type: 'favorites',
                        icon: 'heart-filled',
                        title: $t('label.defavorizeProduct'),
                        action: () => favoriteCb(product, false)
                    });
                } else {
                    actions.push({
                        type: 'favorites',
                        icon: 'heart',
                        title: $t('label.favorizeProduct'),
                        action: () => favoriteCb(product, true)
                    });
                }
            }
            /*  listing info */
            if (product.isDirectlyOrderable ||
                /*  product detail info */
                (product.isOrderable && !product.bundleParent && product.productFeatures.length === 0 &&
                    rootGetters['shop/isOrderDirectlyActive'])
            ) {
                let _buyCb = null;
                if (buyCb && typeof buyCb === 'function') {
                    _buyCb = () => buyCb(product);
                }
                if (rootGetters['basket/isProductInBasket'](product.id)) {
                    actions.push({
                        type: 'basket',
                        icon: 'basket-add_in-basket',
                        title: $t('label.addToBasket'),
                        action: _buyCb
                    });
                } else {
                    actions.push({
                        type: 'basket',
                        icon: 'basket-add',
                        title: $t('label.addToBasket'),
                        action: _buyCb
                    });
                }
            } else if (product.bundleParent) {
                if (rootGetters['basket/isProductInBasket'](product.id)) {
                    actions.push({
                        type: 'basket',
                        icon: 'block-in-basket',
                        title: $t('label.isInBasket'),
                        action: null
                    });
                } else {
                    actions.push({
                        type: 'basket',
                        icon: 'block',
                        title: $t('label.bundle.bundleInfo'),
                        action: null
                    });
                }
            } else if (product.hasVariants) {
                if (rootGetters['basket/isProductInBasket'](product.id)) {
                    actions.push({
                        type: 'basket',
                        icon: 'variant-product_in-basket',
                        title: $t('label.isInBasket'),
                        action: null
                    });
                } else {
                    actions.push({
                        type: 'hasVariants',
                        icon: 'asset-management',
                        title: $t('label.hasVariants'),
                        action: null
                    });
                }
            } else if (rootGetters['basket/isProductInBasket'](product.id)) {
                actions.push({
                    type: 'basket',
                    icon: 'shopping-cart_in-basket',
                    title: $t('label.isInBasket'),
                    action: null
                });
            }

            if (rootGetters['basket/isProductInWatchlist'](product.id)) {
                let watchlistAction = null;
                if ($router) {
                    const watchlistRoute = JSON.parse(JSON.stringify(
                        rootGetters['shop/getRouteByType']('watchlist')
                    ));
                    watchlistAction = () => {
                        $router.push(watchlistRoute);
                    };
                }
                actions.push({
                    type: 'isProductInWatchlist',
                    icon: rootGetters['shop/getIconForType']('watchlist'),
                    title: $t('label.isInWatchlist'),
                    action: watchlistAction
                });
            }

            if ('lastOrder' in product && product.lastOrder) {
                let lastOrderAction = null;
                if ($router) {
                    const orderRoute = JSON.parse(JSON.stringify(
                        rootGetters['shop/getRouteByType']('orders')
                    ));
                    lastOrderAction = () => {
                        $router.push({
                            name: 'OrderDetails',
                            params: {
                                id: orderRoute.params.id,
                                orderId: product.lastOrder.orderId
                            }
                        });
                    };
                }
                let nameParts = [];
                if (product.lastOrder.createdBy.firstname &&
                    product.lastOrder.createdBy.firstname.length > 0
                ) {
                    nameParts.push(product.lastOrder.createdBy.firstname);
                }
                if (product.lastOrder.createdBy.lastname &&
                    product.lastOrder.createdBy.lastname.length > 0
                ) {
                    nameParts.push(product.lastOrder.createdBy.lastname);
                }
                const orderDate = $d(ndxDateConvert(product.lastOrder.createdAt), 'long');
                const title = (nameParts.length > 0)
                    ? $t('label.order_last_order', {date: orderDate, name: nameParts.join(' ')})
                    : $t('label.order_last_order_noName', {date: orderDate});

                actions.push({
                    type: 'lastOrder',
                    icon: rootGetters['shop/getIconForType']('orders'),
                    title: title,
                    action: lastOrderAction
                });
            }

            return actions;
        };
    },
    getInvalidProductFeatures() {
        return function (productFeatures) {
            const invalidFeatures = [];
            const properties = ['value', 'mandatory', 'type', 'key'];

            productFeatures.forEach(feature => {
                // Ensure all required properties exist
                properties.forEach(prop => {
                    if (!(prop in feature)) {
                        throw new Error(`Missing "${prop}" property`);
                    }
                });

                let { value, mandatory, type, key } = feature;
                let isValid = true;

                if (mandatory) {
                    switch (type) {
                        case 'checkbox':
                            isValid = ['1', 1].includes(value);
                            break;
                        case 'select':
                        case 'date':
                        case 'file':
                            isValid = value !== null;
                            break;
                        case 'multiSelect':
                            isValid = Array.isArray(value) && value.length > 0;
                            break;
                        default:
                            isValid = value && value.length > 0;
                    }
                }

                const arrayIdx = invalidFeatures.indexOf(key);
                if (isValid && arrayIdx > -1) {
                    invalidFeatures.splice(arrayIdx, 1);
                } else if (!isValid && arrayIdx < 0) {
                    invalidFeatures.push(key);
                }
            });

            return invalidFeatures;
        };
    }
};

// actions
const actions = {
    getProducts(ctx, {filterCriteria, offset, limit, throbberRule}) {
        return new Promise((resolve, reject) => {
            productApi.filterBy(
                filterCriteria.categoryId,
                offset,
                limit,
                filterCriteria.recursive,
                filterCriteria.searchText,
                filterCriteria.entityIds,
                filterCriteria.tagIds,
                filterCriteria.tagIdOperation,
                throbberRule
            )
                .then((list) => {
                    resolve(list);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    find(ctx, data) {
        return new Promise((resolve, reject) => {
            productApi.find(
                data.productId
            ).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    findNewVariant(ctx, data) {
        return new Promise((resolve, reject) => {
            productApi.findNewVariant(
                data.productId,
                data.attr
            ).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    getFavorites() {
        return new Promise((resolve, reject) => {
            productApi.getFavorites()
                .then((list) => {
                    resolve(list);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    toggleFavorite(ctx, data) {
        return new Promise((resolve, reject) => {
            productApi.toggleFavorite(data.pciId, data.favorize)
                .then((list) => {
                    resolve(list);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getSimilarProducts({commit}, data) {
        return new Promise((resolve, reject) => {
            productApi.getSimilarProducts(data.pciId)
                .then((result) => {
                    commit('SET_SIMILAR_PRODUCTS_HEADLINE', result.headline);
                    resolve(result.list);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getPrices(ctx, data) {
        return new Promise((resolve, reject) => {
            productApi.getPrices(data.pciId)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

// mutations
const mutations = {
    RESET(state) {
        state.similarProductsHeadline = '';
    },
    SET_SIMILAR_PRODUCTS_HEADLINE(state, headline) {
        state.similarProductsHeadline = headline;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
