<script>
    import { mapGetters, mapState } from "vuex";

    export default {
        name: 'BaseTracker',
        data() {
            return {
                currentAssetEntry: null
            };
        },
        computed: {
            ...mapState({
                appTracking: state => state.shop.appTracking,
                productDetailStore: state => state.productDetail.product
            }),
            ...mapGetters('productCategories', {
                productGetCachedSubtree: 'getCachedSubtree'
            }),
            ...mapGetters('assetCategories', {
                assetGetCachedSubtree: 'getCachedSubtree'
            }),
            ...mapGetters('shop', {
                productRootFolderId: 'productRootFolderId',
                assetLibraryRootFolderId: 'assetLibraryRootFolderId'
            })
        },
        created() {
            this.initialize();
        },
        mounted() {
            window.addEventListener('APP_TRACKER_SET_ASSET_ENTRY', this.onSetAssetEntry);
        },
        beforeUnmount() {
            window.removeEventListener('APP_TRACKER_SET_ASSET_ENTRY', this.onSetAssetEntry);
        },
        methods: {
            initialize() {
                throw new Error('Method initialize is not implemented');
            },
            trackRoute() {
                throw new Error('Method trackRoute is not implemented');
            },

            onSetAssetEntry(ev) {
                this.currentAssetEntry = ev.detail.assetEntry;
            },

            getRouteData() {
                return new Promise((resolve) => {
                    this.translateTitle()
                        .then((title) => {
                            resolve({
                                path: this.$route.path,
                                title: title
                            });
                        });
                });
            },
            translateTitle() {
                return new Promise((resolve) => {
                    let titleParts = [
                        this.getWgcName()
                    ];

                    let reqs = [
                        this.getOrderName(),
                        this.getNewsName(),
                        this.getBudgetName(),
                        this.getAssetCategoryName(),
                        this.getAssetName(),
                        this.getProductCategoryName(),
                        this.getProductName()
                    ];

                    Promise.all(reqs)
                        .then(([orderName, newsName, budgetName, assetCategoryName, assetName, productCategoryName,
                                   productName]) => {
                            if (orderName) {
                                titleParts.push(orderName);
                            }
                            if (newsName) {
                                titleParts.push(newsName);
                            }
                            if (budgetName) {
                                titleParts.push(budgetName);
                            }
                            if (assetCategoryName) {
                                titleParts.push(assetCategoryName);
                            }
                            if (assetName) {
                                titleParts.push(assetName);
                            }
                            if (productCategoryName) {
                                titleParts.push(productCategoryName);
                            }
                            if (productName) {
                                titleParts.push(productName);
                            }

                            resolve(titleParts.join('/'));
                        });
                });
            },

            getRouteParam(paramName) {
                if (this.$route && 'params' in this.$route && paramName in this.$route.params) {
                    return this.$route.params[paramName];
                }
                return null;
            },

            timeoutResolve(func, funcData, defaultRes, resolve, loopCount = 0) {
                window.setTimeout(function () {
                    let res = func(funcData);
                    if (res !== null) {
                        resolve(res);
                    } else if (loopCount < 11) {
                        this.timeoutResolve(func, funcData, defaultRes, resolve, loopCount + 1);
                    } else {
                        resolve(defaultRes);
                    }
                }.bind(this), 100);
            },

            getWgcName() {
                const wgcId = +this.getRouteParam('id');
                if (wgcId > 0) {
                    const wgc = this.$store.getters['shop/getWgc'](wgcId);
                    if (wgc && wgc.label && wgc.label.length) {
                        return wgc.label;
                    }
                }
                if (this.$route.name === 'Product') {
                    return this.$tc('label.products', 1);
                }

                return this.$route.name;
            },

            getAssetCategoryName() {
                const func = (categoryId) => {
                    const subTree = this.assetGetCachedSubtree(categoryId);
                    if (subTree && subTree.name && subTree.name.length) {
                        return subTree.name;
                    }
                    return null;
                };

                return new Promise((resolve) => {
                    if (['AssetsInCategory'].includes(this.$route.name)) {
                        const categoryId = +this.getRouteParam('categoryId');
                        if (categoryId > 0 && categoryId !== this.assetLibraryRootFolderId) {
                            const defaultValue = 'assetCategoryId-' + categoryId;
                            this.timeoutResolve(func, categoryId, defaultValue, resolve);
                        } else {
                            resolve(null);
                        }
                    } else {
                        resolve(null);
                    }
                });
            },

            getAssetName() {
                const func = (assetId) => {
                    if (this.currentAssetEntry && this.currentAssetEntry.id == assetId) {
                        return this.currentAssetEntry.name;
                    }
                    return null;
                };
                return new Promise((resolve) => {
                    const assetId = +this.getRouteParam('aid');
                    if (assetId > 0) {
                        const defaultValue = 'assetId-' + assetId;
                        this.timeoutResolve(func, assetId, defaultValue, resolve);
                    } else {
                        resolve(null);
                    }
                });
            },

            getProductCategoryName() {
                const func = (categoryId) => {
                    const subTree = this.productGetCachedSubtree(categoryId);
                    if (subTree && subTree.name && subTree.name.length) {
                        return subTree.name;
                    }
                    return null;
                };

                return new Promise((resolve) => {
                    if (['ProductsInCategory'].includes(this.$route.name)) {
                        const categoryId = +this.getRouteParam('categoryId');
                        if (categoryId > 0 && categoryId !== this.productRootFolderId) {
                            const defaultValue = 'productCategoryId-' + categoryId;
                            this.timeoutResolve(func, categoryId, defaultValue, resolve);
                        } else {
                            resolve(null);
                        }
                    } else {
                        resolve(null);
                    }
                });
            },

            getProductName() {
                const func = (productId) => {
                    if (this.productDetailStore &&
                        (this.productDetailStore.id === productId || this.productDetailStore.parentId === productId)
                    ) {
                        return this.productDetailStore.name;
                    }
                    return null;
                };

                return new Promise((resolve) => {
                    const productId = +this.getRouteParam('pid');
                    if (productId > 0) {
                        const defaultValue = 'productId-' + productId;
                        this.timeoutResolve(func, productId, defaultValue, resolve);
                    } else {
                        resolve(null);
                    }
                });
            },

            getOrderName() {
                return new Promise((resolve) => {
                    const orderId = +this.getRouteParam('orderId');
                    if (orderId > 0) {
                        resolve(this.$t('label.order_id') + '-' + orderId);
                    }
                    resolve(null);
                });
            },

            getBudgetName() {
                return new Promise((resolve) => {
                    if (this.$route.name === 'BudgetProducts') {
                        resolve(this.$t('label.budget_products'));
                    } else if (this.$route.name === 'BudgetCategories') {
                        resolve(this.$t('label.budget_categories'));
                    }
                    resolve(null);
                });
            },

            getNewsName() {
                return new Promise((resolve) => {
                    const newsId = +this.getRouteParam('newsId');
                    if (newsId > 0) {
                        resolve('News-ID-' + newsId);
                    }
                    resolve(null);
                });
            }
        },
        render() {
            return '';
        }
    };
</script>
