<template>
    <DetailPage :breadcrumb="task.name">
        <template #visual>
            <PageDetailDefaultVisual>
                <NdxImage
                    :src="visualInfo.path"
                    :style="'background-color: ' + visualInfo.bgColor"
                    size="1000"
                />
            </PageDetailDefaultVisual>
        </template>

        <template #headline>
            {{ task.name }}
        </template>

        <template #default>
            <div class="task-detail">
                <div class="headline-info mb-2 pb-2">
                    <div class="mt-2 info-txt">
                        <span class="bold">{{ $t('label.workflow.taskId') }}:</span>
                        {{ task.id }}
                    </div>
                </div>
                <div class="task-info separatorBottom pb-3 mb-3">
                    <div class="keyValues">
                        <div>
                            <span>{{ $t('label.state') }}:</span>
                            {{ completed ? $t('label.workflow.state.completed') : $t('label.workflow.state.open') }}
                        </div>

                        <div class="mt-2">
                            <span>{{ $t('label.requestedBy') }}: </span>
                            {{ task.businessObject.createdBy.username }}
                        </div>
                        <div>
                            <span>{{ $t('label.requestedAt') }}: </span>
                            {{ $d(dateConverter(task.createdAt), 'long') }}
                        </div>
                        <div class="mt-2">
                            <span>{{ $t('label.assignee') }}: </span>
                            {{ task.assignee ? task.assignee.username : '' }}
                        </div>
                        <template v-if="completed">
                            <div>
                                <span>{{ $t('label.endedAt') }}: </span>
                                {{ $d(dateConverter(task.endedAt), 'long') }}
                            </div>
                            <div class="mt-2">
                                <span>{{ $t('label.decision') }}: </span>
                                {{ verdictMessage }}
                            </div>
                        </template>
                    </div>
                </div>

                <div class="separatorBottom pb-3 mb-3">
                    <BusinessObjectViewer
                        :business-object="task.businessObject"
                        @block-task="(val) => taskIsBlocked = val"
                    />
                </div>

                <div
                    v-if="!completed && assignedToCurrentUser"
                    class="separatorBottom pb-3 mb-3"
                >
                    <div class="decision-headline mb-2">{{ $t('label.decision') }}</div>
                    <NdxRadioCards
                        v-for="entry in decisionRadio"
                        :key="entry.value"
                        :value="entry.value"
                        :text="entry.text"
                        :selected="entry.value === decision"
                        :invalid="decision === null"
                        @radio-card-selected="() => decision = entry.value"
                    />
                </div>

                <div
                    v-if="task.comments.length > 0"
                    class="separatorBottom mb-3"
                >
                    <Comments :comments="task.comments"/>
                </div>

                <div
                    v-if="!completed && assignedToCurrentUser"
                    class="separatorBottom pb-3 mb-3"
                >
                    <CommentEditor
                        v-model:comment="comment"
                        :mandatory="commentMandatory"
                    />
                </div>

                <template v-if="!completed">
                    <div class="d-flex flex-column gap-3">
                        <template v-if="assignedToCurrentUser">
                            <NdxButton
                                @click="save"
                                variant="primary"
                                :disabled="!isValid"
                            >
                                {{ $t('btn.save') }}
                            </NdxButton>
                            <NdxButton
                                @click="resetDecision"
                                variant="secondary"
                                :disabled="!isDirty"
                            >
                                {{ $t('btn.reset') }}
                            </NdxButton>
                            <NdxButton
                                variant="secondary"
                                @click="unclaim"
                            >
                                {{ $t('btn.unclaim') }}
                            </NdxButton>
                        </template>
                        <template v-else>
                            <NdxButton
                                variant="primary"
                                @click="claim"
                            >
                                {{ $t('btn.claim') }}
                            </NdxButton>
                        </template>
                    </div>
                </template>
            </div>
        </template>

        <template #additions v-if="assignedToCurrentUser">
            <div class="tab-wrapper" :id="'tab-wrapper_' + task.id">
                <div class="tab-nav d-flex flex-wrap gap-3">
                    <NdxButton
                        variant="pill"
                        class="btn pill"
                        :class="{active: activeTab === 'timeline'}"
                        :disabled="!timelineData"
                        @click="activeTab = 'timeline'"
                    >
                        {{ $t('label.timeline') }}
                    </NdxButton>
                    <NdxButton
                        variant="pill"
                        class="btn pill"
                        :class="{active: activeTab === 'logs'}"
                        :disabled="!timelineData"
                        @click="activeTab = 'logs'"
                    >
                        {{ $t('label.logs') }}
                    </NdxButton>
                </div>
                <div class="tab-content">
                    <div v-if="activeTab === 'timeline'" class="logs">
                        <NdxWorkflowProgression
                            :timeline-data="filteredTimelineData"
                            :ndx-icon="ndxIconComponent"
                        />
                    </div>
                    <div v-if="activeTab === 'logs'" class="logs">
                        <WorkflowInstanceTimeline
                            :timeline-data="filteredTimelineData"
                        />
                    </div>
                </div>
            </div>
        </template>
    </DetailPage>
</template>

<script>
    import { ndxDateConvert } from "@utilities/ndxDate";
    import { mapGetters } from "vuex";
    import BusinessObjectViewer from "../businessObject/BusinessObjectViewer.vue";
    import Comments from "./Comments.vue";
    import NdxButton from "../../library/NdxButton.vue";
    import NdxRadioCards from "../../library/formElements/NdxRadioCards.vue";
    import CommentEditor from "./CommentEditor.vue";
    import DetailPage from "../../../pages/detailPage/DetailPage.vue";
    import NdxImage from "../../library/NdxImage.vue";
    import NdxIcon from "../../library/NdxIcon.vue";
    import WorkflowInstanceTimeline from "../WorkflowInstanceTimeline.vue";
    import { DECISIONS } from "@library/NdxWorkflowTimeline.vue";
    import NdxWorkflowProgression from "@library/NdxWorkflowProgression.vue";
    import PageDetailDefaultVisual from "../../library/PageDetailDefaultVisual.vue";

    export default {
        name: 'TaskDetail',
        components: {
            PageDetailDefaultVisual,
            WorkflowInstanceTimeline, NdxImage, NdxWorkflowProgression,
            DetailPage, CommentEditor, NdxRadioCards, NdxButton, Comments, BusinessObjectViewer},
        props: {
            task: {
                type: Object
            }
        },
        emits: ['update-data-model'],
        data() {
            return {
                dateConverter: ndxDateConvert,
                taskIsBlocked: false,

                decision: null,

                comment: {
                    message: '',
                    files: []
                },

                decisionRadio: [{
                    value: DECISIONS.approve,
                    text: this.$t('btn.approve')
                }, {
                    value: DECISIONS.reject,
                    text: this.$t('btn.reject')
                }, {
                    value: DECISIONS.rejectWithRevision,
                    text: this.$t('btn.rejectWithRevision')
                }],

                timelineData: null,
                ndxIconComponent: NdxIcon,

                activeTab: null
            };
        },
        computed: {
            ...mapGetters('user', [
                'currentUserId'
            ]),
            visualInfo() {
                return this.$store.getters['workflowTask/getInfoForVisual'](this.task);
            },
            completed() {
                return 'endedAt' in this.task && this.task.endedAt !== null;
            },
            assignedToCurrentUser() {
                return this.task.assignee !== null && this.task.assignee.id === this.currentUserId;
            },
            commentMandatory() {
                // comment is mandatory, if decision is reject/rejectWithRevision
                // comment message is mandatory if files are uploaded
                return this.decision !== null && this.decision !== DECISIONS.approve || this.comment.files.length > 0;
            },
            isValid() {
                const commentValid = !this.commentMandatory || this.comment.message.length > 0;

                return !this.taskIsBlocked &&
                    commentValid &&
                    Object.values(DECISIONS).includes(this.decision);
            },
            isDirty() {
                return this.comment.message.length > 0 || this.comment.files.length > 0 || this.decision !== null;
            },
            verdictMessage() {
                let verdict = null;
                if ('variables' in this.task && this.task.variables &&
                    'verdict_task' in this.task.variables
                ) {
                    verdict = this.task.variables.verdict_task.value;
                }

                if (verdict === 'reject') {
                    return this.$t('label.workflow.infoReject');
                }
                if (verdict === 'approve') {
                    return this.$t('label.workflow.infoApprove');
                }
                if (verdict === 'revision') {
                    return this.$t('label.workflow.infoRevision');
                }
                return this.$t('label.workflow.infoInProgress');
            },
            filteredTaskList() {
                return this.timelineData.tasks.filter(item => {
                    return item.createdAt <= this.task.createdAt;
                });
            },
            filteredTimeline() {
                let timeline = [...this.timelineData.timeline].sort(
                    (a, b) => a.date < b.date ? 1 : -1
                );

                let isFollowUpTask = true;
                return timeline.filter(item => {
                    if (item.oid.id === this.task.id) {
                        isFollowUpTask = false;
                    }
                    return !isFollowUpTask;
                });
            },
            filteredTimelineData() {
                return {
                    ...this.timelineData,
                    ...{timeline: this.filteredTimeline, tasks: this.filteredTaskList}
                };
            }
        },
        created() {
            this.getTimelineData();
        },
        methods: {
            getTimelineData() {
                let instanceId = this.task.workflowInstance?.id;

                if (instanceId === null) {
                    this.timelineData = null;
                    return;
                }

                this.$store.dispatch('workflowTimeline/getTimeline', {
                    instanceId: instanceId
                }).then((result) => {
                    this.timelineData = result;
                    if (this.activeTab === null) {
                        this.activeTab = 'timeline';
                    }
                }).catch((error) => {
                    console.error(error);
                });
            },
            resetDecision() {
                this.decision = null;
                this.comment = {
                    message: '',
                    files: []
                };
            },
            save() {
                let method = null;

                if (this.decision === DECISIONS.approve) {
                    method = 'workflowTask/approve';
                } else if (this.decision === DECISIONS.reject) {
                    method = 'workflowTask/reject';
                } else if (this.decision === DECISIONS.rejectWithRevision) {
                    method = 'workflowTask/rejectWithRevision';
                }

                if (method !== null) {
                    this.$store.dispatch(method, {
                        taskId: this.task.id,
                        comment: this.comment
                    }).then((model) => {
                        this.$emit('update-data-model', model);
                        this.resetDecision();
                        this.getTimelineData();
                    }).catch((error) => {
                        console.error(error);
                    });
                } else {
                    throw new Error('decision unkown: ' + this.decision);
                }
            },
            claim() {
                this.$store.dispatch('workflowTask/claim', {
                    taskId: this.task.id,
                    userId: this.$store.getters['user/currentUserId']
                }).then((model) => {
                    this.$emit('update-data-model', model);
                    this.resetDecision();
                    this.getTimelineData();
                }).catch((error) => {
                    console.error(error);
                });
            },
            unclaim() {
                this.$store.dispatch('workflowTask/unclaim', {
                    taskId: this.task.id
                }).then((taskModel) => {
                    this.$emit('update-data-model', taskModel);
                    this.resetDecision();
                    this.getTimelineData();
                }).catch((error) => {
                    console.error(error);
                });
            }
        }
    };
</script>

<style scoped>
    .tab-content {
        margin-top: 20px;
    }
</style>
