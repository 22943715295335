import lodashMerge from 'lodash/merge';
import assetCategoryApi from '../../api/assetCategory';
import {
    state as categoriesState,
    getters as categoriesGetters,
    mutations as categoriesMutations,
    buildActions
} from './base/categories';

//state
const state = () => {
    return lodashMerge({}, categoriesState(), {
        // custom stuff
    });
};

// getters
const getters = lodashMerge({}, categoriesGetters, {
    // custom stuff
});

// actions
const actions = lodashMerge({}, buildActions(assetCategoryApi), {
    searchStyleguidesAndCategories(ctx, data) {
        return new Promise((resolve, reject) => {
            assetCategoryApi.searchStyleguidesAndCategories(data.searchText)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
});

// mutations
const mutations = lodashMerge({}, categoriesMutations, {
    // custom stuff
});

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
