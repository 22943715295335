<template>
    <div>
        <div class="name">{{ businessObject.name }}</div>

        <div v-if="businessObject.priceTag === null" class="price">
            <span class="number">
                {{
                    formatPrice(
                        businessObject.basePriceNet * displayQuantity
                    )
                }}
            </span>
            <span class="quantity">&nbsp;/&nbsp;</span>
            <span
                v-if="displayQuantity !== 1"
                class="quantity"
            >{{ displayQuantity }}&nbsp;</span>
            <span class="quantity">
                {{ businessObject.productCatalogItem.quantityUnit.shortName }}
            </span>
        </div>

        <div class="keyValues">
            <div>
                <span>{{ $t('label.quantity') }}:</span>
                {{ $n(+businessObject.quantity) }}
            </div>
            <div>
                <span>{{ $t('label.priceNet') }}: </span>
                <template v-if="businessObject.priceTag !== null">{{ businessObject.priceTag}}</template>
                <template v-else>{{ formatPrice(businessObject.priceNet) }} </template>
            </div>
            <div
                v-if="'campaignRun' in businessObject && businessObject.campaignRun !== null"
                class="mt-2"
            >
                <span>{{ $t('label.campaignRunId') }}: </span>
                {{ businessObject.campaignRun.id }}
            </div>

            <div v-if="serverjobIsRunning || previewfiles.length > 0" class="mt-4">
                <span class="download-file-headline">{{ $t('label.available_files') }}: </span>

                <NdxNotification
                    v-if="serverjobIsRunning"
                    class="mt-2"
                    variant="info"
                    :duration="0"
                    :model-value="true"
                    :message="$t('message.generatingYourDocuments')"
                />

                <div v-if="previewfiles.length > 0" class="d-flex flex-column gap-1 mt-1">
                    <div
                        v-for="item of previewfiles"
                        :key="item.id"
                        class="d-flex gap-2 align-items-center download-file-item"
                        @click="downloadFile(item.file)"
                    >
                        <NdxIcon icon="export" size="s"/>
                        <span>{{ item.file.originalFilename }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { ndxDateConvert } from "@utilities/ndxDate";
    import { PriceFormatter } from "../../../../plugins/formatter";
    import NdxIcon from "../../../library/NdxIcon.vue";
    import NdxNotification from "../../../library/NdxNotification.vue";

    export default {
        name: 'ProductCatalogItem',
        components: {NdxNotification, NdxIcon},
        props: {
            businessObject: {
                type: Object
            }
        },
        emits: ['block-task'],
        data() {
            return {
                dateConverter: ndxDateConvert,
                previewfiles: []
            };
        },
        computed: {
            ...mapState({
                serverJobList: state => state.jobQueue.list,
            }),
            ...mapGetters('jobQueue', {
                isServerjobRunning: 'isRunning'
            }),
            serverjob() {
                let list = [];
                if ('campaignRun' in this.businessObject && this.businessObject.campaignRun) {
                    list = this.serverJobList.filter(
                        job => job.initiatedById === this.businessObject.campaignRun.id
                            && job.initiatedByType === 'CampaignRun'
                    );
                }
                return list.length > 0 ? list[0] : null;
            },
            serverjobIsRunning() {
                return this.serverjob ? this.isServerjobRunning(this.serverjob) : false;
            },

            ...mapGetters('shop', {
                currency: 'currency',
                defaultProductImageBgColor: 'getDefaultProductImageBgColor'
            }),
            displayQuantity() {
                const value = this.businessObject.productCatalogItem.displayQuantity || 1;
                return parseInt(value, 10);
            }
        },
        watch: {
            serverjobIsRunning: {
                immediate: true,
                handler: 'checkBlockTask'
            }
        },
        methods: {
            checkBlockTask() {
                this.$emit('block-task', this.serverjobIsRunning);
                if (!this.serverjobIsRunning) {
                    this.loadOrderItemFiles();
                }
            },
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },
            downloadFile(file) {
                window.open('/file/download/' + file.filename, '_blank');
            },
            loadOrderItemFiles() {
                this.$store.dispatch('transferPool/getFilesByOrderItemId', {
                    orderItemId: this.businessObject.id
                }).then((result) => {
                    this.previewfiles = result;
                }).catch((error) => {
                    console.error(error);
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../../../style/variables_ndx";

    .img-wrapper {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
    }
    .name {
        @extend %font-h3;
    }

    .price {
        padding-top: 8px;

        .number {
            @extend %font-h3;
        }

        .quantity {
            @extend %font-caption;
        }
    }

    .wf-businessObjectViewer .download-file-headline {
        font-weight: bold;
    }
    .download-file-item {
        color: var(--bs-primary);
        cursor: pointer;
        span {
            font-weight: bold;
        }
    }
</style>
