import calendarEntryApi from "../../api/calendarEntry";

//state
const state = {};
// getters
const getters = {
    getNewModel: function () {
        return {
            name: '',
            start: null,
            end: null
        };
    }
};

// actions
const actions = {
    filterBy: (ctx, data) => {
        const customParams = data.customParams || null;
        const appData = data.appData;

        return new Promise((resolve) => {
            calendarEntryApi.filterBy(
                appData,
                appData.offset,
                appData.limit,
                appData.order,
                appData.ascending,
                customParams
            ).then((result) => {
                resolve(result);
            }).catch((error) => {
                resolve(error);
            });
        });
    },
    delete: (ctx, entityId) => {
        return new Promise((resolve) => {
            calendarEntryApi.delete(entityId)
            .then((result) => {
                resolve(result);
            }).catch((error) => {
                resolve(error);
            });
        });
    },
    update: (ctx, data) => {
        return new Promise((resolve) => {
            calendarEntryApi.update(data)
                .then((result) => {
                    resolve(result);
                }).catch((error) => {
                resolve(error);
            });
        });
    }
};


// mutations
const mutations = {
    RESET() {
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
