import lodashMerge from 'lodash/merge';
import { ndxRpc } from "../utils/ndxRpc";

const service = 'workflowTaskHistory';

export default lodashMerge({}, {
    getTasklist(filterDescription, sortBy, sortAsc, searchText, firstResult, maxResults) {
        return new Promise((resolve, reject) => {
            const params = [
                filterDescription,
                sortBy,
                sortAsc,
                searchText,
                firstResult,
                maxResults
            ];
            ndxRpc(service, 'filterBy', params)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    findTask(taskId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'find', [taskId])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
});
