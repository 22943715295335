function addressMatchesString(address, str) {
    if (address && str && str.trim().length > 0) {
        const search = str.trim().toLowerCase();
        const searchKeys = [
            'company', 'firstname', 'lastname', 'street', 'houseNo', 'zipCode', 'city', 'countryId'
        ];

        for (let key of searchKeys) {
            if (key in address &&
                address[key] &&
                address[key].length > 0 &&
                address[key].toLowerCase().includes(search)
            ) {
                return true;
            }
        }
    }

    return false;
}

export { addressMatchesString as AddressMatchesString };
