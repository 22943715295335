<template>
    <NdxPageHeader
        hide-view-mode
        :nav-name="navName"
        v-model:show-filter="showFilter"
        :active-filter="activeFilter"
        :active-route-callback="activeFilter ? clearFilter : null"
    />

    <NdxFlyIn v-if="showFilter" class="limit500">
        <template #title>
            {{ $t('label.filterHeadline') }}
        </template>
        <template #default>
            <NdxMultiSelect
                variant="secondary"
                :model-value="stateList"
                @update:model-value="updateStateList"
                :options="stateListOptions"
                label=""
                text-wrap
            />
        </template>
        <template #buttons>
            <NdxButtonLink
                class="btnFlex"
                @click="clearFilter"
            >
                {{ $t('btn.reset') }}
            </NdxButtonLink>
            <NdxButton class="btnFlex" @click="showFilter = false">
                {{ $t('btn.close') }}
            </NdxButton>
        </template>
    </NdxFlyIn>

    <NdxListing
        ref="listing"
        :filter-criteria="filterCriteria"
        store-action="basket/getWatchlist"
        @items-changed="updateItems"
    >
        <template #emptyList>
            <NdxNotification
                variant="info"
                :duration="0"
                :modelValue="true"
                :message="$t('message.noWatchlistItems')"
            />
        </template>

        <template #default>
            <div v-if="bundleTrees.length > 0" class="watchlist-container p-3">
                <template
                    v-for="bundle in bundleTrees"
                    :key="bundle.bundleId"
                >
                    <BundleTree
                        v-if="bundle.bundleProduct"
                        type="watchlist"
                        :bundle="bundle"
                        :order-items="list"
                        @open-project-run="openProjectRun"
                        @goto-detailpage="gotoDetailPage"
                        @refresh-list="refreshWatchlist"
                    />
                    <WatchlistItem
                        v-else-if="bundle.orderItem"
                        :item="bundle.orderItem"
                        :bundle-id="bundle.bundleId"
                        @open-project-run="openProjectRun"
                        @goto-detailpage="gotoDetailPage"
                        @delete-item="deleteItem"
                        @refresh-watchlist="refreshWatchlist"
                    />
                </template>
            </div>
        </template>
    </NdxListing>

    <ProjectRun
        v-if="projectRunData !== null"
        :campaign-run-id="projectRunData.id"
        :product-name="projectRunData.name"
        :campaign-run-approved="projectRunData.approved"
        :live-price-calculation="livePriceCalculation"
        @close="closeProjectRun"
        @done="closeProjectRun"
    />

    <NdxFlyIn v-if="deleteCandidate !== null">
        <template #default>
            {{ $t('message.confirm_delete_watchlistItem', {name: deleteCandidate.name}) }}
        </template>
        <template #buttons>
            <NdxButtonLink @click="deleteCandidate = null">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton @click="onDeleteItem">{{ $t('btn.remove') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxButtonLink from "../library/NdxButtonLink";
    import { mapState } from "vuex";
    import ProjectRun from "../projectRun/ProjectRun";
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxButton from "../library/NdxButton";
    import NdxNotification from "../library/NdxNotification.vue";
    import WatchlistItem from "./WatchlistItem.vue";
    import BundleTree from "../checkout/steps/parts/BundleTree.vue";
    import NdxMultiSelect from "../library/formElements/NdxMultiSelect.vue";
    import NdxListing from "../library/NdxListing.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: 'Watchlist',
        components: {
            NdxPageHeader,
            NdxListing, BundleTree, NdxMultiSelect,
            WatchlistItem, NdxNotification, NdxButton, NdxFlyIn, ProjectRun, NdxButtonLink
        },
        data() {
            return {
                showFilter: false,

                list: [],

                projectRunData: null,
                deleteCandidate: null,

                withBundles: true,
                withCustomization: true,
                withWorkflow: true,
                withDefaultProducts: true,
                bundleTrees: [],

                stateListOptions: [{
                    value: 'withDefaultProducts',
                    text: this.$t('label.withDefaultProducts')
                }, {
                    value: 'withBundles',
                    text: this.$t('label.withBundles')
                }, {
                    value: 'withCustomization',
                    text: this.$t('label.withCustomization')
                }, {
                    value: 'withWorkflow',
                    text: this.$t('label.workflow.withWorkflow')
                }]
            };
        },
        computed: {
            ...mapState({
                livePriceCalculation: state => state.shop.basket?.liveCalculation
            }),
            stateList() {
                let list = [];

                if (this.withDefaultProducts) {
                    list.push('withDefaultProducts');
                }
                if (this.withBundles) {
                    list.push('withBundles');
                }
                if (this.withCustomization) {
                    list.push('withCustomization');
                }
                if (this.withWorkflow) {
                    list.push('withWorkflow');
                }

                return list;
            },
            filterCriteria() {
                return {
                    withBundles: this.withBundles,
                    withWorkflow: this.withWorkflow,
                    withCustomization: this.withCustomization,
                    withDefaultProducts: this.withDefaultProducts,
                };
            },
            navName() {
                return this.$store.getters['shop/getWgcsByType']('watchlist')[0].label;
            },
            activeFilter() {
                return !this.withBundles ||
                    !this.withWorkflow ||
                    !this.withCustomization ||
                    !this.withDefaultProducts;
            }
        },
        created() {
            this.clearFilter();
        },
        methods: {
            findOrderItem(orderItemId) {
                return this.list.find(item => item.id === orderItemId);
            },
            getBundleIdFromOrderItemId(item) {
                let bundleNode = this.bundleTrees.find((tree) => {
                    return tree.orderItemId === item.id;
                });
                if (bundleNode) {
                    return bundleNode.bundleId;
                } else {
                    return null;
                }
            },
            updateStateList(value) {
                this.withDefaultProducts = value.includes('withDefaultProducts');
                this.withBundles = value.includes('withBundles');
                this.withCustomization = value.includes('withCustomization');
                this.withWorkflow = value.includes('withWorkflow');
            },
            updateItems(result) {
                this.list = result.orderItems;
                this.bundleTrees = result.bundleTrees.map((tree) => {
                    return {
                        bundleId: tree.bundleId,
                        children: tree.children,
                        orderItemId: tree.orderItemId,
                        bundleProduct: tree.bundleProduct,
                        createdAt: tree.createdAt,
                        orderItem: this.findOrderItem(tree.orderItemId)
                    };
                });

                this.checkForAutoOpening();
            },
            refreshWatchlist() {
                this.$refs.listing.fetchItems();
            },
            checkForAutoOpening() {
                const storeKey = 'watchlistAutoEdit';
                const storeEntry = window.localStorage.getItem(storeKey);

                if (storeEntry) {
                    window.localStorage.removeItem(storeKey);
                    const orderItem = this.list.find(item => item.id === +storeEntry);

                    if (orderItem?.campaignRun?.id) {
                        this.openProjectRun(orderItem);
                    } else {
                        console.error('localStorage data corrupt?');
                    }
                }
            },

            openProjectRun(item) {
                this.projectRunData = {
                    id: item.campaignRun.id,
                    name: item.name,
                    approved: false
                };
            },
            gotoDetailPage(item) {
                let params = {
                    oid: item.id
                };
                this.$router.push({name: 'OrderItemDetail', params: params});
            },
            closeProjectRun() {
                this.projectRunData = null;
                this.refreshWatchlist();
            },

            deleteItem(item) {
                this.deleteCandidate = item;
            },
            onDeleteItem() {
                this.$store.dispatch('basket/removeBundle', {
                    bundleId: this.getBundleIdFromOrderItemId(this.deleteCandidate),
                    listType: 'watchlist'
                }).then(() => {
                    this.deleteCandidate = null;
                    this.refreshWatchlist();
                }).catch((error) => {
                    console.error(error);
                });
            },

            clearFilter() {
                this.withBundles = true;
                this.withCustomization = true;
                this.withWorkflow = true;
                this.withDefaultProducts = true;

                this.showFilter = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";

    .watchlist-container {
        border: 2px solid var(--bs-gray-middle);
        border-radius: 4px;

        :deep(.watchlist-item + .watchlist-item),
        :deep(.bundleTree + .watchlist-item),
        :deep(.watchlist-item + .bundleTree),
        :deep(.bundleTree + .bundleTree) {
            border-top: 1px solid var(--bs-gray-middle);
        }
    }
</style>
