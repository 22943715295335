<template>
    <div class="wrapper-img">
        <div class="product-label" :style="productLabelStyle" v-if="product.label">
            {{ product.label.name }}
        </div>

        <div
            v-if="product.images.length > 0"
            :id="carouselId"
            class="carousel slide"
            data-bs-ride="false"
            data-bs-interval="false"
            v-on="{'slid.bs.carousel': markThumbnail}"
        >
            <div class="carousel-inner">
                <div
                    v-for="(image, idx) in product.images"
                    :key="image.id"
                    class="carousel-item"
                    :class="{active: 0 === idx}"
                >
                    <div
                        class="img-wrap"
                        :style="getImageStyle(image)"
                        @click="() => fullscreenImg(image)"
                    >
                        <img
                            :src="'/ndx/file/downloadResized/1000/1000/' + image.filename"
                            :alt="image.filename"
                        >
                    </div>
                </div>
            </div>
            <button
                v-if="product.images.length > 1"
                class="carousel-control-prev"
                type="button"
                :data-bs-target="'#' + carouselId"
                data-bs-slide="prev"
            >
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button
                v-if="product.images.length > 1"
                class="carousel-control-next"
                type="button"
                :data-bs-target="'#' + carouselId"
                data-bs-slide="next"
            >
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
        <div
            v-else
            class="placeholder"
        >
            <NdxImage src="" size="xxl"/>
        </div>

        <div class="actions">
            <div
                v-for="(action, idx) of this.getCardActions()"
                :key="idx"
                class="card-action"
                :class="{info: action.action === null}"
                :title="action.title"
                @click.stop="() => typeof action.action === 'function' ? action.action() : null"
            >
                <NdxIcon :icon="action.icon"/>
            </div>
        </div>
    </div>

    <div
        v-if="product.images.length > 1"
        class="thumbnailWrapper d-none d-md-flex align-items-center flex-wrap gap-2 mt-3"
    >
        <div
            v-for="(image, idx) in product.images"
            :key="image.id"
            :style="getImageStyle(image)"
            class="d-flex justify-content-center align-items-center"
            :class="{active: idx === 0}"
            @click="() => jumpCarousel(idx)"
        >
            <img
                :src="'/ndx/file/downloadResized/220/220/' + image.filename"
                :class="{
                    'img-fluid': image.width >= image.height,
                    'img-fluid-h': image.height > image.width
                }"
                :alt="image.filename"
                loading="lazy"
            >
        </div>
    </div>

    <div class="notificationWrapper">
        <NdxNotification
            variant="info"
            :duration="5"
            v-model="addToCartSucceeded"
            :message="$t('message.addToCartSucceeded', {url: getBasketUrl()})"
        />

        <NdxNotification
            variant="info"
            :duration="5"
            v-model="addToWatchlistSucceeded"
            :message="$t('message.addToWatchlistSucceeded', {url: getWatchlistUrl()})"
        />
    </div>

    <NdxImageOverlay v-model="fullscreenImage"/>
</template>

<script>
    import NdxIcon from "../../library/NdxIcon";
    import NdxImageOverlay from "../../library/NdxImageOverlay";
    import NdxImage from "../../library/NdxImage";
    import {mapGetters} from "vuex";
    import NdxNotification from "../../library/NdxNotification.vue";

    export default {
        name: 'ProductImages',
        components: {NdxNotification, NdxImage, NdxImageOverlay, NdxIcon},
        props: {
            product: {
                type: Object
            }
        },
        data() {
            return {
                carouselId: 'productDetailCarousel_' + this.ndxUuid,
                fullscreenImage: null,

                addToCartSucceeded: false,
                addToWatchlistSucceeded: false
            };
        },
        computed: {
            ...mapGetters('shop', {
                defaultProductImageBgColor: 'getDefaultProductImageBgColor'
            }),
            productLabelStyle() {
                if (this.product && this.product.label) {
                    return 'background-color: ' + this.product.label.backgroundColor +
                        '; color: ' + this.product.label.fontColor;
                }
                return '';
            }
        },
        methods: {
            getBasketUrl() {
                const data = this.$store.getters['shop/getRouteByType']('basket');
                return data ? this.$router.resolve(data).href : '';
            },
            getWatchlistUrl() {
                const data = this.$store.getters['shop/getRouteByType']('watchlist');
                return data ? this.$router.resolve(data).href : '';
            },
            getImageStyle(image) {
                return 'background-color: ' + (image.imageBgColor || this.defaultProductImageBgColor || '#ffffff');
            },
            getCardActions() {
                return this.$store.getters['products/cardActions'](
                    this.product,
                    this.toggleFavState,
                    this.addToBasket,
                    this.$t,
                    this.$d,
                    this.$router
                );
            },
            toggleFavState() {
                this.$store.dispatch('products/toggleFavorite', {
                    pciId: this.product.id,
                    favorize: !this.product.isFavorite
                }).then(() => this.$store.dispatch('productDetail/setFavoriteState', !this.product.isFavorite));
            },
            jumpCarousel(imgIdx) {
                Array.from(this.$el.parentNode.querySelectorAll('.carousel-item.active'))
                    .forEach(entry => entry.classList.remove('active'));
                this.$el.parentNode.querySelectorAll('.carousel-item').item(imgIdx).classList.add('active');

                this.markThumbnail();
            },
            markThumbnail() {
                const idx = Array.from(this.$el.parentNode.querySelectorAll('.carousel-item'))
                    .findIndex(entry => entry.classList.contains('active'));
                Array.from(this.$el.parentNode.querySelectorAll('.thumbnailWrapper > *'))
                    .forEach(node => node.classList.remove('active'));
                this.$el.parentNode.querySelectorAll('.thumbnailWrapper > *').item(idx).classList.add('active');
            },
            fullscreenImg(image) {
                this.fullscreenImage = image;
            },
            addToBasket(product) {
                this.$store.dispatch('basket/addToBasket', {
                    productId: product.id,
                    quantity: 1
                }).then((result) => {
                    if (result.watchItems.find(item => item.id === result.orderItemId)) {
                        this.addToWatchlistSucceeded = true;
                    } else {
                        this.addToCartSucceeded = true;
                    }
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/bootstrap";

    .wrapper-img {
        border: 1px solid var(--bs-gray-middle);
        aspect-ratio: 1;
        width: 100%;
        position: relative;
        overflow: hidden;

        .placeholder {
            height: 100% !important;
            width: 100% !important;

            :deep(.img_wrapper.placeholder) {
                --userDefinedColor: v-bind(defaultProductImageBgColor);
                background-color: var(--userDefinedColor, var(--bs-gray-light));
            }
        }
        .product-label {
            padding: 8px 16px;
            width: auto;
            border-radius: 0 4px 4px 0;
            position: absolute;
            left: 0;
            top: 6px;
            z-index: 1;
        }

        .actions {
            position: absolute;
            top: 8px;
            right: 8px;
            /* to put it on top of image slider*/
            z-index: 1;

            .card-action {
                z-index: 1;
                background-color: white;
                border: 2px solid var(--bs-gray-middle);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover, &:active {
                    border-color: var(--bs-primary);
                    background-color: var(--bs-primary-light);
                }

                & + .card-action {
                    margin-top: 8px;
                }
            }
        }

        .carousel {
            .carousel-inner {
                aspect-ratio: 1;

                .carousel-item {
                    aspect-ratio: 1;
                    overflow: hidden;

                    .img-wrap {
                        width: 100%;
                        aspect-ratio: 1;
                        cursor: pointer;

                        img {
                            object-fit: scale-down;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .carousel-control-prev-icon,
            .carousel-control-next-icon {
                background-color: rgba(0, 0, 0, 0.15);
                border-radius: 50%;
                background-size: 70%;
            }
        }
    }

    .thumbnailWrapper > * {
        aspect-ratio: 1;
        width: 100px;
        cursor: pointer;
        border: 2px solid white;
        overflow: hidden;

        &:hover,
        &.active {
            border-color: var(--bs-primary);
        }
    }

    @include media-breakpoint-down(lg) {
        .thumbnailWrapper > * {
            width: 70px;
        }
    }
</style>
