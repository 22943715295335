<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th class="min-width text-nowrap">{{ $t('label.name') }}</th>
                    <th class="min-width text-nowrap">{{ $t('label.deliveryTime') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="option in product.deliveryOptions"
                    :key="option.id"
                >
                    <td class="min-width text-nowrap">
                        {{ option.name }}
                    </td>
                    <td class="min-width text-nowrap">
                        <span class="d-flex flex-column gap-2">
                            <span
                                v-for="scale in option.scales"
                                :key="scale.id"
                                class="d-flex flex-column gap-1"
                            >
                                <span v-if="scale.deliveryTimeTag !== null" class="d-flex gap-2">
                                    {{ scale.deliveryTimeTag }}
                                </span>
                                <span v-else class="d-flex gap-2">
                                    <span>{{ getWorkingDays(scale) }}</span>
                                    <span v-if="scale.regions.length > 0">({{ scale.regions.join(', ') }})</span>
                                    <span v-else-if="scale.regions.length === 0 && option.scales.length > 1">
                                        ({{ $t('label.worldRegion') }})
                                    </span>
                                </span>
                                <span
                                    v-if="scale.freeShippingValue !== null"
                                    class="freeShipping"
                                >
                                    {{ formatFreeShipping(scale.freeShippingValue) }}
                                </span>
                            </span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { PriceFormatter } from "../../../plugins/formatter";

    export default {
        name: "DeliveryOptions",
        computed: {
            ...mapState({
                product: state => state.productDetail.product,
                currency: state => state.shop.currency
            })
        },
        methods: {
            formatFreeShipping(value) {
                return this.$t('label.freeShippingValue', {
                    value: PriceFormatter(value, this.currency, this)
                });
            },
            getWorkingDays(scale) {
                let infoText = '';
                if (scale.min > 0 || scale.max > 0) {
                    if (scale.min === scale.max) {
                        infoText = scale.min + ' ' + this.$tc('units.workingday', scale.min);
                    } else {
                        infoText = scale.min + ' - ' + scale.max + ' ' + this.$tc('units.workingday', scale.max);
                    }
                }
                return infoText;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../../style/variables_ndx";

    .freeShipping {
        @extend %font-caption;
    }
</style>
