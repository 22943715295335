<template>
    <div class="ndx-tree-nav-widget">
        <div
            class="rootNode"
            @click="onRootClick"
        >
            <span class="node-text">{{ wgc.label }}</span>
        </div>

        <div
            v-if="sumCounts > 0"
            class="childNode"
            @click="onItemClick('-1')"
            :class="{active: filter == -1}"
        >
            <span>{{ $t('label.news.type_-1') }}</span><span>{{sumCounts}}</span>
        </div>
        <div
            class="childNode"
            @click="onItemClick('all')"
            :class="{active: filter === 'all'}"
        >
            <span>{{ $t('label.news.type_all') }}</span><span></span>
        </div>
        <div
            v-for="(count, type) of counts"
            :key="type"
            class="childNode"
            @click="onItemClick(type)"
            :class="{active: filter == type}"
        >
            <span>{{ $t('label.news.type_' + type) }}</span><span>{{count}}</span>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import {newsTypes} from "../../../store/modules/news";

    export default {
        name: "NewsCategoryWidget",
        computed: {
            ...mapState({
                counts: state => state.news.unreadNewsCounts,
                sumCounts: state => state.news.unreadNewsCount,
                filter: state => state.news.newsFilter,
                wgc: state => state.shop.headerMenu?.find(entry => entry.type === 'news') ?? {}
            })
        },
        methods: {
            onRootClick() {
                this.onItemClick(newsTypes.all);
            },
            onItemClick(type) {
                this.$store.dispatch('news/setNewsFilter', type);
                this.$router.push(this.wgc.route);
            }
        }
    };
</script>
