import approveOrderItemApi from "../../api/approveOrderItem";

//state
const state = {};

// getters
const getters = {};

// actions
const actions = {
    startApproval(ctx, data) {
        return new Promise((resolve, reject) => {
            approveOrderItemApi.startApproval(data.businessOid)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    quantityChanged(ctx, data) {
        return new Promise((resolve, reject) => {
            approveOrderItemApi.quantityChanged(data.businessOid, data.newQuantity)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// mutations
const mutations = {
    RESET() {
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
