<template>
    <NdxFlyIn>
        <template #default>
            <div
                class="card variant-card"
                :class="{
                    'ndx-product-card': flyInData.type === 'product',
                    'ndx-category-card': flyInData.type === 'folder'
                }"
            >
                <div class="image-wrapper d-flex align-items-center justify-content-center">
                    <NdxImage
                        class="card-img-top text-center"
                        :class="{'img-circle': flyInData.type === 'folder'}"
                        :src="flyInData.imgFile"
                        alt=""
                        size="xxl"
                        :style="flyInData.type === 'folder' && !flyInData.imgFile
                            ? 'background-color: var(--bs-primary);'
                            : ''
                        "
                    />
                </div>
            </div>

            <div class="flyIn-main-headline" v-if="flyInData.name && flyInData.name.length > 0">
                {{ flyInData.name }}
            </div>

            <div
                v-if="flyInData.description && flyInData.description.length > 0"
                class="flyIn-description"
                v-html="flyInData.description"
            ></div>

            <template v-if="flyInData.price">
                <div class="flyIn-headline">{{ $t('label.price') }}</div>
                <div class="flyIn-value">
                    {{ flyInData.price }}

                    <span class="quantity">&nbsp;/&nbsp;</span>
                    <span
                        v-if="parseInt(displayQuantity || 1, 10) !== 1"
                        class="quantity"
                    >{{ displayQuantity || 1 }}&nbsp;</span>
                    <span class="quantity">{{ flyInData.quantityUnit.shortName}}</span>

                </div>
            </template>

            <div class="flyIn-headline">{{ $t('label.schedule') }}</div>
            <div class="flyIn-value" v-for="(schedule, idx) in flyInData.schedules" :key="idx">
                {{ $d(schedule.startDate, 'short') }} - {{ $d(schedule.endDate, 'short') }}
                <template v-if="schedule.name">
                    &mdash; {{ schedule.name }}
                </template>
            </div>

            <template v-if="flyInData.orderHistory && flyInData.orderHistory.length > 0">
                <div class="flyIn-headline">{{ $t('label.history') }}</div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="min-width text-nowrap">{{ $t('label.order_date') }}</th>
                                <th class="min-width text-nowrap">{{ $t('label.order_ByUser') }}</th>
                                <th class="min-width text-nowrap">{{ $t('label.order_id') }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="order in flyInData.orderHistory"
                                :key="order.orderId"
                            >
                                <td class="min-width">{{ $d(dateConverter(order.orderDate), 'long') }}</td>
                                <td class="min-width">
                                    {{ order.orderedBy.firstname }} {{ order.orderedBy.lastname }}
                                </td>
                                <td>{{ order.orderId }}</td>
                                <td class="text-end">
                                    <span class="ndx-inline-link" @click="goToOrder(order)">
                                        {{ $t('label.order_details') }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </template>
        <template #buttons>
            <NdxButtonLink class="btnFlex" @click="$emit('on-close')">{{ $t('btn.close') }}</NdxButtonLink>
            <NdxButton
                v-if="flyInData.route !== null"
                @click="$router.push(flyInData.route);"
            >{{ $t('label.moreProductInfos') }}
            </NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxButton from "../library/NdxButton";
    import NdxButtonLink from "../library/NdxButtonLink";
    import { ndxDateConvert } from "@utilities/ndxDate";
    import NdxImage from "../library/NdxImage";
    import NdxFlyIn from "../library/NdxFlyIn";

    export default {
        name: 'DetailFlyIn',
        components: {NdxFlyIn, NdxImage, NdxButtonLink, NdxButton},
        props: {
            flyInData: {
                type: Object
            },
            displayQuantity: {
                type: Number
            }
        },
        emits: ['on-close'],
        data() {
            return {
                dateConverter: ndxDateConvert,
            };
        },
        methods: {
            goToOrder(order) {
                let route = JSON.parse(JSON.stringify(
                    this.$store.getters['shop/getRouteByType']('orders')
                ));

                this.$router.push({
                    name: 'OrderDetails',
                    params: {
                        id: route.params.id,
                        orderId: order.orderId
                    }
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";

    .card.variant-card {
        max-width: 360px;
        margin: 0 auto;
        cursor: default;

        &:hover .image-wrapper {
            border-color: transparent;
        }
    }

    .flyIn-main-headline {
        @extend %font-h1;

        padding: 16px 0;
        max-width: 500px;
    }

    .flyIn-description {
        @extend %font-body2;

        max-width: 500px;
        max-height: 2.75em;
        overflow: hidden;
    }

    .flyIn-headline {
        @extend %font-body2;

        margin-top: 16px;
        margin-bottom: 8px;
    }

    .flyIn-main-headline + .flyIn-headline {
        margin-top: 0;
    }

    .flyIn-value {
        @extend %font-body2;

        font-weight: bold;
        padding-left: 16px;

        .quantity {
            @extend %font-caption;
        }
    }
</style>
