<template>
    <template v-if="isRequestRunning()">
        <NdxThrobber/>
    </template>

    <div class="dataCheck-wrapper">
        <div class="dataCheck-top-wrapper d-flex justify-content-between align-items-center">
            <div>
                <img
                    v-if="logo && logo.length > 0"
                    :src="logo"
                    alt="logo"
                    class="img-fluid main-logo"
                >
            </div>
            <NdxButtonLink @click="logout">{{ $t('btn.logout') }}</NdxButtonLink>
        </div>
        <div class="dataCheck-content-wrapper">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import NdxThrobber from "../components/library/NdxThrobber";
    import NdxButtonLink from "../components/library/NdxButtonLink";

    export default {
        name: 'NdxDataCheck',
        components: {NdxButtonLink, NdxThrobber},
        computed: {
            ...mapGetters('session', [
                'isRequestRunning'
            ]),
            ...mapState({
                logo: state => state.shop.logo
            })
        },
        methods: {
            logout() {
                this.$store.dispatch(
                    'session/logout'
                ).then(() => {
                    this.$router.push({name: 'Login'});
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .dataCheck-wrapper {
        background-color: white;
        padding: 32px;
        min-height: 100%;

        .dataCheck-top-wrapper {
            padding-bottom: 32px;

            .btn {
                margin-left: 16px;
            }
        }
    }

    .main-logo {
        max-width: 300px;
        max-height: 45px;
    }
</style>
