<template>
    <component
        ref="trackingProvider"
        v-if="trackingProvider"
        :is="trackingProvider"
    />
</template>

<script>
    import { mapState } from "vuex";
    import GoogleAnalytics from "./provider/GoogleAnalytics.vue";
    import { markRaw } from "vue";

    export default {
        name: 'AppTracking',
        data() {
            return {
                trackingProvider: null,

                providerMap: {
                    'googleAnalytics': markRaw(GoogleAnalytics)
                }
            };
        },
        computed: {
            ...mapState({
                appTracking: state => state.shop.appTracking
            })
        },
        watch: {
            $route: {
                handler: 'trackRoute',
                deep: true
            }
        },
        created() {
            this.initTracking();
        },
        methods: {
            initTracking() {
                if (this.appTracking && this.appTracking.active) {
                    if (this.appTracking.provider in this.providerMap) {
                        this.trackingProvider = this.providerMap[this.appTracking.provider];
                    } else {
                        console.error('Tracking provider not supported: ' + this.appTracking.provider);
                    }
                }
            },
            trackRoute() {
                if (this.$refs.trackingProvider) {
                    this.$refs.trackingProvider.trackRoute();
                }
            }
        }
    };
</script>
