<template>
    <div
        v-if="loading === false"
        class="frameContainer"
    >
        <Top/>
        <div class="groupContainer">
            <ShippingGroup
                v-for="group in basketGroupsFiltered"
                :key="group.vendor.id"
                :group="group"
                @group-item-updated="validate"
            />
        </div>
    </div>
</template>

<script>
    import Top from "./parts/Top";
    import ShippingGroup from "./ShippingGroup";
    import { mapGetters, mapState } from "vuex";

    export default {
        name: 'Shipping',
        components: {ShippingGroup, Top},
        computed: {
            ...mapGetters('basket', {
                basket: 'getBasket',
                isMultiAddressDelivery: 'isMultiAddressDelivery',
            }),
            ...mapState({
                checkoutData: state => state.basket.checkoutData,
                loading: state => state.checkout.loading
            }),
            isDeliveryOptionsValid() {
                if (this.isMultiAddressDelivery) {
                    for (const orderItemId in this.checkoutData.orderItems) {
                        if (this.$store.getters['basket/getOrderItemById'](+orderItemId).quantity == 0) {
                            continue;
                        }
                        for (const j in this.checkoutData.orderItems[orderItemId].addresses) {
                            const addressItem = this.checkoutData.orderItems[orderItemId].addresses[j];
                            if (addressItem.selectedOptionId === null ||
                                !addressItem.deliveryOptions.find((entry) => entry.id === addressItem.selectedOptionId)
                            ) {
                                return false;
                            }
                        }
                    }
                } else if (this.checkoutData) {
                    for (const orderItemId in this.checkoutData.orderItems) {
                        if (this.$store.getters['basket/getOrderItemById'](+orderItemId).quantity == 0) {
                            continue;
                        }
                        const orderItem = this.checkoutData.orderItems[orderItemId];
                        if (orderItem.selectedOptionId === null ||
                            !orderItem.deliveryOptions.find((entry) => entry.id === orderItem.selectedOptionId)
                        ) {
                            return false;
                        }
                    }
                }

                return true;
            },
            isBudgetsValid() {
                return this.basket.summary && 'budgetExceeded' in this.basket.summary ?
                    !this.basket.summary.budgetExceeded :
                    false;
            },
            basketGroupsFiltered() {
                return this.basket.groups.filter(group => {
                    return group.items.filter(item => item.quantity > 0).length > 0;
                });
            }
        },
        created() {
            this.$store.dispatch('basket/getCheckoutData').finally(() => {
                this.$store.dispatch('checkout/loadingFinished');
                this.validate();
            });
        },
        methods: {
            validate() {
                this.$store.dispatch(
                    'checkout/setIsValid',
                    this.isDeliveryOptionsValid && this.isBudgetsValid
                );
            }
        }
    };
</script>
