<template>
    <DoughnutChartLib v-bind="doughnutChartProps" />
</template>

<script>
import { computed } from "vue";
import lodashMerge from "lodash/merge";
import { DoughnutChart as DoughnutChartLib, useDoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const plugin = {
    id: 'doughnut-center-text-plugin',
    beforeDraw: function (chart) {
        if (chart.config.options?.elements?.center) {
            // Get ctx from string
            let ctx = chart.ctx;

            // Get options from the center object in options
            let centerConfig = chart.config.options.elements.center;
            let fontStyle = centerConfig.fontStyle || 'Roboto';
            let txt = centerConfig.text;
            let color = centerConfig.color || '#808080';
            let sidePadding = centerConfig.sidePadding || 20;

            let fontSizeToUse;

            if ('fontSize' in centerConfig) {
                fontSizeToUse = centerConfig.fontSize;
            } else {
                let sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);

                // Start with a base font of 30px
                ctx.font = "30px " + fontStyle;

                // Get the width of the string and also the width of the element minus 10 to give it 5px
                // side padding
                let stringWidth = ctx.measureText(txt).width;
                let elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

                // Find out how much the font can grow in width.
                let widthRatio = elementWidth / stringWidth;
                let newFontSize = Math.floor(30 * widthRatio);
                let elementHeight = (chart.innerRadius * 2);

                // Pick a new font size so it will not be larger than the height of label.
                fontSizeToUse = Math.min(newFontSize, elementHeight);
            }

            // Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;

            // Draw text in center
            ctx.fillText(txt, centerX, centerY);
        }
    }
};

const defaultOptions = {
    /**
     * @see https://www.chartjs.org/docs/latest/configuration/legend.html
     */
    legend: {
        //display: true,
        //align: 'left',
        labels: {
            fontColor: '#808080', // grey
            fontFamily: "'Roboto', 'Verdana', 'sans-serif'",
            fontSize: 11,
            fontStyle: 'normal'
        },
    },
    /**
     * @see https://www.chartjs.org/docs/latest/configuration/layout.html
     */
    layout: {
        padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
        }
    }
};

export default {
    name: "DoughnutChart",
    components: { DoughnutChartLib },
    setup(props) {
        const testData = computed(() => (props.chartData));

        const options = computed(() => lodashMerge({}, defaultOptions, props.options));

        const { doughnutChartProps, doughnutChartRef } = useDoughnutChart({
            chartData: testData,
            options,
            plugins: [plugin]
        });

        return {
            testData,
            options,
            doughnutChartRef,
            doughnutChartProps,
        };
    },
    props: ['chartData']
};
</script>
