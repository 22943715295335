<template>
    <NdxFlyIn
        v-if="showFlyIn"
        class="basketFlyIn"
        @click-outside="closeFlyIn"
    >
        <template #title v-if="!deleteItem">
            <div class="d-flex gap-2">
                <NdxIcon v-if="icon" :icon="icon"/>
                <div>{{ basketMenuItem.label }}</div>
            </div>
        </template>

        <template #default>
            <template v-if="deleteItem">
                {{
                    $t('message.deleteBasketItem', {
                        name: deleteItem.productName,
                        quantity: $n(parseFloat(deleteItem.quantity))
                    })
                }}
            </template>

            <template v-else-if="!deleteItem">
                <SummaryWidget class="roundedBorder mt-3"/>

                <div class="roundedBorder p-3 pt-0 mt-3" v-if="newlyAdded !== null">
                    <div class="item-group-header border-b ps-0 d-flex justify-content-between align-items-start">
                        <div>
                            <div>{{ $t('message.productAddedToCart') }}</div>
                        </div>
                    </div>
                    <GroupItem
                        v-if="newlyAdded.bundleProduct === null"
                        :item="findOrderItem(newlyAdded.orderItemId)"
                        @goto-campaignrun="gotoCampaignRun(newlyAdded.orderItemId)"
                        @goto-detailpage="gotoDetailPage(newlyAdded.orderItemId)"
                        @remove-from-basket="removeBundle(newlyAdded)"
                        hide-call-to-actions
                    >
                        <template #user-actions>
                            <div class="d-flex justify-content-end">
                                <NdxButtonLink @click="removeBundle(newlyAdded)">
                                    {{ $t('btn.removeFromBasket') }}
                                </NdxButtonLink>
                            </div>
                        </template>
                    </GroupItem>
                    <BundleProductItem
                        v-else
                        :bundle="newlyAdded"
                        type="basket"
                    />
                </div>
                <div v-if="newlyAdded!==null && bundleTrees.length > 1 || newlyAdded===null && bundleTrees.length > 0"
                     class="roundedBorder p-3 pt-0 mt-3">
                    <div class="item-group-header border-b ps-0 d-flex justify-content-between align-items-start">
                        <div>
                            <div>{{ $t('message.moreProductsInCart') }}</div>
                        </div>
                    </div>
                    <template v-for="tree of bundleTrees" :key="tree.bundleId">
                        <GroupItem
                            v-if="tree.orderItemId && (newlyAdded === null || newlyAdded.bundleId !== tree.bundleId)"
                            :item="findOrderItem(tree.orderItemId)"
                            @goto-campaignrun="gotoCampaignRun(tree.orderItemId)"
                            @goto-detailpage="gotoDetailPage(tree.orderItemId)"
                            @remove-from-basket="removeBundle(tree)"
                            hide-call-to-actions
                        >
                            <template #user-actions>
                                <div class="d-flex justify-content-end">
                                    <NdxButtonLink @click="removeBundle(tree)">
                                        {{ $t('btn.removeFromBasket') }}
                                    </NdxButtonLink>
                                </div>
                            </template>
                        </GroupItem>
                        <BundleProductItem
                            v-if="tree.bundleProduct && (newlyAdded === null || newlyAdded.bundleId !== tree.bundleId)"
                            :bundle="tree"
                            type="basket"
                        />
                    </template>
                </div>
            </template>

            <NdxNotification
                v-if="bundleTrees.length === 0"
                class="mt-3"
                variant="info"
                :model-value="true"
                :duration="0"
                :message="$t('message.basketIsEmpty')"
            />
        </template>

        <template #buttons>
            <template v-if="deleteItem">
                <NdxButton class="btnFlex" @click="doRemoveItemFromBasket">{{ $t('btn.yes') }}</NdxButton>
                <NdxButton class="btnFlex" @click="abortRemoveItemFromBasket">{{ $t('btn.no') }}</NdxButton>
            </template>
            <template v-else>
                <NdxButtonLink @click="closeFlyIn">{{ $t('btn.close') }}</NdxButtonLink>
                <NdxButton @click="gotoBasket">
                    {{ basketMenuItem.label }}
                </NdxButton>
            </template>
        </template>
    </NdxFlyIn>

    <ProjectRun
        v-if="campaignRunOrderItem"
        :campaign-run-id="campaignRunOrderItem.campaignRun.id"
        :product-name="campaignRunOrderItem.productName"
        :campaign-run-approved="campaignRunOrderItem.isApproved"
        :live-price-calculation="livePriceCalculation"
        @close="refreshBasket"
        @done="refreshBasket"
    />
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import NdxButton from "../library/NdxButton.vue";
    import NdxIcon from "../library/NdxIcon.vue";
    import NdxFlyIn from "../library/NdxFlyIn.vue";
    import NdxButtonLink from "../library/NdxButtonLink.vue";
    import GroupItem from "../checkout/steps/parts/GroupItem.vue";
    import NdxNotification from "../library/NdxNotification.vue";
    import SummaryWidget from "../checkout/steps/parts/SummaryWidget.vue";
    import BundleProductItem from "../checkout/steps/parts/BundleProductItem.vue";
    import ProjectRun from "../projectRun/ProjectRun.vue";

    export default {
        name: 'BasketFlyIn',
        components: {
            ProjectRun,
            BundleProductItem, SummaryWidget, NdxNotification, GroupItem, NdxButtonLink, NdxFlyIn, NdxIcon, NdxButton
        },
        data() {
            return {
                deleteItem: null,
                deleteBundle: null,
                campaignRunOrderItem: null
            };
        },
        computed: {
            ...mapState({
                bundleTrees: state => state.basket.basket.bundleTrees,
                showFlyIn: state => state.basket.showFlyIn,
                newlyAdded: state => state.basket.newlyAdded,
                livePriceCalculation: state => state.shop.basket?.liveCalculation
            }),
            ...mapGetters('basket', {
                orderItems: 'getOrderItems'
            }),
            basketMenuItem() {
                let data = this.$store.getters['shop/getWgcsByType']('basket');
                return data[0];
            },
            icon() {
                if (!this.basketMenuItem.icon) {
                    return this.$store.getters['shop/getIconForType'](this.basketMenuItem.type);
                }
                return this.basketMenuItem.icon;
            }
        },
        watch: {
            '$route': {
                handler: 'closeFlyIn',
                deep: true
            }
        },
        methods: {
            findOrderItem(orderItemId) {
                return this.orderItems.find(item => item.orderItemId === orderItemId);
            },
            gotoBasket() {
                this.$router.push(this.basketMenuItem.route);
                this.closeFlyIn();
            },
            gotoDetailPage(orderItemId) {
                this.$router.push({name: 'OrderItemDetail', params: {
                        oid: orderItemId
                    }});
                this.closeFlyIn();
            },
            closeFlyIn() {
                this.deleteItem = null;
                this.deleteBundle = null;
                this.campaignRunOrderItem = null;
                this.$store.dispatch('basket/hideBasketFlyIn');
            },
            removeBundle(bundle) {
                this.deleteBundle = bundle;
                this.deleteItem = this.findOrderItem(bundle.orderItemId);
            },
            doRemoveItemFromBasket() {
                this.$store.dispatch('basket/removeBundle', {
                    bundleId: this.deleteBundle.bundleId,
                    listType: 'basket'
                }).finally(() => {
                    if (this.newlyAdded?.bundleId === this.deleteBundle.bundleId) {
                        this.$store.dispatch('basket/resetNewlyAdded');
                    }
                    this.deleteItem = null;
                    this.deleteBundle = null;
                });
            },
            abortRemoveItemFromBasket() {
                this.deleteItem = null;
                this.deleteBundle = null;
            },
            gotoCampaignRun(orderItemId) {
                const orderItem = this.findOrderItem(orderItemId);
                if (orderItem?.campaignRun?.id) {
                    this.campaignRunOrderItem = orderItem;
                }
            },
            refreshBasket() {
                this.campaignRunOrderItem = null;
                this.$store.dispatch('basket/getBasket');
            },
        }
    };
</script>
