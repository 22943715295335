// @see http://www.ecma-international.org/ecma-402/2.0/#sec-intl-datetimeformat-constructor
export const datetimeFormats = {
    de: {
        short: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        },
        time: {
            second: '2-digit',
            minute: '2-digit',
            hour: '2-digit',
            hour12: false
        },
        long: {
            second: '2-digit',
            minute: '2-digit',
            hour: '2-digit',
            hour12: false,
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        },
        complete: {
            second: '2-digit',
            minute: '2-digit',
            hour: '2-digit',
            hour12: false,
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            weekday: 'long',
            era: 'long',
            timeZoneName: 'long'
        }
    },
    en: {
        short: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        },
        time: {
            second: '2-digit',
            minute: '2-digit',
            hour: '2-digit',
            hour12: true
        },
        long: {
            second: '2-digit',
            minute: '2-digit',
            hour: '2-digit',
            hour12: true,
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        },
        complete: {
            second: '2-digit',
            minute: '2-digit',
            hour: '2-digit',
            hour12: true,
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            weekday: 'long',
            era: 'long',
            timeZoneName: 'long'
        }
    }
};
