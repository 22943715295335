<template>
    <div>
        <div class="bundle-filter-title mb-2 me-3">
            {{ $t('label.bundles') }}
        </div>
        <div
            class="bundleFilter d-flex gap-3 flex-wrap"
            :class="{
                order: type === 'order',
                'flex-column': !!breadcrumb
            }"
        >
            <template v-if="!breadcrumb">
                <BundleTile
                    v-for="bundle in firstLevelBundles"
                    :key="bundle.bundleId"
                    :bundle="bundle"
                    :type="type"
                    @click="selectBundle(bundle)"
                />
            </template>
            <template v-else>
                <div
                    v-for="(part,level) in breadcrumb"
                    :key="part.bundleId"

                    class="d-flex flex-wrap gap-3 flex-column"
                >
                    <BundleTile
                        :bundle="part"
                        :type="type"
                        :active="part === activeBundle"
                        @click="selectBundle(part)"
                    />
                    <div
                        v-if="level+1 === breadcrumb.length && getNextLevelBundles(part)"
                        class="d-flex flex-wrap gap-3"
                    >
                        <BundleTile
                            v-for="nextBundle in getNextLevelBundles(part)"
                            :key="nextBundle.bundleId"
                            :bundle="nextBundle"
                            :type="type"
                            @click="selectBundle(nextBundle)"
                        />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import BundleTile from "./BundleTile.vue";

    export default {
        name: 'BundleFilter',
        components: {BundleTile},
        props: {
            firstLevelBundles: {
                type: Array,
                required: true
            },
            type: {
                type: String,
                validator: function (value) {
                    return ['order', 'basket'].indexOf(value) !== -1;
                }
            }
        },
        computed: {
            ...mapGetters('shop', {
                currency: 'currency',
                defaultProductImageBgColor: 'getDefaultProductImageBgColor'
            }),
            ...mapState({
                activeBundle: state => state.bundles.activeBundle,
                breadcrumb: state => state.bundles.breadcrumb
            })
        },
        methods: {
            getNextLevelBundles(bundle) {
                if (!bundle.children) {
                    return null;
                }

                const next = bundle.children.filter((node) => {
                    return node.bundleProduct !== null && this.hasVisibleChildren(node);
                });

                return next.length ? next : null;
            },
            hasVisibleChildren(node) {
                if (node.children?.length) {
                    return node.children.reduce((visibleCount, child) => {
                            const quantity = this.$store.getters['basket/getOrderItemById'](child.orderItemId)
                                ?.quantity;

                            return visibleCount || quantity > 0;
                        },
                        false
                    );
                }

                return true;
            },
            selectBundle(bundle) {
                let path = this.$store.getters['bundles/getPathFromBundleId'](bundle.bundleId);
                if (bundle !== this.activeBundle) {
                    this.$store.dispatch('bundles/setPath', path);
                } else if (path.length > 1) {
                    path.pop();
                    this.$store.dispatch('bundles/setPath', path);
                } else {
                    this.$store.dispatch('bundles/setPath', null);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../../style/variables_ndx";

    .bundle-filter-title {
        @extend %font-h2;
    }

    .flex-column > div + div {
        border-top: 1px solid var(--bs-gray-middle);
        padding-top: 2em;
    }

    .bundleFilter {
        .bundleTile {
            max-width: 160px;
            position: relative;
        }
    }
</style>
