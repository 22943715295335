<template>
    <LoginWireframe>
        <template #leftSide>
            <div class="d-none d-md-block col-6 bg-light w-50">
                <div class="d-flex justify-content-center align-items-center w-100 h-100 p-3">
                    <PasswordChangeCriteria :form="form" :all-white="false"/>
                </div>
            </div>
        </template>
        <template #form>
            <PasswordChangeForm
                v-model:form-validation="form"
                @on-change-success="onPasswordChange"
            />
        </template>
    </LoginWireframe>
</template>

<script>
    import LoginWireframe from '../../wireframes/NdxLogin';
    import PasswordChangeForm from '../../components/passwordChange/PasswordChange';
    import PasswordChangeCriteria from "../../components/passwordChange/PasswordChangeCriteria";
    import { getRouteAfterLogin } from "../../router";

    export default {
        name: 'PasswordChange',
        components: {PasswordChangeCriteria, LoginWireframe, PasswordChangeForm},
        data() {
            return {
                form: {
                    oldPassword: {
                        required: false
                    },
                    newPassword: {
                        required: false,
                        hasAlpha: false,
                        hasNumeric: false,
                        hasSpecialChar: false,
                        hasNoWhiteSpace: true,
                        minLength: false,
                        notSameAs: false
                    },
                    confirmPassword: {
                        required: false,
                        sameAs: true
                    }
                }
            };
        },
        methods: {
            onPasswordChange() {
                this.$router.push(getRouteAfterLogin());
            }
        }
    };
</script>
