<template>
    <div
        v-if="item.quantity > 0"
        class="watchlist-item"
        :id="'orderitem-' + item.id"
        v-scroll-into-view="{route: $route}"
    >
        <WorkflowInfo
            v-if="instanceId !== null && instanceVariables !== null"
            :variables="instanceVariables"
            :start-approval-possible="startApprovalPossible"
            :show-instance-details="showInstanceDetails"
            :move-to-basket="!inTree"
            @start-approval="startApproval"
            @show-details="instanceDetails"
            @move-to-basket="moveToBasket"
            @action-type="(type) => workflowActionType = type"
            class="mb-2"
        />

        <NdxNotification
            v-if="invalidProductFeatures"
            variant="warning"
            :model-value="true"
            :duration="0"
            :message="$t('message.basketItem_not_orderable_reason_product_features')"
            class="mb-2"
            :call-to-action="{text: $t('btn.edit')}"
            @call-to-action="$emit('goto-detailpage', item)"
        />
        <NdxNotification
            v-if="item.campaignRun?.hasPortalLock ?? false"
            variant="error"
            :model-value="true"
            :duration="0"
            :message="portalLockMessage"
            class="mb-2"
        />
        <NdxNotification
            v-if="campaignRunDeleted"
            variant="error"
            :model-value="true"
            :duration="0"
            class="mb-2"
            :message="$t('message.basketItem_not_orderable_reason_cr_deleted')"
            :call-to-action="{text: $t('btn.delete')}"
            @call-to-action="$emit('remove-from-basket')"
        />
        <NdxNotification
            v-else-if="campaignRunNotReady"
            variant="info"
            :model-value="true"
            :duration="0"
            :message="$t('message.campaignRun_not_ready')"
            class="mb-2"
            :call-to-action="{text: $t('btn.editCustomization')}"
            @call-to-action="$emit('open-project-run', item)"
        />
        <div class="d-flex">
            <div class="img-wrapper">
                <NdxImage
                    :src="item.image"
                    :style="
                        'background-color: ' + (item.imageBgColor || defaultProductImageBgColor || '#f2f2f2')
                    "
                    size="xsOrder"
                />
            </div>
            <div class="item-info">
                <div class="name">{{ item.name }}</div>
                <div v-if="item.priceTag === null" class="price">
                    <span class="number">
                        {{ formatPrice(item.basePriceNet * (item.displayQuantity || 1)) }}
                    </span>
                    <span class="quantity">&nbsp;/&nbsp;</span>
                    <span
                        v-if="parseInt(item.displayQuantity || 1, 10) !== 1"
                        class="quantity"
                    >{{ item.displayQuantity || 1 }}&nbsp;</span>
                    <span class="quantity">{{ item.quantityUnit.shortName }}</span>
                </div>
                <div class="variantValues">
                    <div>
                        <span>{{ $t('label.quantity') }}:</span> {{ $n(+item.quantity) }}
                    </div>
                    <div>
                        <span>{{ $t('label.priceNet') }}: </span>
                        <template v-if="item.priceTag == null "> {{ formatPrice(item.priceNet) }}  </template>
                        <template v-else>{{item.priceTag}}</template>
                    </div>
                </div>
                <div class="variantValues">
                    <div>
                        <span>{{ $t('label.createdAt') }}:</span> {{ $d(new Date(item.createdAt), 'long') }}
                    </div>
                </div>
                <div v-if="item.variantValues !== null" class="variantValues">
                    <div
                        v-for="variantValue in item.variantValues"
                        :key="'variantValue_' + variantValue.value"
                    >
                        <span>{{ variantValue.displayName }}:</span> {{ variantValue.displayOption }}
                    </div>
                </div>
                <div v-if="item.campaignRun !== null" class="campaignRunInfo">
                    <div>
                        <span>{{ $t('label.campaignRunId') }}:</span> {{ item.campaignRun.id }}
                    </div>
                    <div
                        v-if="item.campaignRun.shopShortDescription && item.campaignRun.shopShortDescription.length > 0"
                    >
                        <span>{{ $t('label.campaignRunDesc') }}:</span>
                        {{ item.campaignRun.shopShortDescription }}
                    </div>
                </div>
                <div v-if="item.productFeatures.length" class="productFeatures">
                    <ProductFeaturesList
                        :product-features="item.productFeatures"
                    />
                </div>
            </div>
        </div>

        <div class="d-flex flex-wrap gap-3 justify-content-between">
            <div class="d-flex flex-wrap gap-3 hideOnPrintView">
                <NdxButtonLink
                    v-for="btn in leftActionBtns"
                    :key="btn"
                    @click="() => onButtonClick(btn.action)"
                >
                    {{ btn.text }}
                </NdxButtonLink>
            </div>

            <div class="d-flex flex-wrap gap-3 hideOnPrintView">
                <NdxButtonLink
                    v-for="btn in rightActionBtns"
                    :key="btn"
                    @click="() => onButtonClick(btn.action)"
                >
                    {{ btn.text }}
                </NdxButtonLink>
            </div>
        </div>
    </div>
</template>

<script>
    import NdxImage from "../library/NdxImage.vue";
    import NdxNotification from "../library/NdxNotification.vue";
    import WorkflowInfo from "../workflows/WorkflowInfo.vue";
    import NdxButtonLink from "../library/NdxButtonLink.vue";
    import ProductFeaturesList from "../basket/parts/ProductFeaturesList.vue";
    import { PriceFormatter } from "../../plugins/formatter";
    import {mapGetters} from "vuex";
    import OrderItemActions from "../../mixins/OrderItemActions.vue";

    export default {
        name: 'WatchlistItem',
        components: {ProductFeaturesList, NdxButtonLink, WorkflowInfo, NdxNotification, NdxImage},
        emits: ['open-project-run', 'delete-item', 'refresh-watchlist', 'action-required', 'goto-detailpage'],
        mixins: [OrderItemActions],
        props: {
            item: Object,
            bundleId: String,
            inTree: Boolean
        },
        data() {
            return {
                workflowActionType: null,
                timestamp: 0,
                type: "watchlist"
            };
        },
        computed: {
            ...mapGetters('shop', {
                currency: 'currency',
                defaultProductImageBgColor: 'getDefaultProductImageBgColor'
            }),
            portalLockMessage() {
                if (this.item.campaignRun?.lockMessage) {
                    return this.item.campaignRun.lockMessage;
                }

                return this.$t('message.basketItem_not_orderable_campaignRun_locked');
            },

            workflowInstanceEvents() {
                if (this.instanceId) {
                    return this.$store.getters['workflowEvents/getByInstanceId'](this.instanceId);
                }
                return [];
            },
            startApprovalPossible() {
                const campaignRunReady = !('campaignRun' in this.item && this.item.campaignRun
                    && !this.item.campaignRun.isReady);
                return this.approvalRequired && this.isEditable && campaignRunReady;
            },
            editCustomizationPossible() {
                return this.item.campaignRun !== null && this.item.permissions.isEditable && this.isEditable;
            },
            invalidProductFeatures() {
                const validateFn = this.$store.getters['products/getInvalidProductFeatures'];
                return !!(this.item?.productFeatures?.length &&
                    validateFn(this.item.productFeatures).length > 0);
            },
            campaignRunNotReady() {
                return !!(this.item.campaignRun && !this.item.campaignRun.isReady);
            },
            campaignRunDeleted() {
                return !!(this.item.checks?.CAMPAIGNRUN_DELETED);
            }
        },
        watch: {
            workflowInstanceEvents(newValue) {
                let newEvents = newValue.filter(item => item.timestamp > this.timestamp);
                if (newEvents.length) {
                    this.getWorkflowInstanceVariables();
                    this.setTimestamp();
                }
            },
            modelValue: {
                immediate: true,
                handler: function (newVal) {
                    if (newVal) {
                        this.$emit('update:modelValue', newVal);
                        this.dismissCountDown = this.duration;
                    } else {
                        this.dismissCountDown = 0;
                    }
                }
            },
            item: {
                immediate: true,
                deep: true,
                handler: 'sendActionRequiredEvent'
            },
            workflowActionType: {
                immediate: true,
                handler: 'sendActionRequiredEvent'
            }
        },
        methods: {
            sendActionRequiredEvent() {
                const actionRequired = this.campaignRunNotReady ||
                    this.invalidProductFeatures ||
                    this.workflowActionType === 'start-approval';

                if ('quantity' in this.item && this.item.quantity) {
                    this.$emit('action-required', actionRequired);
                }
            },
            setTimestamp() {
                this.timestamp = Math.floor(Date.now() / 1000);
            },

            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },

            getWorkflowInstanceVariables() {
                this.$store.dispatch('workflowInstance/getVariables', {
                    businessOid: this.workflowBusinessOid
                }).then((result) => {
                    this.instanceId = result.id;
                    this.instanceVariables = result.variables;
                }).catch((error) => {
                    console.error(error);
                });
            },
            instanceDetails() {
                this.$router.push({
                    name: 'WorkflowsInstanceDetail',
                    params: {
                        instanceId: this.instanceId
                    }
                });
            },

            startApproval() {
                this.$store.dispatch('approveOrderItem/startApproval', {
                    businessOid: this.workflowBusinessOid
                }).then((result) => {
                    this.instanceId = result.id;
                    this.instanceVariables = result.variables;
                }).catch((error) => {
                    console.error(error);
                });
            },

            moveToBasket() {
                this.$store.dispatch('basket/moveBundleToBasket', {
                    bundleId: this.bundleId
                }).then(() => {
                    this.$emit('refresh-watchlist');
                }).catch((error) => {
                    console.error(error);
                });
            },
            onButtonClick(action)
            {
                switch (action) {
                    case 'instanceDetails':
                        this.instanceDetails();
                        break;
                    case 'startApproval':
                        this.startApproval();
                        break;
                    case 'gotoCampaignRun':
                        this.$emit('open-project-run', this.item);
                        break;
                    case 'moveToBasket':
                        this.moveToBasket();
                        break;
                    case 'removeFromWatchlist':
                        this.$emit('delete-item', this.item);
                        break;
                    default:
                        console.error('Unknown button action: ', action);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";

    .watchlist-item {
        padding: 16px;

        .img-wrapper {
            width: 80px;
            height: 80px;
            flex-shrink: 0;
        }

        .item-info {
            padding-left: 16px;

            .name {
                @extend %font-h3;
            }

            .price {
                padding-top: 8px;

                .number {
                    @extend %font-h3;
                }

                .quantity {
                    @extend %font-caption;
                }
            }

            .variantValues {
                @extend %font-h3;
                padding-top: 8px;

                span {
                    font-weight: normal;
                }
            }

            .campaignRunInfo {
                @extend %font-h3;
                padding-top: 8px;

                span {
                    font-weight: normal;
                }
            }

            .productFeatures :deep(.listKey), .productFeatures :deep(.listValue) {
                @extend %font-h3;
                padding-top: 8px;
            }

            .productFeatures :deep(.listKey) {
                font-weight: normal;
            }
        }

        .actions {
            padding-top: 16px;
        }
    }
</style>
