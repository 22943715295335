<template>
    <DetailPage v-if="entry !== null" :breadcrumb="entry.name">
        <template #visual v-if="showPreview">
            <div class="wrapper-preview" :class="{fontPreview: entry.type === 'font'}">
                <div
                    v-if="entry.type === 'image'"
                    :class="{imagePreview: !isPdf}"
                >
                    <NdxPdf
                        v-if="isPdf"
                        :url="'/file/download/' + entry.filename"
                        :zoom="1"
                        class="w-100"
                        @click="setFullscreenData(entry)"
                    />
                    <img
                        v-else
                        :src="'/ndx/file/downloadResized/1000/1000/' + entry.filename"
                        :alt="entry.name"
                        @click="fullscreenImage = entry"
                    >
                </div>
                <div
                    v-else-if="entry.type === 'color'"
                    class="colorPreview"
                    :style="colorStyle"
                ></div>
                <div
                    v-else-if="entry.type === 'font'"
                >
                    <div v-html="fontDef"></div>
                    <span :style="fontStyle">{{ entry.name }}</span>
                </div>
                <div
                    v-else-if="entry.type === 'htmlFragment'"
                    class="htmlFragmentPreview"
                    @click="fullscreenFragment = entry.value"
                >
                    <div class="overlay"></div>
                    <NdxIframe scrolling="no">
                        <div v-html="entry.value"></div>
                    </NdxIframe>
                </div>
                <div
                    v-else-if="entry.type === 'file' && isPreviewableFile"
                    class="filePreview position-relative cursor-pointer"
                    @click="setFullscreenData(entry)"
                >
                    <video
                        v-if="isVideo"
                        :src="'/file/download/' + entry.filename"
                        class="w-100"
                        controls
                    />
                    <NdxPdf
                        v-else-if="isPdf"
                        :url="'/file/download/' + entry.filename"
                        :zoom="1"
                        class="w-100"
                    />
                    <div class="overlay"></div>
                </div>
            </div>
        </template>

        <template #headline>
            {{ entry.name }}
        </template>

        <template #default>
            <div
                v-if="entry.description && entry.description.length > 0"
                class="description preserveLineBreaks"
            >{{ entry.description }}</div>
            <div class="key-value-list" v-if="keyValueList.length > 0">
                <div
                    v-for="(item, idx) in keyValueList"
                    :key="idx"
                    class="key-value"
                >
                    <div class="key">{{ item.key }}:</div>
                    <div class="value">{{ item.value }}</div>
                </div>
            </div>
            <div
                v-if="showFileWrapper"
                class="file-wrapper"
            >
                <div class="label">{{ $t('label.available_files') }}</div>
                <div class="file-item" @click="downloadFile()">
                    <div class="d-flex align-items-start">
                        <NdxIcon icon="export" size="s"/>
                        <span class="filename">{{ downloadFilename }}</span>
                    </div>
                    <div class="file-size">
                        {{ downloadFilesize }}
                    </div>
                </div>
            </div>


            <NdxImageOverlay v-model="fullscreenImage"/>
            <NdxFragmentOverlay v-model="fullscreenFragment"/>
            <NdxFileOverlay
                v-model="fullscreenFile"
                :additional-data="fullscreenEntry"
                @update:model-value="resetFullscreenData"
            />
        </template>
    </DetailPage>

    <NdxNotification
        v-else-if="error"
        variant="info"
        class="m-3"
        :model-value="true"
        :duration="0"
        :message="$t('message.styleguide_entry_not_found')"
    />
</template>

<script>
    import NdxIframe from "../library/NdxIframe";
    import NdxIcon from "../library/NdxIcon";
    import { fileSize } from "@utilities/ndxFile";
    import NdxImageOverlay from "../library/NdxImageOverlay";
    import NdxFragmentOverlay from "../library/NdxFragmentOverlay";
    import NdxFileOverlay from "../library/NdxFileOverlay";
    import NdxPdf from "../library/NdxPdf";
    import NdxNotification from "../library/NdxNotification.vue";
    import { ndxTracking } from "../../utils/ndxRpc";
    import DetailPage from "../../pages/detailPage/DetailPage.vue";

    export default {
        name: 'EntryDetail',
        components: {
            DetailPage,
            NdxNotification, NdxPdf, NdxFileOverlay, NdxFragmentOverlay, NdxImageOverlay, NdxIcon, NdxIframe
        },
        props: {
            entryId: Number
        },
        data() {
            return {
                entry: null,
                fullscreenImage: null,
                fullscreenFragment: null,
                fullscreenFile: null,
                fullscreenEntry: null,
                error: false,
            };
        },
        computed: {
            showPreview() {
                const whiteList = ['image', 'htmlFragment', 'color', 'font'];
                return this.entry && (whiteList.indexOf(this.entry.type) > -1 || this.isPreviewableFile);
            },
            showFileWrapper() {
                const whiteList = ['image', 'font', 'file', 'htmlFragment'];
                return this.entry && whiteList.indexOf(this.entry.type) > -1;
            },
            fileSize() {
                if (this.entry && 'fileSize' in this.entry) {
                    const data = fileSize(this.entry.fileSize);
                    return data.value + ' ' + data.unit;
                }
                return '';
            },
            colorStyle() {
                return this.entry ? 'background-color: ' + this.entry.value + ';' : '';
            },
            downloadFilename() {
                return this.entry.type === 'htmlFragment'
                    ? this.entry.name + '.html'
                    : this.entry.value;

            },
            downloadFilesize() {
                return this.entry.type === 'htmlFragment'
                    ? ''
                    : this.fileSize;
            },
            keyValueList() {
                let list = [];

                if (this.entry.groupName && this.entry.groupName.length > 0) {
                    list.push({
                        key: this.$t('label.assetGroup'),
                        value: this.entry.groupName
                    });
                }
                list.push({
                    key: this.$t('label.assetType.label'),
                    value: this.$t('label.assetType.' + this.entry.type)
                });

                if (this.entry.type === 'color') {
                    list.push({
                        key: this.$t('label.colorType.hex'),
                        value: this.entry.value
                    });
                    if (this.entry.cmyk && this.entry.cmyk.length > 0) {
                        list.push({
                            key: this.$t('label.colorType.cmyk'),
                            value: this.entry.cmyk
                        });
                    }
                    if (this.entry.ral && this.entry.ral.length > 0) {
                        list.push({
                            key: this.$t('label.colorType.ral'),
                            value: this.entry.ral
                        });
                    }
                    if (this.entry.pantone && this.entry.pantone.length > 0) {
                        list.push({
                            key: this.$t('label.colorType.pantone'),
                            value: this.entry.pantone
                        });
                    }
                }
                if (this.entry.type === 'image') {
                    list.push({
                        key: this.$t('label.imageDimensions'),
                        value: this.entry.width + ' x ' + this.entry.height + ' px'
                    });
                    if (this.entry.colorDepth && this.entry.colorDepth > 0) {
                        list.push({
                            key: this.$t('label.colorDepth'),
                            value: this.entry.colorDepth
                        });
                    }
                    if (this.entry.colorModel && this.entry.colorModel !== 'N/A') {
                        list.push({
                            key: this.$t('label.colorModel'),
                            value: this.entry.colorModel
                        });
                    }
                }

                return list;
            },
            isVideo() {
                const videoEl = document.createElement('video');
                return this.entry.type === 'file' && this.entry.filename && this.entry.mimeType &&
                    ['maybe', 'probably'].indexOf(videoEl.canPlayType(this.entry.mimeType)) >= 0;
            },
            isPdf() {
                return ['file', 'image'].includes(this.entry.type) && this.entry.filename &&
                    ['pdf'].includes(this.entry.fileExtension);
            },
            isPreviewableFile() {
                return this.isVideo || this.isPdf;
            },
            fontDef() {
                if (this.entry.type === 'font') {
                    return `
                    <style>
                        @font-face {
                        font-family: "${this.entry.name}";
                        font-style: normal;
                        font-weight: 400;
                        src: url("/file/download/${this.entry.filename}") format("woff2");
                    }
                    </style>`;
                }

                return '';
            },
            fontStyle: function () {
                return 'font-family: "' + this.entry.name + '"; font-size: 3em;';
            },
        },
        watch: {
            entryId: {
                handler: 'loadEntry',
                immediate: true
            }
        },
        methods: {
            loadEntry() {
                if (this.entry === null || (this.entry && this.entry.id !== this.entryId)) {
                    this.$store.dispatch('assets/getEntry', {
                        id: this.entryId
                    }).then((entry) => {
                        if (entry) {
                            this.entry = entry;
                            this.error = false;
                            this.sendAppTrackingEvent();
                        } else {
                            this.error = true;
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            },
            sendAppTrackingEvent() {
                let evt = new CustomEvent('APP_TRACKER_SET_ASSET_ENTRY', {
                    detail: {
                        assetEntry: this.entry
                            ? {id: this.entry.id, name: this.entry.name}
                            : null
                    }
                });
                window.dispatchEvent(evt);
                this.sendNdxTrackEvent();
            },
            downloadFile() {
                if (this.entry.type === 'htmlFragment') {
                    this.downloadHtmlFile();
                } else {
                    const categoryId = this.$route.params.categoryId || null;
                    const downloadUrl = '/portal/file/assetDownload/' +
                        categoryId + '/' + this.entry.id + '/' + this.entry.filename;
                    window.open(downloadUrl, '_blank');
                }
            },
            downloadHtmlFile() {
                const text = this.entry.value;
                const filename = this.entry.name + '.html';

                // create downloadable html file on the fly
                let element = document.createElement('a');
                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
                element.setAttribute('download', filename);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            },

            setFullscreenData(entry) {
                this.fullscreenEntry = entry;
                this.fullscreenFile = entry.filename;
            },
            resetFullscreenData() {
                this.fullscreenEntry = null;
                this.fullscreenFile = null;
            },
            sendNdxTrackEvent() {
                ndxTracking(
                    'assetDetailView',
                    {id: this.entry.id, type: 'assetEntry'},
                    {
                        categoryId: this.$route.params.categoryId || null
                    }
                );
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_bootstrap";
    @import "../../style/variables_ndx";
    @import "~bootstrap/scss/mixins/breakpoints";

    .wrapper-preview {
        aspect-ratio: 1;

        &.fontPreview {
            aspect-ratio: auto;
        }

        .imagePreview {
            border: 1px solid var(--bs-gray-middle);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            cursor: pointer;

            &:hover {
                border-color: var(--bs-primary);
            }

            img {
                object-fit: scale-down;
                width: 100%;
                height: 100%;
            }
        }

        .colorPreview {
            width: 100%;
            height: 100%;
        }

        .htmlFragmentPreview {
            position: relative;
            width: 100%;
            height: 100%;
            background-color: var(--bs-gray-extra-light);
            cursor: pointer;
            border: 1px solid white;
            padding: 2px;

            &:hover {
                border-color: var(--bs-primary);
            }

            .overlay {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
            }

            :deep(iframe) {
                overflow: hidden;
                height: 100%;
            }
        }

        .filePreview {
            min-height: 200px;

            .overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }

    .description {
        @extend %font-body2;
        padding-top: 16px;
    }

    .key-value-list {
        .key-value {
            display: flex;

            .key {
                @extend %font-body2;
            }

            .value {
                @extend %font-h3;
                padding-left: 8px;
            }

            & + .key-value {
                margin-top: 4px;
            }
        }
    }

    .key-value:first-child {
        margin-top: 16px;
    }

    .file-wrapper {
        padding-top: 16px;

        .label {
            @extend %font-h3;
            border-bottom: 1px solid var(--bs-gray-middle);
            padding: 32px 0 16px;
        }

        .file-item {
            cursor: pointer;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            color: var(--bs-primary);
            padding: 16px 0;
            border-bottom: 1px solid var(--bs-gray-middle);

            .bi {
                margin-right: 8px;
                margin-top: 3px;
            }

            .filename {
                overflow-wrap: anywhere;
            }

            .file-size {
                @extend %font-body2;
                padding-top: 2px;
                padding-left: 16px;
                white-space: nowrap;
            }
        }
    }
</style>
