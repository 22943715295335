<template>
    <NdxPageFrame>
        <Component :is="activeComponent"/>
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import Budgets from "../../components/budgets/Budgets";
    import BudgetCategories from "../../components/budgets/BudgetCategories";
    import BudgetProducts from "../../components/budgets/BudgetProducts";
    import { mapGetters } from "vuex";

    export default {
        name: 'Budgets',
        components: {NdxPageFrame},
        computed: {
            ...mapGetters('shop', [
                'getWgc',
            ]),
            activeComponent() {
                if (this.$route.name === 'BudgetCategories') {
                    return BudgetCategories;
                }
                if (this.$route.name === 'BudgetProducts') {
                    return BudgetProducts;
                }
                return Budgets;
            }
        }
    };
</script>

<style lang="scss">
    .back-btn {
        display: flex;
        padding-bottom: 8px;
    }
</style>
