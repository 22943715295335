<template>
    <div
        :id="colorPickerId"
        class="ndx-form-element ndxColorPicker printPicker"
        :class="{
            'disabled': readonly,
            'focused': focused,
            'filled': filled,
            'error': invalid,
            [variant]: true
        }"
    >
        <div v-if="label.length > 0" class="label" @click.stop="openPicker">{{ label }}</div>
        <div class="colorPreviewWrapper d-flex align-items-center">
            <div class="dropdown" :id="colorPickerId + '_dropDown'">
                <a
                    class="btn btn-secondary dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    ref="colorinput"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                >
                    <div class="printColorPreview" :style="previewStyle"></div>
                </a>
                <div class="dropdown-menu">
                    <div v-if="!disableRgb" class="color-chooser">
                        <div
                            @click="shownType = 'cmyk'"
                            :class="{'active': shownType === 'cmyk'}"
                        >
                            CMYK
                        </div>
                        <div
                            @click="shownType = 'rgb'"
                            :class="{'active': shownType === 'rgb'}"
                        >
                            RGB
                        </div>
                    </div>

                    <CmykEditor v-if="shownType === 'cmyk'" v-model="localValue"/>
                    <RgbEditor v-if="shownType === 'rgb'" v-model="localValue"/>
                </div>
            </div>


            <div class="selectedValue" @click.stop="openPicker">{{ displayValue }}</div>

            <span class="actions" v-show="!readonly && clearButton && localValue !== null && localValue !== ''">
                <NdxIcon
                    icon="close"
                    class="action_icon icon-s"
                    @click.stop="clearColor"
                />
            </span>
            <span class="actions">
                <NdxIcon icon="color"/>
            </span>
        </div>
    </div>

</template>

<script>
    import { Dropdown } from "bootstrap/dist/js/bootstrap.esm.js";
    import { cmyk2rgb } from "@utilities/ndxColor";
    import CmykEditor from "../color/CmykEditor";
    import RgbEditor from "../color/RgbEditor";
    import NdxIcon from "../NdxIcon";

    export default {
        name: 'NdxColorPickerPrint',
        components: {NdxIcon, RgbEditor, CmykEditor},
        props: {
            /**
             * Sets the label visible to the user
             */
            label: {
                type: String,
                required: false,
                default: ''
            },

            /**
             * The selected color
             */
            modelValue: {
                validator: function (value) {
                    return value === null || typeof (value) === 'string';
                }
            },

            /**
             * Look according to the styleguide.
             * @values outlined, workingArea
             */
            variant: {
                type: String,
                default: 'primary',
                validator: function (value) {
                    return ['primary', 'secondary'].includes(value);
                }
            },

            /**
             * Whether the element should be displayed as valid or invalid
             */
            invalid: {
                type: Boolean,
                default: false
            },
            /**
             * Whether the user can edit the content or not
             */
            readonly: {
                type: Boolean,
                default: false
            },
            /**
             * show a clear button to set the color to NULL
             */
            clearButton: {
                type: Boolean,
                default: false
            },
            disableRgb: {
                type: Boolean,
                default: false
            }
        },
        emits: ['update:modelValue'],
        data() {
            return {
                cmyk2rgb: cmyk2rgb,
                bootstrapInst: null,

                shownType: 'cmyk',

                localValue: '',
                focused: false,
                colorPickerId: null
            };
        },
        computed: {
            displayValue() {
                if (this.localValue === 'inherit') {
                    return this.$t('label.textFormatting.fontColorDefault');
                } else if (this.localValue === 'rgba(0,0,0,0)') {
                    return 'transparent';
                } else if (this.localValue !== null) {
                    return this.localValue;
                }
                return '';
            },
            filled() {
                return this.localValue && this.localValue.length > 0;
            },
            previewStyle() {
                if (this.localValue && this.localValue.length > 0) {
                    if (this.localValue.substr(0, 1) === 'c') {
                        let rgb = this.cmyk2rgb(this.localValue);
                        return 'background-color: rgb(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ');';
                    }
                    if (this.localValue.substr(0, 1) === 'r') {
                        return 'background-color:' + this.localValue + ';';
                    }
                }
                return '';
            }
        },
        watch: {
            modelValue: {
                immediate: true,
                handler: 'setCurrentColor'
            }
        },
        created() {
            this.colorPickerId = 'colorPicker_' + this.ndxUuid;
            if (this.disableRgb) {
                this.shownType = 'cmyk';
            }
        },
        mounted() {
            let dropDownEl = document.getElementById(this.colorPickerId + '_dropDown');
            if (dropDownEl) {
                let me = this;
                this.bootstrapInst = new Dropdown(dropDownEl);
                dropDownEl.addEventListener('shown.bs.dropdown', function () {
                    me.focused = true;
                });
                dropDownEl.addEventListener('hidden.bs.dropdown', function () {
                    me.focused = false;
                    me.$emit('update:modelValue', me.localValue);
                });
            }
        },
        methods: {
            clearColor() {
                this.$emit('update:modelValue', null);
            },
            setCurrentColor() {
                this.localValue = this.modelValue;

                if (this.localValue && this.localValue.length > 0
                    && this.localValue.substr(0, 1) === 'r'
                ) {
                    this.shownType = 'rgb';
                } else {
                    this.shownType = 'cmyk';
                }
            },
            openPicker() {
                this.$refs.colorinput.click();
            }
        }
    };
</script>
