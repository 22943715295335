<template>
    <div
        :id="colorPickerId"
        class="ndx-form-element ndxColorPicker"
        :class="{
            'disabled': readonly,
            'focused': focused,
            'filled': filled,
            'error': invalid,
            [variant]: true
        }"
    >
        <div v-if="label.length > 0" class="label" @click="openPicker">{{ label }}</div>
        <div class="colorPreviewWrapper d-flex align-items-center">
            <input
                type="color"
                @input="onInputChange"
                :value="localValue || nullColor"
                :readonly="readonly"
                :disabled="readonly"
                @click.stop
                ref="colorinput"
            />

            <div class="selectedValue" @click="openPicker">{{ displayValue }}</div>

            <span class="actions" v-show="!readonly && clearButton && localValue !== null && localValue !== ''">
                <NdxIcon
                    icon="close"
                    class="action_icon icon-s"
                    @click.stop="clearColor"
                />
            </span>
            <span class="actions">
                <NdxIcon icon="color"/>
            </span>
        </div>
    </div>

</template>

<script>
    import NdxIcon from "../NdxIcon";

    export default {
        name: 'NdxColorPicker',
        components: {NdxIcon},
        props: {
            /**
             * Sets the label visible to the user
             */
            label: {
                type: String,
                required: false,
                default: ''
            },

            /**
             * The selected color
             */
            modelValue: {
                validator: function (value) {
                    return value === null || typeof (value) === 'string';
                }
            },

            /**
             * Look according to the styleguide.
             * @values outlined, workingArea
             */
            variant: {
                type: String,
                default: 'primary',
                validator: function (value) {
                    return ['primary', 'secondary'].includes(value);
                }
            },

            /**
             * Whether the element should be displayed as valid or invalid
             */
            invalid: {
                type: Boolean,
                default: false
            },
            /**
             * Whether the user can edit the content or not
             */
            readonly: {
                type: Boolean,
                default: false
            },
            /**
             * show a clear button to set the color to NULL
             */
            clearButton: {
                type: Boolean,
                default: false
            },
            /**
             * Value has to be in HEX
             */
            nullColor: {
                type: String,
                default: ''
            }
        },
        emits: ['update:modelValue'],
        data() {
            return {
                localValue: '',
                focused: false,
                colorPickerId: null
            };
        },
        computed: {
            displayValue() {
                if (this.localValue === 'inherit') {
                    return this.$t('label.textFormatting.fontColorDefault');
                } else if (this.localValue === 'rgba(0,0,0,0)') {
                    return 'transparent';
                } else if (this.localValue !== null) {
                    return this.localValue;
                }
                return '';
            },
            filled() {
                return this.localValue && this.localValue.length > 0;
            }
        },
        watch: {
            modelValue: {
                immediate: true,
                handler: 'setCurrentColor'
            }
        },
        created() {
            this.colorPickerId = 'colorPicker_' + this.ndxUuid;
        },
        methods: {
            onInputChange(evt) {
                const newHexColor = evt.target.value;
                this.$emit('update:modelValue', newHexColor);
            },
            clearColor() {
                this.$emit('update:modelValue', null);
            },
            setCurrentColor() {
                this.localValue = this.modelValue;
            },
            openPicker() {
                this.$refs.colorinput.click();
            }
        }
    };
</script>
