import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'product';

export default {
    filterBy(categoryId, offset, limit, recursive = false, searchText = '', entityIds = null,
             tagIds= null, tagIdOperation = null, throbberRule = throbberRules.FULLSCREEN
    ) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'filterBy',
                [categoryId, offset, limit, recursive, searchText, entityIds, tagIds, tagIdOperation],
                throbberRule
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getFavorites() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getFavorites', [], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    toggleFavorite(pciId, favorize) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'markAsFavorite', [pciId, favorize], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    find(pciId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'find', [+pciId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    findNewVariant(pciId, attr) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'findNewVariant', [+pciId, attr], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    calculatePrice(pciId, quantity, orderItemId) {
        return new Promise((resolve, reject) => {
            ndxRpc('basket', 'calculatePrice', [+pciId, +quantity, orderItemId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getPrices(pciId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getPrices', [+pciId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getSimilarProducts(pciId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getSimilarProducts', [+pciId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
