<template>
    <div class="roundedBorder">

        <div class="border-b d-flex flex-column gap-3">
            <div class="d-flex justify-content-between gap-3">
                <div class="key">{{ $t('label.deliveryAddress') }}</div>
                <div v-if="isMultiAddressDefinition" class="text-end">
                    <div class="value">{{ $t('message.multiAddressDelivery') }}</div>
                    <a
                        v-if="editable"
                        class="secondLvl highlight pointer"
                        @click="$emit('deactivate-multi-address')"
                    >
                        {{ $t('btn.deactivateMultiAddress') }}
                    </a>
                </div>
                <div
                    v-else
                    class="value"
                    :class="{'is-action': editable}"
                    @click="$emit('change-address', 'deliveryAddress')"
                >
                    <template v-if="editable && !hasDeliveryAddress">{{ $t('label.setNow') }}</template>
                    <template v-else-if="hasDeliveryAddress">
                        {{ formatAddress(checkoutData.deliveryAddress) }}
                    </template>
                    <NdxIcon v-if="editable" icon="drop-right"/>
                </div>
            </div>
            <div v-if="isDeliveryAddressInvalid" class="d-flex justify-content-between">
                <div class="key error">
                    {{ $t('message.invalidAddressError') }}
                </div>
                <div
                    v-if="editable && isDeliveryAddressEditingAllowed"
                    class="value is-action"
                    @click="$emit('edit-address', 'deliveryAddress')"
                >
                    {{ $t('label.editAddress') }}
                    <NdxIcon icon="drop-right"/>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between border-b gap-3">
            <div class="key">{{ $t('label.deliveryAddressComment') }}</div>
            <div
                v-if="hasAddressComments"
                class="value"
                :class="{'is-action': editable}"
                @click="$emit('change-comment')"
            >
                <div>
                    <template
                        v-for="(data, addressId) in addressComments"
                        :key="addressId"
                    >
                        <div v-if="data.comment?.length">
                            <div class="key disabled">{{ formatAddress(data.address) }}</div>
                            <div
                                class="d-inline-block value mb-2 text-end preserveLineBreaks"
                            >
                                {{ addressCommentSanitize(data.comment) }}
                            </div>
                        </div>
                    </template>
                </div>
                <NdxIcon v-if="editable" icon="drop-right"/>
            </div>
            <div
                v-else-if="editable"
                class="value is-action disabled"
                @click="$emit('change-comment')"
            >
                {{ $t('label.setNow') }}
                <NdxIcon icon="drop-right"/>
            </div>
        </div>
        <div class="d-flex justify-content-between border-b gap-3">
            <div class="key">{{ $t('label.paymentMethod') }}</div>
            <div class="value">{{ $t('label.paymentOffline') }}</div>
        </div>
        <div class="d-flex flex-column gap-3">
            <div class="d-flex justify-content-between gap-3">
                <div class="key">{{ $t('label.invoiceAddress') }}</div>
                <div
                    v-if="isInvoiceAddressSameAsDeliveryAddress"
                    class="value"
                    :class="{'is-action': editable}"
                    @click="$emit('change-address', 'invoiceAddress')"
                >
                    {{ $t('message.sameAsDeliveryAddress') }}
                    <NdxIcon v-if="editable" icon="drop-right"/>
                </div>
                <div
                    v-else
                    class="value"
                    :class="{'is-action': editable}"
                    @click="$emit('change-address', 'invoiceAddress')"
                >
                    {{ formatAddress(checkoutData.invoiceAddress) }}
                    <template v-if="editable">
                        {{ $t('label.setNow') }}
                        <NdxIcon icon="drop-right"/>
                    </template>
                </div>
            </div>
            <div v-if="isinvoiceAddressInvalid" class="d-flex justify-content-between">
                <div class="key error">
                    {{ $t('message.invalidAddressError') }}
                </div>
                <div
                    v-if="editable && isInvoiceAddressEditingAllowed"
                    class="value is-action"
                    @click="$emit('edit-address', 'invoiceAddress')"
                >
                    {{ $t('label.editAddress') }}
                    <NdxIcon icon="drop-right"/>
                </div>
            </div>
        </div>
    </div>
    <div
        class="roundedBorder"
        v-if="checkoutData.hasCustomCheckoutStep && listCustomCheckoutFields && groups.length"
    >
        <CheckoutCustomizationFields
            :groups="groups"
            readonly
        />
    </div>
</template>

<script>
    import { AddressFormatter } from "../../../../plugins/formatter";
    import NdxIcon from "../../../library/NdxIcon";
    import { stripHtmlTags } from "@utilities/ndxText";
    import { mapGetters, mapState } from "vuex";
    import CheckoutCustomizationFields from "./CheckoutCustomizationFields";

    export default {
        name: 'Top',
        components: {CheckoutCustomizationFields, NdxIcon},
        props: {
            editable: {
                type: Boolean
            },
            forceMultiAddress: {
                type: Boolean
            },
            listCustomCheckoutFields: {
                type: Boolean
            }
        },
        emits: ['deactivate-multi-address', 'change-address', 'change-comment', 'edit-address'],
        computed: {
            ...mapState({
                checkoutData: state => state.basket.checkoutData,
                isDeliveryAddressEditingAllowed: state => state.shop.isDeliveryAddressEditingAllowed,
                isInvoiceAddressEditingAllowed: state => state.shop.isInvoiceAddressEditingAllowed
            }),
            ...mapGetters('basket', {
                isMultiAddressDelivery: 'isMultiAddressDelivery',
                getInvalidAddresses: 'getInvalidAddresses'
            }),
            ...mapGetters('checkout', {
                groups: 'checkoutCustomization'
            }),
            ...mapGetters('user', [
                'getAddress'
            ]),
            isMultiAddressDefinition() {
                return this.forceMultiAddress || this.isMultiAddressDelivery;
            },
            isInvoiceAddressSameAsDeliveryAddress() {
                if (this.isMultiAddressDefinition) {
                    return false;
                }
                if (this.checkoutData && this.checkoutData.deliveryAddress && this.checkoutData.invoiceAddress) {
                    return this.checkoutData.deliveryAddress.id === this.checkoutData.invoiceAddress.id;
                }
                return false;
            },
            hasDeliveryAddress() {
                return this.checkoutData.deliveryAddress !== null;
            },
            hasAddressComments() {
                if (this.addressComments) {
                    for (const i in this.addressComments) {
                        if (this.addressComments[i].comment?.length) {
                            return true;
                        }
                    }
                }
                return false;
            },
            addressComments() {
                const addressComments = {};
                if (this.isMultiAddressDefinition) {
                    for (const orderItemId in this.checkoutData?.orderItems) {
                        const orderItem = this.checkoutData?.orderItems[orderItemId];
                        for (const idx in orderItem.addresses) {
                            const addressInfo = orderItem.addresses[idx];
                            addressComments[addressInfo.addressId] = {
                                address: this.getAddress(addressInfo.addressId),
                                comment: addressInfo.addressComment
                            };
                        }
                    }
                } else if (this.hasDeliveryAddress) {
                    addressComments[this.checkoutData.deliveryAddress.id] = {
                        address: this.getAddress(this.checkoutData.deliveryAddress.id),
                        comment: this.checkoutData.orderDeliveryAddressComment
                    };
                }

                return addressComments;
            },
            isDeliveryAddressInvalid() {
                return !this.isMultiAddressDefinition && this.hasDeliveryAddress &&
                    this.getInvalidAddresses.includes(this.checkoutData.deliveryAddress.id);
            },
            isinvoiceAddressInvalid() {
                return !this.isInvoiceAddressSameAsDeliveryAddress && this.checkoutData.invoiceAddress !== null &&
                    this.getInvalidAddresses.includes(this.checkoutData.invoiceAddress.id);
            }
        },
        methods: {
            formatAddress(addressArr) {
                return AddressFormatter(addressArr, this, ', ');
            },
            addressCommentSanitize(comment) {
                return stripHtmlTags(
                    JSON.parse(JSON.stringify(
                        comment || ''
                    )));
            }
        }
    };
</script>
