const scrollIntoView = {
    mounted(el, binding) {
        if (!binding.value || !('route' in binding.value)) {
            console.error('missing route parameter for directive v-scroll-into-view');
            return;
        }
        if (el.id === binding.value?.route?.hash?.replace('#', '')) {
            if ('scrollIntoViewIfNeeded' in el && typeof el.scrollIntoViewIfNeeded === 'function') {
                el.scrollIntoViewIfNeeded();
            } else if ('scrollIntoView' in el && typeof el.scrollIntoView === 'function') {
                el.scrollIntoView();
            }

            el.classList.add('blink-animation');
            window.setTimeout(() => {
                el.classList.remove('blink-animation');
            }, 5000);

            binding.value.route.hash = '';
        }
    }
};

export default scrollIntoView;
