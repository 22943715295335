<template>
    <NdxFlyIn width="450px">
        <template #title>
            {{ $t('label.switchClient') }}
        </template>
        <template #default>
            <NdxClientSelect/>
        </template>
        <template #buttons>
            <NdxButtonLink
                class="btnFlex"
                @click="() => $emit('client-select-cancelled')"
            >
                {{ $t('btn.cancel') }}
            </NdxButtonLink>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxClientSelect from "../library/NdxClientSelect.vue";

    export default {
        name: "MainClientSelect",
        components: {NdxClientSelect, NdxButtonLink, NdxFlyIn},
        emits: ['client-select-cancelled']
    };
</script>
