<template>
    <div
        class="img_wrapper d-flex justify-content-center"
        :class="wrapperClass"
    >
        <img
            :src="isPlaceholder ? placeHolderImgPath : imgPath"
            :alt="alt"
            :title="tooltip"
            :style="imgStyle"
            loading="lazy"
        />
    </div>
</template>

<script>
    const SIZES = {
        'xxs': 24,
        'xs': 55,
        'xsOrder': 80,
        's': 155,
        'm': 119,
        'l': 151,
        'xl': 240,
        'xxl': 460,
        '1000': 1000
    };

    export default {
        name: "NdxImage",
        props: {
            /**
             * The image src. <br />
             * If no src is given, a placeholder image is shown
             */
            src: {
                type: String,
                required: false,
                default: ''
            },
            url: {
                type: String,
                required: false,
                default: ''
            },

            /**
             * The alt attribute of the image
             */
            alt: {
                type: String,
                required: false,
                default: ''
            },

            tooltip: {
                type: String,
                required: false,
                default: ''
            },

            /**
             * Predefined sizes for the image <br />
             * @values xxs, xs, xsOrder, s, m, l, xl, xxl, '1000', ""
             */
            size: {
                type: String,
                required: false,
                default: 'm',
                validator: function (value) {
                    return ['xxs', 'xs', 'xsOrder', 's', 'm', 'l', 'xl', 'xxl', '1000',  ''].indexOf(value) !== -1;
                }
            },
            /**
             * If set, the image is aligned at the top of the image container,
             * otherwise it is centered
             */
            alignImageTop: {
                type: Boolean
            }
        },
        data() {
            return {
                svgCode: '',
                placeHolderImgPath: '/js/apps/shared/assets/images/no-image.svg'
            };
        },
        computed: {
            hasSrc() {
                return this.src && this.src.length > 0;
            },
            hasUrl() {
                return this.url && this.url.length > 0;
            },
            isPlaceholder() {
                return !this.hasSrc && !this.hasUrl;
            },
            wrapperClass() {
                let classNames = [
                    'size-' + this.size
                ];

                if (this.isPlaceholder) {
                    classNames.push('placeholder');
                }
                if (this.hasUrl) {
                    classNames.push('img-url');
                }
                if (this.alignImageTop) {
                    classNames.push('align-items-start');
                } else {
                    classNames.push('align-items-center');
                }

                return classNames.join(' ');
            },
            sizePx() {
                return this.size in SIZES ? SIZES[this.size] : SIZES['xl'];
            },
            imgPath() {
                if (this.hasUrl) {
                    return this.url;
                }
                return '/ndx/file/downloadResized/' + this.sizePx + '/' + this.sizePx + '/' + this.src;
            },
            imgStyle() {
                if (!this.hasUrl) {
                    return '';
                }
                return 'max-width: ' + this.sizePx + 'px; max-height: ' + this.sizePx + 'px;';
            }
        }
    };
</script>

<style scoped lang="scss">
    .img_wrapper {
        height: 100%;
        width: 100%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &.placeholder {
            background-color: var(--bs-gray-light);
            padding: 8px;
            justify-content: center;
            opacity: 1;

            img {
                max-width: 70% !important;
                max-height: 70% !important;
                height: auto;
            }
        }
    }
</style>
