import { createI18n } from 'vue-i18n';
import messages from '../locales/de.json';
import axios from 'axios';
import { datetimeFormats } from '@locales/dateTimeFormats';
import { numberFormats } from '@locales/numberFormats';

const i18n = createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'de',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: {
        de: messages
    },
    datetimeFormats,
    numberFormats
});

const loadedLanguages = ['de']; // our default language that is preloaded

function setI18nLanguage (lang) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = lang;
    } else {
        i18n.global.locale.value = lang;
    }
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);

    return lang;
}

export function loadLanguageAsync (lang = loadedLanguages[0]) {
    // If the same language
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "lang-[request]" */ `../locales/${lang}.json`).then(
        messages => {
            i18n.global.setLocaleMessage(lang, messages.default);
            if (i18n.mode === 'legacy') {
                i18n.global.locale = lang;
            } else {
                i18n.global.locale.value = lang;
            }
            loadedLanguages.push(lang);
            return setI18nLanguage(lang);
        }
    );
}

export function loadLanguageAsyncWithoutSetAppLang (lang) {
    // If the same language
    if (i18n.locale === lang) {
        return Promise.resolve(lang);
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(lang);
    }

    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "lang-[request]" */ `../locales/${lang}.json`).then(
        messages => {
            i18n.global.setLocaleMessage(lang, messages.default);
            if (i18n.mode === 'legacy') {
                i18n.global.locale = lang;
            } else {
                i18n.global.locale.value = lang;
            }
            loadedLanguages.push(lang);
            return lang;
        }
    );
}

export default i18n;
