<template>
    <NdxPageHeader
        hide-filter
        hide-view-mode
        :nav-name="getLabel('Substitutes', this)"
        :breadcrumbs="breadcrumbs"
    />
    <div class="myAccount-data-container" v-if="initiated">
        <div class="myAccount-data">
            <div class="myAccount-data-item">
                <div class="control">
                    <div class="key"></div>
                    <div class="value-wrapper">
                        <div class="value">
                            <NdxButtonLink @click="openPicker()">{{ $t('btn.add') }}</NdxButtonLink>
                        </div>
                    </div>
                </div>
            </div>
            <div class="myAccount-data-item" v-for="sub in substitutes" :key="sub.id">
                <div class="control">
                    <div class="key">{{sub.username}}</div>
                    <div class="value-wrapper">
                        <div class="value">
                            <NdxButtonLink @click="removeUser(sub)">{{ $t('btn.remove') }}</NdxButtonLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <NdxFlyIn v-if="showPicker">
        <template #title>{{ $t('label.selectSubstitutes') }}</template>
        <template #default>
            <div class="d-flex justify-content-end py-3">
                <NdxSearchInput @on-key-enter="triggerSearch" :model-value="searchText"/>
            </div>
            <NdxListing
                store-action="user/getAllClientUsers"
                :filter-criteria="filterCriteria"
                @items-changed="updateItems"
            >
                <div class="myAccount-data-container">
                    <div class="myAccount-data mb-2" v-if="listItems.length > 0">
                        <div class="myAccount-data-item" v-for="item in listItems" :key="item.id">
                            <div class="control">
                                <div class="key">{{item.username}}</div>
                                <div class="value-wrapper">
                                    <div class="value">
                                        <NdxButtonLink v-if="!ignoreIds.includes(item.id)" @click="addUser(item)">
                                            {{ $t('btn.add') }}
                                        </NdxButtonLink>
                                        <span v-else class="inUse"><NdxIcon icon="done"/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </NdxListing>
        </template>
        <template #buttons>
            <NdxButtonLink @click="hidePicker">{{ $t('btn.close') }}</NdxButtonLink>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxIcon from "../library/NdxIcon";
    import {mapGetters, mapState} from "vuex";
    import NdxButtonLink from "../library/NdxButtonLink.vue";
    import NdxFlyIn from "../library/NdxFlyIn.vue";
    import NdxSearchInput from "../library/formElements/NdxSearchInput.vue";
    import NdxListing from "../library/NdxListing.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: 'Substitutes',
        components: {NdxPageHeader, NdxListing, NdxSearchInput, NdxFlyIn, NdxButtonLink, NdxIcon},
        data() {
            return {
                searchText: '',
                showPicker: false,
                listItems: [],
                ignoreIds: [],
            };
        },
        computed: {
            ...mapState({
                initiated: state => state.myAccount.initiated,
            }),
            ...mapGetters('user', [
                'currentUserId',
                'substitutes'
            ]),
            ...mapGetters('myAccount', [
                'getLabel'
            ]),
            filterCriteria() {
                return {
                    searchText: this.searchText,
                    hideList: [this.currentUserId]
                };
            },
            breadcrumbs() {
                return [{
                    label: this.$t('label.myAccount'),
                    click: () => this.$router.push({name: 'MyAccount'})
                }, {
                    label: this.getLabel('Substitutes', this)
                }];
            }
        },
        created() {
            this.$store.dispatch('myAccount/getDataCheckConfig');
        },
        methods: {
            openPicker: function () {
                this.showPicker = true;
            },
            updateItems(items) {
                this.listItems = items;
                this.renderUser();
            },
            renderUser: function () {
                this.ignoreIds = [this.currentUserId];
                const substituteIds = this.substitutes.map((sub) => sub.id);
                this.listItems.forEach((item) => {
                    if (substituteIds.includes(item.id)) {
                        this.ignoreIds.push(item.id);
                    }
                });
            },
            hidePicker: function () {
                this.listItems = [];
                this.searchText = '';
                this.showPicker = false;
            },
            addUser: function (item) {
                this.$store.dispatch('user/addSubstitute', {
                    userId: this.currentUserId,
                    substituteId: item.id
                }).then(() => {
                    this.$store.dispatch('user/getUserAndClient')
                        .then(() => {
                            this.renderUser();
                        });
                });
            },
            removeUser: function (item) {
                this.$store.dispatch('user/removeSubstitute', {
                    userId: this.currentUserId,
                    substituteId: item.id
                }).then(() => {
                    this.$store.dispatch('user/getUserAndClient');
                });
            },
            triggerSearch(searchStr) {
                this.searchText = searchStr;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/bootstrap-utilities";

    .inUse {
        font-size: 16px;
        padding: 0.375rem;
    }

    .flyIn .myAccount-data-container {
        max-height: calc(100vh - 265px);
    }

    @include media-breakpoint-down(md) {
        .flyIn .myAccount-data-container {
            max-height: calc(100vh - 400px);
        }
    }
</style>
