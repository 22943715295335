<template>
    <NdxPageFrame>
        <OrderDetail :orderId="+$route.params.orderId" />
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import OrderDetail from "../../components/orders/detail/OrderDetail";

    export default {
        name: 'OrderDetails',
        components: {NdxPageFrame, OrderDetail},
    };
</script>
