<template>
    <template v-if="isRequestRunning()">
        <NdxThrobber/>
    </template>

    <div class="login-app d-flex h-100 justify-content-center align-items-center">
        <div class="login-wrapper container-fluid p-0 m-0 m-sm-4">
            <div class="row g-0 h-100">
                <slot name="leftSide">
                    <div
                        class="d-none d-md-block col-6"
                        :class="{
                            'bg-white': hasLoginFragment,
                            'bg-primary': !hasLoginFragment
                        }"
                    >
                        <div
                            v-if="hasLoginFragment"
                            v-html="loginFragment"
                            class="h-100 w-100"
                        ></div>
                    </div>
                </slot>
                <div class="col col-md-6 d-flex flex-column h-100">
                    <div class="ps">
                        <div class="login-form-container w-100 d-flex align-items-center flex-column">
                            <div class="align-self-end">
                                <NdxSelect
                                    :options="languageSelect"
                                    v-model="selectedLang"
                                    variant="outline"
                                />
                            </div>
                            <div class="logo">
                                <img
                                    v-if="loginLogo && loginLogo.length > 0"
                                    :src="'/ndx/image/resize/300/50/' + loginLogo"
                                    alt="logo"
                                    class="img-fluid"
                                >
                            </div>
                            <slot name="form"></slot>
                        </div>
                    </div>
                    <div v-if="headlineLinks.length > 0" class="flex-grow-1 d-flex">
                        <div class="col align-self-end text-center p-3">
                            <NdxMenuEntry
                                v-for="item in headlineLinks"
                                :key="item.id"
                                :wgc="item"
                                icon-size="s"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NdxThrobber from "../components/library/NdxThrobber";
    import { mapGetters, mapState } from "vuex";
    import NdxSelect from "../components/library/formElements/NdxSelect";
    import NdxMenuEntry from "../components/library/NdxMenuEntry";

    export default {
        name: "NdxLogin",
        components: {NdxMenuEntry, NdxSelect, NdxThrobber},
        data() {
            return {
                languageSelect: [
                    {value: "de", text: this.$t('language_chooser.de')},
                    {value: "en", text: this.$t('language_chooser.en')}
                ]
            };
        },
        computed: {
            ...mapState({
                loginFragment: state => state.shop.loginFragment,
                loginLogo: state => state.shop.loginLogo,
                headlineLinks: state => state.shop.headlineLinks
            }),
            ...mapGetters('session', [
                'isRequestRunning'
            ]),
            hasLoginFragment() {
                return this.loginFragment && this.loginFragment.length > 0;
            },
            selectedLang: {
                get() {
                    if (this.$i18n && this.$i18n.locale.length) {
                        return this.$i18n.locale;
                    }
                    return 'de';
                },
                set(newLang) {
                    this.$store.dispatch('session/switchLanguage', newLang);
                }
            }
        },
        watch: {
            selectedLang: function (newLang) {
                this.$store.dispatch('shop/getPreLoginData', newLang);
            }
        },
        methods: {
            getLinkLabel(link) {
                if (link.label && link.label.length > 0) {
                    return link.label;
                }
                return this.$t('label.' + link.type);
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../style/custom_vars";

    .login-app {
        background-color: white;
    }

    a {
        white-space: nowrap;
        display: inline-block;

        & + a {
            &::before {
                content: "|";
                padding-left: 8px;
                padding-right: 8px;
            }
        }
    }

    .login-wrapper {
        height: 100%;
        max-width: 960px;
        background-color: white;
        overflow: hidden;

        .login-form-container {
            padding: 30px;

            .logo {
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .login-wrapper {
            height: calc(100vh - 50px);
            border-radius: 12px;
            box-shadow: 0 0 16px 4px #999999;
        }
    }

    @include media-breakpoint-up(md) {
        .login-wrapper {
            height: 710px;
        }
    }
</style>
