<template>
    <div
        class="dropdown"
        :class="{'dropstart': dropLeft}"
    >
        <div
            class="dropdown-toggle d-flex align-items-center"
            :class="{'justify-content-between': !dropLeft}"
            :id="dropdownId"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-boundary="body"
        >
            <NdxIcon v-if="!noCaret && dropLeft" icon="drop-left" class="me-2" size="m"/>
            <div>
                <slot name="default"/>
            </div>
            <NdxIcon v-if="!noCaret && !dropLeft" icon="drop-down" size="m"/>
        </div>
        <div
            class="dropdown-menu"
            :class="{'has-submenu': hasSubmenu}"
            :aria-labelledby="dropdownId"
        >
            <div
                v-for="(option, idx) in options"
                :key="idx"
                :class="{
                    'dropdown-item': true,
                    'divider': 'divider' in option && option.divider === true,
                    'highlighted': 'highlighted' in option && option.highlighted === true,
                    active: 'active' in option && option.active,
                    disabled: 'disabled' in option && option.disabled
                }"
                @click="onClick(option)"
            >
                <template v-if="'items' in option && Array.isArray(option.items) && option.items.length > 0">
                    <NdxDropdown
                        :options="option.items"
                        @click.stop
                        drop-left
                        @close-dropdown="closeDropdown"
                    >
                        <div class="sub-dropdown-label">{{ option.text }}</div>
                    </NdxDropdown>
                </template>
                <template v-else>
                    <div class="dropdown-item-label d-flex align-items-center">
                        <template v-if="'icon' in option && option.icon.length > 0">
                            <NdxIcon :icon="option.icon" size="s" class="me-2"/>
                        </template>
                        <span>{{ option.text }}</span>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "./NdxIcon";
    import { Dropdown } from "bootstrap";

    export default {
        name: 'NdxDropdown',
        components: {NdxIcon},
        props: {
            options: {
                type: Array,
                default: function () {
                    return [];
                },
                required: true
            },
            noCaret: {
                type: Boolean
            },
            dropLeft: {
                type: Boolean
            }
        },
        emits: ['on-click', 'close-dropdown'],
        data() {
            return {
                bootstrapInst: null,
                dropdownId: 'dropdownMenuButton_' + this.ndxUuid
            };
        },
        computed: {
            hasSubmenu() {
                for (let option of this.options) {
                    if ('items' in option && Array.isArray(option.items) && option.items.length > 0) {
                        return true;
                    }
                }
                return false;
            }
        },
        mounted() {
            let dropDownEl = document.getElementById(this.dropdownId);
            if (dropDownEl) {
                this.bootstrapInst = new Dropdown(dropDownEl);
            }
        },
        methods: {
            onClick: function (option) {
                if ('click' in option && typeof option.click === 'function') {
                    option.click(option);
                } else if ('route' in option) {
                    this.$router.push(option.route);
                } else {
                    this.$emit('on-click', option);
                }
                this.closeDropdown();
            },
            closeDropdown() {
                this.$emit('close-dropdown');
                if (this.bootstrapInst) {
                    this.bootstrapInst.hide();
                }
            }
        }
    };
</script>
