<template>
    <NdxHorizontalSlider
        v-if="products.length > 0 && isSlider"
        :headline="widget.displayName ? widget.name : ''"
        :autoplay="autoplay"
    >
        <NdxProductCard
            v-for="product in products"
            :key="product.id"
            :card-img="product.image"
            :card-img-background-color="product.imageBgColor"
            :title="product.name"
            :description="product.listingDescription"
            :price="product.minBasePrice"
            :price-tag="product.priceTag"
            :list-price="product.listPrice"
            :currency="currency"
            :quantity="product.displayQuantity || 1"
            :quantityUnit="product.quantityUnit"
            :label="product.label"
            :actions="getCardActions(product)"
            variant="card"
            :bundleParent="product.bundleParent"
            @card-click="() => gotoDetailPage(product)"
        />
    </NdxHorizontalSlider>
    <div
        v-else-if="products.length > 0"
        class="card-container viewmode-card"
    >
        <NdxProductCard
            v-for="product in products"
            :key="product.id"
            :card-img="product.image"
            :card-img-background-color="product.imageBgColor"
            :title="product.name"
            :description="product.listingDescription"
            :price="product.minBasePrice"
            :price-tag="product.priceTag"
            :list-price="product.listPrice"
            :currency="currency"
            :quantity="product.displayQuantity || 1"
            :quantityUnit="product.quantityUnit"
            :label="product.label"
            :actions="getCardActions(product)"
            variant="card"
            :bundleParent="product.bundleParent"
            @card-click="() => gotoDetailPage(product)"
        />
    </div>
    <div class="notificationWrapper">
        <NdxNotification
            variant="info"
            :duration="5"
            v-model="addToCartSucceeded"
            :message="$t('message.addToCartSucceeded', {url: getBasketUrl()})"
        />

        <NdxNotification
            variant="success"
            :duration="5"
            v-model="addToWatchlistSucceeded"
            :message="$t('message.addToWatchlistSucceeded', {url: getWatchlistUrl()})"
        />
    </div>
</template>

<script>
import NdxHorizontalSlider from "../library/NdxHorizontalSlider";
import NdxProductCard from "../library/NdxProductCard";
import {mapGetters} from "vuex";
import NdxNotification from "../library/NdxNotification";

export default {
    name: 'ProductListWidget',
    components: {NdxNotification, NdxProductCard, NdxHorizontalSlider},
    props: {
        widget: Object
    },
    data() {
        return {
            products: [],
            querySize: 25,
            addToCartSucceeded: false,
            addToWatchlistSucceeded: false
        };
    },
    computed: {
        ...mapGetters('shop', [
            'currency'
        ]),
        autoplay() {
            return this.widget.config.autoplay === true;
        },
        isSlider() {
            return this.widget.config.version === 1;
        }
    },
    created() {
        this.loadProducts();
    },
    methods: {
        getBasketUrl() {
            const data = this.$store.getters['shop/getRouteByType']('basket');
            return data ? this.$router.resolve(data).href : '';
        },
        getWatchlistUrl() {
            const data = this.$store.getters['shop/getRouteByType']('watchlist');
            return data ? this.$router.resolve(data).href : '';
        },
        loadProducts() {
            if (this.widget) {
                this.$store.dispatch('products/getProducts', {
                    filterCriteria: {
                        categoryId: this.widget.config.selectedId
                    },
                    offset: 0,
                    limit: this.querySize
                }).then((result) => {
                    this.products = result.list;
                }).catch((error) => {
                    console.error(error);
                    this.products = [];
                });
            }
        },
        getCardActions(product) {
            return this.$store.getters['products/cardActions'](
                product,
                this.favorizeProduct,
                this.addToBasket,
                this.$t,
                this.$d,
                this.$router
            );
        },
        favorizeProduct(product, newFavoriteState) {
            this.$store.dispatch('products/toggleFavorite', {
                pciId: product.id,
                favorize: newFavoriteState
            }).then(() => this.products.forEach(prod => {
                if (product.id === prod.id) {
                    prod.isFavorite = !prod.isFavorite;
                }
            }));
        },
        gotoDetailPage(product) {
            this.$router.push({name: 'Product', params: {pid: product.id}});
        },
        addToBasket(product) {
            this.$store.dispatch('basket/addToBasket', {
                productId: product.id,
                quantity: 1
            }).then((result) => {
                if (result.watchItems.find(item => item.id === result.orderItemId)) {
                    this.addToWatchlistSucceeded = true;
                } else {
                    this.addToCartSucceeded = true;
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap-utilities";

.card-container.viewmode-card {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 16px;
}

@include media-breakpoint-up(md) {
    .card-container.viewmode-card {
        grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
    }
}
</style>
