// for build-in validators @see https://vuelidate.js.org/#sub-builtin-validators

import {helpers, minValue, maxValue, url} from "@vuelidate/validators";

export const hasUppercase = helpers.regex(/^[A-Z]+$/);
export const hasLowercase = helpers.regex(/^[a-z]+$/);
export const hasNoWhiteSpace = helpers.regex(/^[^\s]+$/);
export const isFloatIntl = helpers.regex(/^-?(\d+([,.]\d{3})*|\d+([,.]\d{3})*[,.]\d+)$/);
export const isPhone = helpers.regex(/^\(?((0{2}|\+)|(0))?[123456789]([-\d\s()/]+)$/);
export const isDomain = helpers.regex(
    /^(((?!-))(xn--)?[a-z0-9-_]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30})\.[a-z]{2,}$/);

export const xml = (params) =>
    helpers.withParams({type: 'xml', ...params}, (formValue) => {
        let valid = true;

        try {
            if (typeof formValue === 'string' && formValue.length) {
                let classDefinitionXml = (new DOMParser()).parseFromString(formValue, "text/xml");

                valid = classDefinitionXml.getElementsByTagName('parsererror').length === 0;
            } else {
                valid = formValue === null || formValue === '';
            }
        } catch (e1) {
            if (e1.message === 'SyntaxError') {
                valid = false;
            }
        }

        return valid;
    });

export const isInList = function (list) {
    return helpers.withParams({type: 'isInList', list}, (formValue) => {
        return list.includes(formValue);
    });
};

export const isInBlacklistCi = function (list) {
    return helpers.withParams({type: 'isInBlackListCi', list}, (formValue) => {
        return list.map(entry => entry.toLowerCase()).includes(formValue.toLowerCase());
    });
};

export const arrayNotEmpty = (params) => {
    return Array.isArray(params) && params.length > 0;
};

/**
 * If value has a length, then
 *     - at least one textVar is needed
 *     - all containing textVars has to be valid
 *     - value must start with a textVar
 *
 * @param {String|null} value
 * @returns {boolean}
 */
export const textVar = function (value) {
    let textVar;
    let textVars = [];

    // valid if empty
    if (!value || (value && !value.length)) {
        return true;
    }
    if (value && value.length) {
        textVars = value.match(/\{\{([^}]+)}}/g) || [];
    }
    // not valid if value has length and no textVars are given
    if (!textVars.length && value && value.length) {
        return false;
    }
    // not valid if value has length and value doesn't starts with textVar
    if (value.startsWith('{{') === false) {
        return false;
    }

    for (let i = 0; i < textVars.length; i++) {
        textVar = textVars[i].replace(/\{\{|\}\}/g, '');
        // not valid if a single textVar is invalid
        if (!textVar.match(/^[a-zA-ZÀ-ÿ][a-zA-Z0-9À-ÿ ._-]*$/)) {
            return false;
        }
    }

    return true;
};

/**
 * @param {Array} validUnits
 * @param {Number|null} [min]
 * @param {Number|null} [max]
 * @returns {boolean}
 */
export const measurement = (validUnits, min, max) => {
    return helpers.withParams({type: 'measurement', validUnits, min, max}, (formValue) => {
        const split = /^(-?[\d+,.]+)(.*)$/.exec(formValue);

        if (!split || split.length !== 3) {
            return false;
        }

        const value = split[1];
        const unit = split[2];

        return validUnits.includes(unit) &&
            (isNaN(min) || minValue(min).$validator(parseFloat(value))) &&
            (isNaN(max) || maxValue(max).$validator(parseFloat(value)));
    });
};

export const hasAlpha = (params) =>
    helpers.withParams({type: 'hasAlpha', ...params}, (formValue) => {
        return params.emptyStringIsValid === true || /[a-z]+/.test(formValue) && /[A-Z]+/.test(formValue);
    });

export const hasNumeric = (params) =>
    helpers.withParams({type: 'hasNumeric', ...params}, (formValue) => {
        return params.emptyStringIsValid === true || /[0-9]+/.test(formValue);
    });

export const hasSpecialChar = (params) =>
    helpers.withParams({type: 'hasSpecialChar', ...params}, (formValue) => {
        return params.emptyStringIsValid === true || /[^0-9a-zA-Z \t]+/.test(formValue);
    });

export const msgParams = (params) =>
    helpers.withParams({type: 'msgParams', ...params}, () => helpers.regex('msgParams', /.*/));

export const urlWithoutProtocol = (urlString) => {
    return url.$validator(urlString) ||
        url.$validator('http://' + urlString) ||
        url.$validator('https://' + urlString);
};
export const urlWithProtocol = (urlString) => {
    return url.$validator(urlString);
};

export const neg = (validator) =>
    helpers.withParams(validator.$params, (formvalue) => !validator.$validator(formvalue));

