import countryApi from '../../api/country';

//state
const state = {
    countries: [],
    isoLanguageCodes: null
};

// getters
const getters = {
};

// actions
const actions = {
    getCountryList({commit, state}) {
        return new Promise((resolve) => {
            if (state.countries.length === 0) {
                countryApi.getCountryList()
                    .then((result) => {
                        commit('SET_COUNTRIES', result);
                        resolve(result);
                    })
                    .catch((error) => {
                        commit('RESET');
                        resolve(error);
                    });
            } else {
                resolve(state.countries);
            }
        });
    },
    getIsoLanguageCodes({commit, state}) {
        return new Promise((resolve) => {
            if (!state.isoLanguageCodes) {
                countryApi.getIsoLanguageCodes()
                    .then((result) => {
                        commit('SET_ISO_LANGUAGE_CODES', result);
                        resolve(result);
                    })
                    .catch((error) => {
                        commit('RESET');
                        resolve(error);
                    });
            } else {
                resolve(state.isoLanguageCodes);
            }
        });
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.countries = [];
        state.isoLanguageCodes = null;
    },
    SET_COUNTRIES: (state, countries) => {
        state.countries = countries;
    },
    SET_ISO_LANGUAGE_CODES: (state, isoLanguageCodes) => {
        state.isoLanguageCodes = isoLanguageCodes;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
