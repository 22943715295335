import tagApi from "../../api/tags";

//state
const state = {
    cache: {}
};

// getters
const getters = {};

// actions
const actions = {
    find({commit, state}, data) {
        return new Promise((resolve, reject) => {
            const tagId = +data.id;
            if (tagId in state.cache) {
                resolve(state.cache[tagId]);
            }
            tagApi.find(tagId)
                .then((tag) => {
                    commit('ADD_TAG', tag);
                    resolve(tag);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getTagsByIdsOfSameType({commit}, data) {
        return new Promise((resolve, reject) => {
            tagApi.getTagsByIdsOfSameType(data.ids, data.type)
                .then((list) => {
                    for (let i in list) {
                        for (let j in list[i].tags) {
                            commit('ADD_TAG', list[i].tags[j]);
                        }
                    }
                    resolve(list);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getTags({commit}, data) {
        return new Promise((resolve, reject) => {
            tagApi.getReferencedTags(data.type)
                .then((list) => {
                    for (let i in list) {
                        commit('ADD_TAG', list[i]);
                    }
                    resolve(list);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.cache = {};
    },
    ADD_TAG(state, tag) {
        state.cache[tag.id] = tag;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
