<template>
    <div
        v-if="printView"
        class="d-flex justify-content-between d-print-none pt-3 pb-3"
    >
        <NdxButton @click="disablePrintView">{{ $t('btn.disablePrintView') }}</NdxButton>
        <NdxButton @click="print">{{ $t('btn.openPrintDialog') }}</NdxButton>
    </div>
    <div
        v-if="basket && !loading"
        class="basket-frame"
    >
        <div class="frameContainer">
            <template v-if="printView">
                <div v-html="contentBlocks.header"></div>
                <table class="printViewTbl roundedBorder w-100">
                    <tbody>
                        <tr>
                            <td class="border-right">
                                <table class="list-key-table">
                                    <tbody>
                                        <tr>
                                            <th class="listKey">{{ $t('label.date') }}</th>
                                            <td class="listValue">{{ $d(new Date(), 'short') }}</td>
                                        </tr>
                                        <tr>
                                            <th class="listKey">{{ $t('label.client') }}</th>
                                            <td class="listValue">{{ $store.getters['user/shopClientName'] }}</td>
                                        </tr>
                                        <tr>
                                            <th class="listKey">{{ $t('label.user') }}</th>
                                            <td class="listValue">{{ $store.getters['user/userFullname'] }}</td>
                                        </tr>
                                        <tr>
                                            <th class="listKey">{{ $t('label.email') }}</th>
                                            <td class="listValue">{{ $store.getters['user/userEmail'] }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                                <SummaryWidget/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>

            <NdxPageHeader
                class="hideOnPrintView"
                :breadcrumbs="breadcrumbs"
                :active-route-callback="activeBundle ? clearFilter : null"
                :nav-name="basketMenuItem.label"
                :view-mode-options="viewModeOptions"
                :view-mode-storage-key="VIEW_MODE_STORAGE_KEY"
                v-model:view-mode="viewMode"
                :active-filter="activeBundle !== null"
                :filter-btn-disabled="!(bundles?.length) || viewMode !== VIEW_MODE_VENDORS"
                v-model:show-filter="showFilter"
            />

            <NdxFlyIn v-if="showFilter" class="limit500">
                <template #title>
                    {{ $t('label.filterHeadline') }}
                </template>
                <template #default>
                    <BundleFilter v-if="bundles?.length" type="basket" :first-level-bundles="bundles"/>
                </template>
                <template #buttons>
                    <NdxButtonLink
                        class="btnFlex"
                        @click="clearFilter"
                        :disabled="activeBundle === null"
                    >
                        {{ $t('btn.reset') }}
                    </NdxButtonLink>
                    <NdxButton class="btnFlex" @click="showFilter = false">
                        {{ $t('btn.close') }}
                    </NdxButton>
                </template>
            </NdxFlyIn>

            <NdxNotification
                class="hideOnPrintView"
                v-if="basket.summary?.budgets"
                variant="info"
                :model-value="Object.keys(basket.summary.budgets).length > 0"
                :duration="0"
                :message="$t('message.budgetsUsed')"
            />
            <NdxNotification
                class="hideOnPrintView"
                v-if="isBudgetExceeded"
                variant="error"
                :model-value="isBudgetExceeded"
                :duration="0"
                :message="$t('message.budgetExceeded')"
            />

            <BasketSummary
                v-if="!printView"
                class="d-block d-xl-none mt-2 mb-4"
            />

            <div
                v-if="viewMode === VIEW_MODE_VENDORS && filteredGroups?.length"
                class="groupContainer"
            >
                <BasketGroup
                    v-for="group in filteredGroups"
                    :key="group.vendor.id"
                    :group="group"
                    @refresh-basket="getBasket"
                />
            </div>
            <div
                v-else-if="viewMode === VIEW_MODE_PRODUCTS && filteredList?.length"
                class="treeView roundedBorder p-3"
            >
                <template v-for="bundle in filteredList" :key="bundle.bundleId">
                    <BundleTree
                        v-if="bundle.bundleProduct"
                        type="basket"
                        :bundle="bundle"
                        :order-items="getOrderItems"
                        @refresh-list="getBasket"
                    />
                    <BasketGroupItem
                        v-else-if="bundle.orderItem"
                        :item="getOrderItem(bundle.orderItem.orderItemId)"
                        @refresh-basket="getBasket"
                    />
                </template>
            </div>
            <div v-else>
                <NdxNotification
                    variant="info"
                    :model-value="true"
                    :duration="0"
                    :message="$t('message.basketIsEmpty')"
                />
            </div>
            <div v-if="printView" v-html="contentBlocks.footer"></div>
        </div>
    </div>
    <div v-else>
        <NdxNotification
            variant="info"
            :model-value="true"
            :duration="0"
            :message="$t('message.basketCalculationRunning')"
        />
    </div>

    <BasketQuickCheckout/>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import BasketGroup from "./parts/BasketGroup";
    import BasketQuickCheckout from "./BasketQuickCheckout";
    import SummaryWidget from "../checkout/steps/parts/SummaryWidget";
    import BundleFilter from "../checkout/steps/parts/BundleFilter.vue";
    import NdxNotification from "../library/NdxNotification";
    import NdxButton from "../library/NdxButton";
    import NdxFlyIn from "../library/NdxFlyIn.vue";
    import NdxButtonLink from "../library/NdxButtonLink.vue";
    import BundleTree from "../checkout/steps/parts/BundleTree.vue";
    import BasketGroupItem from "./parts/BasketGroupItem.vue";
    import BasketSummary from "./parts/BasketSummary.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: "BasketFrame",
        components: {
            NdxPageHeader, BasketSummary, BasketGroupItem, BundleTree, NdxNotification,
            BasketGroup, NdxButtonLink, NdxFlyIn, BundleFilter, BasketQuickCheckout, SummaryWidget, NdxButton
        },
        data() {
            return {
                loading: false,
                showFilter: false,

                VIEW_MODE_STORAGE_KEY: 'basketViewMode',
                VIEW_MODE_VENDORS: 'vendors',
                VIEW_MODE_PRODUCTS: 'products',
                viewMode: 'vendors'
            };
        },
        computed: {
            ...mapGetters('basket', {
                isBudgetExceeded: 'isBudgetLimitExceeded',
                getOrderItem: 'getOrderItem',
                getOrderItems: 'getOrderItems',
                hasPrintView: 'hasPrintView',
                productCount: 'getProductCount'
            }),
            ...mapState({
                bundleBreadcrumbs: state => state.bundles.breadcrumb,
                activeBundle: state => state.bundles.activeBundle,
                printView: state => state.basket.printView,
                contentBlocks: state => state.basket.contentBlocks
            }),
            showPrintViewBtn() {
                return this.hasPrintView && this.productCount > 0;
            },
            basket() {
                return this.$store.getters['basket/getBasket'];
            },
            basketMenuItem() {
                let data = this.$store.getters['shop/getWgcsByType']('basket');
                return data[0];
            },
            bundles() {
                // we must use bundleTrees from basket here
                if (this.basket.bundleTrees) {
                    return this.basket.bundleTrees.filter((tree) => {
                        return tree.bundleProduct !== null;
                    });
                }
                return [];
            },
            filteredGroups() {
                let _filteredGroups = [];

                const hasNode = (nodes, orderItemId) => {
                    let found = nodes.find((node) => {
                        if (node.orderItemId === orderItemId) {
                            return true;
                        }
                        if (node.bundleProduct) {
                            return hasNode(node.children, orderItemId);
                        }
                    });
                    return !!found;
                };
                const isItemVisible = (item) => {
                    if (this.activeBundle === null || !this.activeBundle.children) {
                        return true;
                    }
                    return hasNode(this.activeBundle.children, item.orderItemId);
                };

                this.basket.groups.forEach((group) => {
                    let filteredItems = group.items.filter((item) => {
                        return isItemVisible(item);
                    });
                    if (filteredItems.length) {
                        _filteredGroups.push({
                            items: filteredItems,
                            summary: group.summary,
                            vendor: group.vendor
                        });
                    }
                });
                return _filteredGroups;
            },
            filteredList() {
                return this.basket.bundleTrees.map((tree) => {
                    return {
                        bundleId: tree.bundleId,
                        children: tree.children,
                        orderItemId: tree.orderItemId,
                        bundleProduct: tree.bundleProduct,
                        orderItem: this.getOrderItem(tree.orderItemId)
                    };
                }).filter((entry) => {
                    // add vendor filter
                    console.log(entry);
                    return true;
                });
            },
            breadcrumbs() {
                let list = [];
                if (this.bundleBreadcrumbs) {
                    for (let item of this.bundleBreadcrumbs) {
                        let click = () => {
                            const path = this.$store.getters['bundles/getPathFromBundleId'](item.bundleId);
                            this.$store.dispatch('bundles/setPath', path);
                        };
                        if (this.activeBundle && this.activeBundle.bundleId === item.bundleId) {
                            click = null;
                        }
                        list.push({
                            label: item.bundleProduct.name,
                            click: click
                        });
                    }
                }
                return list;
            },
            viewModeOptions() {
                let options = [{
                    highlighted: this.viewMode === this.VIEW_MODE_VENDORS,
                    value: this.VIEW_MODE_VENDORS,
                    text: this.$t('label.vendors'),
                    click: () => this.setViewMode(this.VIEW_MODE_VENDORS)
                }, {
                    highlighted: this.viewMode === this.VIEW_MODE_PRODUCTS,
                    value: this.VIEW_MODE_PRODUCTS,
                    text: this.$t('label.product'),
                    click: () => this.setViewMode(this.VIEW_MODE_PRODUCTS)
                }];

                if (this.showPrintViewBtn) {
                    options.push({
                        divider: true,
                        text: this.$t('btn.openPrintView'),
                        click: () => this.generatePrintView()
                    });
                }

                return options;
            }
        },
        watch: {
            printView: function (isPrintView) {
                if (isPrintView) {
                    document.body.classList.add('printView');
                } else {
                    document.body.classList.remove('printView');
                }
            },
            bundles: {
                immediate: true,
                handler: function (trees) {
                    this.$store.dispatch('bundles/setBundleTrees', trees);
                }
            }
        },
        created() {
            this.getBasket();
            this.$store.dispatch('bundles/resetBundles');
        },
        unmounted() {
            this.$store.dispatch('basket/resetQuickCheckoutData');
            this.$store.dispatch('bundles/resetBundles');
        },
        methods: {
            getBasket() {
                this.loading = true;
                this.$store.dispatch('basket/getBasket').finally(() => {
                    this.loading = false;
                });
            },
            print() {
                window.print();
            },
            disablePrintView() {
                this.$store.dispatch('basket/showPrintView', {
                    printView: false,
                    contentBlocks: {}
                });
            },
            clearFilter() {
                this.$store.dispatch('bundles/setPath', null);
                this.showFilter = false;
            },
            setViewMode(viewMode) {
                window.localStorage.setItem(this.VIEW_MODE_STORAGE_KEY, viewMode);
                this.viewMode = viewMode;
                this.clearFilter();
            },
            generatePrintView() {
                this.$store.dispatch('basket/getPrintContentBlocks').then((contentBlocks) => {
                    this.$store.dispatch('basket/showPrintView', {
                        contentBlocks,
                        printView: true
                    });
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .printViewTbl {
        border-collapse: collapse;

        & > tbody > tr > td {
            padding: 32px;

            &.border-right {
                border-right: 1px solid var(--bs-gray-middle);
            }
        }
    }

    .treeView {
        :deep(.bundleTree .children > .product-groupItem) {
            padding: 16px 0 0 16px;
        }

        :deep(.product-groupItem + .product-groupItem),
        :deep(.bundleTree + .product-groupItem),
        :deep(.product-groupItem + .bundleTree),
        :deep(.bundleTree + .bundleTree) {
            border-top: 1px solid var(--bs-gray-middle);
        }
    }
</style>
