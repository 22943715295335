<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th>{{ $t('label.title') }}</th>
                    <th v-if="!isSmallVariant" class="d-none d-md-table-cell">{{ $t('label.news.description') }}</th>
                    <th v-if="!isSmallVariant" class="d-none d-md-table-cell">{{ $t('label.date') }}</th>
                    <th v-if="!isSmallVariant" class="d-none d-md-table-cell">{{ $t('label.type') }}</th>
                    <th v-if="!isSmallVariant" class="d-none d-md-table-cell"></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="news in listData"
                    :key="news.id"
                    :class="{unread: getUnreadCount(news) > 0}"
                    class="pointer"
                    @click="() => gotoNews(news.id)"
                >
                    <td class="text-nowrap highlight">
                        <NdxIcon icon="bell" size="s"/>
                        <span
                            v-if="news.replyInfo.unreadCount > 0 && !isSmallVariant"
                            class="ms-2"
                        >{{news.replyInfo.unreadCount}}</span>
                    </td>
                    <td class="highlight">{{ news.title }}</td>
                    <td v-if="!isSmallVariant" class="d-none d-md-table-cell">{{ news.description }}</td>
                    <td
                        v-if="!isSmallVariant"
                        class="d-none d-md-table-cell"
                    >{{ $d(new Date(news.createdAt), 'long') }}</td>
                    <td
                        v-if="!isSmallVariant"
                        class="d-none d-md-table-cell"
                    >{{ $t('label.news.type_' + news.type) }}</td>
                    <td v-if="!isSmallVariant" class="d-none d-md-table-cell">{{ $t('btn.read') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import NdxIcon from "../library/NdxIcon";
    export default {
        name: 'NotificationsWidget',
        components: {NdxIcon},
        props: {
            widget: Object,
            position: String
        },
        data() {
            return {
                listData: []
            };
        },
        computed: {
            listSize() {
                return this.widget.config.count;
            },
            isSmallVariant() {
                return this.widget.config.isSmall || ['left', 'right'].indexOf(this.position) > -1;
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                this.$store.dispatch('news/findAllRelevant', {
                    offset: 0,
                    limit: this.listSize,
                    order: {
                        id: 'DESC'
                    }
                }).then(listData => this.listData = listData);
            },
            getUnreadCount(news) {
                return news.replyInfo.unreadCount + (news.readByLoggedUser ? 0 : 1);
            },
            gotoNews(newsId) {
                this.$router.push({
                    name: 'NewsDetail',
                    params: {
                        newsId: newsId
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    body #app table.table tbody .unread {
        td, th {
            @extend %font-h3;

            &.highlight {
                color: var(--bs-primary);
            }
        }
    }

    .pointer {
        cursor: pointer;
    }
</style>
