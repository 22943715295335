//state
import assetsApi from "../../api/assets";

const state = {};

// getters
const getters = {
    getAssetIcon: () => (entry) => {
        const wordExt = ['doc', 'docx', 'dot', 'dotx', 'odt'];
        const excelExt = ['xlsx', 'xls', 'xltx', 'xlt'];
        const powerpointExt = ['pptx', 'ppt', 'potx', 'pot', 'odp', 'pps', 'ppsx'];
        const pdfExt = ['pdf'];
        const video = ['mov', 'avi', 'mpeg', 'webm', 'mp4', 'wmv'];

        let extension = null;
        if ('fileExtension' in entry) {
            extension = entry.fileExtension;
        } else if ('filename' in entry) {
            let ext = entry.filename.split('.');
            if (ext.length > 0) {
                extension = ext.pop();
            }
        }

        if (extension && extension.length > 0) {
            if (wordExt.indexOf(extension) > -1) {
                return 'word';
            } else if (excelExt.indexOf(extension) > -1) {
                return 'excel';
            } else if (powerpointExt.indexOf(extension) > -1) {
                return 'powerpoint';
            } else if (pdfExt.indexOf(extension) > -1) {
                return 'pdf';
            } else if (video.indexOf(extension) > -1) {
                return 'video';
            }
        }

        return 'documents';
    }
};

// actions
const actions = {
    searchEntries(ctx, params) {
        return new Promise((resolve, reject) => {
            assetsApi.searchEntries(
                params.categoryId, params.limit, params.offset, params.searchText
            ).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    getEntries(ctx, {filterCriteria, offset, limit}) {
        return new Promise((resolve, reject) => {
            assetsApi.getEntries(
                filterCriteria.categoryId, limit, offset, filterCriteria.searchText
            ).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    getEntry(ctx, params) {
        return new Promise((resolve, reject) => {
            assetsApi.getEntry(
                params.id
            ).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
};

// mutations
const mutations = {
    RESET() {
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
