import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'contentClass';

export default {
    getTemplateVariantsByClassName(contentClass) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getTemplateVariantsByClassName', [contentClass], throbberRules.NONE)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
