<template>
    <template v-if="initiated">
        <div class="infoText" v-if="infoText && infoText.length > 0">
            <div v-html="infoText"></div>
        </div>

        <NdxNotification
            v-if="helpText && helpText.length > 0"
            class="helpText"
            variant="info"
            :duration="0"
            :message="helpText"
            :model-value="true"
        />
    </template>
</template>

<script>
    import NdxNotification from "../library/NdxNotification";
    import { mapState } from "vuex";

    export default {
        name: 'DataCheckInfos',
        components: {NdxNotification},
        computed: {
            ...mapState({
                initiated: state => state.myAccount.initiated,
                infoText: state => state.myAccount.infoText,
                helpText: state => state.myAccount.helpText
            })
        }
    };
</script>

<style lang="scss" scoped>
    .infoText + .helpText {
        margin-top: 32px;
    }
</style>
