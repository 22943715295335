<template>
    <div v-if="modelValue" class="videoOverlay">
        <div class="greyOut"></div>
        <div class="contentWrapper">
            <div class="text-end pe-4">
                <NdxIcon icon="close" size="l" is-action @click="onClose"/>
            </div>
            <div class="h-100 p-3 w-100 text-center">
                <video v-if="videoFile" :src="videoFile" controls autoplay/>
                <NdxPdf
                    v-else-if="pdfFile"
                    :url="pdfFile"
                    :zoom="1"
                    :complete-pdf="true"
                    class="w-100"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "./NdxIcon";
    import NdxPdf from "./NdxPdf";

    export default {
        name: 'NdxFileOverlay',
        components: {NdxPdf, NdxIcon},
        props: {
            modelValue: {
                type: [null, String],
                required: true
            },
            additionalData: {
                type: Object
            }
        },
        emits: ['update:modelValue'],
        computed: {
            videoFile() {
                return this.isVideo ? '/file/download/' + this.modelValue : null;
            },
            pdfFile() {
                return this.isPdf ? '/file/download/' + this.modelValue : null;
            },
            isVideo() {
                if (this.additionalData && 'mimeType' in this.additionalData) {
                    const videoEl = document.createElement('video');
                    return ['maybe', 'probably'].indexOf(videoEl.canPlayType(this.additionalData.mimeType)) >= 0;
                }
                return ['mov', 'avi', 'mpeg', 'webm', 'mp4', 'wmv'].includes(this.fileExtension);
            },
            isPdf() {
                return ['pdf'].includes(this.fileExtension);
            },
            fileExtension() {
                return this.modelValue?.substr(this.modelValue.lastIndexOf('.') + 1);
            }
        },
        methods: {
            onClose() {
                this.$emit('update:modelValue', null);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .videoOverlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .contentWrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 0;

            background-color: white;

            padding: 32px 0;
            max-height: calc(100% - 50px);

            video {
                max-width: 100%;
            }
        }
    }
</style>
