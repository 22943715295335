<template>
    <NdxPageHeader
        hide-filter
        hide-view-mode
        :nav-name="getLabel('Client', this)"
        :breadcrumbs="breadcrumbs"
    />
    <div class="myAccount-data-container" v-if="initiated && client !== null">
        <div>
            <div class="d-flex gap-3 justify-content-between align-items-end mb-3">
                <div class="filter d-flex gap-2 flex-wrap">
                    <div
                        @click="addressTypeFilter=null"
                        :class="{active:addressTypeFilter===null}"
                    >{{ $t('label.allAddresses') }}
                    </div>
                    <div
                        @click="addressTypeFilter='main'"
                        :class="{active:addressTypeFilter==='main'}"
                    >{{ $t('label.mainAddresses') }}
                    </div>
                    <div
                        @click="addressTypeFilter='delivery'"
                        :class="{active:addressTypeFilter==='delivery'}"
                    >{{ $t('label.deliveryAddresses') }}
                    </div>
                    <div
                        @click="addressTypeFilter='invoice'"
                        :class="{active:addressTypeFilter==='invoice'}"
                    >{{ $t('label.invoiceAddresses') }}
                    </div>
                </div>

                <div class="flex-shrink-0 d-flex align-self-end">
                    <NdxSearchInput v-model="searchStr"/>
                </div>
            </div>
            <div class="d-flex gap-3 justify-content-between align-items-end mb-3">
                <div class="filter d-flex gap-2 flex-wrap">
                    <div
                        @click="addressParentFilter=null"
                        :class="{active:addressParentFilter===null}"
                    >{{ $t('label.allAddresses') }}
                    </div>
                    <div
                        @click="addressParentFilter='client'"
                        :class="{active:addressParentFilter==='client'}"
                    >{{ $t('label.client') }}
                    </div>
                    <div
                        @click="addressParentFilter='user'"
                        :class="{active:addressParentFilter==='user'}"
                    >{{ $t('label.user') }}
                    </div>
                </div>
            </div>
        </div>


        <div class="myAccount-data">
            <div v-if="searchNoResults" class="p-3">
                {{ $t('message.noSearchHits') }}
            </div>

            <div
                class="myAccount-data-item"
                v-if="(isClientAddressCreateAllowed || isUserAddressCreateAllowed) && !searchActive"
            >
                <div class="control">
                    <div class="key">{{ $t('label.address') }}</div>
                    <div class="value-wrapper">
                        <NdxButtonLink
                            v-if="isClientAddressCreateAllowed || isUserAddressCreateAllowed"
                            @click="onCreate()"
                        >
                            {{ $t('btn.add') }}
                        </NdxButtonLink>
                    </div>
                </div>
            </div>

            <div
                v-for="address of addressList"
                :key="address.id"
                class="myAccount-data-item"
            >
                <div
                    class="control has-editor"
                    @click="onEdit(address)"
                >
                    <div class="key">
                        <div class="d-flex gap-3">
                            <NdxIcon v-if="isClientAddress(address)" icon="clients"/>
                            <NdxIcon v-else icon="users"/>

                            <div>
                                <div
                                    v-for="item of address.addressTypes"
                                    :key="item.addressType"
                                    class="addressType"
                                    :class="{isDefault: item.isDefault}"
                                >
                                    {{ $t('label.' + item.addressType + 'Address') }}
                                    <div v-if="!isAddressValid(address)" class="key invalid p-0 pt-2">
                                        {{ $t('message.invalidAddressError') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="value-wrapper">
                        <div class="value">
                            <div
                                class="text-end preserveLineBreaks"
                            >
                                {{ formatAddress(address) }}
                            </div>
                        </div>
                        <NdxIcon class="editor-toggle" icon="drop-right"/>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <NdxFlyIn v-if="showAddressEditor && !showAddressDefaultEditor">
        <template #title>{{ editAddressTitle }}</template>
        <template #default>
            <AddressForm
                v-model:address="editAddressModel"
                v-model:address-type="editAddressType"
                :address-book="editAddressParent"
                @is-valid="isValid => editAddressValid = isValid"
                @update:addressBook="onAddressbookChanged"
            />
        </template>
        <template #buttons>
            <NdxButtonLink
                v-if="isAddressDefaultChangeAllowed"
                @click="openAddressDefaultEditor"
            >
                {{ $t('label.editAddresDefault') }}
            </NdxButtonLink>
            <NdxButtonLink
                v-if="isAddressDeleteAllowed"
                @click="onDelete"
            >
                {{ $t('btn.delete') }}
            </NdxButtonLink>
            <NdxButtonLink @click="closeEditor">
                {{ isAddressEditingAllowed ? $t('btn.cancel') : $t('btn.close') }}
            </NdxButtonLink>
            <NdxButton
                v-if="isAddressEditingAllowed"
                :disabled="!editAddressValid"
                @click="saveAddress"
            >
                {{ $t('btn.save') }}
            </NdxButton>
        </template>
    </NdxFlyIn>

    <NdxFlyIn v-if="showAddressDefaultEditor">
        <template #title>{{ $t('label.editAddresDefault') }}</template>
        <template #default>
            <NdxCheckbox
                v-if="showAddressDefaultMain"
                variant="primary"
                :label="$t('label.mainAddress')"
                :model-value="getAddressDefaultValue('main')"
                @update:model-value="(val) => setAddressDefaultValue('main', val)"
                :readonly="!isMainAddressCreateAllowed"
            />
            <NdxCheckbox
                v-if="showAddressDefaultDelivery"
                variant="primary"
                :label="$t('label.deliveryAddress')"
                :model-value="getAddressDefaultValue('delivery')"
                @update:model-value="(val) => setAddressDefaultValue('delivery', val)"
                :readonly="isClientAddress(editAddressModel)
                    ? !isDeliveryAddressCreateAllowed
                    : !isUserDeliveryAddressCreateAllowed"
            />
            <NdxCheckbox
                v-if="showAddressDefaultInvoice"
                variant="primary"
                :label="$t('label.invoiceAddress')"
                :model-value="getAddressDefaultValue('invoice')"
                @update:model-value="(val) => setAddressDefaultValue('invoice', val)"
                :readonly="isClientAddress(editAddressModel)
                    ? !isInvoiceAddressCreateAllowed
                    : !isUserInvoiceAddressCreateAllowed"
            />
        </template>
        <template #buttons>
            <NdxButtonLink @click="closeAddressDefaultEditor">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton @click="saveAddressDefault">{{ $t('btn.save') }}</NdxButton>
        </template>
    </NdxFlyIn>

    <NdxFlyIn v-if="showDelete">
        <template #default>
            <template v-if="deleteErrorMsg && deleteErrorMsg.length > 0">
                {{ deleteErrorMsg }}
            </template>
            <template v-else>
                {{ $t('message.confirmDeleteAddress') }}
            </template>
        </template>
        <template #buttons>
            <template v-if="deleteErrorMsg && deleteErrorMsg.length > 0">
                <NdxButton @click="cancelDelete">{{ $t('btn.close') }}</NdxButton>
            </template>
            <template v-else>
                <NdxButtonLink @click="cancelDelete">{{ $t('btn.cancel') }}</NdxButtonLink>
                <NdxButton @click="doDelete">{{ $t('btn.delete') }}</NdxButton>
            </template>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxButton from "../library/NdxButton";
    import NdxIcon from "../library/NdxIcon";
    import lodashClone from "lodash/cloneDeep";
    import {mapGetters, mapState} from "vuex";
    import {AddressFormatter} from "../../plugins/formatter";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxFlyIn from "../library/NdxFlyIn";
    import AddressForm from "../checkout/AddressForm";
    import NdxSearchInput from "../library/formElements/NdxSearchInput";
    import {AddressMatchesString} from "../../plugins/addressSearch";
    import NdxCheckbox from "../library/formElements/NdxCheckbox.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: 'Addresses',
        components: {
            NdxPageHeader,
            NdxCheckbox, NdxSearchInput, AddressForm, NdxFlyIn, NdxButtonLink, NdxIcon, NdxButton},
        data() {
            return {
                client: null,
                user: null,

                showAddressEditor: false,
                editAddressModel: null,
                editAddressType: null,
                editAddressParent: null,
                editAddressValid: false,

                showDelete: false,
                deleteErrorMsg: null,

                showAddressDefaultEditor: false,
                addressDefaultModel: null,

                searchStr: '',
                addressTypeFilter: null,
                addressParentFilter: null,
            };
        },
        computed: {
            ...mapState({
                initiated: state => state.myAccount.initiated,
                storeClient: state => state.user.client,
                storeUser: state => state.user.user,
                isMainAddressEditingAllowed: state => state.shop.isMainAddressEditingAllowed,
                isDeliveryAddressEditingAllowed: state => state.shop.isDeliveryAddressEditingAllowed,
                isInvoiceAddressEditingAllowed: state => state.shop.isInvoiceAddressEditingAllowed,
                isMainAddressCreateAllowed: state => state.shop.isMainAddressCreateAllowed,
                isDeliveryAddressCreateAllowed: state => state.shop.isDeliveryAddressCreateAllowed,
                isInvoiceAddressCreateAllowed: state => state.shop.isInvoiceAddressCreateAllowed,
                isUserMainAddressEditingAllowed: state => state.shop.isUserMainAddressEditingAllowed,
                isUserDeliveryAddressEditingAllowed: state => state.shop.isUserDeliveryAddressEditingAllowed,
                isUserInvoiceAddressEditingAllowed: state => state.shop.isUserInvoiceAddressEditingAllowed,
                isUserMainAddressCreateAllowed: state => state.shop.isUserMainAddressCreateAllowed,
                isUserDeliveryAddressCreateAllowed: state => state.shop.isUserDeliveryAddressCreateAllowed,
                isUserInvoiceAddressCreateAllowed: state => state.shop.isUserInvoiceAddressCreateAllowed,
            }),
            ...mapGetters('user', [
                'getNewAddress'
            ]),
            ...mapGetters('myAccount', [
                'getLabel',
                'invalidAddressItems'
            ]),
            isAddressDefaultChangeAllowed() {
                const isNew = !(this.editAddressModel &&
                    'id' in this.editAddressModel && +this.editAddressModel.id > 0);

                return !isNew &&
                    (this.showAddressDefaultMain || this.showAddressDefaultDelivery || this.showAddressDefaultInvoice);
            },
            isAddressEditingAllowed() {
                if (this.editAddressModel) {
                    if (this.editAddressParent === 'client') {
                        if ('addressTypes' in this.editAddressModel) {
                            return this.editAddressModel.addressTypes.find((data) => {
                                return (data.addressType === 'main' && this.isMainAddressEditingAllowed) ||
                                    (data.addressType === 'delivery' && this.isDeliveryAddressEditingAllowed) ||
                                    (data.addressType === 'invoice' && this.isInvoiceAddressEditingAllowed);
                            }) !== undefined;
                        }
                        if (this.editAddressType) {
                            return (this.editAddressType === 'main' && this.isMainAddressEditingAllowed) ||
                                (this.editAddressType === 'delivery' && this.isDeliveryAddressEditingAllowed) ||
                                (this.editAddressType === 'invoice' && this.isInvoiceAddressEditingAllowed);
                        }
                    } else {
                        if ('addressTypes' in this.editAddressModel) {
                            return this.editAddressModel.addressTypes.find((data) => {
                                return (data.addressType === 'main' && this.isUserMainAddressEditingAllowed) ||
                                    (data.addressType === 'delivery' && this.isUserDeliveryAddressEditingAllowed) ||
                                    (data.addressType === 'invoice' && this.isUserInvoiceAddressEditingAllowed);
                            }) !== undefined;
                        }
                        if (this.editAddressType) {
                            return (this.editAddressType === 'main' && this.isUserMainAddressEditingAllowed) ||
                                (this.editAddressType === 'delivery' && this.isUserDeliveryAddressEditingAllowed) ||
                                (this.editAddressType === 'invoice' && this.isUserInvoiceAddressEditingAllowed);
                        }
                    }
                }
                return false;
            },
            isAddressDeleteAllowed() {
                const isNew = !(this.editAddressModel &&
                    'id' in this.editAddressModel && +this.editAddressModel.id > 0);

                if (isNew) {
                    return false;
                }

                const isAllCreateable = this.editAddressParent === 'client'
                    ? this.editAddressModel.addressTypes.reduce((_isCreateable, data) => {
                        const check = (data.addressType === 'main' && this.isMainAddressCreateAllowed) ||
                            (data.addressType === 'delivery' && this.isDeliveryAddressCreateAllowed) ||
                            (data.addressType === 'invoice' && this.isInvoiceAddressCreateAllowed);
                        return _isCreateable && check;
                    }, true)
                    : this.editAddressModel.addressTypes.reduce((_isCreateable, data) => {
                        const check = (data.addressType === 'main' && this.isUserMainAddressCreateAllowed) ||
                            (data.addressType === 'delivery' && this.isUserDeliveryAddressCreateAllowed) ||
                            (data.addressType === 'invoice' && this.isUserInvoiceAddressCreateAllowed);
                        return _isCreateable && check;
                    }, true);

                if (!isAllCreateable) {
                    return false;
                }

                // it is allowed to delete the last address of a client
                if (this.editAddressParent === 'client') {
                    return true;
                }

                const isMainAddress = this.editAddressModel.addressTypes
                    .find(data => data.addressType === 'main') !== undefined;

                if (!isMainAddress) {
                    return true;
                }

                // is not last main address
                return this[this.editAddressParent].contact.addresses
                    .filter((address) => {
                        return address.addressTypes.filter(item => item.addressType === 'main').length > 0;
                    }).length > 1;
            },
            isClientAddressCreateAllowed() {
                return this.isMainAddressCreateAllowed || this.isDeliveryAddressCreateAllowed
                    || this.isInvoiceAddressCreateAllowed;
            },
            isUserAddressCreateAllowed() {
                return this.isUserMainAddressCreateAllowed || this.isUserDeliveryAddressCreateAllowed
                    || this.isUserInvoiceAddressCreateAllowed;
            },
            addressList() {
                const clientAddresses = this.client.contact.addresses
                    .filter((address) => {
                        return !this.addressTypeFilter || address.addressTypes
                            .find(item => item.addressType === this.addressTypeFilter) !== undefined;
                    })
                    .filter((address) => {
                        return !this.searchActive
                            || (this.editAddressModel && this.editAddressModel.id === address.id)
                            || this.addressListMatchesSearch(address);
                    });
                const userAddresses = this.user.contact.addresses
                    .filter((address) => {
                        return !this.addressTypeFilter || address.addressTypes
                            .find(item => item.addressType === this.addressTypeFilter) !== undefined;
                    })
                    .filter((address) => {
                        return !this.searchActive
                            || (this.editAddressModel && this.editAddressModel.id === address.id)
                            || this.addressListMatchesSearch(address);
                    });

                switch (this.addressParentFilter) {
                    case 'client':
                        return clientAddresses;
                    case 'user':
                        return userAddresses;
                    default:
                        return userAddresses.concat(clientAddresses);
                }
            },
            searchActive() {
                return this.searchStr && this.searchStr.trim().length > 0;
            },
            searchNoResults() {
                return this.searchActive && this.addressList.length === 0;
            },
            editAddressTitle() {
                return this.editAddressModel && 'id' in this.editAddressModel && +this.editAddressModel.id > 0
                    ? this.$t('label.editAddress')
                    : this.$t('label.createAddress');
            },

            showAddressDefaultMain() {
                if (!this.editAddressModel ||
                    (this.editAddressModel && !this.isClientAddress(this.editAddressModel))
                ) {
                    return false;
                }

                return this.editAddressModel.addressTypes
                    .filter(item => item.addressType === 'main')
                    .length > 0;
            },
            showAddressDefaultDelivery() {
                if (!this.editAddressModel) {
                    return false;
                }

                return this.editAddressModel.addressTypes
                    .filter(item => item.addressType === 'delivery')
                    .length > 0;
            },
            showAddressDefaultInvoice() {
                if (!this.editAddressModel) {
                    return false;
                }

                return this.editAddressModel.addressTypes
                    .filter(item => item.addressType === 'invoice')
                    .length > 0;
            },
            breadcrumbs() {
                return [{
                    label: this.$t('label.myAccount'),
                    click: () => this.$router.push({name: 'MyAccount'})
                }, {
                    label: this.getLabel('Addresses', this)
                }];
            }
        },
        created() {
            this.$store.dispatch('myAccount/getDataCheckConfig');
        },
        mounted() {
            this.resetData();
        },
        methods: {
            isAddressValid(address) {
                return this.invalidAddressItems(
                    address,
                    address.addressTypes.map(item => item.addressType),
                    this.isClientAddress(address) ? 'client' : 'user'
                ).length === 0;
            },
            isClientAddress(address) {
                return !!this.client.contact.addresses
                    .find((addr) => {
                        return address.id === addr.id;
                    });
            },
            addressListMatchesSearch(address) {
                return AddressMatchesString(address, this.searchStr);
            },
            onCreate() {
                this.editAddressParent = this.isUserAddressCreateAllowed ?
                    'user' :
                    (this.isClientAddressCreateAllowed ? 'client' : null);

                if (!this.editAddressParent) {
                    console.error('add address button should not appear if no address type is allowed');
                    return;
                }
                this.onAddressbookChanged(this.editAddressParent);
                this.onEdit(JSON.parse(JSON.stringify(this.getNewAddress)));
            },
            onEdit(address) {
                if (!this.editAddressParent) {
                    this.editAddressParent = this.isClientAddress(address) ? 'client' : 'user';
                }
                this.editAddressValid = false;
                this.editAddressModel = address;
                this.showAddressEditor = true;
            },
            closeEditor() {
                this.showAddressEditor = false;
                this.editAddressModel = null;
                this.editAddressType = null;
                this.editAddressParent = null;
                this.resetData();
            },
            cancelDelete() {
                this.showDelete = false;
                this.deleteErrorMsg = null;
                this.showAddressEditor = true;
            },
            onDelete() {
                this.showAddressEditor = false;
                this.showDelete = true;
            },
            doDelete() {
                const storeAction = this.isClientAddress(this.editAddressModel) ?
                    'user/deleteClientAddress' :
                    'user/deleteUserAddress';
                this.$store.dispatch(storeAction, {
                    addressId: this.editAddressModel.id
                }).then(() => {
                    this.resetData();
                    this.showDelete = false;
                    this.closeEditor();
                }).catch((error) => {
                    console.error(error);
                    this.deleteErrorMsg = this.$t('message.error_address_not_deleted');
                });
            },
            resetData() {
                this.client = lodashClone(this.storeClient);
                this.user = lodashClone(this.storeUser);
            },
            formatAddress(address) {
                return AddressFormatter(address, this, "\n");
            },
            saveAddress() {
                if (this.editAddressValid === false) {
                    return;
                }
                const storeAction = this.editAddressParent === 'client' ?
                    'user/updateClientAddress' :
                    'user/updateUserAddress';
                this.$store.dispatch(storeAction, {
                    addressData: this.editAddressModel,
                    addressType: this.editAddressType
                }).then(() => {
                    this.resetData();
                    this.closeEditor();
                }).catch((error) => {
                    console.error(error);
                });
            },

            openAddressDefaultEditor() {
                this.addressDefaultModel = lodashClone(this.editAddressModel.addressTypes);
                this.showAddressDefaultEditor = true;
            },
            closeAddressDefaultEditor() {
                this.showAddressDefaultEditor = false;
                this.addressDefaultModel = null;
            },
            getAddressDefaultValue(type) {
                let item = this.addressDefaultModel.find(data => data.addressType === type);
                return item ? item.isDefault : false;
            },
            setAddressDefaultValue(type, value) {
                let idx = this.addressDefaultModel.findIndex(data => data.addressType === type);
                if (idx > -1) {
                    this.addressDefaultModel[idx].isDefault = value;
                } else {
                    this.addressDefaultModel.push({
                        addressType: type,
                        isDefault: value
                    });
                }
            },
            saveAddressDefault() {
                this.$store.dispatch('user/updateAddressSettings', {
                    addressId: this.editAddressModel.id,
                    settings: this.addressDefaultModel,
                    addressParent: this.editAddressParent
                }).then(() => {
                    this.resetData();
                    let address = this.addressList.find(address => address.id === this.editAddressModel.id);
                    if (address) {
                        this.onEdit(address);
                    }
                    this.closeAddressDefaultEditor();
                }).catch((error) => {
                    console.error(error);
                });
            },
            onAddressbookChanged(addressBook) {
                this.editAddressParent = addressBook;

                if (this.editAddressParent === 'client') {
                    let currentAddressTypeAllowed =
                        this.editAddressType === 'delivery' && this.isDeliveryAddressCreateAllowed ||
                        this.editAddressType === 'invoice' && this.isInvoiceAddressCreateAllowed ||
                        this.editAddressType === 'main' && this.isMainAddressCreateAllowed;

                    if (!currentAddressTypeAllowed) {
                        if (this.isDeliveryAddressCreateAllowed) {
                            this.editAddressType = 'delivery';
                        } else if (this.isInvoiceAddressCreateAllowed) {
                            this.editAddressType = 'invoice';
                        } else if (this.isMainAddressCreateAllowed) {
                            this.editAddressType = 'main';
                        }
                    }
                } else if (this.editAddressParent === 'user') {
                    let currentAddressTypeAllowed =
                        this.editAddressType === 'delivery' && this.isUserDeliveryAddressCreateAllowed ||
                        this.editAddressType === 'invoice' && this.isUserInvoiceAddressCreateAllowed ||
                        this.editAddressType === 'main' && this.isUserMainAddressCreateAllowed;

                    if (!currentAddressTypeAllowed) {
                        if (this.isUserDeliveryAddressCreateAllowed) {
                            this.editAddressType = 'delivery';
                        } else if (this.isUserInvoiceAddressCreateAllowed) {
                            this.editAddressType = 'invoice';
                        } else if (this.isUserMainAddressCreateAllowed) {
                            this.editAddressType = 'main';
                        }
                    }
                }

            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";

    .addressType {
        &.isDefault {
            color: var(--bs-primary);
        }
    }

    .filter {
        > div {
            @extend %font-body2;

            border: 1px solid var(--bs-gray-300);
            border-radius: 4px;
            padding: 5px 10px;
            cursor: pointer;

            &:hover:not(.active) {
                background-color: var(--bs-primary-light);
            }

            &.active {
                background-color: var(--bs-primary);
                color: white;
            }
        }
    }
</style>
