export const basketGetters = {
    getSumNet(state) {
        const result = state.basket?.summary
            ? state.basket.summary.priceNet || 0
            : 0;
        return parseFloat(result);
    },
    getSumAdditionalCostsNet(state) {
        const result = state.basket?.summary
            ? state.basket.summary.additionalCostsNet || 0
            : 0;
        return parseFloat(result);
    },
    getSumTax(state) {
        let result = 0;
        if (state.basket?.summary) {
            result = (state.basket.summary.priceNetTax || 0)
                + (state.basket.summary.additionalCostsNetTax || 0)
                + (state.basket.summary.deliveryCostsTax || 0);
        }
        return parseFloat(result);
    },
    getSumGross(state, getters) {
        return getters.getSumNet + getters.getSumAdditionalCostsNet
            + getters.getSumTax + getters.getSumDeliveryCostsNet;
    },
    getSumDeliveryCostsNet(state) {
        const result = state.basket?.summary
            ? state.basket.summary.deliveryCosts || 0
            : 0;
        return parseFloat(result);
    },
    getRelevantSum: (state, getters) => (showGross, isNetClient) => {
        let relevantSum = 0;

        if (showGross && !isNetClient) {
            relevantSum = getters.getSumGross;
        } else {
            relevantSum = getters.getSumNet + getters.getSumAdditionalCostsNet + getters.getSumDeliveryCostsNet;
        }

        let budgetValues = 0;
        getters.getPriceRelevantBudgets.map((budget) => {
            budgetValues += budget.value;
        });

        relevantSum -= budgetValues;
        relevantSum = relevantSum < 0 ? 0 : relevantSum;

        return parseFloat(relevantSum);
    },
    getPriceRelevantBudgets(state) {
        if (state.basket?.summary) {
            return state.basket.summary.budgets.filter((budget) => {
                return budget.isGift;
            });
        }
        return [];
    },
    getProductCount(state) {
        return state.basketItemCount;
    },

    getBudgetSummary(state) {
        const infos = [];

        if (state.basket?.summary?.budgets) {
            for (const id in state.basket.summary.budgets) {
                const budget = state.basket.summary.budgets[id];
                infos.push({
                    id: id,
                    name: budget.name,
                    description: budget.description,
                    totalValue: parseFloat(budget.totalValue),
                    reducingValue: budget.value,
                    usedValue: parseFloat(budget.usedValue),
                    remainingValue: parseFloat(budget.remainingValue) - budget.value,
                    validDate: budget.validDate && new Date(budget.validDate) || null,
                });
            }
        }

        return infos;
    },
    getBasket(state) {
        return state.basket;
    },
    getOrderItems(state) {
        let items = [];

        if (state.basket?.groups) {
            for (let i in state.basket.groups) {
                for (let j in state.basket.groups[i].items) {
                    let item = state.basket.groups[i].items[j];
                    let res = items.find(listItem => listItem.orderItemId === item.orderItemId);
                    if (!res || res.length === 0) {
                        items.push(item);
                    }
                }
            }
        }

        return items;
    },

    getOrderItemById(state, getters) {
        return (orderItemId) => {
            return getters.getOrderItems.find(item => item.orderItemId === orderItemId);
        };
    },

    isOrderable(state) {
        return !!state.basket?.summary?.orderable;
    },
    isOrderableMinimalCheck(state) {
        return !!state.basket?.summary?.orderableMinimalCheck;
    },
    hasPrintView(state, getters, rootState, rootGetters) {
        const wgcs = rootGetters['shop/getWgcsByType']('basket');
        if (wgcs && wgcs.length > 0) {
            return wgcs[0].config.basketPrintViewActive;
        }
        return false;
    },
    isBudgetLimitExceeded(state) {
        return !!state.basket?.summary?.budgetExceeded;
    },
    stockQuantityUnderCut(state, getters) {
        let stockInfo = {};

        state.basket?.groups.forEach(group => group.items.forEach(
            item => {
                if (item.stockInfo && item.stockInfo.state !== 'noStock') {
                    stockInfo[item.productId] = item.stockInfo.stock;
                }
            }
        ));

        for (const productId in stockInfo) {
            if (stockInfo[productId] < getters.getQuantityForProduct(productId)) {
                return true;
            }
        }

        return false;
    },
    areProductFeaturesValid(state) {
        let areValid = true;
        state.basket?.groups.forEach(group => group.items.forEach(
            item => {
                if (!item.areProductFeaturesValid) {
                    areValid = false;
                }
            }
        ));

        return areValid;
    },
    hasLockedCustomization(state) {
        return state.basket?.groups.reduce(
            (hasLockedCustomization, group) => hasLockedCustomization || group.items.reduce(
                (hasLockedCustomization, item) => hasLockedCustomization || (item.campaignRun?.hasPortalLock ?? false),
                false
            ),
            false
        );
    },
    getQuantityForProduct: (state) => {
        return (productId) => {
            let quantityInfo = {};

            state.basket?.groups.forEach(group => group.items.forEach(
                item => {
                    quantityInfo[item.productId] = (quantityInfo[item.productId] || 0) + parseFloat(item.quantity);
                }
            ));

            return quantityInfo[productId];
        };
    },

    getBasketCallToAction: (state, getters, rootState, rootGetters) => {
        const wgc = rootGetters['shop/getWgcsByType']('basket')[0];
        if ('config' in wgc
            && 'basketCallToAction' in wgc.config
            && wgc.config.basketCallToAction
            && wgc.config.basketCallToAction.length > 0
        ) {
            return wgc.config.basketCallToAction;
        }
        return 'checkout';
    },
    getBasketTextCheckout: (state, getters, rootState, rootGetters) => {
        const wgc = rootGetters['shop/getWgcsByType']('basket')[0];
        if ('config' in wgc
            && 'basketTextCheckout' in wgc.config
            && wgc.config.basketTextCheckout
            && wgc.config.basketTextCheckout.length > 0
        ) {
            return wgc.config.basketTextCheckout;
        }
        return null;
    },
    getBasketTextQuickCheckout: (state, getters, rootState, rootGetters) => {
        const wgc = rootGetters['shop/getWgcsByType']('basket')[0];
        if ('config' in wgc
            && 'basketTextQuickCheckout' in wgc.config
            && wgc.config.basketTextQuickCheckout
            && wgc.config.basketTextQuickCheckout.length > 0
        ) {
            return wgc.config.basketTextQuickCheckout;
        }
        return null;
    },
    isBasketCheckoutActive: (state, getters, rootState, rootGetters) => {
        const wgc = rootGetters['shop/getWgcsByType']('basket')[0];
        if ('config' in wgc && 'basketActiveCheckout' in wgc.config) {
            return wgc.config.basketActiveCheckout;
        }
        return true;
    },
    isBasketQuickCheckoutActive: (state, getters, rootState, rootGetters) => {
        const wgc = rootGetters['shop/getWgcsByType']('basket')[0];
        if ('config' in wgc && 'basketActiveQuickCheckout' in wgc.config) {
            return wgc.config.basketActiveQuickCheckout;
        }
        return true;
    },

    isProductInBasket(state) {
        return (productId) => {
            let found = !!state.basket.bundleTrees?.find((tree) => tree.originalProductId === productId);
            found ||= !!basketGetters.getOrderItems(state).find(
                (orderItem) => orderItem.productId === productId || orderItem.parentProductId === productId
            );
            return found;
        };
    },

    isProductInWatchlist(state) {
        return (productId) => !!state.watchlist.find(
            (watchlistItem) => watchlistItem.productId === productId || watchlistItem.parentProductId === productId
        );
    },

    areAddressesValid(state) {
        if ('basket' in state && 'summary' in state.basket && 'invalidAddresses' in state.basket.summary) {
            return state.basket.summary.invalidAddresses.length === 0;
        }
        return true;
    },

    getInvalidAddresses(state) {
        if ('basket' in state && 'summary' in state.basket && 'invalidAddresses' in state.basket.summary) {
            return state.basket.summary.invalidAddresses;
        }
        return [];
    }
};
