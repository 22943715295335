import budgetsApi from "../../api/budgets";

//state
const state = {};

// getters
const getters = {};

// actions
const actions = {
    getBudgets(ctx, data) {
        return new Promise((resolve, reject) => {
            budgetsApi.filterBy(
                data.type, data.offset, data.limit
            )
                .then((list) => {
                    resolve(list);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getProducts(ctx, data) {
        return new Promise((resolve, reject) => {
            budgetsApi.getProducts(
                data.criteria, data.offset, data.limit
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getOrders(ctx, data) {
        return new Promise((resolve, reject) => {
            budgetsApi.getOrders(
                data.budgetId, data.offset, data.limit
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// mutations
const mutations = {
    RESET() {
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
