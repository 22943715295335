<template>
    <Group :group="group">
        <template #group-items>
            <ShippingGroupItem
                v-for="item in items"
                :key="item.orderItemId"
                :item="item"
                @group-item-updated="$emit('group-item-updated')"
            />
        </template>
    </Group>
</template>

<script>
    import ShippingGroupItem from "./ShippingGroupItem";
    import Group from "./parts/Group";

    export default {
        name: "ShippingGroup",
        components: {Group, ShippingGroupItem},
        props: {
            group: {
                type: Object,
                required: true
            }
        },
        emits: ['group-item-updated'],
        computed: {
            items() {
                return this.group.items.filter(item => item.quantity > 0);
            }
        }
    };
</script>
