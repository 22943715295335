<template>
    <div v-if="basket && checkoutData" class="checkout-wizard" id="checkout-wizard">
        <div class="wizardBulletContainer d-flex align-items-center justify-content-center">
            <div class="wizardBulletLine"></div>
            <div
                v-for="(step, idx) in this.steps"
                :key="idx"
                class="wizardStepBullet"
                :class="{active: activeStep === idx + 1}"
            >
                <div class="circle"><div>{{ idx + 1 }}</div></div>
                <div class="label">{{ step.label }}</div>
            </div>
        </div>

        <NdxNotification
            variant="error"
            :model-value="orderingError"
            :duration="0"
            :message="$t('message.errorsDuringOrdering')"
            class="mb-2"
        />
        <NdxNotification
            v-if="activeStep !== 1"
            variant="error"
            :model-value="!areAddressesValid"
            :duration="0"
            :message="$t('message.invalidAddressesError')"
            class="mb-2"
        />

        <CheckoutSummary class="d-block d-xl-none py-3"/>

        <div class="pt-3" v-if="activeComponent" >
            <Component
                :is="activeComponent"
            />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import Addresses from "./steps/Addresses";
    import Shipping from "./steps/Shipping";
    import CustomFields from "./steps/CustomFields";
    import BasketSummary from "./steps/BasketSummary";
    import CheckoutSummary from "./steps/parts/CheckoutSummary";
    import NdxNotification from "../library/NdxNotification";

    export default {
        name: 'Wizard',
        components: {NdxNotification, CheckoutSummary},
        computed: {
            ...mapState({
                checkoutData: state => state.basket.checkoutData,
                activeStep: state => state.checkout.activeStep,
                orderingError: state => state.basket.orderingFailure
            }),
            ...mapGetters('basket', {
                basket: 'getBasket',
                areAddressesValid: 'areAddressesValid'
            }),
            ...mapGetters('checkout', [
                'checkoutStepsCount'
            ]),
            activeComponent() {
                if (this.steps[this.activeStep - 1]) {
                    return this.steps[this.activeStep - 1].component;
                }

                console.warn('Unknown step index');

                return null;
            },
            steps() {
                let steps = [
                    {
                        component: Addresses,
                        label: this.$t('label.addresses'),
                    }, {
                        component: Shipping,
                        label: this.$t('label.product_deliveryOptions'),
                    }, {
                        component: BasketSummary,
                        label: this.$t('label.summary'),
                    }
                ];

                if (this.checkoutStepsCount === 4) {
                    const wgc = this.$store.getters['shop/getWgc'](+this.$route.params.id);
                    const customStep = {
                        component: CustomFields,
                        label: wgc?.config?.additionalStep || this.$t('label.customFields'),
                    };

                    steps.splice(2, 0, customStep);
                }

                return steps;
            },
            bundles() {
                // we must use bundleTrees from basket here
                if (this.basket.bundleTrees) {
                    return this.basket.bundleTrees.filter((tree) => {
                        return tree.bundleProduct !== null;
                    });
                }
                return [];
            }
        },
        watch: {
            activeStep: {
                immediate: true,
                handler: function () {
                    this.$store.dispatch('basket/resetOrderingFailure');
                    const el = document.getElementById('checkout-wizard');
                    if (el) {
                        el.scrollIntoView();
                    }
                }
            },
            bundles: {
                immediate: true,
                handler: function (trees) {
                    this.$store.dispatch('bundles/setBundleTrees', trees);
                }
            }
        },
        created() {
            this.$store.dispatch('basket/getAllData');
        },
        unmounted() {
            this.$store.dispatch('checkout/reset');
        }
    };
</script>
