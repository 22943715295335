const state = {
    runLocks: {}
};

// getters
const getters = {
    isLocked: (state) => {
        return (campaignRunId) => campaignRunId in state.runLocks ? state.runLocks[campaignRunId] : null;
    }
};

const actions = {
    updateRunLocks({commit}, eventData) {
        let updates = {};
        eventData.ids.forEach((id) => updates[id] = eventData.portalLock);
        commit('UPDATE_RUN_LOCKS', updates);
    }
};

const mutations = {
    RESET: (state) => {
        state.runLocks = {};
    },
    UPDATE_RUN_LOCKS: (state, updates) => {
        state.runLocks = Object.assign(state.runLocks, updates);
}
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
