<template>
    <div
        class="ndx-avatar d-flex justify-content-center align-items-center"
        :style="customStyle"
    >
        <NdxIcon v-if="!showImage" icon="users" size="l"/>
    </div>
</template>

<script>
    import NdxIcon from "./NdxIcon";
    export default {
        name: 'NdxAvatar',
        components: {NdxIcon},
        props: {
            src: {
                type: String,
                default: function () {
                    return '';
                }
            }
        },
        computed: {
            customStyle() {
                let style = {};

                if (this.showImage) {
                    style['background-image'] = 'url("' + this.src + '")';
                    style['background-size'] = 'cover';
                    style['background-position'] = 'center';
                }

                return style;
            },
            showImage() {
                return this.src && this.src.length > 0;
            }
        }
    };
</script>
