import { createStore } from 'vuex';

import jobQueueSocket from '../plugins/jobQueueSocket';
import campaignRunSocket from '../plugins/campaignRunSocket';
import workflowSocket from '../plugins/workflowSocket';

import approveOrderItem from "./modules/approveOrderItem";
import assetCategories from "./modules/assetCategories";
import assets from "./modules/assets";
import basket from './modules/basket';
import budgets from "./modules/budgets";
import bundleConfigurator from "./modules/bundleConfigurator";
import bundles from "./modules/bundles";
import calendarEntry from "./modules/calendarEntry";
import campaignRun from "./modules/campaignRun";
import checkout from "./modules/checkout";
import country from './modules/country';
import digitalVCard from "./modules/digitalVCard";
import document from "./modules/document";
import files from "./modules/files";
import htmlFragment from "./modules/htmlFragment";
import image from './modules/image';
import jobQueue from "./modules/jobQueue";
import marketingPlanning from "./modules/marketingPlanning";
import myAccount from "./modules/myAccount";
import news from "./modules/news";
import orders from './modules/orders';
import productCategories from './modules/productCategories';
import productDetail from './modules/productDetail';
import productNav from "./modules/productNav";
import products from './modules/products';
import quickCheckout from './modules/quickCheckout';
import search from "./modules/search";
import session from './modules/session';
import shop from './modules/shop';
import slider from "./modules/slider";
import tags from "./modules/tags";
import transferPool from "./modules/transferPool";
import user  from './modules/user';
import widgets from "./modules/widgets";
import workflowEvents from "./modules/workflowEvents";
import workflowInstance from "./modules/workflowInstance";
import workflowInstanceHistory from "./modules/workflowInstanceHistory";
import workflowInstanceSnapshot from "./modules/workflowInstanceSnapshot";
import workflowTask from "./modules/workflowTask";
import workflowTaskHistory from "./modules/workflowTaskHistory";
import workflowTimeline from "./modules/workflowTimeline";

const debug = process.env.NODE_ENV !== 'production';

const modules = {
    approveOrderItem,
    assetCategories,
    assets,
    basket,
    budgets,
    bundleConfigurator,
    bundles,
    calendarEntry,
    campaignRun,
    checkout,
    country,
    digitalVCard,
    document,
    files,
    htmlFragment,
    image,
    jobQueue,
    marketingPlanning,
    myAccount,
    news,
    orders,
    productCategories,
    productDetail,
    productNav,
    products,
    quickCheckout,
    search,
    session,
    shop,
    slider,
    tags,
    transferPool,
    user,
    widgets,
    workflowEvents,
    workflowInstance,
    workflowInstanceHistory,
    workflowInstanceSnapshot,
    workflowTask,
    workflowTaskHistory,
    workflowTimeline
};

const actions = {
    // reset the app -> User is loggedOut
    resetAll ({ commit, dispatch }) {
        commit('session/RESET');
        dispatch('clearAll');
    },
    // clear all modules -> User stays loggedIn#
    // e.g. the user switch shop context
    clearAll ({ commit }) {
        commit('approveOrderItem/RESET');
        commit('assetCategories/RESET');
        commit('assets/RESET');
        commit('basket/RESET');
        commit('budgets/RESET');
        commit('bundles/RESET');
        commit('calendarEntry/RESET');
        commit('campaignRun/RESET');
        commit('checkout/RESET');
        commit('country/RESET');
        commit('document/RESET');
        commit('files/RESET');
        commit('htmlFragment/RESET');
        commit('image/RESET');
        commit('jobQueue/RESET');
        commit('marketingPlanning/RESET');
        commit('myAccount/RESET');
        commit('news/RESET');
        commit('orders/RESET');
        commit('productCategories/RESET');
        commit('productDetail/RESET');
        commit('productNav/RESET');
        commit('products/RESET');
        commit('quickCheckout/RESET');
        commit('search/RESET');
        commit('shop/RESET');
        commit('slider/RESET');
        commit('tags/RESET');
        commit('transferPool/RESET');
        commit('user/RESET');
        commit('widgets/RESET');
        commit('workflowEvents/RESET');
        commit('workflowInstance/RESET');
        commit('workflowInstanceHistory/RESET');
        commit('workflowInstanceSnapshot/RESET');
        commit('workflowTask/RESET');
        commit('workflowTaskHistory/RESET');
        commit('workflowTimeline/RESET');
        commit('bundleConfigurator/RESET');
    }
};

export default createStore({
    modules,
    actions,
    strict: debug,
    plugins: [jobQueueSocket(), campaignRunSocket(), workflowSocket()]
});
