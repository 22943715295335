<template>
    <div v-if="budgetInfos && budgetInfos.length">
        <div
            class="rootNode"
            :class="{'action': hasBudgetPage}"
            @click="() => gotoBudgetPage()"
        >
            <span class="node-text">
                {{ $t('label.budgets') }}
            </span>
            <span class="circled" v-if="hasBudgetPage">
                <NdxIcon
                    icon="arrow-left"
                    flip-v
                />
            </span>
        </div>
        <div
            v-for="(budget, key) in budgetInfos"
            :key="key"
            class="budgetContainer pt-3 pb-3"
        >
            <div class="d-flex justify-content-between gap-3">
                <div>
                    <div class="budgetName">{{ budget.name }}</div>
                    <div class="budgetDescription">{{ budget.description }}</div>
                </div>
                <div class="chartContainer" v-if="showRemainingValue">
                    <BudgetChart :budget="budget"/>
                    <div class="preventTooltip"></div>
                </div>
            </div>
            <table class="w-100 budgetInfos">
                <tbody>
                    <tr v-if="budget.validDate">
                        <th>{{ $t('label.validTill') }}</th>
                        <td>{{ $d(budget.validDate, 'short') }}</td>
                    </tr>
                    <tr v-if="'reducingValue' in budget">
                        <th>{{ $t('label.reducingValue') }}</th>
                        <td class="negative">
                            - {{ formatPrice(budget.reducingValue) }}
                        </td>
                    </tr>
                    <tr v-if="showRemainingValue">
                        <th>{{ $t('label.remainingAmount') }}</th>
                        <td
                            :class="{
                                negative: budget.remainingValue < 0,
                                positive: budget.remainingValue >= 0
                            }"
                        >{{ formatPrice(budget.remainingValue) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "../../library/NdxIcon";
    import { mapGetters, mapState } from "vuex";
    import { PriceFormatter } from "../../../plugins/formatter";
    import BudgetChart from "../../budgets/BudgetChart";

    export default {
        name: "BudgetWidget",
        components: {BudgetChart, NdxIcon},
        props: {
            budgets: {
                type: [Object, Array],
                validator(values) {
                    let valid = true;
                    for (const idx in values) {
                        const budget = values[idx];
                        valid = valid && 'name' in budget && 'description' in budget &&
                            typeof budget.totalValue === 'number' && typeof budget.reducingValue === 'number' &&
                            (!budget.validDate || budget.validDate instanceof Date);
                    }

                    return valid;
                }
            }
        },
        data() {
            return {
                orderBudgets: null
            };
        },
        computed: {
            ...mapGetters('shop', [
                'currency'
            ]),
            ...mapGetters('basket', {
                basketBudgets: 'getBudgetSummary'
            }),
            ...mapState({
                productBudgets: state => state.productDetail.product?.budgets,
                order: state => state.orders.order
            }),
            budgetInfos() {
                if (this.budgets && this.budgets.length > 0) {
                    return this.budgets;
                }
                if (this.productBudgets && this.productBudgets.length > 0) {
                    return this.productBudgets;
                }
                if (this.basketBudgets && this.basketBudgets.length > 0) {
                    return this.basketBudgets;
                }
                if (this.orderBudgets && this.orderBudgets.length > 0) {
                    return this.orderBudgets;
                }

                return null;
            },
            showRemainingValue() {
                return !(this.orderBudgets && this.orderBudgets.length > 0);
            },
            hasBudgetPage() {
                const budgetSite = this.$store.getters['shop/getRouteByType']('budgets');
                return budgetSite !== null;
            }
        },
        watch: {
            order: {
                immediate: true,
                deep: true,
                handler: function () {
                    this.orderBudgets = this.$store.getters['orders/getSummaryBudgets'];
                }
            }
        },
        methods: {
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },
            gotoBudgetPage() {
                const budgetSite = this.$store.getters['shop/getRouteByType']('budgets');
                if (budgetSite) {
                    this.$router.push(budgetSite);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '../../../style/variables_ndx';

    .rootNode {
        @extend %font-h2;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 16px 16px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid var(--bs-gray-middle);

        &.action {
            cursor: pointer;
        }

        .circled {
            border: 2px solid var(--bs-gray-light);
            border-radius: 50%;
            display: flex;
            width: 32px;
            height: 32px;
            justify-content: center;
            align-items: center;
        }

        .node-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }


        &:hover.action .node-text,
        &:active.action .node-text {
            color: var(--bs-primary);
        }

        &:active .circled {
            border-color: var(--bs-primary);
            background-color: var(--bs-primary-light);
        }
    }

    .budgetContainer {
        & + .budgetContainer {
            border-top: 1px solid var(--bs-gray-middle);
        }

        .budgetName {
            @extend %font-body1;
        }

        .budgetDescription {
            @extend %font-caption;
        }

        .chartContainer {
            min-width: 40px;
            width: 40px;
            min-height: 40px;
            height: 40px;
            max-height: 40px;
            overflow: hidden;
            position: relative;

            .preventTooltip {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .budgetInfos {
            th {
                @extend %font-body2;

                color: var(--bs-disabled);
                padding-top: 8px;
                padding-bottom: 8px;
                vertical-align: text-bottom;
            }

            td {
                @extend %font-h3;

                padding-top: 8px;
                padding-bottom: 8px;
                vertical-align: text-bottom;
                text-align: end;

                &.negative {
                    color: var(--bs-danger);
                }

                &.positive {
                    color: var(--bs-success);
                }
            }
        }
    }
</style>
