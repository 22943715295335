<template>
    <table>
        <tbody>
            <template
                v-for="(entry, idx) in formData"
                :key="idx"
            >
                <tr>
                    <td class="pe-2 align-top">
                        <input
                            type="checkbox"
                            :id="'optinEl' + idx"
                            :name="'field_' + idx"
                            :data-mandatory="entry.mandatory"
                            @change="validateForm"
                        />
                    </td>
                    <td class="pb-2">
                        <label :for="'optinEl' + idx" v-html="entry.text + (entry.mandatory ? '*' : '')"></label>
                    </td>
                </tr>
                <template v-if="entry.files?.length">
                    <tr>
                        <td></td>
                        <td>
                            <a
                                v-for="(file, fileIdx) in entry.files"
                                :key="'file' + fileIdx"
                                :href="'/file/download/' + file.filename"
                                target="_blank"
                            >
                                {{ file.originalFilename }}
                            </a>
                        </td>
                    </tr>
                </template>
            </template>
        </tbody>
    </table>

    <div class="d-flex justify-content-between flex-column flex-md-row gap-3 mt-3">
        <NdxButtonLink @click="logout">{{ $t('btn.logout') }}</NdxButtonLink>
        <NdxButton
            :disabled="!formValid"
            @click="save"
        >
            {{ $t('btn.confirm') }}
        </NdxButton>
    </div>
</template>

<script>
    import NdxButton from "../library/NdxButton";
    import NdxButtonLink from "../library/NdxButtonLink";
    import { getRouteAfterLogin } from "../../router";

    export default {
        name: "OptIn",
        components: {NdxButtonLink, NdxButton},
        data() {
            return {
                formData: null,
                formValid: false
            };
        },
        created() {
            this.formData = this.getOptinFormData();
        },
        mounted() {
            this.validateForm();
        },
        methods: {
            getOptinFormData() {
                return this.$store.dispatch('shop/loadOptInPageData')
                    .then(formData => {
                        this.formData = formData;
                        this.$nextTick(() => this.validateForm());
                    })
                    .catch(er => console.log(er));
            },
            validateForm() {
                this.formValid = this.$el?.parentElement &&
                    Array.from(
                        this.$el.parentElement.querySelectorAll('input[data-mandatory="true"]')
                    ).reduce(
                        (valid, el) => el.checked && valid, true
                    );
            },
            logout() {
                this.$store.dispatch(
                    'session/logout'
                ).then(() => {
                    this.$router.push({name: 'Login'});
                });
            },
            save() {
                this.validateForm();
                if (this.formValid) {
                    this.$store.dispatch('shop/saveOptinPageData',
                        Array.from(
                            this.$el.parentElement.querySelectorAll('input[type="checkbox"]')
                        ).map(el => el.checked)
                    ).then(result => {
                        if (result) {
                            if (this.$store.getters['user/hasClientSwitch']) {
                                this.$router.push({name: 'ClientSelect'});
                            } else {
                                this.$router.push(getRouteAfterLogin());
                            }
                        } else {
                            this.logout();
                        }
                    });
                }
            }
        }
    };
</script>
