<template>
    <div
        class="summaryWrapper d-flex flex-column"
        :class="{
            roundedBorder: isRightSideWidget,
            isRightSideWidget: isRightSideWidget,

            'flex-md-row': !isRightSideWidget,
            'align-items-md-end': !isRightSideWidget
        }"
    >
        <div class="flex-grow-1">
            <div class="productCount text-nowrap">
                {{ $t('label.total') }} ({{productCount}} {{ $tc('label.products', productCount) }})
            </div>

            <table
                v-if="showNet || showDeliveryCosts || deliveryCostsAltText?.length > 0
                    || (!isNetClient && (showTax || showGross))"
                class="summary"
            >
                <tbody>
                    <template v-if="showNet">
                        <tr>
                            <th>{{ $t('label.priceNet') }}</th>
                            <td class="text-end">{{ formatPrice(sumNet) }}</td>
                        </tr>
                        <tr
                            v-for="additionalCost in relevantAdditionalCost"
                            :key="additionalCost.txt"
                        >
                            <th>{{ additionalCost.txt}}</th>
                            <td class="text-end">{{formatPrice(additionalCost.value)}}</td>
                        </tr>
                    </template>
                    <tr v-if="showDeliveryCosts">
                        <th>{{ $t('label.deliveryCostsNet') }}</th>
                        <td class="text-end">{{ formatPrice(sumDeliveryCostsNet) }}</td>
                    </tr>
                    <tr v-else-if="deliveryCostsAltText?.length">
                        <th class="align-top">{{ $t('label.deliveryCostsNet') }}</th>
                        <td class="text-end text-wrap">{{ deliveryCostsAltText }}</td>
                    </tr>
                    <tr v-if="showTax && !isNetClient">
                        <th>{{ $t('label.tax') }}</th>
                        <td class="text-end">{{ formatPrice(sumTax) }}</td>
                    </tr>
                    <tr v-if="showGross && !isNetClient">
                        <th>{{ $t('label.priceGross') }}</th>
                        <td class="text-end">{{ formatPrice(sumGross) }}</td>
                    </tr>
                    <tr
                        v-for="budget in relevantBudgets"
                        :key="budget.id"
                    >
                        <th>{{ budget.name}}</th>
                        <td class="text-end">-{{formatPrice(budget.value)}}</td>
                    </tr>
                </tbody>
            </table>

            <div class="priceSum text-nowrap">{{ formatPrice(relevantSum) }}</div>
        </div>

        <slot name="actions"></slot>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { PriceFormatter } from "../../../../plugins/formatter";

    export default {
        name: 'SummaryWidget',
        props: {
            widget: {
                type: Object
            },
            storeName: {
                type: String,
                default: 'basket'
            },
            forcedCurrency: {
                type: String,
                default: null
            }
        },
        computed: {
            ...mapState({
                showNet: state => state.shop.basket.showNetPrice,
                showDeliveryCosts: state => state.shop.deliveryCosts?.show,
                deliveryCostsAltText: state => state.shop.deliveryCosts?.alternativeText,
                shopAdditionalPriceText: state => state.shop.additionalPriceText,
                showGross: state => state.shop.basket.showGrossPrice,
                showTax: state => state.shop.showTax,
                isNetClient: state => state.user?.shopClient?.isNet
            }),
            ...mapGetters('shop', [
                'currency'
            ]),
            isRightSideWidget() {
                return !!this.widget?.config?.isRightSideWidget;
            },

            productCount() {
                return this.$store.getters[this.storeName + '/getProductCount'];
            },
            sumNet() {
                return this.$store.getters[this.storeName + '/getSumNet'];
            },
            sumTax() {
                return this.$store.getters[this.storeName + '/getSumTax'];
            },
            sumGross() {
                return this.$store.getters[this.storeName + '/getSumGross'];
            },
            sumDeliveryCostsNet() {
                return this.$store.getters[this.storeName + '/getSumDeliveryCostsNet'];
            },
            relevantSum() {
                return this.$store.getters[this.storeName + '/getRelevantSum'](this.showGross, this.isNetClient);
            },
            relevantBudgets() {
                return this.$store.getters[this.storeName + '/getPriceRelevantBudgets'];
            },
            orderItems() {
                return this.$store.getters[this.storeName + '/getOrderItems'];
            },
            relevantAdditionalCost() {
                let list = {};

                for (let item of this.orderItems) {
                    if (item.additionalPriceNet && item.additionalPriceNet > 0) {
                        let value = parseFloat(item.additionalPriceNet);

                        let txt = this.$t('label.additionalPrice');
                        if (item.additionalCostsText && item.additionalCostsText.length > 0) {
                            txt = item.additionalCostsText;
                        } else if (this.shopAdditionalPriceText && this.shopAdditionalPriceText.length > 0) {
                            txt = this.shopAdditionalPriceText;
                        }

                        let txtHash = this.hashCode(txt);

                        if (!(txtHash in list)) {
                            list[txtHash] = {
                                txt: txt,
                                value: 0
                            };
                        }

                        list[txtHash].value += value;
                    }
                }

                return list;
            }
        },
        methods: {
            formatPrice(value) {
                return PriceFormatter(value, this.forcedCurrency || this.currency, this);
            },
            hashCode(str) {
                let hash = 0;
                if (str.length === 0) {
                    return hash;
                }
                for (let i = 0; i < str.length; i++) {
                    let chr   = str[i].charCodeAt();
                    hash  = ((hash << 5) - hash) + chr;
                    hash |= 0; // Convert to 32bit integer
                }
                return hash;
            }
        }
    };
</script>
