<template>
    <div class="info-txt w-100">{{ $t('message.passwordForgotten') }}</div>

    <NdxNotification
        :message="errorMsg"
        v-model="showPwRecoverErrorMsg"
        variant="error"
        :duration="0"
        class="w-100"
    />

    <NdxNotification
        :message="successMsg"
        v-model="showSuccessMsg"
        variant="success"
        :duration="10"
        class="w-100"
    />

    <div class="ndxFormWrapper">
        <NdxInput
            name="username"
            :label="$t('label.username')"
            v-model="username"
            icon="users"
            @on-key-enter="submitForm"
        />
    </div>

    <NdxButton :disabled="!formValid" @click="submitForm">{{ $t('btn.requestPassword') }}</NdxButton>
    <NdxButtonLink @click="$router.push({name: 'Login'})">{{ $t('btn.toLogin') }}</NdxButtonLink>
</template>

<script>
    import NdxInput from "../library/formElements/NdxInput";
    import NdxNotification from "../library/NdxNotification";
    import { mapState } from "vuex";
    import NdxButton from "../library/NdxButton";
    import NdxButtonLink from "../library/NdxButtonLink";

    export default {
        name: 'PasswordRecover',
        components: {NdxButtonLink, NdxButton, NdxNotification, NdxInput},
        data() {
            return {
                username: '',
                successMsg: null
            };
        },
        computed: {
            ...mapState({
                passwordRecoverError: state => state.session.passwordRecoverError,
            }),
            showPwRecoverErrorMsg: {
                get() {
                    return this.passwordRecoverError !== null;
                },
                set(val) {
                    if (!val) {
                        this.$store.dispatch('session/clearPasswordRecoverError');
                    }
                }
            },
            errorMsg() {
                if (this.showPwRecoverErrorMsg && this.passwordRecoverError === 'passwordRecoverError') {
                    return (this.passwordRecoverError === 'passwordRecoverError') ?
                        this.$t('message.passwordRecoverError') : this.passwordRecoverError;
                }
                return '';
            },
            formValid() {
                return this.username && this.username.length > 0;
            },
            showSuccessMsg: {
                get() {
                    return this.successMsg && this.successMsg.length > 0;
                },
                set(val) {
                    if (!val) {
                        this.successMsg = null;
                    }
                }
            }
        },
        beforeUnmount() {
            this.showPwRecoverErrorMsg = false;
        },
        methods: {
            submitForm() {
                if (!this.formValid) {
                    return;
                }
                this.$store.dispatch(
                    'session/recoverPassword',
                    {
                        username: this.username.trim()
                    }
                ).then(() => {
                    this.successMsg = this.$t('message.passwordRecoverSucceeded');
                    this.username = '';
                }).catch(() => {
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .notification {
        margin-top: 30px;
    }
    .ndxFormWrapper {
        margin-top: 30px;
    }
    .info-txt {
        @extend %font-body2;
    }
    .btn {
        margin-top: 20px;
    }
</style>
