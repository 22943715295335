<script>
    import CategoryTreeMixin from "./CategoryTreeMixin.vue";

    export default {
        name: "AssetCategoryTreeWidget",
        mixins: [CategoryTreeMixin],
        data() {
            return {
                store: 'assetCategories'
            };
        },
        computed: {
            isDetailPage() {
                return this.$route.params
                    && 'aid' in this.$route.params
                    && +this.$route.params.aid > 0;
            }
        },
        methods: {
            getCurrentCategory() {
                const isTopCategory = +this.currentCategoryId === +this.rootCategoryId;
                this.$store.dispatch('assetCategories/getSubtreeBulk', {
                    idList: isTopCategory ?
                        this.widget.config.wgc.config.styleguideIds :
                        [this.widget.config.categoryId]
                }).then(subTrees => {
                    if (isTopCategory) {
                        const getIdx = (item) => {
                            return this.widget.config.wgc.config.styleguideIds.findIndex((id) => id == item.id);
                        };
                        this.currentCategory = {
                            name: '',
                            children: subTrees.sort((a,b) => getIdx(a) - getIdx(b))
                        };
                    } else {
                        this.currentCategory = subTrees[0];
                    }
                }).catch((error) => {
                    console.error(error);
                    this.currentCategory = {
                        name: '',
                        children: []
                    };
                });
            },
            gotoCategory(categoryId) {
                this.$router.push({
                    name: 'AssetsInCategory',
                    params: {
                        id: this.$route.params.id,
                        categoryId: categoryId,
                        aid: ''
                    }
                });
            }
        }
    };
</script>
