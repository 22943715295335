import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'outputHandler';

export default {
    getData(projectSetupId, userSpecific) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getData', [+projectSetupId, userSpecific], throbberRules.NONE)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getVCardDataByProjectRunIds(projectRunIds) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getVCardDataByProjectRunIds', [projectRunIds], throbberRules.NONE)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
