import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'asset';

export default {
    searchEntries(categoryId, limit, offset, searchText, throbberRule = throbberRules.FULLSCREEN) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'searchEntries',
                [categoryId, limit, offset, searchText],
                throbberRule
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getEntries(categoryId, limit, offset, searchText, throbberRule = throbberRules.FULLSCREEN) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'getEntries',
                [categoryId, limit, offset, searchText],
                throbberRule
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getEntry(entryId, throbberRule = throbberRules.FULLSCREEN) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'getEntry',
                [+entryId],
                throbberRule
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
