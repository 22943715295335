<template>
    <div class="info-txt w-100">{{ $t('message.login-info') }}</div>

    <NdxNotification
        v-if="loginErrorMsg && loginErrorMsg.length > 0"
        :message="loginErrorMsg"
        v-model="showLoginErrorMsg"
        variant="error"
        :duration="0"
        class="w-100"
    />

    <div class="ndxFormWrapper">
        <NdxInput
            name="username"
            :label="$t('label.username')"
            v-model="username"
            icon="users"
            @on-key-enter="submitForm"
        />
        <NdxInput
            name="password"
            :label="$t('label.password')"
            v-model="password"
            icon="single-sign-on"
            type="password"
            @on-key-enter="submitForm"
        />
    </div>

    <NdxButton :disabled="!formValid" @click="submitForm">{{ $t('btn.login') }}</NdxButton>
    <NdxButtonLink @click="$router.push({name: 'PasswordRecover'})">{{ $t('btn.recoverPassword') }}</NdxButtonLink>
    <NdxButtonLink v-if="showBackBtn" @click="$emit('do-cancel')">{{ $t('btn.cancel') }}</NdxButtonLink>
</template>

<script>
    import { mapState } from 'vuex';
    import { getRouteAfterLogin } from "../../router";
    import NdxInput from "../library/formElements/NdxInput";
    import NdxNotification from "../library/NdxNotification";
    import NdxButton from "../library/NdxButton";
    import NdxButtonLink from "../library/NdxButtonLink";

    export default {
        name: 'Login',
        components: {NdxButtonLink, NdxButton, NdxNotification, NdxInput},
        props: {
            showBackBtn: {
                type: Boolean
            }
        },
        emits: ['do-cancel'],
        data() {
            return {
                username: '',
                password: ''
            };
        },
        computed: {
            ...mapState({
                loginError: state => state.session.loginError
            }),
            showLoginErrorMsg: {
                get() {
                    return this.loginError !== null;
                },
                set(val) {
                    if (!val) {
                        this.$store.dispatch('session/clearLoginError');
                    }
                }
            },
            formValid() {
                return this.username && this.username.length &&
                    this.password && this.password.length;
            },
            loginErrorMsg() {
                return this.$store.getters['session/getLoginErrorMsg'](this.$t, this.$te);
            }
        },
        beforeUnmount() {
            this.showLoginErrorMsg = false;
        },
        methods: {
            submitForm() {
                if (!this.formValid) {
                    return;
                }
                this.$store.dispatch(
                    'session/login',
                    {
                        username: this.username.trim(),
                        password: this.password.trim()
                    }
                ).then((hasClientSwitch) => {
                    if (hasClientSwitch) {
                        this.$router.push({name: 'ClientSelect'});
                    } else {
                        this.$router.push(getRouteAfterLogin());
                    }
                }).catch(() => {
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .notification {
        margin-top: 30px;
    }

    .ndxFormWrapper {
        margin-top: 30px;
    }

    .info-txt {
        @extend %font-body2;
    }

    .btn {
        margin-top: 20px;

        &.link-primary + .btn.link-primary {
            margin-top: 10px;
        }
    }
</style>
