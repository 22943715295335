<template>
    <Group :group="group">
        <template #group-items>
            <AddressesGroupItem
                v-for="item in items"
                :key="item.orderItemId"
                :item="item"
                :is-multi-address="isMultiAddress"
                @update-valid="(data) => $emit('update-valid', data)"
            />
        </template>
    </Group>
</template>

<script>
    import AddressesGroupItem from "./AddressesGroupItem";
    import Group from "./parts/Group";

    export default {
        name: "AddressesGroup",
        components: {Group, AddressesGroupItem},
        props: {
            group: {
                type: Object,
                required: true
            },
            isMultiAddress: {
                type: Boolean
            }
        },
        emits: ['update-valid'],
        computed: {
            items() {
                return this.group.items.filter(item => item.quantity > 0);
            }
        }
    };
</script>
