<template>
    <div
        class="ndx-badge"
        :class="{
            [variant]: true,
            ['size-' + size]: true
        }"
    >
        <slot name="default"/>
    </div>
</template>

<script>
    export default {
        name: 'NdxBadge',
        props: {
            variant: {
                type: String,
                default: 'secondary',
                validator: function (value) {
                    return ['primary', 'secondary'].indexOf(value) !== -1;
                }
            },
            size: {
                type: String,
                default: 'm',
                validator: function (value) {
                    return ['s', 'm'].indexOf(value) !== -1;
                }
            }
        }
    };
</script>
