<template>
    <div class="product-groupItem">
        <div class="bundleParent d-flex flex-column gap-3">
            <div class="d-flex flex-wrap gap-3 justify-content-between">
                <div class="d-flex flex-row justify-content-start flex-grow-0 gap-3 cursorPointer flex-wrap">
                    <div class="imageWrapper" @click="goToProduct">
                        <NdxImage
                            :src="bundle.bundleProduct.image"
                            size="xsOrder"
                        />
                    </div>
                    <div class="flex-grow-0">
                        <div class="title">{{ bundle.bundleProduct.name }}</div>
                        <div class="d-flex flex-row justify-content-start gap-3 flex-wrap">
                            <div>
                                <table class="list-key-table">
                                    <tbody>
                                        <tr>
                                            <th class="listKey align-top min-width">{{ $t('label.quantity') }}:</th>
                                            <td class="listValue align-top">1</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="type==='basket'" class="bundlePrice">{{formatPrice(bundle.bundleProduct.priceNet)}}</div>
            </div>
        </div>
        <div class="d-flex flex-wrap gap-3 justify-content-end mt-2">
            <NdxButtonLink v-if="type==='basketx'" @click="moveBundleToWatchlist">
                {{ $t('btn.pushToWatchlist') }}
            </NdxButtonLink>
            <NdxButtonLink v-if="type==='watchlist'" @click="moveBundleToBasket">
                {{ $t('label.addToBasket') }}
            </NdxButtonLink>
            <NdxButtonLink @click="removeBundle">{{ $t('btn.removeFromBasket') }}</NdxButtonLink>
        </div>
    </div>
    <NdxFlyIn v-if="deleteBundle">
        <template #default>
            {{
                $t('message.deleteBundle', {
                    name: bundle.bundleProduct.name
                })
            }}
        </template>
        <template #buttons>
            <NdxButton class="btnFlex" @click="doRemoveBundle">{{ $t('btn.yes') }}</NdxButton>
            <NdxButton class="btnFlex" @click="() => deleteBundle = false">{{ $t('btn.no') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxImage from "../../../library/NdxImage";
    import { PriceFormatter } from "../../../../plugins/formatter";
    import { mapGetters} from "vuex";
    import NdxButtonLink from "../../../library/NdxButtonLink.vue";
    import NdxFlyIn from "../../../library/NdxFlyIn.vue";
    import NdxButton from "../../../library/NdxButton.vue";

    export default {
        name: 'BundleProductItem',
        components: {NdxButton, NdxFlyIn, NdxButtonLink, NdxImage},
        props: {
            bundle: {
                type: Object,
                required: true
            },
            type: {
                type: String,
                default: 'basket'
            }
        },
        emits: ['refresh-list'],
        data() {
            return {
                deleteBundle: false
            };
        },
        computed: {
            ...mapGetters('shop', {
                currency: 'currency',
                defaultProductImageBgColor: 'getDefaultProductImageBgColor'
            })
        },
        methods: {
            removeBundle() {
                this.deleteBundle = true;
            },
            doRemoveBundle() {
                this.deleteBundle = false;
                this.$store.dispatch('basket/removeBundle', {
                    bundleId: this.bundle.bundleId,
                    listType: this.type
                }).then(() => {
                    this.$emit('refresh-list');
                });
            },
            moveBundleToBasket() {
                this.$store.dispatch('basket/moveBundleToBasket', {
                    bundleId: this.bundle.bundleId
                }).then(() => {
                    this.$emit('refresh-list');
                });
            },
            moveBundleToWatchlist() {
                this.$store.dispatch('basket/moveBundleToWatchlist', {
                    bundleId: this.bundle.bundleId
                }).then(() => {
                    this.$emit('refresh-list');
                });
            },
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            }
        }
    };
</script>

<style scoped lang="scss">
.bundleParent {
    .bundlePrice {
        color: var(--bs-primary);
    }
    .imageWrapper {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
    }
}
</style>
