<template>
    <div
        v-if="basketGroups !== null"
        class="frameContainer"
    >
        <Top
            :list-custom-checkout-fields="true"
        />
        <div class="groupContainer">
            <BasketSummaryGroup
                v-for="group in basketGroupsFiltered"
                :key="group.vendor.id"
                :group="group"
            />
        </div>
    </div>
</template>

<script>
    import Top from "./parts/Top";
    import BasketSummaryGroup from "./BasketSummaryGroup";
    import { mapGetters, mapState } from "vuex";

    export default {
        name: 'BasketSummary',
        components: {BasketSummaryGroup, Top},
        computed: {
            ...mapState({
                basketGroups: state => state.checkout.basketGroups
            }),
            ...mapGetters('basket', {
                orderable: 'isOrderable'
            }),
            basketGroupsFiltered() {
                return this.basketGroups.filter(group => {
                    return group.items.filter(item => item.quantity > 0).length > 0;
                });
            }
        },
        created() {
            this.$store.dispatch('basket/getAllData').then(() => {
                this.$store.dispatch('checkout/loadingFinished');
                this.validate();
            });
        },
        methods: {
            validate() {
                this.$store.dispatch('checkout/setIsValid', this.orderable);
            }
        }
    };
</script>
