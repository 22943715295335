<template>
    <NdxPageFrame>
        <EntryDetail v-if="assetId !== null" :entry-id="assetId"/>
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import EntryDetail from "../../components/assets/EntryDetail";

    export default {
        name: 'AssetEntryDetail',
        components: {EntryDetail, NdxPageFrame},
        data() {
            return {
                assetId: null
            };
        },
        watch: {
            '$route.params': {
                handler: 'setAssetId',
                deep: true,
                immediate: true
            }
        },
        methods: {
            setAssetId() {
                if (this.$route.params
                    && 'aid' in this.$route.params
                    && +this.$route.params.aid > 0
                ) {
                    this.assetId = +this.$route.params.aid;
                } else {
                    this.assetId = null;
                }
            }
        }
    };
</script>
