<template>
    <NdxPageFrame>
        <template #default="slotProps">
            <ProductDetailComponent
                v-if="showProductsDetails && product"
                :parent-width="slotProps.parentWidth"
            />
            <ProductList v-else-if="!showProductsDetails"/>
        </template>
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import ProductList from "../../components/products/ProductList";
    import ProductDetailComponent from "../../components/products/detail/ProductDetail";
    import { mapState } from "vuex";

    export default {
        name: 'Products',
        components: {ProductList, ProductDetailComponent, NdxPageFrame},
        data() {
            return {
                showProductsDetails: false,
                leaving: false
            };
        },
        computed: {
            ...mapState({
                product: state => state.productDetail.product
            })
        },
        watch: {
            '$route.params': {
                handler: 'calculateState',
                deep: true,
                immediate: true
            }
        },
        beforeRouteLeave() {
            this.leaving = true;
        },
        methods: {
            calculateState() {
                if (this.leaving) {
                    return;
                }
                this.showProductsDetails = this.$route.params && 'pid' in this.$route.params
                    && +this.$route.params.pid > 0;

                if (this.showProductsDetails) {
                    this.$store.dispatch('productDetail/find', {
                        productId: +this.$route.params.pid
                    }).then(() => {
                        this.$store.dispatch('productDetail/setDataComplete', true);
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            }
        }
    };
</script>
