<template>
    <div class="flyIn p-0">
        <div class="greyOut"></div>
        <div
            class="contentWrapper mh-100"
            :style="computedStyle"
            v-click-outside="clickOutside"
        >
            <div class="ps ms-3">
                <div class="title mb-2 me-3 flex-shrink-0">
                    <slot name="title" />
                </div>
                <div class="me-3">
                    <slot name="default" />
                </div>
            </div>
            <div class="d-flex flex-column gap-2 justify-content-end mx-3 mt-3">
                <slot name="buttons" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NdxFlyIn",
        props: {
            /**
             * Use to set a fixed width
             */
            width: {
                type: String
            }
        },
        emits: ['click-outside'],
        computed: {
            computedStyle() {
                return this.width ? 'min-width: ' + this.width + ';' + 'max-width: ' + this.width + ';' : '';
            }
        },
        mounted() {
            this.$store.dispatch('session/addOverlay');
        },
        beforeUnmount() {
            this.$store.dispatch('session/removeOverlay');
        },
        methods: {
            clickOutside() {
                this.$emit('click-outside');
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_bootstrap";
    @import "../../style/variables_ndx";
    @import "~bootstrap/scss/mixins/breakpoints";

</style>
