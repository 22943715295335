<template>
    <NdxPageHeader
        hide-filter
        hide-view-mode
        :nav-name="getLabel('Absence', this)"
        :breadcrumbs="breadcrumbs"
    />
    <div class="myAccount-data-container" v-if="initiated">
        <div class="myAccount-data">
            <div class="myAccount-data-item">
                <div class="control">
                    <div class="key"></div>
                    <div class="value-wrapper">
                        <div class="value">
                            <NdxButtonLink @click="newEntry()">{{ $t('btn.add') }}</NdxButtonLink>
                        </div>
                    </div>
                </div>
            </div>
            <div class="myAccount-data-item" v-for="entry in entries" :key="entry.id">
                <div class="control">
                    <div class="key">
                        <b>{{entry.name}}</b>
                        {{ $t('label.dateFrom') }} {{ $d(ndxDateConvert(entry.start), 'long')}}
                        {{ $t('label.dateTo') }} {{ entry.end ? $d(ndxDateConvert(entry.end), 'long') : ' ...' }}
                    </div>
                    <div class="value-wrapper">
                        <div class="value">
                            <NdxButtonLink @click="openEditor(entry)">{{ $t('btn.edit') }}</NdxButtonLink>
                            <NdxButtonLink @click="remove(entry)">{{ $t('btn.remove') }}</NdxButtonLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <NdxFlyIn v-if="showEditor">
        <template #title>
            {{ 'id' in localEntry && +localEntry.id > 0 ? $t('label.absenceEditor') : $t('label.absenceEditorCreate') }}
        </template>
        <template #default>
            <NdxInput
                :label="$t('label.name')"
                v-model="localEntry.name"
                :invalid="!localEntry.name"
                @update:model-value="validate"
            />
            <NdxTextarea
                :label="$t('label.description')"
                v-model="localEntry.description"
            />
            <NdxDatePicker
                name="startDate"
                :label="$t('label.dateFrom')"
                v-model="startDate"
                clearButton
                :invalid="!startDate"
                @update:model-value="validate"
                :max-date="endDate"
                :set-noon="false"
            />
            <NdxDatePicker
                name="endDate"
                :label="$t('label.dateTo')"
                v-model="endDate"
                @update:model-value="updateEndDate"
                clearButton
                :min-date="startDate"
                :set-noon="false"
            />
        </template>
        <template #buttons>
            <NdxButtonLink @click="hideEditor">{{ $t('btn.close') }}</NdxButtonLink>
            <NdxButton :disabled="!isValid || !isDirty" @click="save">{{ $t('btn.save') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxButton from "../library/NdxButton";
    import { mapGetters, mapState } from "vuex";
    import NdxButtonLink from "../library/NdxButtonLink.vue";
    import NdxFlyIn from "../library/NdxFlyIn.vue";
    import { ndxDateConvert } from "@utilities/ndxDate";
    import NdxInput from "../library/formElements/NdxInput.vue";
    import NdxTextarea from "../library/formElements/NdxTextarea.vue";
    import NdxDatePicker from "../library/formElements/NdxDatePicker.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: 'Absence',
        components: {NdxPageHeader, NdxDatePicker, NdxTextarea, NdxInput, NdxFlyIn, NdxButtonLink, NdxButton},
        data() {
            return {
                showEditor: false,
                entries: [],
                localEntry: null,
                localEntryOrg: null,
                isValid: false
            };
        },
        computed: {
            ...mapState({
                initiated: state => state.myAccount.initiated,
            }),
            ...mapGetters('user', [
                'currentUserId'
            ]),
            ...mapGetters('myAccount', [
                'getLabel'
            ]),
            startDate: {
                get() {
                    return this.localEntry ? ndxDateConvert(this.localEntry.start) : null;
                },
                set(newDate) {
                    if (newDate !== null) {
                        newDate = ndxDateConvert(newDate);
                        this.localEntry.start = newDate.toISOString();
                    } else {
                        this.localEntry.start = newDate;
                    }
                }
            },
            endDate: {
                get() {
                    return this.localEntry ? ndxDateConvert(this.localEntry.end) : null;
                },
                set(newDate) {
                    if (newDate !== null) {
                        newDate = ndxDateConvert(newDate);
                        this.localEntry.end = newDate.toISOString();
                    } else {
                        this.localEntry.end = newDate;
                    }
                }
            },
            isDirty() {
                if (this.localEntryOrg === null || this.localEntry === null) {
                    return true;
                }

                let org = JSON.parse(JSON.stringify(this.localEntryOrg));
                let local = JSON.parse(JSON.stringify(this.localEntry));

                const startOrg = org.start ? ndxDateConvert(org.start).toISOString() : null;
                const endOrg = org.end ? ndxDateConvert(org.end).toISOString() : null;

                const startLocal = local.start ? ndxDateConvert(local.start).toISOString() : null;
                const endLocal = local.end ? ndxDateConvert(local.end).toISOString() : null;

                delete org.start;
                delete org.end;

                delete local.start;
                delete local.end;

                return JSON.stringify(org) !== JSON.stringify(local) || startOrg !== startLocal || endOrg !== endLocal;
            },
            breadcrumbs() {
                return [{
                    label: this.$t('label.myAccount'),
                    click: () => this.$router.push({name: 'MyAccount'})
                }, {
                    label: this.getLabel('Absence', this)
                }];
            }
        },
        created() {
            this.$store.dispatch('myAccount/getDataCheckConfig');
        },
        mounted() {
            this.fetchCalendarEntries();
        },
        methods: {
            ndxDateConvert,
            fetchCalendarEntries: function () {
                const specifier = {
                    user: this.currentUserId
                };
                this.$store.dispatch('calendarEntry/filterBy', {
                    appData: specifier
                }).then((result) => {
                    this.entries = result.list;
                });
            },
            remove: function (entry) {
                this.$store.dispatch('calendarEntry/delete', entry.id).then(() => {
                    this.fetchCalendarEntries();
                });
            },
            newEntry: function () {
                let newEntry = JSON.parse(JSON.stringify(this.$store.getters['calendarEntry/getNewModel']));
                newEntry.user = this.currentUserId;
                this.openEditor(newEntry);
            },
            openEditor: function (entry) {
                this.localEntry = JSON.parse(JSON.stringify(entry));
                this.localEntryOrg = JSON.parse(JSON.stringify(entry));
                this.showEditor = true;
                this.validate();
            },
            hideEditor: function () {
                this.showEditor = false;
                this.localEntry = null;
                this.localEntryOrg = null;
            },
            validate: function () {
                this.isValid = this.localEntry.name && this.localEntry.start ? true : false;
                return this.isValid;
            },
            updateEndDate(date) {
                if (date !== null) {
                    // set end time to 23:59
                    // !!! do NOT try to consider daylight saving time or time zones here !!!
                    let d = new Date(date);
                    d.setTime(d.getTime() + (24 * 60 * 60 * 1000 ) - 1000 * 60);
                    this.endDate = d;
                }
            },
            save: function () {
                this.localEntry.start = this.startDate;
                this.localEntry.end = this.endDate;
                this.$store.dispatch('calendarEntry/update', this.localEntry)
                    .then((result) => {
                        this.localEntry = JSON.parse(JSON.stringify(result));
                        this.localEntryOrg = JSON.parse(JSON.stringify(result));
                        this.fetchCalendarEntries();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    };
</script>

<style lang="scss" scoped>
.ndx-form-element  {
    margin-top: 20px;
}
.ndx-form-element.ndx-date-picker .datePreviewWrapper {
    border-bottom: none;
}
</style>
