import newsApi from "../../api/news";

// Compare this with the constants in NewsItem.php
const types = {
    unread: -1, // fe type
    all: 'all', // fe type
    news: 0,
    task: 1,
    system: 2,
    prospect: 3,
    genericRequest: 4,
    reply: 5,
    approveRequest: 6,
    voucherRequest: 7,
    contentObjectRequest: 8,
    productQuestion: 9,
    groupPublic: 10,
    groupPrivate: 11
};

//state
const state = {
    unreadNewsCount: 0,
    newsFilter: types.all,
    unreadNewsCounts: {},
    userSettings: {}
};

// getters
const getters = {
    getNewModel() {
        return JSON.parse(JSON.stringify({
            title: '',
            content: '',
            dueDate: null,
            startDate: null,
            readByLoggedUser: true,
            allowReply: true,
            replyTo: null,
            type: types.reply,
            files: []
        }));
    },
    replaceStyleguideLinks(state, getters, rootState, rootGetters) {
        const wgcs = rootGetters['shop/getWgcsByType']('styleguide');
        const devSwitch = window.location.pathname.includes("/portal/") ? 'portal/' : '';
        return (content) => {
            content = content.replace(
                /\/shop\/secure\/styleguide\/(\d+)#sge(\d+)/g,
                function (completeMatch, styleguideId, styleguideEntryId) {
                    return "/" + devSwitch + "#/asset/" + styleguideEntryId;
                }
            );

            content = content.replace(
                /\/shop\/secure\/styleguide\/(\d+)/g,
                function (completeMatch, styleguideId) {
                    const wgc = wgcs.find(wgc => wgc.config.styleguideIds.includes(+styleguideId));
                    if (wgc) {
                        return "/" + devSwitch + "#/" + wgc?.id + "/assets/" + styleguideId;
                    } else {
                        return "/" + devSwitch + "#/pageNotFound";
                    }
                }
            );

            return content;
        };
    },
    getTimesortedThreadNews(state, getters) {
        return (thread) => {
            let tmp = [];
            for (const i in thread) {
                for (const j in thread[i]) {
                    let news = thread[i][j];
                    news.content = getters.replaceStyleguideLinks(news.content);
                    tmp.push(news);
                }
            }
            tmp.sort(function (newsItem1, newsItem2) {
                return newsItem1.createdAt.date < newsItem2.createdAt.date ? -1 : 1;
            });

            return tmp;
        };
    },
    isEmailNotification(state) {
        return state?.userSettings.isEmailNotification ?? false;
    },
    isInstantNotification(state) {
        return state?.userSettings.isInstantNotification ?? false;
    },
    isWorkflowNotification(state) {
        return state?.userSettings.isWorkflowNotification ?? false;
    },
    isDownloadProductFilesEmailNotification(state) {
        return state?.userSettings.isDownloadProductFilesEmailNotification ?? false;
    },
    productQuestionsAllowed(state, getters, rootState, rootGetters) {
        const wgcs = rootGetters['shop/getWgcsByType']('news');
        if (wgcs && wgcs.length > 0) {
            return wgcs[0].config.productQuestions;
        }
        return false;
    },
    messageCreate(state, getters, rootState, rootGetters) {
        const wgcs = rootGetters['shop/getWgcsByType']('news');
        if (wgcs && wgcs.length > 0) {
            return wgcs[0].config.messageCreate;
        }
        return 0;
    }
};

// actions
const actions = {
    findAllRelevant({commit}, data) {
        return new Promise((resolve, reject) => {
            newsApi.findAllRelevant(data.limit, data.offset)
                .then((list) => {
                    resolve(list);
                    if (data.limit === null && data.offset === 0) {
                        // use result to update "global" unread news count
                        const newsCounts = {};
                        const unreadNewsCount = list.reduce(
                            (sum, news) => {
                                const count = news.replyInfo.unreadCount + (news.readByLoggedUser ? 0 : 1);
                                newsCounts['' + news.type] = (newsCounts[news.type] || 0) + count;
                                return sum + count;
                            },
                            0
                        );

                        commit('SET_UNREAD_COUNT', unreadNewsCount);
                        commit('SET_NEWS_COUNTS', newsCounts);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getThread(ctx, data) {
        return new Promise((resolve, reject) => {
            newsApi.getThread(data.newsId)
                .then((thread) => {
                    resolve(thread);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    create({commit}, data) {
        return new Promise((resolve, reject) => {
            newsApi.create(data.newsData, data.threadStartId)
                .then((res) => {
                    commit('SET_UNREAD_COUNT', res.unreadCount);
                    resolve(res.thread);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    markReadBottomUp({commit}, data) {
        return new Promise((resolve, reject) => {
            newsApi.markReadBottomUp(data.newsId, data.threadStartId)
                .then((res) => {
                    commit('SET_UNREAD_COUNT', res.unreadCount);
                    commit('SET_NEWS_COUNTS', res.unreadCounts);
                    resolve(res.thread);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    markReadTopDown({commit}, data) {
        return new Promise((resolve, reject) => {
            newsApi.markReadTopDown(data.newsId, data.threadStartId)
                .then((res) => {
                    commit('SET_UNREAD_COUNT', res.unreadCount);
                    commit('SET_NEWS_COUNTS', res.unreadCounts);
                    resolve(res.thread);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setUnreadCount({commit}, count) {
        commit('SET_UNREAD_COUNT', Math.max(0, +count));
    },
    setNewsFilter({commit}, filterValue) {
        commit('SET_NEWS_FILTER', filterValue);
    },
    setNewsCounts({commit}, counts) {
        commit('SET_NEWS_COUNT', counts);
    },
    setNewsSettings({commit}, settings) {
        commit('SET_NEWS_SETTINGS', settings);
    },
    getRecipients(ctx, data) {
        return new Promise((resolve, reject) => {
            newsApi.getRecipients(data.searchText, null, 0)
                .then((userList) => {
                    resolve(userList);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    saveNewsSettings({commit}, data) {
        return new Promise((resolve, reject) => {
            newsApi.saveNewsSettings(
                data.unreadNotification,
                data.instantNotification,
                data.workflowNotification,
                data.downloadProductFilesEmailNotification
            )
                .then((userSettings) => {
                    commit('SET_NEWS_SETTINGS', userSettings);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.unreadNewsCount = 0;
        state.newsFilter = 'all';
        state.unreadNewsCounts = {};
        state.userSettings = {};
    },
    SET_UNREAD_COUNT(state, count) {
        state.unreadNewsCount = count;
    },
    SET_NEWS_FILTER(state, filter) {
        state.newsFilter = filter;
    },
    SET_NEWS_COUNTS(state, counts) {
        state.unreadNewsCounts = counts;
    },
    SET_NEWS_SETTINGS(state, settings) {
        state.userSettings = settings;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export const newsTypes = types;
