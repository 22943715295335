<template>
    <div v-if="!loading && items.length === 0" class="empty-result">
        {{ $t('message.result_no_data') }}
    </div>
    <div v-else-if="loading" class="empty-result">
        {{ $t('label.loading_data') }}
    </div>
    <div
        v-else
        v-for="item in items"
        :key="item.orderId"
        class="order"
        :class="{small: isSmallWidth}"
    >
        <div class="order-details">
            <div>
                <div class="key-value">
                    <div class="key">{{ $t('label.orderNo') }}</div>
                    <div class="value">{{ item.orderId }}</div>
                </div>
                <div class="key-value">
                    <div class="key">{{ $t('label.orderedAt') }}</div>
                    <div class="value">{{ $d(ndxDateConvert(item.orderDate), 'long') }}</div>
                </div>
                <div class="key-value">
                    <div class="key">{{ $t('label.orderedBy') }}</div>
                    <div class="value">{{ generateOrderedBy(item.orderedBy) }}</div>
                </div>
            </div>
            <NdxButtonLink
                class="p-0"
                @click="gotoOrderDetail(item.orderId)"
            >{{ $t('btn.orderDetail') }}</NdxButtonLink>
        </div>
        <div
            v-if="item.files.length > 0"
            class="files"
        >
            <div
                v-for="file in item.files"
                :key="file.filename"
                class="file"
            >
                <div>
                    <div class="file-details">
                        <div class="file-name">{{ file.originalFilename }}</div>
                        <div class="file-size">{{ fileSize(file.size).asString() }}</div>
                    </div>
                    <div
                        class="shopShortDescription"
                        v-if="file.shopShortDescription && file.shopShortDescription.length > 0"
                    >
                        {{ $t('label.campaignRunDesc') }}: <b>{{ file.shopShortDescription }}</b>
                    </div>
                </div>
                <div class="file-actions">
                    <NdxIcon
                        v-if="isPreviewable(file.filename) && widget.config.interactions.indexOf('show') > -1"
                        icon="preview"
                        is-action
                        @click="fullscreenFile = file.filename"
                    />
                    <NdxIcon
                        v-if="widget.config.interactions.indexOf('download') > -1"
                        icon="export"
                        is-action
                        @click="downloadFile(file.filename)"
                    />
                    <NdxIcon
                        v-if="widget.config.interactions.indexOf('link') > -1"
                        icon="sent"
                        is-action
                        @click="startShareProcess(file)"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="item.digitalVCards.length > 0"
            class="files digitalVCards"
        >
            <div
                v-for="vCard in item.digitalVCards"
                :key="vCard.digitalVCardId"
                class="file vCard"
            >
                <div>
                    <div class="file-details">
                        <div class="file-name">{{ vCard.digitalVCardSetupName }}</div>
                    </div>
                    <div
                        class="shopShortDescription"
                        v-if="vCard.shopShortDescription && vCard.shopShortDescription.length > 0"
                    >
                        {{ $t('label.campaignRunDesc') }}: <b>{{ vCard.shopShortDescription }}</b>
                    </div>
                </div>
                <div class="file-actions isWallet">
                    <template v-if="widget.config.interactions.indexOf('addToWallet') > -1">
                        <img
                            v-if="vCard.hasGoogleConfig"
                            :src="walletImgSrc('google')"
                            alt="addToGoogleWallet"
                            class="walletBtn"
                            height="34"
                            @click="generateGooglePay(vCard.digitalVCardId)"
                        />
                        <img
                            v-if="vCard.hasAppleConfig"
                            :src="walletImgSrc('apple')"
                            alt="addToAppleWallet"
                            class="walletBtn"
                            @click="generateAppleWallet(vCard.digitalVCardId)"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>

    <NdxImageOverlay
        v-if="isPreviewable(fullscreenFile) && isImg(fullscreenFile)"
        :model-value="{filename: fullscreenFile}"
        @update:modelValue="() => fullscreenFile = null"
    />
    <NdxFileOverlay v-if="isPreviewable(fullscreenFile) && !isImg(fullscreenFile)" v-model="fullscreenFile"/>

    <NdxFlyIn v-if="fileToShare">
        <template #default>
            <div>
                <NdxSelect
                    :label="$t('label.expirationTime')"
                    v-model="expirationTime"
                    :options="expirationTimeOptions"
                    :support-search="false"
                />
                <div v-if="shareLink" class="d-flex gap-2 mt-2 mb-2">
                    <NdxInput
                        :label="$t('label.link')"
                        :model-value="shareLink"
                        :readonly="true"
                    />
                </div>
            </div>
        </template>
        <template #buttons>
            <NdxButtonLink @click="() => this.fileToShare = null">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButtonLink v-if="shareLink">
                <a
                    :href="'mailto:?body=' + $t('message.sendSecureDownloadLink', {link: shareLink})"
                >{{ $t('btn.sendAsEmail') }}</a>
            </NdxButtonLink>
            <NdxButton @click="generateLink">{{ $t('btn.createLink') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import outputHandlerApi from "../../api/outputHandler";
    import NdxButtonLink from "../library/NdxButtonLink";
    import { fileSize } from "@utilities/ndxFile";
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxButton from "../library/NdxButton";
    import NdxSelect from "../library/formElements/NdxSelect";
    import NdxInput from "../library/formElements/NdxInput";
    import NdxIcon from "../library/NdxIcon";
    import NdxFileOverlay from "../library/NdxFileOverlay";
    import { ndxDateConvert } from "@utilities/ndxDate";
    import NdxImageOverlay from "../library/NdxImageOverlay";

    export default {
        name: 'OutputHandler',
        components: {NdxImageOverlay, NdxFileOverlay, NdxIcon, NdxInput, NdxSelect, NdxButton, NdxFlyIn, NdxButtonLink},
        props: {
            widget: Object,
            parentWidth: Number,
            position: String
        },
        data() {
            return {
                loading: false,
                items: [],

                fileToShare: null,
                shareLink: null,
                expirationTime: 43200,
                expirationTimeOptions: [
                    {value: 300, text: this.$t('label.expirationTimeOptions.300')},
                    {value: 3600, text: this.$t('label.expirationTimeOptions.3600')},
                    {value: 43200, text: this.$t('label.expirationTimeOptions.43200')},
                    {value: 86400, text: this.$t('label.expirationTimeOptions.86400')},
                    {value: 172800, text: this.$t('label.expirationTimeOptions.172800')},
                    {value: 259200, text: this.$t('label.expirationTimeOptions.259200')},
                    {value: 604800, text: this.$t('label.expirationTimeOptions.604800')},
                    {value: 2419200, text: this.$t('label.expirationTimeOptions.2419200')},
                ],

                fullscreenFile: null
            };
        },
        computed: {
            isSmallWidth() {
                return ['left', 'right'].indexOf(this.position) > -1 || this.parentWidth < 400;
            },
        },
        mounted() {
            this.getData();
        },
        methods: {
            ndxDateConvert,
            fileSize,
            isImg(filename) {
                const imageFormats = ['bmp', 'svg', 'png', 'jpeg', 'jpg', 'gif', 'tif', 'tiff'];
                return imageFormats.includes(this.fileExtension(filename));
            },
            isPreviewable(filename) {
                return ['mov', 'avi', 'mpeg', 'webm', 'pdf'].includes(this.fileExtension(filename)) ||
                    this.isImg(filename);
            },
            fileExtension(filename) {
                return typeof filename === "string" ? filename.substr(filename.lastIndexOf('.') + 1) : '';
            },
            getData() {
                this.loading = true;
                outputHandlerApi.getData(
                    this.widget.config.selectedId,
                    this.widget.config.userSpecific
                ).then((result) => {
                    this.items = result;
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    this.loading = false;
                });
            },
            gotoOrderDetail(orderId) {
                let route = JSON.parse(JSON.stringify(
                    this.$store.getters['shop/getRouteByType']('orders')
                ));

                this.$router.push({
                    name: 'OrderDetails',
                    params: {
                        id: route.params.id,
                        orderId: orderId
                    }
                });
            },

            walletImgSrc(type) {
                return this.$store.getters['digitalVCard/getWalletImgSrc'](type);
            },

            generateAppleWallet(vCardId) {
                window.open(
                    this.$store.getters['digitalVCard/getAppleWalletUri'](vCardId),
                    '_blank'
                );
            },
            generateGooglePay(vCardId) {
                window.open(
                    this.$store.getters['digitalVCard/getGoogleWalletUri'](vCardId),
                    '_blank'
                );
            },

            downloadFile(fileName) {
                const downloadUrl = '/file/download/' + fileName;
                window.open(downloadUrl, '_blank');
            },

            startShareProcess(file) {
                this.shareLink = null;
                this.fileToShare = file;
            },
            generateLink() {
                this.$store.dispatch('files/generateSecureDownloadLink', {
                    filename: this.fileToShare.filename,
                    expirationTime: this.expirationTime
                }).then(link => {
                    this.shareLink = link;
                });
            },

            generateOrderedBy(data) {
                let value = [data.firstname, data.lastname].join(' ');
                return value.trim().length > 0 ? value : data.username;
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";

    .empty-result {
        @extend %font-caption;
    }

    .order {
        border: 2px solid var(--bs-gray-middle);
        border-radius: 4px;

        & + .order {
            margin-top: 16px;
        }

        .order-details {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 16px;
            padding: 16px;

            .key-value {
                display: flex;
                gap: 8px;

                .key {
                    @extend %font-body2;
                }

                .value {
                    @extend %font-h3;
                }
            }
        }

        .files {
            .file {
                border-top: 1px solid var(--bs-gray-middle);
                padding: 8px 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;

                .file-details {
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;

                    .file-name {
                        @extend %font-body2;

                        word-break: break-word;
                    }

                    .file-size {
                        @extend %font-caption;
                        white-space: nowrap;
                    }
                }

                .shopShortDescription {
                    @extend %font-caption;
                    padding-top: 4px;
                }

                .file-actions {
                    display: flex;
                    gap: 8px;
                    align-items: center;

                    &.isWallet {
                        flex-wrap: wrap;
                        justify-content: end;
                    }

                    .walletBtn {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .order.small {
        .order-details {
            flex-direction: column;
            align-items: flex-start;
        }

        .files {
            .file {
                flex-direction: column;
                align-items: flex-start;

                .file-actions {
                    flex-wrap: wrap;
                }
            }
        }
    }
</style>
