<template>
    <SummaryWidget
        :widget="widget"
        storeName="orders"
        :forced-currency="currency"
        v-bind="$attrs"
        class="basketSummary"
    >
        <template #actions>
        </template>
    </SummaryWidget>
</template>

<script>
    import SummaryWidget from "../../checkout/steps/parts/SummaryWidget";
    import { mapGetters } from "vuex";

    export default {
        name: "OrderDetailWidget",
        components: {SummaryWidget},
        props: {
            widget: {
                type: Object
            }
        },
        computed: {
            ...mapGetters('orders', {
                currency: 'getCurrency',
            })
        }
    };
</script>
