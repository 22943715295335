import sliderApi from '../../api/slider';

//state
const state = {};

// getters
const getters = {};

// actions
const actions = {
    getTiles(ctx, data) {
        return new Promise((resolve, reject) => {
            sliderApi.getTiles(
                data.widgetId
            ).then((data) => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }
};

// mutations
const mutations = {
    RESET() {
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
