<template>
    <NdxPageFrame>
        <Workflow v-if="!$route.params.taskType"/>
        <WorkflowInstances v-else/>
    </NdxPageFrame>
</template>

<script>
    import WorkflowInstances from "../../components/workflows/WorkflowInstances.vue";
    import NdxPageFrame from "../../components/library/NdxPageFrame.vue";
    import Workflow from "../../components/workflows/Workflow.vue";

    export default {
        name: 'WorkflowsInstances',
        components: {Workflow, NdxPageFrame, WorkflowInstances}
    };
</script>
