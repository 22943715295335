<template>
    <div class="d-flex align-items-center">
        <NdxSelect
            :key="currentQuantity"
            :variant="variant"
            :model-value="Math.round(currentQuantity)"
            :options="selectOptions"
            @update:model-value="setNewQuantity"
            :label="label"
            :readonly="!quantityChangeable && !onlyChangeOperationIsRemove"
            :support-search="false"
        />
        <NdxIcon
            v-if="quantityChangeable"
            icon="add"
            class="roundBtn"
            is-action
            @click="increaseQuantity"
            size="l"
        />
        <NdxIcon
            v-if="quantityChangeable || onlyChangeOperationIsRemove"
            icon="remove"
            class="roundBtn"
            is-action
            @click="decreaseQuantity"
            size="l"
        />
    </div>
</template>

<script>
    import NdxIcon from "../../library/NdxIcon";
    import NdxSelect from "../../library/formElements/NdxSelect";

    export default {
        name: "QuantityDiscrete",
        components: {NdxSelect, NdxIcon},
        emits: ['quantity-changed'],
        props: {
            data: {
                type: Array,
                required: true
            },
            currentQuantity: {
                type: Number,
                required: true
            },
            variant: {
                type: String,
                default: 'secondary'
            },
            label: {
                type: String
            },
            readonly: {
                type: Boolean
            },
            minQuantityByPricelist: {
                type: Number,
                default: 1
            },
            isQuantityZeroAllowed: {
                type: Boolean,
                required: true
            },
            onlyChangeOperationIsRemove: {
                type: Boolean
            }
        },
        computed: {
            sortedValues: function () {
                let values = JSON.parse(JSON.stringify(this.data));

                if (this.onlyChangeOperationIsRemove) {
                    values = [this.currentQuantity];
                }

                if (this.isQuantityZeroAllowed) {
                    values.unshift(0);
                }

                return values.sort(
                    (a, b) => parseFloat(a) > parseFloat(b) ? 1 : -1
                );
            },
            selectOptions: function () {
                let options = [];

                this.sortedValues.forEach(allowedQuantity => {
                    if (this.minQuantityByPricelist <= allowedQuantity ||
                        this.isQuantityZeroAllowed && allowedQuantity === 0
                    ) {
                        options.push({
                            value: allowedQuantity,
                            text: this.$n(allowedQuantity)
                        });
                    }
                });

                if (!options.find((entry) => entry.value === this.currentQuantity)) {
                    options.splice(0, 0, {
                        value: this.currentQuantity,
                        text: this.$n(this.currentQuantity)
                    });
                }

                return options;
            },
            currentListIdx: function () {
                return this.sortedValues.indexOf(parseFloat(this.currentQuantity));
            },
            quantityChangeable: function () {
                return !this.readonly && this.sortedValues.length > 1;
            }
        },
        methods: {
            increaseQuantity() {
                if (this.currentListIdx < this.sortedValues.length - 1) {
                    this.$emit('quantity-changed', this.sortedValues[this.currentListIdx + 1]);
                }
            },
            decreaseQuantity() {
                if (this.currentListIdx > 0 &&
                    (this.sortedValues[this.currentListIdx - 1] >= this.minQuantityByPricelist ||
                        this.sortedValues[this.currentListIdx - 1] === 0 && this.isQuantityZeroAllowed
                    )
                ) {
                    this.$emit('quantity-changed', this.sortedValues[this.currentListIdx - 1]);
                }
            },
            setNewQuantity(newQuantity) {
                if (newQuantity !== this.currentQuantity) {
                    this.$emit('quantity-changed', newQuantity);
                }
            }
        }
    };
</script>
