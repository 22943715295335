function addressFormatter (addressObject, vueCtx, glue = '<br />') {
    let ret = '',
        _ret = [],
        tmp;

    if (addressObject && addressObject.formattedAddress && addressObject.formattedAddress.length > 0) {
        ret = addressObject.formattedAddress.replace(/\n/g, glue);
    } else if (addressObject)  {

        /////////////////////////////////////////////////

        if (addressObject.company && addressObject.company.length) {
            _ret.push(addressObject.company);
        }

        /////////////////////////////////////////////////

        if (addressObject.gender && ['male', 'female'].includes(addressObject.gender) ) {
            tmp = vueCtx.$t('label.gender_' + addressObject.gender);

            if (addressObject.firstname && addressObject.firstname.length) {
                tmp += ' ' + addressObject.firstname;
            }

            if (addressObject.lastname && addressObject.lastname.length) {
                tmp += ' ' + addressObject.lastname;
            }

            _ret.push(tmp);
        } else {
            tmp = '';

            if (addressObject.firstname && addressObject.firstname.length) {
                tmp = addressObject.firstname;
            }

            if (addressObject.lastname && addressObject.lastname.length) {
                if (addressObject.firstname && addressObject.firstname.length) {
                    tmp += ' ';
                }

                tmp += addressObject.lastname;
            }

            if (tmp.length) {
                _ret.push(tmp);
            }
        }

        /////////////////////////////////////////////////

        if (addressObject.street && addressObject.street.length) {
            tmp = addressObject.street;

            if (addressObject.houseNo && addressObject.houseNo.length) {
                tmp += ' ' + addressObject.houseNo;
            }

            _ret.push(tmp);
        }

        /////////////////////////////////////////////////

        tmp = '';

        if (addressObject.countryId && addressObject.countryId.length) {
            tmp += addressObject.countryId;
        }

        if (addressObject.zipCode && addressObject.zipCode.length) {
            if (tmp.length) {
                tmp += ' - ';
            }
            tmp += addressObject.zipCode;
        } else if (addressObject.zipcode && addressObject.zipcode.length) {
            if (tmp.length) {
                tmp += ' - ';
            }
            tmp += addressObject.zipcode;
        }

        if (addressObject.city && addressObject.city.length) {
            if (tmp.length) {
                tmp += ' ';
            }
            tmp += addressObject.city;
        }

        if (tmp.length) {
            _ret.push(tmp);
        }

        /////////////////////////////////////////////////

        if (_ret.length) {
            ret = _ret.join(glue);
        }
    }

    return ret;
}

export { addressFormatter as AddressFormatter };

function priceFormatter (value, currency, vueCtx) {
    let formatConfig = {
        maximumFractionDigits: 2,
        currencyDisplay: 'symbol',
        style: 'currency',
        currency: currency.toUpperCase()
    };

    // check if we have to deal with fragments of the sub-unit and show the first two non-zero fragments
    const firstNonZeroDecimalIndex = (value + "").substr((value + "").indexOf('.')).search(/[1-9]/);
    if (firstNonZeroDecimalIndex > 2) {
        formatConfig.minimumFractionDigits = firstNonZeroDecimalIndex;
        formatConfig.maximumFractionDigits = firstNonZeroDecimalIndex + 1;
    }

    return vueCtx.$n(parseFloat(value), 'currency', formatConfig);
}

export { priceFormatter as PriceFormatter };
