<template>
    <div class="rgb-editor">
        <div class="preview-wrapper" :style="previewStyle"></div>
        <div class="item">
            <div class="label">R</div>
            <input
                type="number"
                min="0"
                max="255"
                :value="valueR"
                @input="(evt) => updateValue(evt.target.value, 'r')"
            />
            <input
                tabindex="-1"
                type="range"
                min="0"
                max="255"
                class="form-range"
                id="customRangeR"
                :value="valueR"
                @input="(evt) => updateValue(evt.target.value, 'r')"
            />
        </div>
        <div class="item">
            <div class="label">G</div>
            <input
                type="number"
                min="0"
                max="255"
                :value="valueG"
                @input="(evt) => updateValue(evt.target.value, 'g')"
            />
            <input
                tabindex="-1"
                type="range"
                min="0"
                max="255"
                class="form-range"
                id="customRangeG"
                :value="valueG"
                @input="(evt) => updateValue(evt.target.value, 'g')"
            />
        </div>
        <div class="item">
            <div class="label">B</div>
            <input
                type="number"
                min="0"
                max="255"
                :value="valueB"
                @input="(evt) => updateValue(evt.target.value, 'b')"
            />
            <input
                tabindex="-1"
                type="range"
                min="0"
                max="255"
                class="form-range"
                id="customRangeB"
                :value="valueB"
                @input="(evt) => updateValue(evt.target.value, 'b')"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RgbEditor',
        props: {
            /**
             * The selected color
             */
            modelValue: {
                validator: function (value) {
                    return value === null || typeof (value) === 'string';
                }
            }
        },
        emits: ['update:modelValue'],
        data() {
            return {
                valueR: 0,
                valueG: 0,
                valueB: 0
            };
        },
        computed: {
            previewStyle() {
                return 'background-color: rgb(' + this.valueR + ', ' + this.valueG + ', ' + this.valueB + ');';
            }
        },
        watch: {
            modelValue: {
                immediate: true,
                handler: 'setVariables'
            }
        },
        methods: {
            setVariables() {
                if (this.modelValue === null || this.modelValue === 'transparent') {
                    this.valueR = 0;
                    this.valueG = 0;
                    this.valueB = 0;
                } else {
                    let color = this.modelValue
                        .replace('rgb', '')
                        .replaceAll('(', '')
                        .replaceAll(')', '')
                        .split(',');

                    if (color.length !== 3) {
                        return;
                    }

                    color = color.map(v => parseInt(v, 10));

                    this.valueR = color[0];
                    this.valueG = color[1];
                    this.valueB = color[2];
                }
            },
            updateValue(val, type) {
                let value = parseInt(val, 10);
                value = value < 0 ? 0 : value;
                value = value > 255 ? 255 : value;

                if (type === 'r') {
                    this.valueR = value;
                } else if (type === 'g') {
                    this.valueG = value;
                } else if (type === 'b') {
                    this.valueB = value;
                }

                this.emitUpdate();
            },
            emitUpdate() {
                this.$emit('update:modelValue', this.getfomtatedString());
            },
            getfomtatedString() {
                return 'rgb(' + this.valueR + ', ' + this.valueG + ', ' + this.valueB + ')';
            }
        }
    };
</script>
