<template>
    <div class="ndxFormWrapper">
        <div class="recipients ndx-form-element">
            <div class="label">{{ $t('label.news.recipients') }}</div>
            <div class="border-bottom w-100 d-flex justify-content-between">
                <div>
                    <span
                        v-for="recipient in recipients"
                        :key="recipient.id"
                        class="badge badge-pill badge-primary"
                    >
                        {{recipient.username}}
                        <NdxIcon icon="remove" @click="() => removeFromRecipientList(recipient.id)" />
                    </span>
                    <span class="badge" v-if="recipients.length === 0">
                        {{ $t('label.news.admins') }}
                    </span>
                </div>
                <span class="primary" v-if="recipientsAllowed">
                    <NdxIcon icon="add" @click="openRecipientPicker" />
                </span>
            </div>
        </div>
        <NdxInput
            :label="$t('label.news.description')"
            v-model="subject"
            :invalid="hasError('subject')"
            @update:model-value="validateState"
        />
        <NdxTextarea
            :label="$t('label.news.message')"
            v-model="message"
            :invalid="hasError('message')"
            @update:model-value="validateState"
            class="mb-3"
        />
        <div
            class="attachment d-flex justify-content-between align-items-center"
            v-for="(att, idx) in attachments"
            :key="att.cacheId"
        >
            <span>{{att.originalFilename}}</span>
            <NdxIcon icon="trash" @click="deleteAttachment(idx)" size="s" />
        </div>
        <NdxFileUpload
            class="mb-3"
            variant="link"
            :model-value="null"
            @update:modelValue="addAttachment"
        />
    </div>
    <NdxButton
        :disabled="vuelidateDataError()"
        @click="createMessage"
        class="sendBtn"
    >{{ $t('btn.send') }}</NdxButton>

    <NdxFlyIn v-if="recipientPick">
        <template #title></template>
        <template #default>
            <NdxSearchInput
                v-model="recipientSearch"
                class="w-100"
            />
            <div
                v-for="entry in filteredRecipientList"
                :key="entry.id"
                class="userListEntry d-flex justify-content-between gap-2"
                :class="{picked: isPicked(entry.id)}"
                @click="() => isPicked(entry.id) ?
                    removeFromRecipientTmpList(entry.id) :
                    addToRecipientList(entry.id)"
            >
                <span>{{ [entry.lastname, entry.firstname].join(', ') }}</span>
                <div class="d-flex gap-2 align-items-center text-end">
                    <span class="d-inline-flex flex-column">
                        <span>{{ Object.values(entry.shopClients).join(', ') }}</span>
                        <span class="title">{{ entry.username }}</span>
                    </span>
                    <span>
                        <NdxIcon
                            v-if="isPicked(entry.id)"
                            icon="remove"
                        />
                        <NdxIcon
                            v-else
                            icon="add"
                        />
                    </span>
                </div>
            </div>
        </template>
        <template #buttons>
            <NdxButtonLink @click="recipientPick=false;">{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton @click="updateRecipients">{{ $t('btn.done') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxInput from "../../library/formElements/NdxInput";
    import NdxTextarea from "../../library/formElements/NdxTextarea";
    import NdxFlyIn from "../../library/NdxFlyIn";
    import NdxButtonLink from "../../library/NdxButtonLink";
    import NdxButton from "../../library/NdxButton";
    import Validation from "../../../mixins/Validation";
    import { required } from '@vuelidate/validators';
    import NdxIcon from "../../library/NdxIcon";
    import NdxSearchInput from "../../library/formElements/NdxSearchInput";
    import { msgParams } from "@utilities/ndxValidationRules";
    import { newsTypes } from "../../../store/modules/news";
    import NdxFileUpload from "../../library/formElements/NdxFileUpload";
    import { mapGetters } from "vuex";

    export default {
        name: "NewsCreate",
        components: {NdxFileUpload, NdxSearchInput, NdxIcon, NdxButton, NdxButtonLink, NdxFlyIn, NdxTextarea, NdxInput},
        mixins: [Validation],
        emits: ['news-created'],
        validations() {
            return {
                subject: {
                    required,
                    msgParams: msgParams({
                        field: 'news.description'
                    })
                },
                message: {
                    required,
                    msgParams: msgParams({
                        field: 'news.message'
                    })
                }
            };
        },
        data() {
            return {
                /* recipients used*/
                recipients: [],
                recipientPick: false,
                recipientSearch: '',
                /* list of available recipients*/
                recipientList: [],
                /* list of picked recipients during pick process */
                recipientsTmp: [],
                subject: '',
                message: '',
                attachments: [],
            };
        },
        computed: {
            ...mapGetters('news', {
                messageCreate: 'messageCreate'
            }),
            recipientsAllowed() {
                return this.messageCreate > 1;
            },
            filteredRecipientList() {
                const searchStr = this.recipientSearch.toLowerCase();
                return this.recipientList.filter((listEntry) => {
                    return listEntry.username.toLowerCase().includes(searchStr) ||
                        listEntry.firstname?.toLowerCase().includes(searchStr) ||
                        listEntry.lastname?.toLowerCase().includes(searchStr) ||
                        Object.values(listEntry.shopClients).reduce((found, shopClient) => {
                            return found || shopClient.toLowerCase().includes(searchStr);
                        }, false);
                });
            }
        },
        created() {
            this.getRecipients();
        },
        methods: {
            getRecipients() {
                if (this.recipientsAllowed) {
                    this.$store.dispatch('news/getRecipients', {
                        searchText: ''
                    }).then((list) => {
                        this.recipientList = list;
                    });
                } else {
                    this.recipientList = [];
                }
            },
            validateState() {
                this.vuelidateData();
            },
            createMessage() {
                let news = JSON.parse(JSON.stringify(
                    this.$store.getters['news/getNewModel']
                ));

                news.title = this.subject;
                news.content = this.message;

                if (this.recipients.length) {
                    news.affectedUsers = this.recipients.map(user => ({id: user.id}));
                    news.type = newsTypes.groupPrivate;
                } else {
                    news.affectedShops = [{id: this.$store.getters['session/getShopId']}];
                    news.type = newsTypes.genericRequest;
                }

                if (this.attachments) {
                    news.files = this.attachments;
                }

                this.$store.dispatch('news/create', {
                    newsData: news
                }).then(() => {
                    this.$emit('news-created');
                });
            },
            addToRecipientList(recipientId) {
                const recipient = this.recipientList.find(entry => entry.id === recipientId);
                this.recipientsTmp.push(recipient);
            },
            removeFromRecipientList(recipientId) {
                const recipientIdx = this.recipients.findIndex(entry => entry.id === recipientId);
                this.recipients.splice(recipientIdx, 1);
                this.recipients = Array.from(this.recipients);
            },
            removeFromRecipientTmpList(recipientId) {
                const recipientIdx = this.recipientsTmp.findIndex(entry => entry.id === recipientId);
                this.recipientsTmp.splice(recipientIdx, 1);
            },
            isPicked(recipientId) {
                return this.recipientsTmp.findIndex(entry => entry.id === recipientId) !== -1;
            },
            updateRecipients() {
                this.recipients = JSON.parse(JSON.stringify(this.recipientsTmp));
                this.recipientPick = false;
            },
            addAttachment(cacheFileData) {
                this.attachments.push(cacheFileData);
            },
            deleteAttachment(idx) {
                this.attachments.splice(idx, 1);
            },
            openRecipientPicker() {
                this.recipientSearch = '';
                this.recipientsTmp = JSON.parse(JSON.stringify(this.recipients));
                this.recipientPick = true;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../style/variables_ndx";

    .border-bottom {
        border-color: var(--bs-gray-dark) !important;
    }

    svg:hover {
        fill: var(--bs-primary);
        cursor: pointer;
    }

    .badge {
        @extend %font-body2;

        color: #fff;
        background-color: var(--bs-primary);
        height: 36px;
        border-radius: 50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 4px;
        padding: 0 16px;
        margin-right: 8px;
        gap: 12px;
    }

    .userListEntry {
        @extend %font-body2;

        color: var(--bs-disabled);
        padding: 16px;
        border-bottom: 1px solid var(--bs-gray-middle);
        cursor: pointer;

        .title {
            @extend %font-h3;
        }

        &.picked {
            background-color: var(--bs-primary-light);
        }
    }

    .attachment {
         @extend %font-body2;
    }

    .sendBtn {
        margin-left: auto;
        margin-right: auto;
        max-width: 250px;
    }
</style>
