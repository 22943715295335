import lodashMerge from 'lodash/merge';
import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";
import { find, filterBy, update } from "./defaultMethods";

const service = 'user';

export default lodashMerge({}, {
    find: find(service),
    filterBy: filterBy(service),
    update: update(service)
}, {
    getUserAndClient() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getUserAndClient', [], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateClient(data) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'updateClient', [data], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateClientAddress(addressData, addressType) {
        let method = 'createClientAddress';
        let params = [addressData, addressType];
        if ('id' in addressData && +addressData.id > 0) {
            method = 'updateClientAddress';
            params = [addressData];
        }
        return new Promise((resolve, reject) => {
            ndxRpc(service, method, params, throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateUserAddress(addressData, addressType) {
        let method = 'createUserAddress';
        let params = [addressData, addressType];
        if ('id' in addressData && +addressData.id > 0) {
            method = 'updateUserAddress';
            params = [addressData];
        }

        return new Promise((resolve, reject) => {
            ndxRpc(service, method, params, throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteUserAddress(addressId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'deleteUserAddress', [+addressId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    changeClientAddress(type, addressId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'changeClientAddress', [type, +addressId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteClientAddress(addressId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'deleteClientAddress', [+addressId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateAddressSettings(addressId, settings, addressParent) {
        const params = [+addressId, settings, addressParent];
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'updateAddressSettings', params, throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    login(params) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'login', params, throbberRules.FULLSCREEN)
                .then((data) => {
                    if (data?.error) {
                        throw new Error(data.error.message);
                    }

                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    /**
     * @param {String} authId
     * @param {Object} options
     * @returns {Promise<String|null>}
     */
    getLogoutUrl(authId, options) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getLogoutUrl', [authId, options], throbberRules.FULLSCREEN)
                .then((redirectUrl) => {
                    resolve(redirectUrl);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    logout() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'logout', [], throbberRules.FULLSCREEN)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    recoverPassword(username, shopId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'recoverPassword', [username, shopId], throbberRules.FULLSCREEN)
                .then((result) => {
                    if (result) {
                        resolve();
                    } else {
                        reject(new Error('passwordRecoverError'));
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    changePassword(data) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'update', [data], throbberRules.FULLSCREEN)
                .then(resolve)
                .catch(reject);
        });
    },
    getShopClients(limit, offset, searchText, ignoreShopClientId, shopClientGroupId) {
        const params = [limit, offset, searchText, ignoreShopClientId, shopClientGroupId];
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getShopClients', params, throbberRules.FULLSCREEN)
                .then(resolve)
                .catch(reject);
        });
    },
    switchShopClient(shopClientId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'switchShopClient', [shopClientId], throbberRules.FULLSCREEN)
                .then(resolve)
                .catch(reject);
        });
    },
    logDataCheckAction() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'logDataCheckAction', [], throbberRules.FULLSCREEN)
                .then(resolve)
                .catch(reject);
        });
    },
    addSubstitute(userId, substituteId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'addSubstitute', [userId, substituteId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    removeSubstitute(userId, substituteId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'removeSubstitute', [userId, substituteId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getAllClientUsers(criteria, orderBy, limit, offset) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getAllClientUsers', [criteria, orderBy, limit, offset], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
});
