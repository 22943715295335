<template>
    <div class="roundedBorder p-3">
        <div class="item-group-header border-b d-flex justify-content-between align-items-start">
            <div>
                <div>{{ group.vendor.name }}</div>
                <div class="item-group-sub-header"><slot name="sub-header"></slot></div>
            </div>
            <div class="text-end">
                <div class="highlight">{{ formatPrice(groupPrice) }}</div>
                <div class="item-group-sub-price">
                    <SubPriceInfo
                        :group="group"
                        :items="group.items"
                        :budgets="group.summary.budgets"
                        :is-summary="isSummary"
                        :forced-currency="forcedCurrency"
                    />
                </div>
            </div>
        </div>
        <slot name="group-items"></slot>
    </div>
</template>

<script>
    import { PriceFormatter } from "../../../../plugins/formatter";
    import {mapGetters} from "vuex";
    import SubPriceInfo from "./SubPriceInfo";

    export default {
        name: "Group",
        components: {SubPriceInfo},
        props: {
            group: {
                type: Object,
                required: true
            },
            isSummary: {
                type: Boolean
            },
            forcedCurrency: {
                type: String,
                default: null
            }
        },
        computed: {
            ...mapGetters('shop', {
                currency: 'currency'
            }),
            groupPrice() {
                if (this.group.summary.priceNet) {
                    return parseFloat(this.group.summary.priceNet);
                }
                return 0.0;
            }
        },
        methods: {
            formatPrice(value) {
                const _currency = this.forcedCurrency ? this.forcedCurrency : this.currency;
                return PriceFormatter(value, _currency, this);
            }
        }
    };
</script>

