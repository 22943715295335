<template>
    <NdxPageFrame>
        <NewsDetailContent />
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import NewsDetailContent from "../../components/news/NewsDetail";
    import {mapState} from "vuex";

    export default {
        name: 'NewsDetail',
        components: {NdxPageFrame, NewsDetailContent},
        computed: {
            ...mapState({
                newsFilter: state => state.news.newsFilter,
            })
        },
        watch: {
            newsFilter: function ()  {
                this.$router.push(this.$store.getters['shop/getRouteByType']('news'));
            }
        }
    };
</script>
