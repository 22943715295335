<template>
    <div
        type="button"
        class="btn align-items-center gap-2"
        :class="{
            'd-flex': !variant.includes('pill'),
            'd-inline-flex': variant.includes('pill'),

            'narrow': variant.includes('narrow'),

            'btn-primary': variant.includes('primary'),
            'btn-secondary': variant.includes('secondary'),
            'btn-pill': variant.includes('pill'),
            'disabled': disabled
        }"
    >
        <div class="icon_wrapper"><slot name="icon"></slot></div>
        <div class="flex-grow-1 btn_label"><slot name="default"></slot></div>
    </div>
</template>

<script>
    export default {
        name: 'NdxButton',
        props: {
            variant: {
                type: String,
                default: 'primary',
                validator: function (value) {
                    return ['primary', 'secondary', 'secondary narrow', 'pill'].indexOf(value) !== -1;
                }
            },
            disabled: {
                type: Boolean
            }
        }
    };
</script>
