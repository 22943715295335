<template>
    <div
        v-if="modelValue && (dismissCountDown > 0 || duration === 0)"
        class="notification d-flex justify-content-between gap-2"
        :class="{
            [variant]: true,
            'align-items-center': !showDetails,
            'align-items-start': showDetails
        }"
    >
        <NdxIcon :icon="getIcon()" size="m" />
        <div class="msg flex-grow-1">
            <div class="d-flex justify-content-between">
                <div v-html="getMsg()" />
            </div>
            <div v-if="showDetails && details" class="details" v-html="details" />
        </div>
        <div class="d-flex align-items-center gap-2">
            <div
                v-if="details"
                class="moreOrLess"
                @click="showDetails = !showDetails"
            >{{$t('label.details')}}</div>
            <NdxButton
                v-if="showCallToAction"
                class="cta hideOnPrintView"
                @click="$emit('call-to-action')"
            >{{ callToActionText}}</NdxButton>
            <NdxIcon
                v-if="duration > 0"
                icon="close"
                size="m"
                is-action
                no-hover
                @click="closeNotification"
            />
        </div>
    </div>
</template>

<script>
    import NdxIcon from "./NdxIcon";
    import NdxButton from "./NdxButton.vue";
    export default {
        name: "NdxNotification",
        components: {NdxButton, NdxIcon},
        props: {
            /**
             * styling of the notification
             *
             * @values info, error, warning, success, custom_0, custom_1, custom_2, custom_3, custom_4
             */
            variant: {
                type: String,
                default: 'info',
                validator: function (value) {
                    return[
                        'info', 'error', 'success', 'warning',
                        'custom_0', 'custom_1', 'custom_2', 'custom_3', 'custom_4'
                    ].indexOf(value) > -1;
                }
            },
            /**
             * Content of the notification
             */
            message: {
                type: String,
                required: false
            },
            details: {
                type: String,
                required: false,
                default: ''
            },
            /**
             * if you want another icon than the default one <br/>
             * if variant "snackbar" is used you would not see any message icon whether one is defined or not
             */
            messageIcon: {
                type: String,
                required: false
            },
            /**
             * switch from false to true to display the notification
             */
            modelValue: {
                type: Boolean,
                default: false
            },
            /**
             * duration until dismiss in seconds
             * zero for no dismissable
             */
            duration: {
                type: Number,
                default: 3
            },

            callToAction: {
                type: Object
            }
        },
        emits: ['update:modelValue', 'call-to-action'],
        data() {
            return {
                dismissCountDown: 0,
                timer: null,
                showDetails: false
            };
        },
        computed: {
            showCallToAction() {
                return this.callToAction !== null && this.callToAction !== undefined;
            },
            callToActionText() {
                return this.callToAction && 'text' in this.callToAction && this.callToAction.text.length > 0
                    ? this.callToAction.text
                    : 'CTA';
            }
        },
        watch: {
            modelValue: {
                immediate: true,
                handler: function (newVal) {
                    if (newVal) {
                        this.$emit('update:modelValue', newVal);
                        this.dismissCountDown = this.duration;
                    }
                }
            },
            dismissCountDown(newVal) {
                if (newVal > 0) {
                    this.startCountdown();
                } else {
                    window.clearTimeout(this.timer);
                }
            }
        },
        methods: {
            getIcon: function () {
                if (this.messageIcon && this.messageIcon.length) {
                    return this.messageIcon;
                }

                if (this.variant === 'error') {
                    return 'error';
                } else if (this.variant === 'success') {
                    return 'done-circle';
                }

                return 'info';
            },
            getMsg: function () {
                return this.message || this.$t('notification.' + this.variant);
            },
            startCountdown() {
                window.clearTimeout(this.timer);

                this.timer = window.setTimeout(() => {
                    this.closeNotification();
                }, this.dismissCountDown * 1000);
            },
            closeNotification() {
                this.dismissCountDown = 0;
                this.$emit('update:modelValue', false);
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .details {
        @extend %font-body2;
        background-color: white;
        padding: 8px;
        margin-top: 8px;
        border-radius: 4px;
        white-space: pre-line;
    }
    .moreOrLess {
        cursor: pointer;
        text-decoration: underline;
    }
</style>
