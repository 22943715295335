import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'news';

export default {
    findAllRelevant(limit, offset) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'findAllRelevant', [limit, offset], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getThread(newsId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getThread', [+newsId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    create(newsData, threadStartId = null) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'create', [newsData, threadStartId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    markReadBottomUp(newsId, threadStartId = null) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'markReadBottomUp', [newsId, threadStartId], throbberRules.NONE)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    markReadTopDown(newsId, threadStartId = null) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'markReadTopDown', [newsId, threadStartId], throbberRules.NONE)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getRecipients(searchStr, limit, offset) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getRecipients', [searchStr, limit, offset], throbberRules.NONE)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    saveNewsSettings(
        isEmailNotification,
        instantNotification,
        workflowNotification,
        downloadProductFilesEmailNotification
    ) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'saveNewsSettings',
                [isEmailNotification, instantNotification, workflowNotification,
                    downloadProductFilesEmailNotification],
                throbberRules.FULLSCREEN
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
