<script>
    import { h } from 'vue';

    export default {
        name: 'NdxIframe',
        render() {
            let content = '';
            if (this.$slots.default().length && 'innerHTML' in this.$slots.default()[0].props) {
                content = this.$slots.default()[0].props.innerHTML;
            }

            return h('iframe',
                {
                    class: {
                        'w-100': true,
                        'border-0': true
                    },
                    srcdoc: content
                }
            );
        }
    };
</script>

<docs>
    Diese Komponente wird verwendet um HTML-Inhalt in einem iFrame anzuzeigen.<br/>
    Wichtig dabei ist, dass ein Inhalt übergeben wird. Eine src ist nicht möglich. <br/>
    Dafür gibt es eine eigene Komponente (NdxContentIframe).

    ```jsx
    let content1 = '
    <div>Test iFrame Inhalt</div>
    '
    let content2 = '
    <table>
        <tr>
            <td>Cell 1</td>
            <td>cell2</td>
        </tr>
    </table>
    '

    <NdxIframe style="border: 1px solid red;">
        <div v-html="content1"></div>
    </NdxIframe>

    <br/>

    <NdxIframe style="border: 1px solid blue;">
        <div v-html="content2"></div>
    </NdxIframe>
    ```
</docs>
