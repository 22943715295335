<template>
    <div
        class="w-100 ndx-form-element ndx-input"
        :class="{focused: focused, [variant]: true, disabled: readonly, error: invalid}"
    >
        <div v-if="label && label.length" class="label">{{ label }}</div>
        <div v-if="description && description.length" class="description">{{ description }}</div>
        <div class="form-content d-flex align-items-center justify-content-between">
            <NdxIcon
                v-if="icon && icon.length > 0"
                class="ndx-input-icon"
                :icon="icon"
                size="s"
            />
            <input
                class="w-100"
                v-bind="$attrs"
                :type="computedType"
                :name="name"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                @focusin="focused = true"
                @focusout="focused = false"
                :readonly="readonly"
                :disabled="readonly"
                :placeholder="placeholder"
                @keyup.enter="$emit('on-key-enter')"
            >
            <NdxIcon
                v-if="type === 'password'"
                icon="eye-visible"
                class="ndx-input-password-icon"
                size="s"
                is-action
                :active="showPassword"
                @click="showPassword = !showPassword"
            />
        </div>
    </div>
</template>

<script>
    import NdxIcon from "../NdxIcon";

    export default {
        name: 'NdxInput',
        components: {NdxIcon},
        props: {
            type: {
                type: String,
                default: 'text',
                validator: function (value) {
                    return ['text', 'password'].indexOf(value) !== -1;
                }
            },
            name: {
                type: String
            },
            label: {
                type: String
            },
            description: {
                type: String
            },
            modelValue: {
                type: [String, Number, undefined],
                required: false
            },
            icon: {
                type: String
            },
            variant: {
                type: String,
                default: 'primary',
                validator: function (value) {
                    return ['primary', 'secondary'].indexOf(value) !== -1;
                }
            },
            readonly: {
                type: Boolean,
            },
            invalid: {
                type: Boolean,
            },
            placeholder: {
                type: String,
                required: false
            }
        },
        emits: ['update:modelValue', 'on-key-enter'],
        data() {
            return {
                showPassword: false,
                focused: false
            };
        },
        computed: {
            computedType() {
                if (!this.showPassword && this.type === 'password') {
                    return 'password';
                }
                return 'text';
            }
        }
    };
</script>
