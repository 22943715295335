<template>
    <component
        :is="htmlTag"
        :href="href"
        :target="target"
        :to="route"
        :title="tooltip"
        :class="{
            marker: hasMarker && !displayText
        }"
        @click="onClick"
    >
        <span
            class="menu-entry justify-content-center align-items-center gap-1 text-nowrap"
            :class="{
                'd-inline-flex': htmlTag === 'a',
                'd-flex': htmlTag !== 'a',
                'clickable': htmlTag !== 'span'
            }"
        >
            <NdxIcon
                v-if="displayIcon && typeof icon === 'string'"
                :icon="icon"
                :size="iconSize"
                :title="text"
            />
            <NdxSvg
                v-else-if="displayIcon && icon?.filename?.endsWith('.svg')"
                :src="icon.filename"
                :size="iconSize"
                :svg-style="svgStyle"
                :tooltip="text"
            />
            <NdxImage
                v-else-if="displayIcon && icon?.filename"
                :src="icon.filename"
                :size="iconSize"
                :style="'height: ' + iconSizePx + 'px; width: ' + iconSizePx + 'px;'"
                :tooltip="text"
            />
            <span v-if="displayText" :class="textClass">{{ text }}</span>
        </span>

        <slot name="default"></slot>
    </component>
</template>

<script>
    import NdxIcon from "./NdxIcon";
    import NdxImage from "./NdxImage";
    import NdxSvg from "./NdxSvg";
    import { mapState } from "vuex";

    export default {
        name: "NdxMenuEntry",
        components: {NdxSvg, NdxImage, NdxIcon},
        props: {
            wgc: {
                type: Object,
                required: true
            },
            iconSize: {
                type: String,
                default: 's'
            },
            textClass: {
                type: String,
                default: ''
            }
        },
        computed: {
            ...mapState({
                watchlistItemCount: state => state.basket.watchlistItemCount,
                basketItemCount: state => state.basket.basketItemCount,
                unreadNewsCount: state => state.news.unreadNewsCount,
                openTaskCount: state => state.workflowTask.openTaskCount
            }),
            icon() {
                if (!this.wgc.icon) {
                    return this.$store.getters['shop/getIconForType'](this.wgc.type);
                }

                return this.wgc.icon;
            },
            svgStyle() {
                return `height="${this.iconSizePx}px" width="${this.iconSizePx}px"`;
            },
            displayIcon() {
                return [1, 2].includes(this.wgc.menuBehaviour);
            },
            displayText() {
                return [0, 2].includes(this.wgc.menuBehaviour);
            },
            text() {
                let label = this.wgc.label;

                if (this.wgc.type === 'basket' && this.basketItemCount > 0) {
                    label += " (" + this.basketItemCount + ")";
                } else if (this.wgc.type === 'watchlist' && this.watchlistItemCount > 0) {
                    label += " (" + this.watchlistItemCount + ")";
                } else if (this.wgc.type === 'news' && this.unreadNewsCount > 0) {
                    label += " (" + this.unreadNewsCount + ")";
                } else if (this.wgc.type === 'workflowsTasks' && this.openTaskCount > 0) {
                    label += " (" + this.openTaskCount + ")";
                }

                return label;
            },
            hasMarker() {
                return (this.wgc.type === 'basket' && this.basketItemCount > 0) ||
                    (this.wgc.type === 'watchlist' && this.watchlistItemCount > 0) ||
                    (this.wgc.type === 'news' && this.unreadNewsCount > 0) ||
                    (this.wgc.type === 'workflowsTasks' && this.openTaskCount > 0);
            },
            htmlTag() {
                switch (this.wgc.type) {
                    case 'link': return 'a';
                    case 'text': return 'span';
                    default: return 'router-link';
                }
            },
            href() {
                if (this.wgc.config.link) {
                    if (this.wgc.config.link.startsWith('mailto:') || this.wgc.config.link.includes('//')) {
                        return this.wgc.config.link;
                    } else {
                        return 'https://' + this.wgc.config.link;
                    }
                }
                return null;
            },
            target() {
                return this.wgc.config?.newWindow ? '_blank' : '_self';
            },
            route() {
                return this.wgc.route;
            },
            tooltip() {
                return this.displayIcon ? this.text : '';
            },
            iconSizePx() {
                if (this.iconSize === 'xl') {
                    return 48;
                }
                if (this.iconSize === 'l') {
                    return 32;
                }
                if (this.iconSize === 'm') {
                    return 24;
                }
                return 16;
            }
        },
        methods: {
            onClick() {
                if (typeof this.wgc.click === 'function') {
                    this.wgc.click(this.wgc);
                }
            }
        }
    };
</script>

<style scoped>
    .menu-entry.clickable:hover {
        color: var(--bs-primary);
        fill: var(--bs-primary);
        cursor: pointer;
    }

    :deep(.svg_wrapper, .img_wrapper) {
        background-color: transparent;
    }

    .marker {
        position: relative;
    }
    .marker:after {
        content: "";
        position: absolute;
        top: -4px;
        right: -4px;
        display: block;
        height: 8px;
        width: 8px;
        background-color: var(--bs-danger);
        border-radius: 50%;
    }
</style>
