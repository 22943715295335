//state
const state = {
    events: []
};

// getters
const getters = {
    getByInstanceId: (state) => (instanceId) => {
        return state.events.filter((event) => {
            return 'instanceId' in event && event.instanceId === instanceId;
        });
    },
    getTenantId: (state, getters, rootState, rootGetters) => {
        return rootGetters['session/getShopId'];
    }
};

// actions
const actions = {
    addEvent({ commit }, data) {
        commit('ADD_EVENT', data);

        if (['taskStarted', 'claim', 'unclaim', 'complete'].includes(data.event)) {
            this.dispatch('workflowTask/getOpenTaskCountForUser');
        }
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.events = [];
    },
    ADD_EVENT(state, event) {
        state.events.push(event);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
