<template>
    <NdxPageFrame>
        <Wizard/>
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import Wizard from "../../components/checkout/Wizard";

    export default {
        name: 'Checkout',
        components: {NdxPageFrame, Wizard}
    };
</script>
