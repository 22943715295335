<template>
    <div
        :id="carouselId"
        class="carousel slide"
    >
        <div v-if="tiles.length > 1" class="carousel-indicators">
            <button
                v-for="(tile, idx) in tiles"
                :key="tile.id"
                type="button"
                :data-bs-target="'#' + carouselId"
                :data-bs-slide-to="idx"
                :class="{active: 0 === idx}"
            >
            </button>
        </div>
        <div class="carousel-inner">
            <div
                v-for="(tile, idx) in tiles"
                :key="tile.id"
                class="carousel-item"
                :class="{active: 0 === idx}"
            >
                <img
                    v-if="contentType === resolveFqcnForBe('UploadElement')"
                    :src="makeUrl(tile)"
                    class="w-100"
                    :alt="'image_' + tile.id"
                />
                <div
                    v-if="contentType === resolveFqcnForBe('HtmlFragment')"
                    v-html="tile.content"
                    class="w-100"
                />
            </div>
        </div>
        <button
            v-if="tiles.length > 1"
            class="carousel-control-prev"
            type="button"
            :data-bs-target="'#' + carouselId"
            data-bs-slide="prev"
        >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button
            v-if="tiles.length > 1"
            class="carousel-control-next"
            type="button"
            :data-bs-target="'#' + carouselId"
            data-bs-slide="next"
        >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</template>

<script>
    import { Carousel } from "bootstrap/dist/js/bootstrap.esm";
    import { resolveFqcnForBe } from "@utilities/ndxFqcn";

    export default {
        name: 'SliderWidget',
        props: {
            widget: Object
        },
        data() {
            return {
                carouselId: 'slider_' + this.widget.id,
                contentType: null,
                tiles: [],
                slider: null
            };
        },
        mounted() {
            this.loadTiles();
        },
        methods: {
            resolveFqcnForBe,
            makeUrl(img) {
                return '/ndx/file/downloadResized/2000/2000/' + img.file.filename;
            },
            startSlider() {
                this.slider = new Carousel(
                    document.getElementById(this.carouselId),
                    {
                        pause: 'hover',
                        ride: 'carousel'
                    }
                );
                this.slider.cycle();
            },
            loadTiles() {
                if (this.widget) {
                    this.$store.dispatch('slider/getTiles', {
                        widgetId: this.widget.id
                    }).then((result) => {
                        this.contentType = result.contentType;
                        this.tiles = result.list;
                        this.$nextTick(this.startSlider);
                    }).catch((error) => {
                        console.error(error);
                        this.tiles = [];
                    });
                }
            }
        }
    };
</script>
