<template>
    <div class="cmyk-editor">
        <div class="preview-wrapper" :style="previewStyle"></div>
        <div class="item">
            <div class="label">C</div>
            <input
                type="number"
                min="0"
                max="100"
                :value="valueC"
                @input="(evt) => updateValue(evt.target.value, 'c')"
            />
            <input
                tabindex="-1"
                type="range"
                min="0"
                max="100"
                class="form-range"
                id="customRangeC"
                :value="valueC"
                @input="(evt) => updateValue(evt.target.value, 'c')"
            />
        </div>
        <div class="item">
            <div class="label">M</div>
            <input
                type="number"
                min="0"
                max="100"
                :value="valueM"
                @input="(evt) => updateValue(evt.target.value, 'm')"
            />
            <input
                tabindex="-1"
                type="range"
                min="0"
                max="100"
                class="form-range"
                id="customRangeM"
                :value="valueM"
                @input="(evt) => updateValue(evt.target.value, 'm')"
            />
        </div>
        <div class="item">
            <div class="label">Y</div>
            <input
                type="number"
                min="0"
                max="100"
                :value="valueY"
                @input="(evt) => updateValue(evt.target.value, 'y')"
            />
            <input
                tabindex="-1"
                type="range"
                min="0"
                max="100"
                class="form-range"
                id="customRangeY"
                :value="valueY"
                @input="(evt) => updateValue(evt.target.value, 'y')"
            />
        </div>
        <div class="item">
            <div class="label">K</div>
            <input
                type="number"
                min="0"
                max="100"
                :value="valueK"
                @input="(evt) => updateValue(evt.target.value, 'k')"
            />
            <input
                tabindex="-1"
                type="range"
                min="0"
                max="100"
                class="form-range"
                id="customRangeK"
                :value="valueK"
                @input="(evt) => updateValue(evt.target.value, 'k')"
            />
        </div>
    </div>
</template>

<script>
    import { cmyk2rgb } from "@utilities/ndxColor";

    export default {
        name: 'CmykEditor',
        props: {
            /**
             * The selected color
             */
            modelValue: {
                validator: function (value) {
                    return value === null || typeof (value) === 'string';
                }
            }
        },
        emits: ['update:modelValue'],
        data() {
            return {
                cmyk2rgb: cmyk2rgb,

                updateTimeOut: null,

                valueC: 0,
                valueM: 0,
                valueY: 0,
                valueK: 0
            };
        },
        computed: {
            previewStyle() {
                let rgb = this.cmyk2rgb(this.getfomtatedString());
                return 'background-color: rgb(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ');';
            }
        },
        watch: {
            modelValue: {
                immediate: true,
                handler: 'setVariables'
            }
        },
        methods: {
            setVariables() {
                if (this.modelValue === null || this.modelValue === 'transparent') {
                    this.valueC = 0;
                    this.valueM = 0;
                    this.valueY = 0;/**/
                    this.valueK = 0;
                } else {
                    let color = this.modelValue
                        .replace('cmyk', '')
                        .replaceAll('(', '')
                        .replaceAll(')', '')
                        .split(',');

                    if (color.length !== 4) {
                        return;
                    }

                    color = color.map(v => parseInt((parseFloat(v) * 100), 10));

                    this.valueC = color[0];
                    this.valueM = color[1];
                    this.valueY = color[2];
                    this.valueK = color[3];
                }
            },
            updateValue(val, type) {
                let value = parseInt(val, 10);
                value = value < 0 ? 0 : value;
                value = value > 100 ? 100 : value;

                if (type === 'c') {
                    this.valueC = value;
                } else if (type === 'm') {
                    this.valueM = value;
                } else if (type === 'y') {
                    this.valueY = value;
                } else if (type === 'k') {
                    this.valueK = value;
                }

                this.emitUpdate();
            },
            emitUpdate() {
                this.$emit('update:modelValue', this.getfomtatedString());
            },
            getfomtatedString() {
                let parts = [
                    this.valueC, this.valueM, this.valueY, this.valueK
                ];
                parts = parts.map(v => (v / 100).toFixed(2));
                return 'cmyk(' + parts.join(',') + ')';
            }
        }
    };
</script>
