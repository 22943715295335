<template>
    <div>
        <NdxSelect
            v-for="(option, type) in sortedValidOptions"
            :key="type"
            :options="buildOptions(option)"
            :text-wrap="true"
            :label="option.displayName"
            :model-value="getSelected(type)"
            :readonly="readonly"
            @update:modelValue="(newVal) => onChange(type, newVal)"
        />
    </div>
</template>

<script>
    import NdxSelect from "../../library/formElements/NdxSelect";
    export default {
        name: 'VariantAttrs',
        components: {NdxSelect},
        props: {
            attrValues: {
                type: Object
            },
            validOptions: {
                type: Object
            },
            variantOrder: {
                type: Array
            },
            readonly: {
                type: Boolean
            }
        },
        computed: {
            sortedValidOptions() {
                let orderedOptions = {};
                this.variantOrder.forEach(key => orderedOptions[key] = this.validOptions[key]);
                return orderedOptions;
            }
        },
        methods: {
            buildOptions(option) {
                let ret = [];

                for (let idx in option.sortOrder) {
                    if (option.options[option.sortOrder[idx]]) {
                        ret.push({
                            text: option.options[option.sortOrder[idx]],
                            value: option.sortOrder[idx]
                        });
                    }
                }

                return ret;
            },
            getSelected(type) {
                return this.attrValues[type].value;
            },
            onChange(type, newVal) {
                this.$emit('on-change', {
                    key: type,
                    value: newVal
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../style/variables_bootstrap";
    @import "~bootstrap/scss/mixins/breakpoints";

    .ndx-form-element {
        margin-top: 16px;
    }

    @include media-breakpoint-up(xl) {
        .ndx-form-element {
            margin-top: 0;
        }
        .ndx-form-element + .ndx-form-element {
            margin-top: 16px;
        }
    }
</style>
