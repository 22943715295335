<template>
    <div class="ndx-tree-nav-widget">
        <div
            class="rootNode"
            @click="gotoCategory(rootCategoryId)"
        >
            <span class="node-text">
                {{ widget.name }}
            </span>
        </div>
        <div
            v-if="isDetailPage && currentCategoryId === rootCategoryId"
            class="startNode"
            @click="gotoCategory(rootCategoryId)"
        >
            <NdxIcon icon="arrow-left"/>
            <span class="node-text">
                {{ widget.name }}
            </span>
        </div>
        <div
            v-if="!isDetailPage && parentCategory !== null"
            class="startNode"
            @click="gotoCategory(parentCategory.id)"
        >
            <NdxIcon icon="arrow-left"/>
            <span class="node-text">
                {{ parentCategory.name }}
            </span>
        </div>
        <div
            v-if="currentCategoryId !== rootCategoryId"
            class="startNode"
            :class="{readonly: !isDetailPage, active: !isDetailPage}"
            @click="gotoCategory($route.params.categoryId)"
        >
            <NdxIcon
                v-if="isDetailPage"
                icon="arrow-left"
            />
            <span v-else class="icon_placeholder"></span>
            <span class="node-text">
                {{ currentCategory.name }}
            </span>
        </div>
        <template v-if="!isDetailPage">
            <div
                v-for="child of currentCategory.children"
                :key="child.id"
                class="childNode"
                @click="() => gotoCategory(child.id)"
            >
                <span class="node-text">{{ child.name }}</span>
                <NdxIcon
                    v-if="child.children && child.children.length"
                    icon="drop-right"
                />
            </div>
        </template>
    </div>
</template>

<script>
    import NdxIcon from "../../library/NdxIcon";

    export default {
        name: "CategoryTreeMixin",
        components: {NdxIcon},
        props: {
            widget: Object
        },
        data() {
            return {
                store: '',
                currentCategory: {
                    name: '',
                    children: []
                }
            };
        },
        computed: {
            isDetailPage() {
                console.error('Computed property `isDetailPage` not implemented!');
                return false;
            },
            currentCategoryId() {
                return this.widget.config.categoryId;
            },
            rootCategoryId() {
                return this.widget.config.rootCategoryId;
            },
            parentCategory() {
                if (!this.store.length) {
                    throw new Error('Data `store` not set!');
                }
                const list = this.$store.getters[this.store + '/getParentList'](
                    this.currentCategoryId,
                    this.rootCategoryId
                );
                if (list.length > 1) {
                    return list[list.length - 2];
                }
                return null;
            }
        },
        watch: {
            currentCategoryId: {
                immediate: true,
                handler: 'getCurrentCategory'
            }
        },
        methods: {
            getCurrentCategory() {
                this.$store.dispatch(this.store + '/getSubtree', {
                    rootId: this.currentCategoryId,
                    depth: 3
                }).then((subTree) => {
                    this.currentCategory = subTree;
                }).catch((error) => {
                    console.error(error);
                    this.currentCategory = {
                        name: '',
                        children: []
                    };
                });
            },
            gotoCategory() {
                throw new Error('Method `gotoCategory` not implemented!');
            }
        }
    };
</script>
