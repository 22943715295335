<template>
    <NdxListing
        store-action="workflowInstanceSnapshot/filterBy"
        :filter-criteria="filterCriteria"
        :order-by="{sortBy: 'instanceEndedAt', sortAsc: false}"
        @items-changed="updateItems"
    >
        <template #emptyList>
            <NdxNotification
                variant="info"
                :duration="0"
                :modelValue="true"
                :message="$t('message.noSearchHits')"
            />
        </template>

        <template #default>
            <div class="workflowlist-item-container">
                <WorkflowInstanceItem
                    v-for="item in list"
                    :key="item.id"
                    :item="item"
                />
            </div>
        </template>
    </NdxListing>
</template>

<script>
    import NdxNotification from "../library/NdxNotification.vue";
    import NdxListing from "../library/NdxListing.vue";
    import WorkflowInstanceItem from "./WorkflowInstanceItem.vue";
    import { addDays } from "@utilities/ndxDate";

    export default {
        name: 'WorkflowInstancesCompleted',
        components: {NdxListing, WorkflowInstanceItem, NdxNotification},
        props: {
            filterData: {
                type: Object
            }
        },
        data() {
            return {
                list: [],
            };
        },
        computed: {
            filterCriteria() {
                const criteria = {
                    __noCount: true,
                    instanceCreatedBy: this.$store.getters['user/currentUserId'],
                    searchText: this.filterData.searchText,
                };

                if (this.filterData.startDate.from) {
                    criteria.dateStart = this.filterData.startDate.from;
                }
                if (this.filterData.startDate.to) {
                    criteria.dateFinish = addDays(this.filterData.startDate.to, 1);
                }
                if (this.filterData.endDate.from) {
                    criteria.finishDateAfter = this.filterData.endDate.from;
                }
                if (this.filterData.endDate.to) {
                    criteria.finishDateBefore = addDays(this.filterData.endDate.to, 1);
                }

                return criteria;
            },
        },
        methods: {
            updateItems(items) {
                this.list = items;
            }
        }
    };
</script>
