import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'country';

export default {
    getCountryList() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getCountryList', [], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getIsoLanguageCodes() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getIsoLanguageCodes', [], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
