<template>
    <div>
        <div class="listEntry" @click="() => filter(-1)">
            <span class="unread">{{ $t('label.news.type_-1') }}</span>
            <span>{{ totalUnreadCount }}</span>
        </div>
        <div class="listEntry" @click="() => filter('all')">
            <span :class="{unread: totalUnreadCount > 0}">{{ $t('label.news.type_all') }}</span>
            <span></span>
        </div>
        <div
            class="listEntry"
            v-for="(unreadCount, type) in categoryList"
            :key="type"
            @click="() => filter(type)"
        >
            <span :class="{unread: unreadCount > 0}">{{ $t('label.news.type_' + type) }}</span>
            <span>{{ unreadCount }}</span>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "NewsFilter",
        emits: ['news-filter'],
        props: {
            news: {
                type: Array,
                required: true
            }
        },
        computed: {
            categoryList() {
                let categories = {};

                this.news.forEach(newsItem => {
                    categories[newsItem.type] = (categories[newsItem.type] || 0) +
                        newsItem.replyInfo.unreadCount +
                        (newsItem.readByLoggedUser ? 0 : 1);
                });

                return categories;
            },
            ...mapState({
                totalUnreadCount: state => state.news.unreadNewsCount,
            })
        },
        methods: {
            filter(newsType) {
                this.$emit('news-filter', newsType);
            }
        }
    };
</script>

<style scoped lang="scss">
    .unread {
        font-weight: 600;
    }

    .listEntry {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid var(--bs-gray-middle);
        cursor: pointer;

        &:hover {
            color: var(--bs-primary);
        }
    }
    .highlight {
        background-color: var(--bs-primary-light);
    }
</style>
