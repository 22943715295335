<template>
    <div class="ndx-tree-nav-widget">
        <div
            class="rootNode"
            @click="$router.push($store.getters['shop/getRouteByType']('favorites'))"
        >
            <span class="node-text">{{ widget.name }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FavoritesWidget",
        props: {
            widget: Object
        }
    };
</script>
