<template>
    <div class="wf-businessObjectViewer">
        <component
            v-if="viewerComponent !== null"
            :is="viewerComponent"
            :business-object="businessObject"
            @block-task="(val) => $emit('block-task', val)"
        />
        <div v-else>
            -- unknown businessObject {{ businessObject.businessObjectType }} --
        </div>
    </div>
</template>

<script>
    import ProductCatalogItem from "./types/ProductCatalogItem.vue";
    import ProductCatalogItemCompact from "./types/ProductCatalogItemCompact.vue";
    import MissingBo from "./types/MissingBo.vue";

    export default {
        name: 'BusinessObjectViewer',
        props: {
            businessObject: {
                type: Object
            },
            compact: {
                type: Boolean
            }
        },
        emits: ['block-task'],
        computed: {
            viewerComponent() {
                if (this.businessObject.businessObjectType === 'orderItem') {
                    return this.compact
                        ? ProductCatalogItemCompact
                        : ProductCatalogItem;
                }
                if (this.businessObject.businessObjectType === 'boNotFound') {
                    return MissingBo;
                }
                return null;
            }
        }
    };
</script>
