export function fileSize (size) {
    let data = {
        value: 0,
        unit: '',
        asString: function () {
            return this.value + ' ' + this.unit;
        }
    };

    // Bytes
    if (size < 1024) {
        data.value = size;
        data.unit = 'B';
        // Kilobytes
    } else if (size < 1048576) {
        data.value = Math.round((size / 1024) * 100) / 100;
        data.unit = 'kB';
        // Megabytes
    } else if (size < 1073741824) {
        data.value = (Math.round((size / 1048576) * 100)) / 100;
        data.unit = 'MB';
        // Gigabytes
    } else if (size < 1099511627776) {
        data.value = (Math.round((size / 1073741824) * 100)) / 100;
        data.unit = 'GB';
    }

    return data;
}

export function createLinkName (filename) {
    return filename.replace(/[^\- ._A-Za-z0-9]/g, '_').replace(/\s/g, '');
}

/**
 * @param {Object} csvColumns
 * @param {Array} csvRows
 *
 * @returns {Blob}
 */
export function createCsvBinary (csvColumns, csvRows) {
    const universalBOM = "\uFEFF";
    const fieldDelimiter = '"';
    const fieldSeperator = ';';
    const lineSeperator = "\r\n";
    const technicalColumnKeys = Object.keys(csvColumns);

    let csvContent = "";

    const header = Object.values(csvColumns)
        .map(name => fieldDelimiter + name.replaceAll(fieldDelimiter, fieldDelimiter + fieldDelimiter) + fieldDelimiter)
        .join(fieldSeperator);

    csvContent += header + lineSeperator;

    csvRows.forEach(csvRow => {
        let rowData = [];
        technicalColumnKeys.forEach(key => {
            if (key in csvRow) {
                if (typeof csvRow[key] === 'number') {
                    rowData.push(csvRow[key]);
                } else if (typeof csvRow[key] === 'string') {
                    rowData.push(
                        fieldDelimiter + csvRow[key].replaceAll(fieldDelimiter, fieldDelimiter + fieldDelimiter) +
                        fieldDelimiter
                    );
                } else if (csvRow[key] instanceof Date) {
                    rowData.push(
                        fieldDelimiter +
                        csvRow[key].toLocaleString().replaceAll(fieldDelimiter, fieldDelimiter + fieldDelimiter) +
                        fieldDelimiter
                    );
                } else {
                    // value not representable in csv
                    rowData.push('');
                }
            } else {
                // value not given in csvRow
                rowData.push('');
            }
        });

        const row = rowData.join(fieldSeperator);
        csvContent += row + lineSeperator;
    });

    return new Blob([universalBOM + csvContent], {encoding:"UTF-8", type: 'text/csv;charset=UTF-8'});
}

/**
 * @param {Array} list
 *
 * @returns {Array}
 */
export function handleImageUpload(list) {
    if (list && Array.isArray(list) && list.length) {
        let result = [];

        for (let i in list) {
            if (list[i].status) {
                result.push({
                    id: list[i].uploadElementId,
                    name: list[i].name,
                    description: list[i].description,
                    category: list[i].category,
                    file: list[i].details
                });
            }
        }

        return result;
    }

    throw new Error('list has no items');
}

/**
 * @param {Array} list
 *
 * @returns {Array}
 */
export function handleFontUpload(list) {
    if (list && Array.isArray(list) && list.length) {
        let result = [];

        for (let i in list) {
            if (list[i].status) {
                result.push({
                    id: list[i].uploadElementId,
                    name: list[i].name,
                    description: list[i].description,
                    file: list[i].details
                });
            }
        }

        return result;
    }

    throw new Error('list has no items');
}
