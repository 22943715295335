<template>
    <NdxPageFrame
        v-if="initiated"
        key="shopHomepage"
        :wgc-id="wgcId"
    />
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import { mapState } from "vuex";

    export default {
        name: 'Home',
        components: {NdxPageFrame},
        data() {
            return {
                wgcId: null,
                initiated: false
            };
        },
        computed: {
            ...mapState({
                homeRoute: state => state.shop.homeRoute
            })
        },
        watch: {
            homeRoute() {
                this.init();
            },
            $route: {
                deep: true,
                handler: function () {
                    this.init();
                }
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                if (this.homeRoute) {
                    this.wgcId = this.homeRoute.params.id;
                    this.initiated = true;
                }

                if (['ShopHomepage', 'ShopHomepage1'].includes(this.$route.name) &&
                    (
                        (this.$route.params.id && this.homeRoute.params.id != this.$route.params.id) ||
                        Array.isArray(this.$route.params.pathMatch) && this.$route.params.pathMatch.length
                    )
                ) {
                    this.$router.push({name: 'NotFound', params: {notFound: 'pageNotFound'}});
                }
            }
        }
    };
</script>
