import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'assetCategory';

export default {
    searchStyleguidesAndCategories(searchText, throbberRule = throbberRules.FULLSCREEN) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'searchStyleguidesAndCategories', [searchText], throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getSubtree(rootId, depth, throbberRule = throbberRules.FULLSCREEN) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getSubtree', [rootId, depth], throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getParentId(currentCategoryId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getParentId', [currentCategoryId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getSubtreeBulk(idList) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getSubtreeBulk', [idList], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getParentList(categoryId, rootCategoryId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getParentList', [+categoryId, +rootCategoryId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
