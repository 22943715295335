import lodashMerge from 'lodash/merge';
import { ndxRpc } from "../utils/ndxRpc";

const service = 'workflowInstance';

export default lodashMerge({}, {
    filterBy(filterDescription, sortBy, sortAsc, searchText, offset, limit) {
        const params = [
            filterDescription, sortBy, sortAsc, searchText, offset, limit
        ];
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'filterBy', params)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    find(entityId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'find', [entityId])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getVariables(businessObjId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getVariables', [businessObjId])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setVariables(businessObjId, variables) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'setVariables', [businessObjId, variables])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getOrderItemListData(instanceId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getOrderItemListData', [instanceId])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
});
