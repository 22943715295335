<script>
    import tagApi from "../api/tags";

    export default {
        name: "TagsForProduct",
        data() {
            return {
                tagDict: {}
            };
        },
        methods: {
            getTags(productId) {
                return this.tagDict[productId];
            },
            updateTags: function (products) {
                let ids = [];

                products.forEach((prod) => {
                    if (!(prod.id in this.tagDict)) {
                        ids.push(prod.id);
                    }
                });
                if (ids.length > 0) {
                    this.$store.dispatch('tags/getTagsByIdsOfSameType', {
                        ids: ids,
                        type: tagApi.FQCN.product
                    }).then((result) => {
                        result.forEach((entry) => {
                            this.tagDict[entry.id] = entry.tags;
                        });
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            }
        }
    };
</script>
