import documentApi from "../../api/documents";

const state = {
};

// getters
const getters = {
};

// actions
const actions = {
    filterBy(ctx, {filterCriteria, orderBy, limit, offset}) {
        return new Promise((resolve) => {
            documentApi.filterBy(filterCriteria, orderBy, limit, offset)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    resolve(error);
                });
        });
    },
};

// mutations
const mutations = {
    RESET() {
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
