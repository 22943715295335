//state
const state = {};

// getters
const getters = {
    getAppleWalletUri: () => (vCardId) => {
        return '/portal/file/appleWallet/' + vCardId + '?key=' + Math.round(Math.random() * 1e16);
    },
    getGoogleWalletUri: () => (vCardId) => {
        return '/portal/file/googleWallet/' + vCardId + '?key=' + Math.round(Math.random() * 1e16);
    },
    getWalletImgSrc: (state, getters, rootState, rootGetters) => (type) => {
        let folder = '';
        let icon = '';

        const activeLanguage = rootGetters['session/getActiveLanguage'];

        if (type === 'apple') {
            folder = 'appleWallet';
            icon = activeLanguage === 'de'
                ? 'DE_Add_to_Apple_Wallet_RGB_101421.svg'
                : 'US-UK_Add_to_Apple_Wallet_RGB_101421.svg';
        } else {
            folder = 'googleWallet';
            icon = activeLanguage === 'de'
                ? 'de_add_to_google_wallet_add-wallet-badge.svg'
                : 'enUS_add_to_google_wallet_add-wallet-badge.svg';
        }

        return '/js/apps/shared/assets/images/' + folder + '/' + icon;
    }
};

// actions
const actions = {};

// mutations
const mutations = {
    RESET() {
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
