<template>
    <div class="ndx-tree-nav-widget">
        <div
            class="rootNode"
            @click="gotoOrders"
        >
            <span class="node-text">{{ widget.name }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrdersWidget",
        props: {
            widget: Object
        },
        methods: {
            gotoOrders() {
                const routeData = this.$store.getters['shop/getRouteByType']('orders');
                this.$router.push(routeData);
            }
        }
    };
</script>
