export const numberFormats = {
    de: {
        currency: {
            style: 'decimal',
            currency: 'EUR',
            useGrouping: true,
            currencyDisplay: 'symbol',
            minimumIntegerDigits: 1,
            minimumFractionDigits: 2,
            maximumFractionDigits: 10
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    },
    en: {
        currency: {
            style: 'decimal',
            currency: 'USD',
            useGrouping: true,
            currencyDisplay: 'symbol',
            minimumIntegerDigits: 1,
            minimumFractionDigits: 2,
            maximumFractionDigits: 10
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    }
};
