import lodashMerge from 'lodash/merge';
import productCategoryApi from '../../api/productCategory';
import {
    state as categoriesState,
    getters as categoriesGetters,
    mutations as categoriesMutations,
    buildActions
} from './base/categories';

//state
const state = () => {
    return lodashMerge({}, categoriesState(), {
        // custom stuff
    });
};

// getters
const getters = lodashMerge({}, categoriesGetters, {
    // custom stuff
});

// actions
const actions = lodashMerge({}, buildActions(productCategoryApi), {
    getSubtreeWithWgc(ctx, data) {
        return new Promise((resolve, reject) => {
            productCategoryApi.getSubtreeWithWgc(data.rootId, data.depth)
                .then((subTree) => {
                    resolve(subTree);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    filterBy(ctx, data) {
        return new Promise((resolve, reject) => {
            productCategoryApi.filterBy(
                data.rootId, data.offset, data.limit, data.searchText
            )
                .then((subTree) => {
                    resolve(subTree);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
});

// mutations
const mutations = lodashMerge({}, categoriesMutations, {
    // custom stuff
});

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
