<template>
    <LoginWireframe>
        <template #form>
            <PasswordRecoverForm />
        </template>
    </LoginWireframe>
</template>

<script>
    import LoginWireframe from '../../wireframes/NdxLogin';
    import PasswordRecoverForm from '../../components/passwordRecover/PasswordRecover';

    export default {
        name: 'PasswordRecover',
        components: {LoginWireframe, PasswordRecoverForm}
    };
</script>
