<template>
    <div
        v-if="addressListSearchActive && addressListClient.length === 0 && addressListUser.length === 0"
        class="my-2"
    >
        {{ $t('message.noSearchHits') }}
    </div>
    <div v-if="addressListClient.length" class="groupTitle">{{ $t('label.client') }}</div>
    <NdxRadioCards
        v-for="address in addressListClient"
        :key="address.id"
        :value="address.id"
        :text="formatAddress(address)"
        :selected="address.id === modelValue"
        :disabled="!$store.getters['basket/isUseableDeliveryAddress'](address)"
        @radio-card-selected="(value) => $emit('update:model-value', value)"
    />
    <div v-if="addressListUser.length" class="groupTitle">{{ $t('label.user') }}</div>
    <NdxRadioCards
        v-for="address in addressListUser"
        :key="address.id"
        :value="address.id"
        :text="formatAddress(address)"
        :selected="address.id === modelValue"
        :disabled="!$store.getters['basket/isUseableDeliveryAddress'](address)"
        @radio-card-selected="(value) => $emit('update:model-value', value)"
    />
</template>

<script>
import { mapGetters } from "vuex";
import NdxRadioCards from "../../../library/formElements/NdxRadioCards.vue";
import { AddressFormatter } from "../../../../plugins/formatter";
import { AddressMatchesString } from "../../../../plugins/addressSearch";

export default {
    name: "AddressSelection",
    components: {NdxRadioCards},
    props: {
        addressType: {
            type: String
        },
        searchTerm: {
            type: String,
            required: false
        },
        formatter: {
            type: Function,
            required: false
        },
        modelValue: {
            type: Number,
            required: false
        }
    },
    computed: {
        ...mapGetters('user', [
            'clientAddresses',
            'userAddresses',
        ]),
        addressListSearchActive() {
            return !!this.searchTerm?.trim().length;
        },
        addressListClient() {
            return this.clientAddresses
                // filter by address type
                .filter((address) => {
                    return address.addressTypes
                            .find(item => item.addressType === this.addressType) !== undefined
                        || (this.modelValue && this.modelValue === address.id);
                })
                // filter by search
                .filter((address) => {
                    return !this.addressListSearchActive || this.addressMatchesString(address);
                });
        },
        addressListUser() {
            return this.userAddresses
                // filter by address type
                .filter((address) => {
                    return address.addressTypes
                            .find(item => item.addressType === this.addressType) !== undefined
                        || (this.modelValue && this.modelValue === address.id);
                })
                // filter by search
                .filter((address) => {
                    return !this.addressListSearchActive || this.addressMatchesString(address);
                });
        }
    },
    methods: {
        addressMatchesString(address) {
            return AddressMatchesString(address, this.searchTerm);
        },
        formatAddress(addressArr) {
            if (this.formatter && typeof this.formatter === 'function') {
                return this.formatter(addressArr);
            }
            return AddressFormatter(addressArr, this, ', ');
        }
    }
};
</script>

<style scoped lang="scss">
    @import "../../../../style/variables_ndx";

    .groupTitle {
        @extend %font-h4;
        margin-top: 8px;
        margin-bottom: 4px;
    }
</style>
