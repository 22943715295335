<template>
    <div
        class="ndx-butcon"
        :class="{
            [sizeClassName]: true,
            'disabled': disabled
        }"
        @click.stop.prevent="onClick"
    >
        <NdxIcon :icon="icon" :size="iconSize"/>
    </div>
</template>

<script>
    import NdxIcon from "./NdxIcon";

    export default {
        name: 'NdxButcon',
        components: {NdxIcon},
        props: {
            icon: {
                type: String
            },
            size: {
                type: String,
                default: 'm',
                validator: function (value) {
                    return ['xs', 's', 'm', 'l', 'xl'].indexOf(value) !== -1;
                }
            },
            disabled: {
                type: Boolean
            }
        },
        emits: ['click'],
        computed: {
            sizeClassName() {
                return 'size-' + this.size;
            },
            iconSize() {
                if (this.size === 'xs') {
                    return 's';
                }
                return 'm';
            }
        },
        methods: {
            onClick() {
                if (!this.disabled) {
                    this.$emit('click');
                }
            }
        }
    };
</script>
