import lodashMerge from 'lodash/merge';
import { ndxRpc } from "../utils/ndxRpc";

const service = 'workflowApproveOrderItem';

export default lodashMerge({}, {
    startApproval(businessObjId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'startApproval', [businessObjId])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    quantityChanged(businessObjId, quantity) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'quantityChanged', [businessObjId, +quantity])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
});
