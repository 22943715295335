<template>
    <div class="ndx-overlay-wrapper greyOut">
        <div class="ndx-overlay d-flex flex-column" :class="{['variant_' + variant]: true}">
            <div class="ndx-overlay-header">{{ title }}</div>
            <div class="ndx-overlay-content">
                <div class="ps h-100">
                    <slot name="content"></slot>
                </div>
            </div>
            <div class="ndx-overlay-footer d-flex gap-2 justify-content-end align-items-center">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NdxOverlay',
        props: {
            title: String,
            variant: {
                type: String,
                default: "std",
                validator: function (val) {
                    return ['std', 'fullscreen'].includes(val);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";

    .ndx-overlay-wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;

        .ndx-overlay {
            width: calc(100vw - 64px);
            height: calc(100vh - 64px);
            background-color: white;
            margin: 32px auto;
            border-radius: 5px;
            padding: 16px;

            .ndx-overlay-header {
                @extend %font-h2;

                height: 30px;
                flex-shrink: 0;
            }
            .ndx-overlay-content {
                flex-grow: 1;
                overflow: hidden;
                padding: 16px 0;
            }
            .ndx-overlay-footer {
                flex-shrink: 0;
            }

            &.variant_fullscreen {
                width: 100%;
                height: 100%;
                margin: 0;
                border-radius: 0;
            }
        }
    }
</style>
