<template>
    <LoginWireframe>
        <template #form>
            <span class="m-4 preserveLineBreaks">{{ $t('message.maintenanceIsActive') }}</span>
        </template>
    </LoginWireframe>
</template>

<script>
    import LoginWireframe from "../../wireframes/NdxLogin.vue";

    export default {
        name: "Maintenance",
        components: {LoginWireframe}
    };
</script>
