<template>
    <div class="root">
        <div :data-url="url" class="pdf" :class="{completePdf: completePdf}"/>
    </div>
</template>

<script>
    export default {
        name: 'NdxPdf',
        props: {
            zoom: Number,
            url: String,
            renderOnce: {
                type:Boolean,
                default: false
            },
            completePdf: {
                type:Boolean,
                default: false
            },
        },
        data() {
            return {
                loadingTask: null,
                renderTask: null,
                doc: null
            };
        },
        watch: {
            zoom: function () {
                this.renderPage();
            },
            url: function () {
                this.render(this.url);
            }
        },
        mounted() {
            this.render(this.url);
        },
        beforeUnmount() {
            this.cleanup();
        },
        methods: {
            cleanup: function () {
                if (this.loadingTask && !this.loadingTask.destroyed) {
                    try {
                        this.loadingTask.destroy();
                    } catch (e) {
                        console.log(e);
                    }

                    this.loadingTask = null;
                }

                Array.from(this.$el.querySelector('.pdf').children).forEach(el => el.remove());
            },
            render: function (url) {
                if (!url) {
                    return;
                }

                this.cleanup();
                try {
                    this.loadingTask = window.pdfjsLib.getDocument('//' + window.location.host + url);
                    this.loadingTask.promise.then((doc) => {
                        this.doc = doc;
                        this.renderPage();
                    });
                } catch (e) {
                    console.log(e);
                }
            },

            renderPage: async function () {
                let doc = this.doc;
                if (!doc) {
                    console.error('No Document');
                    return;
                }
                try {
                    const numPagesRendered = this.completePdf ? doc.numPages : 1;
                    for (let i = 1; i <= numPagesRendered ; i++) {
                        let canvas = document.createElement('canvas');
                        canvas.setAttribute('data-page', i);
                        this.$el.querySelector('.pdf').append(canvas);
                        const page = await doc.getPage(i);

                        const viewport = page.getViewport({scale: this.zoom});

                        if (!this.loadingTask || this.loadingTask.destroyed) {
                            return;
                        }
                        // Prepare canvas using PDF page dimensions
                        let context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;

                        // Render PDF page into canvas context
                        this.renderTask = page.render({
                            canvasContext: context,
                            viewport: viewport,
                            background: 'rgb(255,255,255)'
                        });
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    .root {
        height: 100%;
    }

    .pdf {
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        overflow: auto;
        justify-content: flex-start;
        align-items: center;

        &.completePdf {
            :deep(canvas) {
                margin-bottom: 8px;
            }
        }

        :deep(canvas) {
            border: 1px solid var(--bs-gray-200);
            display: inline-block;
            background-color: #fff;
            max-width: 100%;
            max-width: calc(100% - 20px);
        }
    }
</style>
