<template>
    <div class="clusterWrapper">
        <div class="clusterHeader d-flex justify-content-between align-items-center">
            <div class="shorten">{{ headline }}</div>
            <div class="actions d-flex align-items-center">
                <NdxIcon
                    v-for="entry in actions"
                    :key="entry.icon"
                    :icon="entry.icon"
                    @click="entry.click()"
                    class="icon-s action_icon"
                    :title="entry.tooltip || ''"
                />

                <NdxFileUpload
                    v-if="addUpload"
                    :label="''"
                    :name="''"
                    variant="icon"
                    class="d-inline-block"
                    v-bind="uploadProps"
                    @upload-succeeded="(data) => $emit('upload-input', data)"
                    @upload-failed="(error) => $emit('upload-failed', error)"
                />

                <NdxIcon
                    v-if="!bodyHidden"
                    icon="drop-down"
                    class="icon-s action_icon"
                    :title="$t('tooltip.closeCluster')"
                    @click="bodyHidden = !bodyHidden"
                />
                <NdxIcon
                    v-if="bodyHidden"
                    icon="drop-down"
                    class="icon-s action_icon"
                    flip-h
                    :title="$t('tooltip.openCluster')"
                    @click="bodyHidden = !bodyHidden"
                />
            </div>
        </div>
        <div class="clusterBody" v-show="!bodyHidden"><slot /></div>
    </div>
</template>

<script>
import NdxFileUpload from "./NdxFileUpload";
import NdxIcon from "../NdxIcon";
export default {
    name: 'NdxCluster',
    components: {NdxIcon, NdxFileUpload},
    props: {
        /**
         * Text summarizing the clustered content
         */
        headline: {
            type: String,
            required: true
        },
        /**
         * controls if content is shown or not
         */
        closed: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Array,
            required: false,
            validator: function (value) {
                if (Array.isArray(value)) {
                    value.forEach((entry) => {
                        if (!('icon' in entry) || !('click' in entry)) {
                            console.error('you need the keys icon and click inside the actions list entry');
                            return false;
                        }
                    });
                }
                return true;
            }
        },
        /**
         * adds a upload action icon if true
         */
        addUpload: {
            type: Boolean,
            default: false
        },
        /**
         * params for the NdxFileUpload component
         */
        uploadProps: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    emits: ['upload-input', 'upload-failed'],
    data() {
        return {
            bodyHidden: this.closed
        };
    },
    watch: {
        closed: function (newVal) {
            this.bodyHidden = newVal;
        }
    }
};
</script>

<docs>
    ### offen
    ```jsx
        <NdxCluster headline="Titel">Inhalt</NdxCluster>
    ```
    ### geschlossen
    ```jsx
        <NdxCluster headline="Titel" closed>Inhalt</NdxCluster>
    ```
</docs>
