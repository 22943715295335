<template>
    <div class="scroll_container">
        <NewsItem
            :ref="'news' + news.id"
            v-for="news in renderSequence"
            :key="news.id"
            :news-item="news"
            :cite="getCite(news)"
            :variant="getVariant(news)"
            @news-action="newsAction"
        />
    </div>

    <NdxFlyIn v-if="replyNews">
        <template #title>
            {{ $t('label.reply') }}
        </template>
        <template #default>
            <NdxTextarea
                :label="$t('label.replyContent')"
                v-model="reply"
            />
            <div class="cite">
                {{ replyNews.content }}
            </div>
        </template>
        <template #buttons>
            <NdxButtonLink
                @click="() => {replyNews = null;}"
            >{{ $t('btn.cancel') }}</NdxButtonLink>
            <NdxButton
                :disabled="!reply || reply.length === 0"
                @click="saveReply"
            >{{ $t('btn.save') }}</NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NewsItem from "../library/NdxNewsItem";
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxButton from "../library/NdxButton";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxTextarea from "../library/formElements/NdxTextarea";
    import NewsMixin from "../news/NewsMixin";

    export default {
        name: 'ForumWidget',
        components: {NdxTextarea, NdxButtonLink, NdxButton, NdxFlyIn, NewsItem},
        mixins: [NewsMixin],
        props: {
            widget: Object
        },
        data() {
            return {
                replyNews: null,
                reply: null,
            };
        },
        computed: {
            newsId() {
                return this.widget.config.newsId;
            },
            renderSequence() {
                return this.$store.getters['news/getTimesortedThreadNews'](this.thread);
            }
        },
        created() {
            this.getForumThreads();
        },
        mounted() {
            this.scrollToLastNews();
        },
        methods: {
            getForumThreads() {
                this.$store.dispatch('news/getThread', {
                    newsId: this.newsId
                }).then(thread => {
                    this.thread = thread;
                    this.scrollToLastNews();
                });
            },
            getVariant(news) {
                return news.createdBy?.id === this.$store.getters['user/currentUserId'] ? 'mine' : 'other';
            },
            newsAction(evt) {
                switch (evt.action) {
                    case 'reply':
                        this.reply = '';
                        this.replyNews = this.renderSequence.find(msg => msg.id === evt.newsId);
                        break;
                    case 'markRead':
                        this.$store.dispatch('news/markReadBottomUp', {
                            newsId: evt.newsId,
                            threadStartId: this.newsId
                        }).then(thread => this.thread = thread);
                        break;
                }
            },
            saveReply() {
                const replyContent = this.reply.replace(/\n|\r\n/g, '<br />');
                let reply = JSON.parse(JSON.stringify(
                    this.$store.getters['news/getNewModel']
                ));
                reply.content = replyContent;
                reply.title = this.replyNews.title;
                reply.replyTo = this.replyNews.id;
                reply.affectedShops = [{id: this.$store.getters['session/getShopId']}];

                this.$store.dispatch('news/create', {
                    newsData: reply,
                    threadStartId: this.newsId
                }).then((thread) => {
                    this.thread = thread;
                    this.scrollToLastNews();
                    this.replyNews = null;
                    this.reply = '';
                });
            },
            scrollToLastNews() {
                this.$nextTick(() => {
                    const scrollContainer = this.$el.parentNode.querySelector('.scroll_container');

                    if (scrollContainer) {
                        scrollContainer.scrollTop = scrollContainer.scrollHeight;
                    }
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .cite {
        @extend %font-caption;

        color: var(--bs-disabled);
    }

    .scroll_container {
        overflow: auto;
        max-height: min(400px, 50vh);
        min-height: 200px;
    }
</style>
