<template>
    <div class="main-nav-item-container">
        <NdxMenuEntry
            v-for="item in navItems"
            :key="item.id"
            :wgc="item"
            icon-size="m"
            class="main-nav-item"
            :class="{
                'router-link-active': isActive(item)
            }"
            @mouseenter="setActiveProductNav(item)"
            @mouseleave="leaveActiveProductNav(item)"
        >
            <MainProductNav
                v-if="isActiveProductNav(item)"
                :config="item.config"
                :fallback-tile-bg-color="fallbackTileBgColor"
                :route="item.route"
            />
        </NdxMenuEntry>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import MainProductNav from "./MainProductNav";
    import NdxMenuEntry from "../library/NdxMenuEntry";

    export default {
        name: 'MainNav',
        components: {NdxMenuEntry, MainProductNav},
        data() {
            return {
                productMenuCloseTimeout: null,
                activeProductItem: null
            };
        },
        computed: {
            ...mapState({
                navItems: state => state.shop.primaryMenu
            }),
            ...mapGetters('shop', {
                fallbackTileBgColor: 'getDefaultProductImageBgColor'
            })
        },
        watch: {
            '$route': {
                handler: function () {
                    // close flyout menu on route change
                    window.clearTimeout(this.productMenuCloseTimeout);
                    this.activeProductItem = null;
                },
                deep: true
            }
        },
        methods: {
            isActive(item) {
                return this.$route && 'params' in this.$route && 'id' in this.$route.params &&
                    parseInt(this.$route.params.id, 10) === item.id;

            },
            setActiveProductNav(item) {
                if (this.productFlyoutActivated(item)) {
                    window.clearTimeout(this.productMenuCloseTimeout);
                    this.activeProductItem = item.id;
                }
            },
            leaveActiveProductNav(item) {
                if (item.id === this.activeProductItem) {
                    this.productMenuCloseTimeout = window.setTimeout(function () {
                        this.activeProductItem = null;
                    }.bind(this), 666);
                }
            },
            isActiveProductNav(item) {
                return this.productFlyoutActivated(item) && this.activeProductItem === item.id;
            },
            productFlyoutActivated(item) {
                if (item.type === 'products') {
                    let isFlyoutActive = true;
                    if ('showProductsFlyoutMenu' in item.config) {
                        isFlyoutActive = item.config.showProductsFlyoutMenu;
                    }
                    return isFlyoutActive;
                }
                return false;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .main-nav-item-container {
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-wrap: wrap;
        margin: -4px -16px;

        .main-nav-item {
            @extend %font-h2;

            cursor: pointer;
            text-decoration: none;
            text-align: center;
            margin: 4px 16px;

            &.router-link-active,
            &:hover {
                color: var(--bs-primary);
            }
        }
    }
</style>
