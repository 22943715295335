<template>
    <NdxPageFrame>
        <Workflow v-if="!$route.params.taskType"/>
        <Tasklist v-else />
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame.vue";
    import Tasklist from "../../components/workflows/Tasklist.vue";
    import Workflow from "../../components/workflows/Workflow.vue";

    export default {
        name: 'WorkflowsTasks',
        components: {Workflow, NdxPageFrame, Tasklist}
    };
</script>
