import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'transferPool';

export default {
    getFilesByOrderItemId(orderItemId, throbberType = throbberRules.CONTENTAREA) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getFilesByOrderItemId', [+orderItemId], throbberType)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
