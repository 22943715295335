<template>
    <div :id="containerId">
        <div>{{ $t('label.loading_data') }}</div>
    </div>
</template>

<script>
    export default {
        name: 'VueAppWidget',
        props: {
            widget: Object
        },
        data() {
            return {
                containerId: 'customApp_' + this.ndxUuid
            };
        },
        mounted() {
            if (this.widget.config.bucketName && this.widget.config.bucketName.length > 0) {
                let me = this;
                const url = 'https://' + this.widget.config.bucketName + '.eu-central-1.gos3.io/apps/';

                const cacheBuster = '?' + new Date().getTime();

                // load style
                if (this.widget.config.cssSrc && this.widget.config.cssSrc.length > 0) {
                    let linkTag = document.createElement('link');
                    linkTag.setAttribute('href', url + this.widget.config.cssSrc + cacheBuster);
                    linkTag.setAttribute('rel', 'stylesheet');
                    linkTag.setAttribute('id', this.containerId + '_css');
                    document.head.appendChild(linkTag);
                }

                // load script
                if (this.widget.config.jsSrc && this.widget.config.jsSrc.length > 0) {
                    let scriptTag = document.createElement('script');
                    scriptTag.onload = function () {
                        me.loadApp();
                    };
                    scriptTag.setAttribute('src', url + this.widget.config.jsSrc + cacheBuster);
                    scriptTag.setAttribute('id', this.containerId + '_js');
                    document.head.appendChild(scriptTag);
                }
            }
        },
        beforeUnmount() {
            // remove script
            if (this.widget.config.jsSrc && this.widget.config.jsSrc.length > 0) {
                let scriptTags = document.head.getElementsByTagName('script');
                for (const scriptTag of scriptTags) {
                    if (scriptTag.getAttribute('id') === this.containerId + '_js') {
                        scriptTag.remove();
                    }
                }
            }

            // remove style
            if (this.widget.config.cssSrc && this.widget.config.cssSrc.length > 0) {
                let styleTags = document.head.getElementsByTagName('link');
                for (const styleTag of styleTags) {
                    if (styleTag.getAttribute('id') === this.containerId + '_css') {
                        styleTag.remove();
                    }
                }
            }
        },
        methods: {
            loadApp() {
                if (this.widget.config.appName && this.widget.config.appName.length > 0) {
                    const customApp = new window[this.widget.config.appName](
                        '#' + this.containerId,
                        {
                            secret: this.$store.getters['session/getSecret']
                        }
                    );

                    console.log(customApp);
                    console.log('app loaded');
                }
            }
        }
    };
</script>
