import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'budgets';

export default {
    filterBy(type, offset, limit) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'filterBy',
                [type, offset, limit],
                throbberRules.FULLSCREEN
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getProducts(criteria, offset, limit) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'getProducts',
                [criteria, offset, limit],
                throbberRules.FULLSCREEN
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getOrders(budgetId, offset, limit) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                service,
                'getOrders',
                [budgetId, offset, limit],
                throbberRules.FULLSCREEN
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
