<template>
    <Group :group="group" v-if="items.length">
        <template #group-items>
            <BasketGroupItem
                v-for="item in items"
                :key="item.orderItemId"
                :item="item"
                @refresh-basket="() => $emit('refresh-basket')"
            />
        </template>
    </Group>
</template>

<script>
    import BasketGroupItem from "./BasketGroupItem";
    import Group from "../../checkout/steps/parts/Group";

    export default {
        name: "BasketGroup",
        components: {Group, BasketGroupItem},
        emits: ['refresh-basket'],
        props: {
            group: {
                type: Object,
                required: true
            }
        },
        computed: {
            items() {
                return this.group.items.filter(item => item.quantity > 0);
            }
        }
    };
</script>
