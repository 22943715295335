<template>
    <div v-if="restrictive" v-html="content"></div>
    <Vhtml
        v-else-if="content && content.length > 0"
        :html="content"
    />
</template>

<script>
    import Vhtml from "../library/Vhtml";

    export default {
        name: 'HtmlFragmentWidget',
        components: {Vhtml},
        props: {
            widget: Object
        },
        data() {
            return {
                content: '',
                restrictive: true
            };
        },
        mounted() {
            this.$store.dispatch('htmlFragment/find', {
                id: this.widget.config.selectedId
            }).then((result) => {
                this.content = 'content' in result ? result.content : '';
            });
        }
    };
</script>
