<template>
    <NdxHorizontalSlider
        v-if="categories.length > 0 && isSlider"
        :headline="widget.displayName ? widget.name : ''"
        :autoplay="autoplay"
    >
        <NdxCategoryCard
            v-for="category in categories"
            :key="category.id"
            :title="category.name"
            :background-color="category.backgroundColor"
            :foreground-color="category.iconColor"
            :card-img="category.image"
            :mask-img="category.mask"
            variant="card"
            @card-click="() => gotoCategoryPage(category)"
        />
    </NdxHorizontalSlider>
    <div
        v-else-if="categories.length > 0"
        class="card-container viewmode-card"
    >
        <NdxCategoryCard
            v-for="category in categories"
            :key="category.id"
            :title="category.name"
            :background-color="category.backgroundColor"
            :foreground-color="category.iconColor"
            :card-img="category.image"
            :mask-img="category.mask"
            variant="card"
            @card-click="() => gotoCategoryPage(category)"
        />
    </div>
</template>

<script>
    import NdxCategoryCard from "../library/NdxCategoryCard";
    import NdxHorizontalSlider from "../library/NdxHorizontalSlider";

    export default {
        name: 'CategoriesWidget',
        components: {NdxHorizontalSlider, NdxCategoryCard},
        props: {
            widget: Object
        },
        data() {
            return {
                categories: []
            };
        },
        computed: {
            autoplay() {
                return this.widget.config.autoplay === true;
            },
            isSlider() {
                return this.widget.config.version === 1;
            }
        },
        created() {
            this.loadCategories();
        },
        methods: {
            loadCategories() {
                if (this.widget) {
                    this.$store.dispatch('productCategories/getSubtreeWithWgc', {
                        rootId: this.widget.config.selectedId,
                        depth: 1
                    }).then((result) => {
                        if (result && 'children' in result) {
                            this.categories = result.children;
                        } else {
                            this.categories = [];
                        }
                    }).catch((error) => {
                        console.error(error);
                        this.categories = [];
                    });
                }
            },
            gotoCategoryPage(category) {
                if (category.wgc) {
                    this.$router.push({
                        name: 'ProductsInCategory',
                        params: {
                            id: category.wgc.id,
                            categoryId: category.id,
                            pid: ''
                        }
                    });
                } else {
                    console.error('no wgc for productCategory ID: ' + category.id);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "~bootstrap/scss/bootstrap-utilities";

    .card-container.viewmode-card {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 16px;
    }

    @include media-breakpoint-up(md) {
        .card-container.viewmode-card {
            grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
        }
    }
</style>
