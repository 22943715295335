<template>
    <NdxPageFrame>
        <InstanceDetail
            v-if="timelineData !== null"
            :timeline-data="timelineData"
        />
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import InstanceDetail from "../../components/workflows/InstanceDetail.vue";

    export default {
        name: 'WorkflowsInstanceDetail',
        components: {InstanceDetail, NdxPageFrame},
        data() {
            return {
                timelineData: null,
                timestamp: 0
            };
        },
        computed: {
            workflowInstanceEvents() {
                if (this.timelineData) {
                    return this.$store.getters['workflowEvents/getByInstanceId'](this.timelineData.instance.id);
                }
                return [];
            },
        },
        watch: {
            '$route.params': {
                handler: 'loadTimeline',
                deep: true,
                immediate: true
            },
            workflowInstanceEvents(newValue) {
                let newEvents = newValue.filter(item => item.timestamp > this.timestamp);
                if (newEvents.length) {
                    this.loadTimeline();
                    this.setTimestamp();
                }
            }
        },
        created() {
            this.setTimestamp();
        },
        methods: {
            setTimestamp() {
                this.timestamp = Math.floor(Date.now() / 1000);
            },
            loadTimeline() {
                let instanceId = null;
                if (this.$route.params && 'instanceId' in this.$route.params) {
                    instanceId = this.$route.params.instanceId;
                }

                if (instanceId === null) {
                    this.timelineData = null;
                    return;
                }

                this.$store.dispatch('workflowTimeline/getTimeline', {
                    instanceId: instanceId
                }).then((result) => {
                    this.timelineData = result;
                }).catch((error) => {
                    console.error(error);
                });
            }
        }
    };
</script>
