import transferPoolApi from "../../api/transferPool";

//state
const state = {};

// getters
const getters = {};

// actions
const actions = {
    getFilesByOrderItemId(ctx, data) {
        return new Promise((resolve, reject) => {
            transferPoolApi.getFilesByOrderItemId(data.orderItemId)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// mutations
const mutations = {
    RESET() {
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
