import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'file';

export default {
    find(id) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'find', [id], throbberRules.NONE)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    generateSecureDownloadLink(filename, expirationTime) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'generateSecureDownloadLink', [filename, expirationTime], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
