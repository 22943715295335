<template>
    <div class="ndx-tree-nav-widget">
        <div
            class="rootNode"
            @click="onRootClick"
        >
            <span class="node-text">
                {{ mainLabel }}
            </span>
        </div>
        <div
            class="childNode"
            :class="{active: categoriesIsActive}"
            @click="onCategoriesClick"
        >
            <span>{{ $t('label.budget_categories') }}</span>
        </div>
        <div
            class="childNode"
            :class="{active: productsIsActive}"
            @click="onProductsClick"
        >
            <span>{{ $t('label.budget_products') }}</span>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: 'BudgetsNavigation',
        props: {
            widget: Object
        },
        data() {
            return {
                mainLabel: ''
            };
        },
        computed: {
            ...mapGetters('shop', [
                'getWgc'
            ]),
            categoriesIsActive() {
                return this.$route.name === 'BudgetCategories';
            },
            productsIsActive() {
                return this.$route.name === 'BudgetProducts';
            }
        },
        mounted() {
            const item = this.getWgc(+this.$route.params.id);
            this.mainLabel = item && item.label.length > 0 ? item.label : this.$t('label.budgets');
        },
        methods: {
            onRootClick() {
                this.$router.push({name: 'Budgets'});
            },
            onCategoriesClick() {
                this.$router.push({name: 'BudgetCategories'});
            },
            onProductsClick() {
                this.$router.push({name: 'BudgetProducts'});
            }
        }
    };
</script>
