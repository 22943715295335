import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'shop';

export default {
    loadMenu() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'loadMenu', [], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getOptinPageData() {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getOptinPageData', [], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    saveOptinPageData(data) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'saveOptinPageData', [data], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getWidgets(parentOid) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getWidgets', [parentOid], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getPreLoginData(shopId, language) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getPreLoginData', [shopId, language], throbberRules.NONE)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getPortalCustomizationData(throbberRule = throbberRules.FULLSCREEN) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getPortalCustomizationData', [], throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getSearchCounts(productCategoryParams,
                    productParams,
                    assetCategoryParams,
                    assetParams,
                    throbberRule = throbberRules.NONE
    ) {
        return new Promise((resolve, reject) => {
            ndxRpc(
                'search',
                'searchCounts',
                [productCategoryParams, productParams, assetCategoryParams, assetParams],
                throbberRule
            )
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};
