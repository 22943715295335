const clickOutside = {
    mounted: function (el, binding) {
        el.clickOutsideEvent = function (event) {
            const elContainsTarget = function (element, target) {
                if (element == target || element.contains(target)) {
                    return true;
                }
                if ('children' in element) {
                    for (let child of element.children) {
                        if (elContainsTarget(child, target)) {
                            return true;
                        }
                    }
                }
                return false;
            };

            // check if the target is still visible in the DOM, otherwise you get a false pasitive
            if (document.body.contains(event.target) &&
                binding && binding.value &&
                elContainsTarget(el, event.target) === false
            ) {
                binding.value(event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    beforeUnmount: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
};

export default clickOutside;
