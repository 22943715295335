<template>
    <NdxPageFrame>
        <template #default="slotProps">
            <ProductDetailComponent
                v-if="product"
                :parent-width="slotProps.parentWidth"
            />
        </template>
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import ProductDetailComponent from "../../components/products/detail/ProductDetail";
    import { mapState } from "vuex";

    export default {
        name: 'ProductDetail',
        components: {NdxPageFrame, ProductDetailComponent},
        data() {
            return {
                leaving: false
            };
        },
        computed: {
            ...mapState({
                product: state => state.productDetail.product
            })
        },
        watch: {
            '$route.params': {
                handler: 'loadProduct',
                deep: true,
                immediate: true
            }
        },
        beforeRouteLeave() {
            this.leaving = true;
        },
        methods: {
            loadProduct() {
                if(this.leaving) {
                    return;
                }

                let productId = null;
                if (this.$route.params && 'pid' in this.$route.params && +this.$route.params.pid > 0) {
                    productId = this.$route.params.pid;
                }

                if (productId === null || (this.product && productId === this.product.id)) {
                    return;
                }

                this.$store.dispatch('productDetail/find', {
                    productId: productId
                }).then(() => {
                    this.$store.dispatch('productDetail/setDataComplete', true);
                }).catch(() => {
                    this.$store.dispatch('productDetail/setDataComplete', false);
                });
            }
        }
    };
</script>
