import lodashMerge from 'lodash/merge';
import { filterBy, find, update, erase } from "./defaultMethods";

const service = 'calendarEntry';

export default lodashMerge({}, {
    find: find(service),
    update: update(service),
    delete: erase(service),
    filterBy: filterBy(service)
}, {
});
