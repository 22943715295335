<script>
    import CategoryTreeMixin from "./CategoryTreeMixin.vue";

    export default {
        name: "ProductCategoryTreeWidget",
        mixins: [CategoryTreeMixin],
        data() {
            return {
                store: 'productCategories'
            };
        },
        computed: {
            isDetailPage() {
                return this.$route.params
                    && 'pid' in this.$route.params
                    && +this.$route.params.pid > 0;
            }
        },
        methods: {
            gotoCategory(categoryId) {
                this.$router.push({
                    name: 'ProductsInCategory',
                    params: {
                        id: this.$route.params.id,
                        categoryId: categoryId,
                        pid: ''
                    }
                });
            }
        }
    };
</script>
