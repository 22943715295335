import lodashMerge from 'lodash/merge';
import { ndxRpc } from "../utils/ndxRpc";

const service = 'workflowTimeline';

export default lodashMerge({}, {
    getTimeline(instenceId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getTimeline', [instenceId])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
});
