<template>
    <div
        class="ndx-form-element ndxSwitch"
        :class="{
            [variant]: true,
            'disabled': readonly
        }"
    >
        <div class="d-flex align-items-center">
            <div class="label" v-if="label && label.length > 0">{{ label }}</div>
            <div
                class="d-flex align-items-center gap-2"
                @click="click"
                @mouseenter="enter"
                @mouseleave="leave"
            >
                <span
                    class="valueLabel"
                    v-if="!valueLabelRight && valueLabel && valueLabel.length"
                >{{ valueLabel }}</span>
                <div class="boxContainer" tabindex="1" @focusin="focusin" @focusout="focusout">
                    <div :class="{bar: true, checked: checked, readonly: readonly}"></div>
                    <div :class="{circle: true, checked: checked, hover: hover, focus: focus}"></div>
                    <div :class="{handle: true, checked: checked, readonly: readonly}"></div>
                </div>
                <span
                    class="valueLabel"
                    v-if="valueLabelRight && valueLabel && valueLabel.length"
                >{{ valueLabel }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NdxSwitch",
        emits: ['update:modelValue'],
        props: {
            /**
             * Look according to the styleguide.
             * @values outlined, workingArea
             */
            variant: {
                type: String,
                default: 'workingArea',
                validator: function (value) {
                    return ['outlined', 'workingArea'].indexOf(value) !== -1;
                }
            },
            /**
             * Sets the checked state of the form element
             */
            modelValue: {
                type: Boolean
            },
            /**
             * Sets the label visible to the user
             */
            label: {
                type: String,
                required: false
            },
            /**
             * Sets the name attribute of the form element
             */
            name: {
                type: String,
                required: false
            },
            /**
             * Whether the user can edit the content or not
             */
            readonly: {
                type: Boolean
            },
            /**
             * Text indicator of the switch if modelValue === true, could be something like: on | an | ja | aktiv
             */
            onText: {
                type: String
            },
            /**
             * Text indicator of the switch if modelValue === false, could be something like: off | aus | nein | inaktiv
             */
            offText: {
                type: String
            },
            /**
             * sets the value label to the right side of the switch
             */
            valueLabelRight: {
                type: Boolean
            }
        },
        data() {
            return {
                checked: false,
                hover: false,
                focus: false
            };
        },
        computed: {
            valueLabel: function () {
                return this.checked ? this.onTextUse : this.offTextUse;
            },
            onTextUse() {
                return this.onText || this.onText === '' ? this.onText : this.$t('switch.on');
            },
            offTextUse() {
                return this.offText || this.offText === '' ? this.offText : this.$t('switch.off');
            }
        },
        watch: {
            modelValue: {
                immediate: true,
                handler: 'initialize'
            }
        },
        methods: {
            initialize() {
                this.checked = this.modelValue === true;
            },
            click() {
                if (!this.readonly) {
                    this.checked = !this.checked;
                    this.$emit('update:modelValue', this.checked);
                }
            },
            enter() {
                if (!this.readonly) {
                    this.hover = true;
                }
            },
            leave() {
                this.hover = false;
            },
            focusin() {
                if (!this.readonly) {
                    this.focus = true;
                }
            },
            focusout() {
                this.focus = false;
            }
        }
    };
</script>


<style scoped lang="scss">
    @import "../../../style/variables_ndx";

    .boxContainer {
        position: relative;
        width: 54px;
        height: 40px;
        outline: none;
    }

    .circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: left 0.2s;

        &.checked {
            left: auto;
            right: 0;
        }

        &.checked.hover {
            background-color: var(-bs-success);
            opacity: 0.10;
        }

        &:not(.checked).hover {
            background-color: var(--bs-gray-400);
            opacity: 0.10;
        }

        &.checked.focus {
            background-color: var(--bs-success);
            opacity: 0.16;
        }

        &:not(.checked).focus {
            background-color: var(--bs-gray-400);
            opacity: 0.16;
        }
    }

    .bar {
        position: absolute;
        top: 14px;
        left: 11px;
        width: 34px;
        height: 14px;
        border-radius: 7px;
        background-color: var(--bs-gray-100);

        &.readonly {
            background-color: #ebebeb;
        }
    }

    .handle {
        position: absolute;
        top: 10px;
        left: 11px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--bs-gray-100);
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
        transition: left 0.2s;

        &.checked {
            left: auto;
            right: 10px;
            background-color: var(--bs-success);
        }

        &.checked.readonly {
            background-color: #98e797;
        }

        &.readonly {
            background-color: var(--bs-gray-extra-light);
        }

        &:not(.readonly) {
            cursor: pointer;
        }
    }

    .valueLabel {
        @extend %font-body2;

        vertical-align: top;
        line-height: 40px;
        white-space: nowrap;
    }

</style>

