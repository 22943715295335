//state
import basketApi from "../../api/basket";

const state = {
    activeStep: 1,
    isValid: false,
    basketGroups: null,
    loading: true,
    hasCustomCheckoutStep: false,
    customization: []
};

//getters
const getters = {
    checkoutStepsCount(state) {
        const maxSteps = 4;
        return state.hasCustomCheckoutStep ? maxSteps : maxSteps - 1;
    },
    checkoutCustomization(state) {
        return state.customization;
    },
    multiAddressAllowed(state, getters, rootState, rootGetters) {
        const wgcs = rootGetters['shop/getWgcsByType']('orderPayment');
        if (wgcs && wgcs.length > 0) {
            return wgcs[0].config.multiAddressAllowed;
        }
        return false;
    },
};

// actions
const actions = {
    loadingFinished({commit}) {
        commit('SET_LOADING', false);
    },
    setBasketGroups({commit}, data) {
        commit('SET_BASKET_GROUPS', data);
    },
    getBasketGroups({commit}) {
        return new Promise((resolve, reject) => {
            basketApi.getBasketGroups()
                .then((result) => {
                    commit('SET_BASKET_GROUPS', result);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getCustomFields({commit, rootGetters}) {
        const activeLanguage = rootGetters['session/getActiveLanguage'];

        return new Promise((resolve, reject) => {
            basketApi.getCheckoutCustomization(activeLanguage)
                .then((result) => {
                    commit('SET_CUSTOM_FIELDS', result);
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    saveCustomFields({commit, rootGetters}, data) {
        const activeLanguage = rootGetters['session/getActiveLanguage'];

        return new Promise((resolve, reject) => {
            basketApi.saveCheckoutCustomization(data, activeLanguage)
                .then((result) => {
                    commit('SET_CUSTOM_FIELDS', result);
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    previousStep({commit, state}) {
        if (state.activeStep > 1) {
            commit('SET_LOADING', true);
            commit('SET_IS_VALID', false);
            commit('SET_ACTIVE_STEP', state.activeStep - 1);
        } else {
            commit('SET_IS_VALID', false);
        }
    },
    nextStep({commit, state}) {
        if (state.activeStep < 4) {
            commit('SET_LOADING', true);
            commit('SET_IS_VALID', false);
            commit('SET_ACTIVE_STEP', state.activeStep + 1);
        } else {
            commit('SET_IS_VALID', false);
        }
    },
    setIsValid({commit}, isValid) {
        commit('SET_IS_VALID', isValid);
    },
    setHasCustomCheckoutStep({commit}, hasCustomCheckoutStep) {
        commit('SET_HAS_CUSTOM_CHECKOUT_STEP', hasCustomCheckoutStep);
    },
    reset({commit}) {
        commit('RESET');
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.activeStep = 1;
        state.isValid = false;
        state.basketGroups = null;
        state.loading = true;
        state.hasCustomCheckoutStep = false;
        state.customization = [];
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_BASKET_GROUPS(state, basketGroups) {
        state.basketGroups = basketGroups;
    },
    SET_ACTIVE_STEP(state, step) {
        state.activeStep = step;
    },
    SET_IS_VALID(state, isValid) {
        state.isValid = isValid;
    },
    SET_HAS_CUSTOM_CHECKOUT_STEP(state, hasCustomCheckoutStep) {
        state.hasCustomCheckoutStep = hasCustomCheckoutStep;
    },
    SET_CUSTOM_FIELDS(state, groupsAndFields) {
        state.customization = groupsAndFields;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
