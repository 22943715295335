<template>
    <NdxPageHeader
        v-if="!isDataCheck"
        hide-filter
        hide-view-mode
        :nav-name="getLabel('User', this)"
        :breadcrumbs="breadcrumbs"
    />
    <div
        class="myAccount-data-container"
        v-if="initiated && user !== null && showUserTab"
    >
        <div v-if="isDataCheck" class="page-label">{{ getLabel('User', this) }}</div>

        <div class="myAccount-data">
            <template
                v-for="item of list"
                :key="item.id"
            >
                <div v-if="item.type === 'headline'" class="myAccount-data-headline">
                    {{ item.label }}
                </div>

                <div v-else-if="item.type === 'NDX_ADDRESS'" class="myAccount-data-item">
                    <div
                        class="control has-editor"
                        @click="onEditAddress(item.userDefinedName)"
                    >
                        <div class="key">
                            {{ item.userDefinedName }}
                            <div v-if="!mainAddressIsValid" class="key invalid p-0 pt-2">
                                {{ $t('message.invalidAddressError') }}
                            </div>
                        </div>
                        <div class="value-wrapper">
                            <div class="value">
                                <div
                                    class="text-end preserveLineBreaks"
                                >
                                    {{ formatAddress(mainAddress) }}
                                </div>
                            </div>
                            <NdxIcon class="editor-toggle" icon="drop-right"/>
                        </div>
                    </div>
                </div>
                <DataItem
                    v-else-if="item.referencedEntity === 'mainAddress'"
                    :item="item"
                    type="user"
                    v-model="mainAddress"
                    @is-valid="(val) => validate(item.id,val)"
                />
                <DataItem
                    v-else
                    :item="item"
                    type="user"
                    v-model="user"
                    @is-valid="(val) => validate(item.id,val)"
                />
            </template>

            <template v-if="showUserAttributes">
                <div class="myAccount-data-headline">
                    {{ getLabelUserAttributes }}
                </div>
                <DataItem
                    v-for="item of user.attributes"
                    :key="item.attributeDef.id"
                    :item="item"
                    type="userAttribute"
                    v-model="user"
                    @is-valid="(val) => validate(item.key,val)"
                />
            </template>
        </div>

        <div class="form-actions">
            <NdxButtonLink
                v-if="hasEditableItems('user')"
                @click="resetData"
                :disabled="!isDirty"
            >
                {{ $t('btn.cancel') }}
            </NdxButtonLink>
            <NdxButton
                v-if="isDataCheck || hasEditableItems('user')"
                @click="submitUserForm"
                :disabled="submitBtnDisabled"
            >
                {{ isDataCheck ? $t('btn.save_continue') : $t('btn.save') }}
            </NdxButton>
        </div>
    </div>

    <NdxFlyIn v-if="showAddressEditor">
        <template #title>{{ editAddressTitle }}</template>
        <template #default>
            <AddressForm
                v-model:address="mainAddress"
                address-book="user"
            />
        </template>
        <template #buttons>
            <NdxButtonLink @click="closeAddressEditor">
                {{ isAddressEditingAllowed ? $t('btn.cancel') : $t('btn.close') }}
            </NdxButtonLink>
            <NdxButton
                v-if="isAddressEditingAllowed"
                @click="saveAddress"
                :disabled="!mainAddressIsValid"
            >
                {{ $t('btn.save') }}
            </NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import DataItem from "./dataItem/DataItem";
    import lodashClone from "lodash/cloneDeep";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxButton from "../library/NdxButton";
    import NdxIcon from "../library/NdxIcon";
    import { AddressFormatter } from "../../plugins/formatter";
    import NdxFlyIn from "../library/NdxFlyIn";
    import AddressForm from "../checkout/AddressForm";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    const PROPS = {
        'data': [
            'avatar', 'username', 'locale',
            'mainAddress_gender', 'mainAddress_firstname', 'mainAddress_lastname', 'mainAddress_position',
            'mainAddress_academicTitle'
        ],
        'address': ['NDX_ADDRESS'],
        'contact': ['email', 'phone', 'phoneBusiness', 'mobile', 'fax', 'faxBusiness']
    };

    export default {
        name: 'UserData',
        components: {NdxPageHeader, AddressForm, NdxFlyIn, NdxIcon, NdxButton, NdxButtonLink, DataItem},
        props: {
            isDataCheck: {
                type: Boolean
            }
        },
        emits: ['on-saved'],
        data() {
            return {
                user: null,
                mainAddress: null,

                showAddressEditor: false,
                editAddressTitle: '',

                invalidItems: []
            };
        },
        computed: {
            ...mapState({
                storeUser: state => state.user.user,
                initiated: state => state.myAccount.initiated,
                labelUser: state => state.myAccount.labelUser,
                labelUserData: state => state.myAccount.labelUserData,
                labelUserAddress: state => state.myAccount.labelUserAddress,
                labelUserContact: state => state.myAccount.labelUserContact
            }),
            ...mapGetters('user', {
                storeMainAddress: 'mainAddress'
            }),
            ...mapGetters('myAccount', [
                'getItem',
                'showUserTab',
                'showUserAttributes',
                'hasOneItem',
                'hasEditableItems',
                'getLabel',
                'addressProps',
                'invalidAddressItems'
            ]),
            getLabelUserData() {
                return this.labelUserData && this.labelUserData.length
                    ? this.labelUserData
                    : this.$t('contact.label.user_data');
            },
            getLabelUserContact() {
                return this.labelUserContact && this.labelUserContact.length
                    ? this.labelUserContact
                    : this.$t('contact.label.user_contact');
            },
            getLabelUserAddress() {
                return this.labelUserAddress && this.labelUserAddress.length
                    ? this.labelUserAddress
                    : this.$t('contact.label.user_address');
            },
            getLabelUserAttributes() {
                return this.$t('contact.label.user_attributes');
            },
            list() {
                let list = [];

                for (let key in PROPS) {
                    if (key === 'address') {
                        if (this.getAddressProps().length > 0) {
                            list.push({
                                label: this.getLabelUserAddress,
                                type: 'headline'
                            });
                            list.push({
                                userDefinedName: this.getLabelUserAddress,
                                type: 'NDX_ADDRESS'
                            });
                        }
                    } else if (['contact', 'data'].includes(key)) {
                        if (this.hasOneItem('user', 'contact', PROPS[key]) ||
                            this.hasOneItem('user', 'entity', PROPS[key])
                        ) {
                            list.push({
                                label: key === 'data' ? this.getLabelUserData : this.getLabelUserContact,
                                type: 'headline'
                            });
                            for (let item of PROPS[key]) {
                                let tmp;
                                if (item.startsWith('mainAddress_')) {
                                    tmp = this.getItem(
                                        'user', 'mainAddress', item.split('mainAddress_')[1]
                                    );
                                } else {
                                    tmp = this.getItem('user', 'contact', item);
                                    if (!tmp) {
                                        tmp = this.getItem('user', 'entity', item);
                                    }

                                }
                                if (tmp) {
                                    list.push(tmp);
                                }
                            }
                        }
                    }
                }

                return list;
            },
            userIsDirty() {
                const local = JSON.stringify(this.user);
                const org = JSON.stringify(this.storeUser);

                return local !== org;
            },
            mainAddressIsDirty() {
                const local = JSON.stringify(this.mainAddress);
                const org = JSON.stringify(this.storeMainAddress);

                return local !== org;
            },
            isDirty() {
                return this.userIsDirty || this.mainAddressIsDirty;
            },
            isAddressEditingAllowed() {
                for (let prop of this.getAddressProps()) {
                    const item = this.getItem('user', 'mainAddress', prop);
                    if (item && item.editState > 1) {
                        return true;
                    }
                }
                return false;
            },
            submitBtnDisabled() {
                return (!this.isDirty && !this.isDataCheck) ||
                    this.invalidItems.length > 0 ||
                    !this.mainAddressIsValid;
            },
            mainAddressIsValid() {
                return this.invalidAddressItems(
                    this.mainAddress,
                    this.mainAddress.addressTypes.map(item => item.addressType),
                    'user'
                ).length === 0;
            },
            breadcrumbs() {
                return [{
                    label: this.$t('label.myAccount'),
                    click: () => this.$router.push({name: 'MyAccount'})
                }, {
                    label: this.getLabel('User', this)
                }];
            }
        },
        created() {
            this.$store.dispatch('myAccount/getDataCheckConfig');
        },
        mounted() {
            this.resetData();
        },
        methods: {
            getAddressProps() {
                return this.addressProps('user', ['main']);
            },
            resetData() {
                this.user = lodashClone(this.storeUser);
                this.mainAddress = lodashClone(this.storeMainAddress);
            },
            submitUserForm() {
                this._updateUser()
                    .then(() => {
                       this._updateMainAddress()
                           .then(() => {
                               this.resetData();
                               this.$emit('on-saved');
                           })
                           .catch((error) => {
                               console.error(error);
                           });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            _updateUser() {
                return new Promise((resolve, reject) => {
                    if (this.userIsDirty) {
                        this.$store.dispatch('user/updateUser', this.user)
                            .then(() => {
                                resolve();
                            }).catch((error) => {
                                reject(error);
                            });
                    } else {
                        resolve();
                    }
                });
            },
            _updateMainAddress() {
                return new Promise((resolve, reject) => {
                    if (this.mainAddressIsDirty && this.mainAddressIsValid) {
                        this.$store.dispatch('user/updateUserAddress', {
                            addressData: this.mainAddress,
                            addressType: 'main'
                        }).then(() => {
                            resolve();
                        }).catch((error) => {
                            reject(error);
                        });
                    } else {
                        resolve();
                    }
                });
            },

            formatAddress(address) {
                return AddressFormatter(address, this, "\n");
            },
            onEditAddress(title) {
                this.editAddressTitle = title;
                this.showAddressEditor = true;
            },
            closeAddressEditor() {
                this.showAddressEditor = false;
                this.editAddressTitle = '';
                this.resetData();
            },
            saveAddress() {
                if (!this.mainAddressIsValid) {
                    return;
                }

                this._updateMainAddress()
                    .then(() => {
                        this.closeAddressEditor();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            validate(key, isValid) {
                let idx = this.invalidItems.indexOf(key);
                if (isValid && idx > -1) {
                    this.invalidItems.splice(idx, 1);
                } else if (!isValid && idx < 0) {
                    this.invalidItems.push(key);
                }
            }
        }
    };
</script>
