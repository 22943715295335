<template>
    <LoginWireframe>
        <template #form>
            <div v-if="showAuthConfigs" class="w-100">
                <NdxNotification
                    class="mb-4 w-100"
                    :message="loginErrorMsg"
                    v-model="showLoginErrorMsg"
                    variant="error"
                />

                <NdxButton
                    v-for="authConfig in authConfigs"
                    :key="authConfig.id"
                    class="w-100"
                    :style="btnStyle(authConfig)"
                    @click="onClick(authConfig)"
                >
                    <template #icon>
                        <img
                            v-if="authConfig.icon && authConfig.icon.filename"
                            :src="'/ndx/image/resize/24/24/' + authConfig.icon.filename"
                            :alt="getDisplayName(authConfig)"
                        >
                        <NdxIcon
                            v-else-if="authConfig.authId === userPasswordAuth"
                            icon="single-sign-on"
                            size="m"
                        />
                    </template>

                    {{ getDisplayName(authConfig) }}
                </NdxButton>
            </div>
            <LoginForm :show-back-btn="hasAuthConfigs" @do-cancel="showClassicLogin = false" v-else/>
        </template>
    </LoginWireframe>
</template>

<script>
    import LoginWireframe from '../../wireframes/NdxLogin';
    import LoginForm from '../../components/login/Login';
    import { mapState } from "vuex";
    import NdxIcon from "../../components/library/NdxIcon";
    import NdxButton from "../../components/library/NdxButton";
    import NdxNotification from "../../components/library/NdxNotification.vue";

    export default {
        name: 'Login',
        components: {NdxNotification, NdxButton, NdxIcon, LoginWireframe, LoginForm},
        data() {
            return {
                showClassicLogin: false,
                userPasswordAuth: 'ndx'
            };
        },
        computed: {
            ...mapState({
                authConfigs: state => state.session.authConfigs,
                loginError: state => state.session.loginError,
            }),
            hasAuthConfigs() {
                for (let config of this.authConfigs) {
                    if (config.authId !== this.userPasswordAuth) {
                        return true;
                    }
                }
                return false;
            },
            showAuthConfigs() {
                return this.hasAuthConfigs && !this.showClassicLogin;
            },
            showLoginErrorMsg: {
                get() {
                    return this.loginError !== null;
                },
                set(val) {
                    if (!val) {
                        this.$store.dispatch('session/clearLoginError');
                    }
                }
            },
            loginErrorMsg() {
                return this.$store.getters['session/getLoginErrorMsg'](this.$t, this.$te);
            }
        },
        methods: {
            getDisplayName(authConfig) {
                if (authConfig.authId === this.userPasswordAuth) {
                    return this.$t('btn.login_classic');
                }
                return authConfig.displayName;
            },
            onClick(authConfig) {
                this.$store.dispatch('session/clearLoginError');

                if (authConfig.authId === this.userPasswordAuth) {
                    this.showClassicLogin = true;
                } else {
                    window.location.href =
                        window.location.protocol + '//' +
                        window.location.host + '/pidp?' +
                        'authId=' + authConfig.authId;
                }
            },
            btnStyle(authConfig) {
                let styles = [];

                if (authConfig.color) {
                    styles.push('background-color: ' + authConfig.color);
                }

                if (authConfig.fontcolor) {
                    styles.push('color: ' + authConfig.fontcolor);
                }

                return styles.join(';');
            }
        }
    };
</script>

<style scoped>
    .btn + .btn {
        margin-top: 8px;
    }
</style>
