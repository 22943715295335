<template>
    <NdxNotification
        v-if="statusMsg"
        :variant="variant"
        :model-value="true"
        :duration="0"
        :message="statusMsg"
        :call-to-action="callToAction"
        @call-to-action="callToAction ? callToAction.action() : () => {}"
    />
</template>

<script>
    import NdxNotification from "../library/NdxNotification.vue";

    export default {
        name: "WorkflowInfo",
        components: {NdxNotification},
        props: {
            variables: null,
            startApprovalPossible: {
                type: Boolean
            },
            showInstanceDetails: {
                type: Boolean
            },
            moveToBasket: {
                type: Boolean
            },
            moveToWatchlist: {
                type: Boolean
            }
        },
        emits: ['start-approval', 'show-details', 'move-to-basket', 'push-to-watchlist', 'action-type'],
        computed: {
            variant() {
                if (this.verdict === 'reject') {
                    return 'error';
                }
                if (this.verdict === 'approve') {
                    return 'success';
                }
                if (this.verdict === 'revision') {
                    return 'warning';
                }
                return 'info';
            },
            statusMsg() {
                if (this.verdict === 'reject') {
                    return this.$t('label.workflow.infoReject');
                }
                if (this.verdict === 'approve') {
                    return this.$t('label.workflow.infoApprove');
                }
                if (this.verdict === 'revision') {
                    return this.$t('label.workflow.infoRevision');
                }
                if (this.verdict === 'running' || this.executeApproval) {
                    return this.$t('label.workflow.infoInProgress');
                }
                if (this.approvalRequired) {
                    return this.$t('label.workflow.infoApprovalRequired');
                }
                return null;
            },
            approvalRequired() {
                return this.getValueFromVariables('approvalRequired', false);
            },
            executeApproval() {
                return this.getValueFromVariables('executeApproval', false);
            },
            verdict() {
                return this.getValueFromVariables('verdict', 'none');
            },
            remark() {
                return this.getValueFromVariables('remark', null);
            },
            callToAction() {
                if (this.verdict === 'approve' && this.moveToBasket) {
                    return {
                        text: this.$t('label.addToBasket'),
                        type: 'move-to-basket',
                        action: () => {
                            this.$emit('move-to-basket');
                        }
                    };
                } else if (!this.moveToBasket && this.moveToWatchlist &&
                    (this.verdict === 'running' || this.executeApproval)
                ) {
                    return {
                        text: this.$t('btn.pushToWatchlist'),
                        type: 'move-to-watchlist',
                        action: () => {
                            this.$emit('push-to-watchlist');
                        }
                    };
                } else if (this.showInstanceDetails) {
                    return {
                        text: this.$t('btn.workflow.instanceDetails'),
                        type: 'show-details',
                        action: () => {
                            this.$emit('show-details');
                        }
                    };
                } else if (this.startApprovalPossible) {
                    return {
                        text: this.$t('btn.workflow.startApproval'),
                        type: 'start-approval',
                        action: () => {
                            this.$emit('start-approval');
                        }
                    };
                }
                return null;
            }
        },
        watch: {
            callToAction: {
                immediate: true,
                deep: true,
                handler: function () {
                    this.$emit('action-type', this.callToAction !== null ? this.callToAction.type : null);
                }
            }
        },
        methods: {
            getValueFromVariables(name, defaultValue) {
                if (!this.variables) {
                    return defaultValue;
                }
                for (let prop in this.variables) {
                    if (prop === name) {
                        return this.variables[name].value;
                    }
                }
                return defaultValue;
            }
        }
    };

</script>
