<template>
    <NdxPageHeader
        hide-view-mode
        :breadcrumbs="breadcrumbs"
        :active-route-callback="() => $router.push({name: 'Budgets'})"
        :nav-name="$t('label.budget_categories')"
        :active-filter="activeFilter"
        v-model:show-filter="showFilter"
    />

    <NdxFlyIn v-if="showFilter" class="limit500">
        <template #title>
            {{ $t('label.filterHeadline') }}
        </template>
        <template #default>
            <div class="d-flex gap-3 flex-column">
                <NdxSelect
                    :options="listTypeOptions"
                    :text-wrap="true"
                    v-model="listType"
                    variant="secondary"
                />
            </div>
        </template>
        <template #buttons>
            <NdxButtonLink
                class="btnFlex"
                @click="clearFilter"
            >
                {{ $t('btn.reset') }}
            </NdxButtonLink>
            <NdxButton class="btnFlex" @click="showFilter = false">
                {{ $t('btn.close') }}
            </NdxButton>
        </template>
    </NdxFlyIn>

    <div v-if="!loading && list.length === 0" class="empty-result">
        {{ $t('message.budgets_no_result') }}
    </div>

    <div v-if="!loading && list.length > 0" class="list-wrapper">
        <div
            class="budget-item"
            v-for="budget in list"
            :key="budget.id"
        >
            <div class="info-wrapper">
                <div>
                    <div class="name">{{ budget.name }}</div>
                    <div
                        v-if="budget.description && budget.description.length > 0"
                        class="description">
                        {{ budget.description }}
                    </div>
                    <div class="badge-container">
                        <NdxBadge variant="primary" size="s">{{ $t('label.budget_type_' + budget.modus) }}</NdxBadge>
                    </div>
                </div>
                <div class="chartContainer">
                    <BudgetChart :budget="budget"/>
                </div>
            </div>
            <div class="key-value" v-if="budget.validFrom">
                <div class="key">{{ $t('label.validFrom') }}</div>
                <div class="value">{{ $d(dateConverter(budget.validFrom), 'short') }}</div>
            </div>
            <div class="key-value" v-if="budget.validTo">
                <div class="key">{{ $t('label.validTill') }}</div>
                <div class="value">{{ $d(dateConverter(budget.validTo), 'short') }}</div>
            </div>
            <div class="key-value">
                <div class="key">{{ $t('label.budget') }}</div>
                <div class="value">{{ formatPrice(budget.totalValue) }}</div>
            </div>
            <div class="key-value">
                <div class="key">{{ $t('label.usedAmount') }}</div>
                <div class="value gray">{{ formatPrice(budget.usedValue) }}</div>
            </div>
            <div class="key-value">
                <div class="key">{{ $t('label.remainingAmount') }}</div>
                <div class="value green">{{ formatPrice(budget.remainingValue) }}</div>
            </div>
            <div class="key-value">
                <div class="key"></div>
                <div class="value">
                    <NdxButtonLink
                        @click="showOptions(budget)"
                        class="pe-0"
                    >
                        {{ $t('btn.options') }}
                    </NdxButtonLink>
                </div>
            </div>
        </div>
    </div>

    <NdxFlyIn v-if="activeBudget !== null">
        <template #title>
            {{ activeBudget.name }}
        </template>
        <template #default>
            <template v-if="showOrders">
                {{ $t('label.budget_orders') }}
                <BudgetOrders :budget="activeBudget"/>
            </template>
            <template v-else-if="showRequest">
                {{ $t('label.budget_request') }}
                <BudgetRequest
                    ref="budgetRequest"
                    :budget="activeBudget"
                    @request-success="onRequestSuccess"
                />
            </template>
            <template v-else>
                {{ $t('btn.options') }}
            </template>
        </template>
        <template #buttons>
            <template v-if="showOrders">
                <NdxButton @click="showOrders = false">{{ $t('btn.done') }}</NdxButton>
            </template>
            <template v-else-if="showRequest && requestSuccess">
                <NdxButton @click="showRequest = false; requestSuccess = false">{{ $t('btn.done') }}</NdxButton>
            </template>
            <template v-else-if="showRequest && !requestSuccess">
                <NdxButtonLink @click="showRequest = false">{{ $t('btn.cancel') }}</NdxButtonLink>
                <NdxButton @click="doRequest">{{ $t('btn.request') }}</NdxButton>
            </template>
            <template v-else>
                <NdxButtonLink @click="showProducts">{{ $t('label.showProducts') }}</NdxButtonLink>
                <NdxButtonLink @click="showOrders = true">{{ $t('label.budget_orders') }}</NdxButtonLink>
                <NdxButtonLink
                    v-if="activeBudget.voucherRequestAllowed"
                    @click="showRequest = true"
                >
                    {{ $t('label.budget_request') }}
                </NdxButtonLink>
                <NdxButton @click="closeOptions">{{ $t('btn.close') }}</NdxButton>
            </template>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxButton from "../library/NdxButton";
    import { mapGetters } from "vuex";
    import { PriceFormatter } from "../../plugins/formatter";
    import { ndxDateConvert } from "@utilities/ndxDate";
    import BudgetChart from "./BudgetChart";
    import NdxBadge from "../library/NdxBadge";
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxButtonLink from "../library/NdxButtonLink";
    import BudgetOrders from "./BudgetOrders";
    import BudgetRequest from "./BudgetRequest";
    import NdxSelect from "../library/formElements/NdxSelect.vue";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: 'BudgetCategories',
        components: {
            NdxPageHeader, NdxSelect, BudgetRequest, BudgetOrders, NdxButtonLink,
            NdxFlyIn, NdxBadge, BudgetChart, NdxButton
        },
        data() {
            return {
                dateConverter: ndxDateConvert,
                loading: true,

                listType: 'active',
                listTypeOptions: [{
                    text: this.$t('label.budgets_active'),
                    value: 'active'
                }, {
                    text: this.$t('label.budgets_inactive'),
                    value: 'inactive'
                }],

                list: [],
                limit: 9999,
                offset: 0,

                activeBudget: null,
                showOrders: false,

                showRequest: false,
                requestSuccess: false,

                showFilter: false
            };
        },
        computed: {
            ...mapGetters('shop', [
                'currency'
            ]),
            activeFilter() {
                return this.listType !== 'active';
            },
            breadcrumbs() {
                return [{
                    label: this.$t('label.budget_categories'),
                    click: this.activeFilter ? () => this.clearFilter() : null
                }];
            }
        },
        watch: {
            listType() {
                this.offset = 0;
                this.loadBudgets();
            }
        },
        mounted() {
            this.loadBudgets();
        },
        methods: {
            loadBudgets() {
                this.loading = true;

                this.$store.dispatch('budgets/getBudgets', {
                    type: this.listType,
                    limit: this.limit,
                    offset: this.offset
                }).then((result) => {
                    this.list = result;
                }).catch((error) => {
                    console.error(error);
                }).finally(() => {
                    this.loading = false;
                });
            },
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },

            showOptions(budget) {
                this.activeBudget = budget;
            },
            closeOptions() {
                this.activeBudget = null;
                this.showOrders = false;
                this.showRequest = false;
            },
            showProducts() {
                this.$router.push({
                    name: 'BudgetProducts',
                    params: {
                        id: this.$route.params.id,
                        budgetId: this.activeBudget ? this.activeBudget.id : ''
                    }
                });
            },
            doRequest() {
                this.$refs.budgetRequest.sendRequest();
            },
            onRequestSuccess() {
                this.requestSuccess = true;
            },

            clearFilter() {
                this.showFilter = false;
                this.listType = 'active';
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";

    .btn-pill + .btn-pill {
        margin-left: 8px;
    }

    .empty-result {
        @extend %font-caption;
        margin-top: 32px;
    }

    .list-wrapper {
        .budget-item {
            border: 2px solid var(--bs-gray-middle);
            border-radius: 4px;
            margin-top: 16px;

            .info-wrapper {
                padding: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .description {
                    @extend %font-caption;
                }

                .chartContainer {
                    min-width: 60px;
                    width: 60px;
                    min-height: 60px;
                    height: 60px;
                    max-height: 60px;
                    overflow: hidden;

                    align-self: flex-start;
                    margin-left: 16px;
                }

                .badge-container {
                    display: flex;
                    margin-top: 16px;
                }
            }

            .key-value {
                border-top: 1px solid var(--bs-gray-middle);
                padding: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .key {
                    @extend %font-body2;
                    color: var(--bs-disabled);
                }

                .value {
                    @extend %font-h3;

                    &.green {
                        color: var(--bs-success);
                    }

                    &.gray {
                        color: var(--bs-gray-600);
                    }
                }
            }
        }
    }
</style>
