<template>
    <div class="workflowlist-item">
        <div>
            <div class="workflowlist-item-info">
                <div class="name">{{ task.name }}</div>
                <div class="keyValues">
                    <div>
                        <span>{{ $t('label.requestedBy') }}: </span>
                        {{ task.businessObject.createdBy.username }}
                    </div>
                    <div>
                        <span>{{ $t('label.requestedAt') }}: </span>
                        {{ $d(dateConverter(task.createdAt), 'long') }}
                    </div>
                    <div class="mt-2">
                        <span>{{ $t('label.assignee') }}:</span>
                        {{ task.assignee ? task.assignee.username : '' }}
                    </div>
                    <div v-if="'endedAt' in task && task.endedAt">
                        <span>{{ $t('label.endedAt') }}: </span>
                        {{ $d(dateConverter(task.endedAt), 'long') }}
                    </div>
                </div>
            </div>
        </div>

        <BusinessObjectViewer
            class="separatorTop mt-3 pt-3"
            :business-object="task.businessObject"
            compact
        />

        <div class="d-flex align-items-end justify-content-between gap-3 pt-3">
            <div class="d-flex gap-3">
                <NdxButtonLink
                    v-if="!isCompleted && !task.assignee"
                    class="ps-0"
                    @click="claim"
                >
                    {{ $t('btn.claim') }}
                </NdxButtonLink>

                <NdxButtonLink
                    v-if="!(isCompleted || !assignedToCurrentUser)"
                    class="ps-0"
                    @click="editTask"
                >
                    {{ $t('btn.edit') }}
                </NdxButtonLink>

                <NdxButtonLink
                    v-if="!isCompleted && assignedToCurrentUser"
                    class="ps-0"
                    @click="unclaim"
                >
                    {{ $t('btn.unclaim') }}
                </NdxButtonLink>
            </div>

            <NdxButtonLink
                v-if="isCompleted || !assignedToCurrentUser"
                class="ps-0"
                @click="editTask"
            >
                {{ $t('btn.details') }}
            </NdxButtonLink>
        </div>
    </div>
</template>

<script>
    import NdxButtonLink from "../library/NdxButtonLink.vue";
    import { ndxDateConvert } from "@utilities/ndxDate";
    import { mapGetters } from "vuex";
    import BusinessObjectViewer from "./businessObject/BusinessObjectViewer.vue";

    export default {
        name: 'TaskListItem',
        components: {BusinessObjectViewer, NdxButtonLink},
        props: {
            task: {
                type: Object
            }
        },
        emits: ['update-task'],
        data() {
            return {
                dateConverter: ndxDateConvert
            };
        },
        computed: {
            ...mapGetters('shop', [
                'currency'
            ]),
            ...mapGetters('user', [
                'currentUserId'
            ]),
            isCompleted() {
                return 'endedAt' in this.task && this.task.endedAt !== null;
            },
            assignedToCurrentUser() {
                return this.task.assignee && this.task.assignee.id === this.currentUserId;
            }
        },
        methods: {
            editTask() {
                this.$router.push({
                    name: 'WorkflowsTaskDetail',
                    params: {taskId: this.task.id}
                });
            },
            claim() {
                this.$store.dispatch('workflowTask/claim', {
                    taskId: this.task.id,
                    userId: this.$store.getters['user/currentUserId']
                }).then((updatedTask) => {
                    this.$emit('update-task', updatedTask);
                }).catch((error) => {
                    console.error(error);
                });
            },
            unclaim() {
                this.$store.dispatch('workflowTask/unclaim', {
                    taskId: this.task.id
                }).then((updatedTask) => {
                    this.$emit('update-task', updatedTask);
                }).catch((error) => {
                    console.error(error);
                });
            }
        }
    };
</script>
