<template>
    <div class="requestWrapper">
        <div v-if="requestSuccess" class="successWraper">
            {{ $t('message.budget_request_success') }}
        </div>
        <div v-else class="ndxFormWrapper">
            <NdxInput
                :label="$t('label.budget_request_value', {currency: currency})"
                v-model="value"
                :invalid="!isValid"
            />
            <NdxTextarea
                :label="$t('label.news.message')"
                v-model="message"
            />
        </div>
    </div>
</template>

<script>
    import NdxInput from "../library/formElements/NdxInput";
    import NdxTextarea from "../library/formElements/NdxTextarea";
    import { newsTypes } from "../../store/modules/news";
    import { mapState } from "vuex";

    export default {
        name: 'BudgetRequest',
        components: {NdxTextarea, NdxInput},
        props: {
            budget: {
                type: Object
            }
        },
        emits: ['request-success'],
        data() {
            return {
                value: 0,
                message: '',
                requestSuccess: false
            };
        },
        computed: {
            ...mapState({
                currency: state => state.shop.currency,
                shopId: state => state.session.shopId,
                shopClient: state => state.user.shopClient,
                user: state => state.user.user
            }),
            isValid() {
                return parseInt(this.value, 10) > 0;
            }
        },
        methods: {
            sendRequest() {
                if (this.isValid) {
                    const msg = 'Angeforderter Betrag: ' + this.value + ' ' + this.currency + "\n\n" +
                        'BudgetId: ' + this.budget.id + "\n\n" +
                        'MandantenId: ' + this.shopClient.id + "\n\n" +
                        this.message;

                    this.$store.dispatch('news/create', {
                        newsData: {
                            title: 'Bitte um Budgetanpassung',
                            content: msg,
                            affectedShops: [this.shopId],
                            startDate: null,
                            dueDate: null,
                            readByLoggedUser: true,
                            allowReply: true,
                            replyTo: null,
                            type: newsTypes.voucherRequest,
                            files: [],
                            appData: {
                                voucher: this.budget.id,
                                newValue: this.value,
                                currency: this.currency,
                                shopClientId: this.shopClient.id,
                                userId: this.user.id,
                                username: this.user.username
                            }
                        },
                        threadStartId: null
                    }).then(() => {
                        this.requestSuccess = true;
                        this.$emit('request-success');
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            }
        }
    };
</script>

<style scoped>
    .requestWrapper {
        margin-top: 16px;
    }
</style>
