import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

export const filterBy = (service) => {
    /**
     *
     * @param {Object} criteria = {} - Criterias for filtering
     * @param {string} [criteria.searchText = ""] - Search term entered by user
     * @param {number} offset = 0 - starting point in result set
     * @param {number} limit = 25 - number of result entries maximum returned
     * @param {string} orderField = "id" - Attribute to sort result set
     * @param {boolean} ascending = true - sorting direction
     * @param {Object} customParams = {} - additional parameter for the filter
     * @param {string} throbberRule = throbberRules.CONTENTAREA - Throbber to show while fetching result
     *
     * @returns {Promise<Object>}
     */
    return function (criteria = {}, offset = 0, limit = 25, orderField = 'id', ascending = true,
              customParams = {}, throbberRule = throbberRules.FULLSCREEN
    ) {
        let params = criteria;
        params.offset = offset;
        params.limit = limit;
        params.order = orderField;
        params.ascending = ascending;
        params = Object.assign(params, customParams);
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'filterBy', [params], throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
};

export const find = (service) => {
    return function(entityId, throbberRule = throbberRules.FULLSCREEN) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'find', [+entityId], throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
};

export const update = (service) => {
    return function (data, throbberRule = throbberRules.FULLSCREEN) {
        const method = data.id && +data.id > 0 ? 'update' : 'create';
        return new Promise((resolve, reject) => {
            ndxRpc(service, method, [data], throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
};

export const erase = (service) => {
    return function  (entityId, throbberRule = throbberRules.FULLSCREEN) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'delete', [+entityId], throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
};
