<template>
    <NdxPageFrame>
        <WatchlistComponent/>
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import WatchlistComponent from "../../components/watchlist/Watchlist";

    export default {
        name: 'Watchlist',
        components: {NdxPageFrame, WatchlistComponent},
    };
</script>
