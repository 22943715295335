<script>
    export default {
        name: "NewsMixin",
        data() {
            return {
                thread: null,
            };
        },
        methods: {
            getCite(news) {
                for (const newsId in this.thread) {
                    for (const newsIdx in this.thread[newsId]) {
                        const replied = this.thread[newsId][newsIdx].replys.filter(
                            reply => reply.id === news.id
                        ).length > 0;

                        if (replied) {
                            const cite = this.thread[newsId][newsIdx];
                            const renderIdx1 = this.renderSequence.findIndex(msg => msg.id === cite.id);
                            const renderIdx2 = this.renderSequence.findIndex(msg => msg.id === news.id);

                            if (renderIdx1 + 1 === renderIdx2) {
                                return null;
                            }

                            return cite;
                        }
                    }
                }
                return null;
            }
        }
    };
</script>
