<template>
    <div
        class="product-groupItem"
        :id="'orderitem-' + item.orderItemId"
        v-scroll-into-view="{route: $route}"
    >
        <div class="d-flex flex-column gap-3">
            <template v-if="!isBundleItemDetail">
                <WorkflowInfo
                    v-if="workflowData && workflowData.instanceId !== null && workflowData.instanceVariables !== null"
                    :variables="workflowData.instanceVariables"
                    :start-approval-possible="workflowData.startApprovalPossible"
                    :show-instance-details="workflowData.showInstanceDetails"
                    :move-to-watchlist="!bundlePath"
                    @start-approval="$emit('start-approval')"
                    @show-details="$emit('instance-details')"
                    @push-to-watchlist="$emit('push-to-watchlist')"
                    @action-type="(type) => workflowActionType = type"
                />
                <NdxNotification
                    v-if="invalidProductFeatures"
                    variant="warning"
                    :model-value="true"
                    :duration="0"
                    :message="$t('message.basketItem_not_orderable_reason_product_features')"
                    :call-to-action="{text: $t('btn.edit')}"
                    @call-to-action="$emit('goto-detailpage')"
                />
                <NdxNotification
                    v-if="!isSummary && campaignRunDeleted"
                    variant="error"
                    :model-value="true"
                    :duration="0"
                    :message="$t('message.basketItem_not_orderable_reason_cr_deleted')"
                    :call-to-action="{text: $t('btn.delete')}"
                    @call-to-action="$emit('remove-from-basket')"
                />
                <NdxNotification
                    v-else-if="!isSummary && campaignRunNotReady"
                    variant="warning"
                    :model-value="true"
                    :duration="0"
                    :message="$t('message.basketItem_not_orderable_reason_cr_not_ready')"
                    :call-to-action="{text: $t('btn.editCustomization')}"
                    @call-to-action="$emit('goto-campaignrun')"
                />
                <NdxNotification
                    v-else-if="!isSummary && detailedInfos && !detailedInfos.orderableMinimalCheck"
                    variant="error"
                    :model-value="true"
                    :duration="0"
                    :message="$t('message.basketItem_not_orderable_please_delete')"
                    :call-to-action="!hideCallToActions ? {text: $t('btn.removeFromBasket')} : null"
                    @call-to-action="$emit('remove-from-basket')"
                />
                <NdxNotification
                    v-if="!isSummary && (detailedInfos?.campaignRun?.hasPortalLock ?? false)"
                    variant="error"
                    :model-value="true"
                    :duration="0"
                    :message="portalLockMessage"
                    :call-to-action="!hideCallToActions ? {text: $t('btn.pushToWatchlist')} : null"
                    @call-to-action="$emit('push-to-watchlist')"
                />
                <NdxNotification
                    v-if="isSummary && detailedInfos && !detailedInfos.orderable"
                    variant="error"
                    :model-value="true"
                    :duration="0"
                    :message="$t('message.basketItem_not_orderable')"
                />
                <NdxNotification
                    v-if="!isSummary && isStockLimitReached"
                    variant="error"
                    :model-value="true"
                    :duration="0"
                    :message="$t('message.basketItem_not_orderable_reason_stock')"
                    :call-to-action="!hideCallToActions ? {text: $t('btn.pushToWatchlist')} : null"
                    @call-to-action="$emit('push-to-watchlist')"
                />
            </template>
            <NdxNotification
                v-if="!isSummary && !quantityAllowed"
                variant="error"
                :model-value="true"
                :duration="0"
                :message="$t('message.basketItem_not_orderable_reason_quantity_not_allowed')"
                :call-to-action="isBundleItemDetail ? null : {text: $t('btn.edit')}"
                @call-to-action="$emit('goto-detailpage')"
            />
            <div class="d-flex flex-row justify-content-start flex-grow-0 gap-3 cursorPointer flex-wrap">
                <div class="imageWrapper" @click="goToProduct">
                    <NdxImage
                        :src="item.imageFilename.length > 0 ? item.imageFilename[0].filename : ''"
                        size="xsOrder"
                    />
                </div>

                <div class="flex-grow-0">
                    <div class="title" @click="goToProduct">
                        {{ item.productName }}&nbsp;
                    </div>

                    <div class="d-flex flex-row justify-content-start gap-3 flex-wrap">
                        <div>
                            <table class="list-key-table">
                                <tbody>
                                    <tr v-if="!item.priceTag">
                                        <td colspan="2" class="listValue ps-0">
                                            <span class="unitPrice">
                                                {{ formatPrice(item.unitPriceNet * (item.displayQuantity || 1)) }}
                                            </span>
                                            <span class="displayQuantity">&nbsp;/&nbsp;</span>
                                            <span
                                                v-if="parseInt(item.displayQuantity || 1, 10) !== 1"
                                                class="displayQuantity"
                                            >{{ item.displayQuantity || 1 }}&nbsp;</span>
                                            <span class="displayQuantity">{{ item.quantityUnit.shortName }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" class="listValue ps-0">
                                            <span
                                                v-if="bundlePath"
                                                class="bundlePath"
                                                :class="{readonly: inTree}"
                                                @click.stop="setBundlePath"
                                            >
                                                {{ compactBundlePathString }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="listKey align-top min-width">{{ $t('label.quantity') }}:</th>
                                        <td class="listValue align-top">{{ $n(+item.quantity) }}</td>
                                    </tr>
                                    <tr v-if="displayWeight !== '-'">
                                        <th class="listKey align-top min-width">{{ $t('label.weight') }}:</th>
                                        <td class="listValue align-top">{{ displayWeight }}</td>
                                    </tr>

                                    <tr v-if="item.artNo && item.artNo.length > 0">
                                        <th class="listKey align-top min-width">{{ $t('label.product_artNo') }}:</th>
                                        <td class="listValue align-top">{{ item.artNo }}</td>
                                    </tr>

                                    <template v-if="item.variantValues !== null">
                                        <tr
                                            v-for="variantValue in item.variantValues"
                                            :key="'variantValue_' + variantValue.value"
                                        >
                                            <th class="listKey align-top min-width">{{ variantValue.displayName }}:</th>
                                            <td class="listValue align-top">{{ variantValue.displayOption }}</td>
                                        </tr>
                                    </template>

                                    <template v-if="item.campaignRun !== null">
                                        <tr>
                                            <th class="listKey align-top min-width">
                                                {{ $t('label.campaignRunId') }}:
                                            </th>
                                            <td class="listValue align-top">{{ item.campaignRun.id }}</td>
                                        </tr>
                                        <tr
                                            v-if="item.campaignRun.shopShortDescription &&
                                                item.campaignRun.shopShortDescription.length > 0"
                                        >
                                            <th class="listKey align-top min-width">
                                                {{ $t('label.campaignRunDesc') }}:
                                            </th>
                                            <td class="listValue align-top">
                                                {{ item.campaignRun.shopShortDescription }}
                                            </td>
                                        </tr>
                                        <tr v-if="contentGroups.length > 0">
                                            <th class="listKey align-top min-width">
                                                {{ $t('label.contentGroups') }}:
                                            </th>
                                            <td class="listValue align-top">
                                                {{ contentGroups.join(', ') }}
                                            </td>
                                        </tr>
                                    </template>

                                    <tr v-if="!hideBudgetInfo && item.budget">
                                        <th class="listKey align-top min-width">
                                            {{ $t('label.budget') }}:
                                        </th>
                                        <td class="listValue align-top">
                                            {{ item.budget.name }}
                                        </td>
                                    </tr>

                                    <tr v-if="stockTxt.length > 0">
                                        <td colspan="2" class="listValue ps-0">
                                            <span
                                                class="stock-info"
                                                :class="{
                                                    'danger': item.stockInfo.state === 'zeroStock',
                                                    'info': item.stockInfo.state === 'criticalStock',
                                                    'success': ['alwaysStock', 'noStock']
                                                        .indexOf(item.stockInfo.state) > -1
                                                }"
                                            >{{ stockTxt }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="item.productFeatures.length">
                            <ProductFeaturesList :product-features="item.productFeatures"/>
                        </div>
                    </div>
                </div>

                <div
                    class="align-items-end d-flex flex-grow-1 flex-md-column flex-row-reverse
                    justify-content-between justify-content-md-start"
                >
                    <div class="item-price">
                        <template v-if="item.priceTag">
                            {{ item.priceTag }}
                        </template>
                        <template v-else>
                            {{ formatPrice(parseFloat(item.priceNet)) }}
                        </template>
                    </div>
                    <div class="text-md-end">
                        <div class="key" v-if="item.additionalPriceNet && item.additionalPriceNet > 0">
                            <template v-if="isSummary">
                                {{ $t('label.plusAdditionalCosts', {txt: additionalPriceText}) }}
                            </template>
                            <template v-else>
                                {{
                                    $t('label.additionalCosts', {
                                        value: formatPrice(parseFloat(item.additionalPriceNet)),
                                        txt: additionalPriceText
                                    })
                                }}
                            </template>
                        </div>
                    </div>
                </div>

            </div>

            <slot name="user-actions"></slot>
        </div>
        <slot name="after-content"></slot>
    </div>
</template>

<script>
    import NdxImage from "../../../library/NdxImage";
    import ProductFeaturesList from "../../../basket/parts/ProductFeaturesList";
    import { PriceFormatter } from "../../../../plugins/formatter";
    import { mapGetters, mapState } from "vuex";
    import NdxNotification from "../../../library/NdxNotification";
    import WorkflowInfo from "../../../workflows/WorkflowInfo.vue";

    export default {
        name: 'GroupItem',
        components: {WorkflowInfo, NdxNotification, ProductFeaturesList, NdxImage},
        props: {
            item: {
                type: Object,
                required: true
            },
            bundlePath: {
                type: Array
            },
            inTree: {
                type: Boolean
            },
            isSummary: {
                type: Boolean
            },
            hideBudgetInfo: {
                type: Boolean
            },
            hideCallToActions: {
                type: Boolean
            },
            workflowData: {
                type: Object
            }
        },
        emits: [
            'goto-campaignrun', 'remove-from-basket', 'push-to-watchlist', 'start-approval', 'instance-details',
            'goto-detailpage', 'action-required'
        ],
        data() {
            return {
                workflowActionType: null
            };
        },
        computed: {
            ...mapState({
                breadcrumb: state => state.bundles.breadcrumb,
                stockInfo: state => state.shop.stockInfo,
                shopAdditionalPriceText: state => state.shop.additionalPriceText
            }),
            ...mapGetters('shop', {
                currency: 'currency',
                defaultProductImageBgColor: 'getDefaultProductImageBgColor'
            }),
            ...mapGetters('basket', {
                getQuantityForProduct: 'getQuantityForProduct'
            }),
            compactBundlePath() {
                if (!this.bundlePath) {
                    return [];
                }
                let breadcrumbSize = this.breadcrumb ? this.breadcrumb.length : 0;
                if (this.inTree) {
                    breadcrumbSize = 9999999;
                }
                let path = [];
                for (let i = 0; i <= breadcrumbSize && i < this.bundlePath.length; i++) {
                    path.push(this.bundlePath[i]);
                }
                return path;
            },
            compactBundlePathString() {
                return this.compactBundlePath.map(item => item.bundleProduct.name).join(' / ');
            },
            isStockLimitReached() {
                if ('stockInfo' in this.item && this.item.stockInfo.state !== 'noStock') {
                    return this.getQuantityForProduct(this.item.productId) > this.item.stockInfo.stock;
                }
                return false;
            },
            detailedInfos() {
                return this.$store.getters['basket/getOrderItem'](this.item.orderItemId);
            },
            invalidProductFeatures() {
                const validateFn = this.$store.getters['products/getInvalidProductFeatures'];
                return !!(!this.isSummary &&
                    this.detailedInfos?.productFeatures?.length &&
                    validateFn(this.detailedInfos.productFeatures).length > 0);
            },
            cardImgBgColor() {
                return this.item.imageFilename.length > 0 && this.item.imageFilename[0].backgroundColor ||
                    this.defaultProductImageBgColor ||
                    '#f2f2f2';
            },
            additionalPriceText() {
                if (this.item.additionalCostsText && this.item.additionalCostsText.length > 0) {
                    return this.item.additionalCostsText;
                }
                if (this.shopAdditionalPriceText && this.shopAdditionalPriceText.length > 0) {
                    return this.shopAdditionalPriceText;
                }
                return this.$t('label.additionalPrice');
            },
            displayWeight() {
                let weight = this.item.weight;
                let unit = 'g';

                if (this.item.weight === null) {
                    return '-';
                }

                if (this.item.weight < 1) {
                    unit = 'mg';
                    weight = weight * 1000;
                }

                if (this.item.weight > 1000) {
                    unit = 'kg';
                    weight = weight / 1000;
                }

                return this.$n(parseFloat(weight)) + ' ' + unit;
            },
            stockTxt() {
                let txt = '';

                if (this.stockInfo?.showInfo && this.item?.stockInfo?.state
                    && this.stockInfo.text[this.item.stockInfo.state].length > 0
                ) {
                    txt = this.stockInfo.text[this.item.stockInfo.state].replaceAll('{{x}}', this.item.stockInfo.stock);
                }

                return txt;
            },
            contentGroups() {
                let list = [];

                if (this.item.campaignRun?.contentGroups) {
                    for (let groupName in this.item.campaignRun.contentGroups) {
                        let group = this.item.campaignRun.contentGroups[groupName];
                        if ('selectedOption' in group && group.selectedOption && group.selectedOption.length > 0) {
                            list.push(group.selectedOption);
                        }
                    }
                }

                return list;
            },
            portalLockMessage() {
                if (this.item.campaignRun?.lockMessage) {
                    return this.item.campaignRun.lockMessage;
                }

                return this.$t('message.basketItem_not_orderable_campaignRun_locked');
            },
            campaignRunNotReady() {
                return !!this.detailedInfos?.campaignRun && !this.detailedInfos.campaignRun.isReady;
            },
            campaignRunDeleted() {
                return !!(this.detailedInfos?.checks?.CAMPAIGNRUN_DELETED);
            },
            quantityAllowed() {
                return this.detailedInfos ? !!(this.detailedInfos.checks?.QUANTITY_ALLOWED) : true;
            },
            isBundleItemDetail() {
                return this.$route.name === 'BundleItemDetail';
            }
        },
        watch: {
            item: {
                immediate: true,
                deep: true,
                handler: 'sendActionRequiredEvent'
            },
            workflowActionType: {
                immediate: true,
                handler: 'sendActionRequiredEvent'
            }
        },
        methods: {
            setBundlePath() {
                if (this.inTree) {
                    return;
                }
                const bundleId = this.compactBundlePath.length ? this.compactBundlePath.pop().bundleId : null;
                const path = this.$store.getters['bundles/getPathFromBundleId'](bundleId);
                this.$store.dispatch('bundles/setPath', path);
            },
            formatPrice(value) {
                return PriceFormatter(value, this.currency, this);
            },
            goToProduct() {
                this.$router.push({name: 'Product', params: {pid: this.item.productId}});
            },
            sendActionRequiredEvent() {
                const actionRequired = this.invalidProductFeatures ||
                    this.campaignRunNotReady ||
                    this.workflowActionType === 'start-approval';

                const isBundleDetailPage = ['BundleItemDetail'].includes(this.$route.name);

                if (!isBundleDetailPage) {
                    this.$emit('action-required', actionRequired);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    :deep(.img_wrapper),
    :deep(.img_wrapper.placeholder) {
        background-color: v-bind(cardImgBgColor);
    }

    .bundlePath {
        background-color: var(--bs-gray-200);
        font-size: 12px;
        padding: 1px 4px;
        border-radius: 4px;
        border: 1px solid #e5e5e5;
        line-height: 24px;

        &:not(.readonly) {
            cursor: pointer;
        }
    }
</style>
