<template>
    <NdxListing
        v-if="storeAction"
        :store-action="storeAction"
        :page-sizes="storeAction === 'search/searchAssetLibraries' ? [1e10] : $store.getters['search/pageSizes']"
        :filter-criteria="filterCriteria"
    >
        <div
            class="card-container"
            :class="{['viewmode-' + viewMode]: true}"
        >
            <slot name="default"></slot>
        </div>
    </NdxListing>
</template>

<script>
    import NdxListing from "../library/NdxListing.vue";

    export default {
        name:"SearchResult",
        components: {NdxListing},
        emits: ['load-more', 'load-all'],
        props: {
            storeAction: {
                type: String,
                required: true,
            },
            filterCriteria: {
                type: Object,
            },
            viewMode: {
                type: String,
                required: true,
            },
        }
    };
</script>

<style scoped lang="scss">
    @import "~bootstrap/scss/bootstrap-utilities";
    @import "../../style/variables_ndx";

    .card-container {
        margin-bottom: 20px;
    }

    div[type="button"] {
        margin-top: -12px;
        margin-bottom: 20px;
    }

    .card-container.viewmode-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
    }

    .card-container.viewmode-card {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        gap: 16px;
    }

    @include media-breakpoint-up(md) {
        .card-container.viewmode-card {
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        }
    }

    .searchMsg {
        margin-bottom: 40px;
    }
</style>
