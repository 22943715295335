<template>
    <div
        class="w-100 ndx-form-element ndx-text-area"
        :class="{
            [variant]: true,
            disabled: readonly,
            error: invalid,
            focused: focused
        }"
    >
        <div v-if="label && label.length" class="label">{{ label }}</div>
        <div v-if="description && description.length" class="description">{{ description }}</div>
        <textarea
            :readonly="readonly"
            :disabled="readonly"
            :value="modelValue"
            @input="($event) => {
                $emit('update:modelValue', $event.target.value)
            }"
            @focusin="focused = true"
            @focusout="focused = false"
            @change="$emit('change', $event)"
        ></textarea>
    </div>
</template>

<script>
    export default {
        name: 'NdxTextarea',
        props: {
            label: {
                type: String
            },
            description: {
                type: String
            },
            modelValue: {
                type: [String, Number, undefined],
                required: false
            },
            readonly: {
                type: Boolean,
            },
            invalid: {
                type: Boolean,
            },
            variant: {
                type: String,
                default: 'primary',
                validator: function (value) {
                    return ['primary', 'secondary'].indexOf(value) !== -1;
                }
            }
        },
        emits: ['update:modelValue', 'change'],
        data() {
            return {
                focused: false
            };
        }
    };
</script>
