import axios from 'axios';
import { throbberRules } from '@utilities/throbberRules';

axios.defaults.baseURL = '/portal/rpc/';
axios.defaults.headers.common['Content-Type'] = 'application/json; charset=UTF-8';

let store;
const getStore = async function () {
    if (!store) {
        store = await import('../store').then(module => module.default);
    }
    return store;
};
const errorHandler = async function (error) {
    if (error.response && error.response.status === 403) { //forbidden
        if (!/portal\/rpc\/init$/.test(error.request.responseURL)) {
            const store = await getStore();
            store.dispatch('session/forcedLogout');
        }
    }
};

export async function ndxRpc(service, method, params, throbberRule = throbberRules.FULLSCREEN) {
    const store = await getStore();

    store.dispatch("session/incrementRequestCounter", throbberRule);
    let _secret = store.getters["session/getSecret"];
    let secret = '';

    if (_secret && _secret.length) {
        secret = _secret;
    }

    return new Promise((resolve, reject) => {
        axios.post(
            service + '/' + method + '?t=' + secret,
            {
                method: method,
                id: '1:3:5',
                params: params
            }
        ).then(response => {
            if (response.data.error) {
                let evt;
                switch (response.data.error.code) {
                    case 3001:
                        store.dispatch("session/setMaintenance", true);
                        break;
                    case 404:
                        evt = new CustomEvent('REDIRECT_PAGE_NOT_FOUND', {
                            detail: {
                                error: response.data.error
                            }
                        });
                        window.dispatchEvent(evt);
                        reject(response.data.error);
                        break;
                    default:
                        console.log(response.data.error);
                        errorHandler(response);
                        reject(response.data.error);
                }
            }
            resolve(response.data.result);
        }).catch(error => {
            console.log(error);
            errorHandler(error);
            reject(error);
        }).finally(() => store.dispatch("session/decrementRequestCounter", throbberRule));
    });
}

export async function ndxUploadFile(url, formData, throbberRule = throbberRules.FULLSCREEN) {
    const store = await getStore();

    store.dispatch("session/incrementRequestCounter", throbberRule);
    let _secret = store.getters["session/getSecret"];
    let secret = _secret && _secret.length ? _secret : '';

    formData.append('t', secret);

    return new Promise((resolve, reject) => {
        axios.post(
            url,
            formData,
            {
                baseURL: '/',
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
        ).then((result) => {
            if (typeof result.data === 'string') {
                reject(result.data);
            } else {
                resolve('result' in result.data ? result.data.result : result.data);
            }
        }).catch((error) => {
            reject(error);
        }).finally(() => store.dispatch("session/decrementRequestCounter", throbberRule));
    });
}

export function ndxGet(url, withoutBaseUrl = false) {
    return new Promise((resolve, reject) => {
        axios.get(url, {baseURL: withoutBaseUrl ? '/' : axios.defaults.baseURL})
            .then((result) => {
                if (result) {
                    resolve(result.data);
                } else {
                    reject(new Error('no result'));
                }
            })
            .catch((error) => {
                errorHandler(error);

                reject(error);
            });
    });
}

export function ndxTracking(trackedAction, trackedOid, additionalTrackingData) {
    return axios.post(
        '/portal/tracking',
        {
            trackedAction,
            trackedOid,
            additionalTrackingData
        },
        {baseURL: '/'}
    );
}
