<template>
    <NdxPageFrame>
        <OptInContent />
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import OptInContent from "../../components/optIn/OptIn";

    export default {
        name: "OptIn",
        components: {NdxPageFrame, OptInContent}
    };
</script>
