<template>
    <div
        class="w-100 ndx-form-element ndx-img-file-upload"
        :class="{disabled: readonly, error: invalid}"
    >
        <div v-if="label && label.length" class="label">{{ label }}</div>
        <div v-if="description && description.length" class="description">{{ description }}</div>
        <div class="d-flex">
            <div class="img-wrapper" :class="{[imgVariant]: true}" :style="customStyle">
                <img
                    v-if="imgSrc && imgVariant === 'square'"
                    :src="imgSrc"
                    class="img-fluid"
                    alt="preview"
                >
            </div>
            <div class="uploadWrapper" v-if="!readonly">
                <input ref="fileUpload" type="file" @change="handleFileUpload"/>
                <NdxButton variant="secondary">
                    <template #icon>
                        <NdxIcon icon="upload" size="m"/>
                    </template>
                    {{ $t('btn.upload') }}
                </NdxButton>
            </div>
            <NdxButton
                v-if="!readonly"
                variant="secondary"
                :disabled="imgSrc == null"
                @click="remove"
            >
                <template #icon>
                    <NdxIcon icon="trash" size="m"/>
                </template>
                {{ $t('btn.remove') }}
            </NdxButton>
        </div>
        <NdxNotification
            :model-value="errorMsg !== null"
            :message="errorMsg"
            :duration="0"
            variant="error"
        />
    </div>
</template>

<script>
    import NdxIcon from "../NdxIcon";
    import NdxButton from "../NdxButton";
    import NdxNotification from "../NdxNotification";

    export default {
        name: 'NdxImgUpload',
        components: {NdxNotification, NdxButton, NdxIcon},
        props: {
            label: {
                type: String
            },
            description: {
                type: String
            },
            modelValue: {
                type: [Object, undefined],
                required: false
            },
            uploadParams: {
                type: Object,
                required: false,
                default: function () {
                    return {};
                }
            },
            readonly: {
                type: Boolean,
            },
            imgVariant: {
                type: String,
                default: 'round',
                validator: function (value) {
                    return ['round', 'square'].indexOf(value) !== -1;
                }
            },
            invalid: {
                type: Boolean,
            }
        },
        emits: ['update:modelValue'],
        data() {
            return {
                errorMsg: null
            };
        },
        computed: {
            imgSrc() {
                if (this.modelValue && this.modelValue.filename && this.modelValue.filename.length > 0) {
                    return '/ndx/file/downloadResized/100/100/' + this.modelValue.filename;
                }
                return null;
            },
            customStyle() {
                let style = {};

                if (this.imgSrc) {
                    style['background-image'] = 'url("' + this.imgSrc + '")';
                }

                return style;
            },
        },
        methods: {
            remove() {
                this.$emit('update:modelValue', null);
            },
            handleFileUpload() {
                this.errorMsg = null;

                if (this.$refs.fileUpload.files &&
                    this.$refs.fileUpload.files.length &&
                    this.$refs.fileUpload.files.length === 1
                ) {
                    let file = this.$refs.fileUpload.files[0];

                    let formData = new FormData();
                    formData.append('upload', file);
                    formData.append('validation', 'layout_image');

                    for (let key in this.uploadParams) {
                        if (key === 'imageName' && !this.uploadParams[key].length) {
                            formData.append(key, file.name);
                        } else {
                            formData.append(key, this.uploadParams[key]);
                        }
                    }

                    this.$store.dispatch('image/uploadImage', formData)
                        .then((result) => {
                            if (result && result.length === 1) {
                                this.$emit('update:modelValue', {
                                    uploadElementId: result[0].id,
                                    id: result[0].file.id,
                                    filename: result[0].file.filename
                                });
                            }
                        })
                        .catch((error) => {
                            if (error.match(/Ndx\\Exception\\EeFileTypeUnsupported/)) {
                                let msg = error;
                                switch (formData.get('validation')) {
                                    case 'layout_image':
                                        msg = this.$t(
                                            'message.upload_wrong_filetype',
                                            {fileExt: 'jpg, jpeg, png, tif, tiff, gif, pdf, bmp, zip, svg'}
                                        );
                                        break;
                                }

                                this.errorMsg = msg;

                            } else {
                                this.errorMsg = error;
                            }
                        });
                }
            }
        }
    };
</script>
