<template>
    <LoginWireframe>
        <template #form>
            <NdxClientSelect hide-active-client/>
        </template>
    </LoginWireframe>
</template>

<script>
    import LoginWireframe from '../../wireframes/NdxLogin';
    import NdxClientSelect from "../../components/library/NdxClientSelect.vue";

    export default {
        name: "ClientSelect",
        components: {NdxClientSelect, LoginWireframe}
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";
    .title {
        @extend %font-h2;
    }
</style>
