import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'serverJob';

export default {
    findBy(criteria, orderBy, limit, offset, throbberRule = throbberRules.NONE) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'findBy', [criteria, orderBy, limit, offset], throbberRule)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
