<template>
    <div class="headline-links d-flex align-items-center">
        <NdxMenuEntry
            v-for="(wgc, idx) in infoPanelItems"
            :key="idx"
            :wgc="wgc"
            class="support"
        />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import NdxMenuEntry from "../library/NdxMenuEntry";

    export default {
        name: 'MainHeadline',
        components: {NdxMenuEntry},
        computed: {
            ...mapState({
                infoPanelItems: state => state.shop.infoPanelMenu
            })
        }
    };
</script>

<style scoped lang="scss">
    @import "../../style/variables_ndx";

    .headline-links {
        flex-wrap: wrap;

        & > * {
            white-space: nowrap;
            padding: 4px 0;
        }

        a {
            @extend %font-caption;
        }

        .support {
            @extend %font-caption;
            display: flex;
            align-items: center;

            .bi {
                margin-right: 4px;
            }
        }

        :deep(.marker:after) {
            top: 1px;
            right: -2px;
            height: 6px;
            width: 6px;
        }

        .support + .support,
        .support + a,
        a + a {
            &::before {
                content: "|";
                padding-left: 8px;
                padding-right: 8px;
            }
        }
    }
</style>
