import lodashMerge from 'lodash/merge';
import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'workflowTask';

export default lodashMerge({}, {
    getTasklist(filterDescription, sortBy, sortAsc, searchText, firstResult, maxResults) {
        return new Promise((resolve, reject) => {
            const params = [
                filterDescription,
                sortBy,
                sortAsc,
                searchText,
                firstResult,
                maxResults
            ];

            ndxRpc(service, 'filterBy', params)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    findTask(taskId, historyFallback) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'find', [taskId, historyFallback])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    claim(taskId, userId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'claim', [taskId, userId])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    unclaim(taskId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'unclaim', [taskId])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    approve (taskId, comment) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'approve', [taskId, comment])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    reject (taskId, comment) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'reject', [taskId, comment])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    rejectWithRevision (taskId, comment) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'rejectWithRevision', [taskId, comment])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getOpenTaskCountForUser (userId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getOpenTaskCountForUser', [userId], throbberRules.NONE)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
});
