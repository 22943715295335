<script>
    import { h } from "vue";
    import NdxIcon from "./NdxIcon";
    import Glide, { Controls, Autoplay, Swipe } from "@glidejs/glide/dist/glide.modular.esm";

    // @see https://glidejs.com/docs/setup/

    export default {
        name: 'NdxHorizontalSlider',
        components: {NdxIcon},
        props: {
            headline: {
                type: String
            },
            autoplay: {
                type: Boolean
            }
        },
        data() {
            return {
                myId: 'mySlider_' + this.ndxUuid,
                glide: null
            };
        },
        mounted() {
            window.addEventListener('resize', this.updateWidth);
            this.initGlide();
        },
        beforeUnmount() {
            window.removeEventListener('resize', this.updateWidth);
            if (this.glide) {
                this.glide.destroy();
            }
        },
        methods: {
            initGlide() {
                if (document.querySelectorAll('#' + this.myId).length > 0) {
                    if (this.glide) {
                        this.glide.destroy();
                    }

                    this.glide = new Glide('#' + this.myId, {
                        type: 'slider',
                        focusAt: 0,
                        startAt: 0,
                        gap: 16,
                        perView: this.getViewCount(),
                        bound: true,
                        autoplay: this.autoplay ? 3000 : false
                    });

                    this.glide.mount({Controls, Autoplay, Swipe});
                }
            },
            updateWidth() {
                this.glide.update({
                    perView: this.getViewCount()
                });
            },
            getViewCount() {
                const sliderWidth = document.querySelectorAll('#' + this.myId)[0].offsetWidth;
                const singleSlideWidth = 200;
                return sliderWidth / singleSlideWidth;
            }
        },
        render() {
            let slideItems = [];
            let mainContent = [];
            let headlineContent = [];

            if (this.headline && this.headline.length > 0) {
                headlineContent.push(h(
                    'div',
                    {
                        class: ['title']
                    },
                    this.headline
                ));
            }

            let arrows = [];
            arrows.push(h(
                NdxIcon,
                {
                    class: ['glide__arrow', 'glide__arrow--left'],
                    'data-glide-dir': '<',
                    icon: 'drop-left',
                    'is-action': true
                }
            ));
            arrows.push(h(
                NdxIcon,
                {
                    class: ['glide__arrow', 'glide__arrow--right'],
                    'data-glide-dir': '>',
                    icon: 'drop-right',
                    'is-action': true
                }
            ));

            headlineContent.push(h(
                'div',
                {
                    class: ['glide__arrows'],
                    'data-glide-el': 'controls'
                },
                arrows
            ));

            mainContent.push(h(
                'div',
                {
                    class: ['headline']
                },
                headlineContent
            ));

            const childNodes = this.$slots.default()[0].children;

            for (let node of childNodes) {
                slideItems.push(h(
                    'li',
                    {
                        class: ['glide__slide']
                    },
                    node
                ));
            }

            const slideWrapper = h(
                'ul',
                {
                    class: ['glide__slides']
                },
                slideItems
            );

            mainContent.push(h(
                'div',
                {
                    class: ['glide__track'],
                    'data-glide-el': 'track'
                },
                slideWrapper
            ));

            return h(
                'div',
                {
                    id: this.myId,
                    class: ['glide']
                },
                mainContent
            );
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";
    @import "../../../node_modules/@glidejs/glide/src/assets/sass/glide.core";

    .headline {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;

        .title {
            @extend %font-h2;
        }

        .glide__arrows {
            .glide__arrow {
                background-color: var(--bs-gray-middle);
                border-radius: 32px;

                & + .glide__arrow {
                    margin-left: 16px;
                }
            }
        }
    }
</style>
