<template>
    <NdxPageHeader
        hide-view-mode
        hide-filter
        :nav-name="currentWgc.label"
        :breadcrumbs="breadcrumbs"
    />

    <div class="d-flex flex-column">
        <div class="head border_bottom mb-3">
            <div class="d-flex justify-content-between">
                <div class="d-flex gap-2 align-items-center">
                    <template v-if="viewState === 'create'">
                        <NdxIcon
                            icon="arrow-left"
                            class="rounded-icon"
                            @click="viewState = 'overview'"
                        />
                        {{ $t('label.news.create') }}
                    </template>
                </div>
                <NdxButtonLink
                    v-if="showCreateMessageBtn && viewState !== 'create'"
                    @click="viewState = 'create'"
                >{{ $t('label.news.create')}}</NdxButtonLink>
            </div>
            <div
                v-if="viewState !== 'create'"
                class="w-100 d-flex justify-content-between d-md-none filter"
                @click="toggleFilter"
            >
                {{ $t('label.news.type_' + newsFilter) }}
                <NdxIcon
                    v-if="viewState === 'filter'"
                    icon="drop-down"
                    flip-v
                />
                <NdxIcon
                    v-else
                    icon="drop-down"
                />
            </div>
        </div>
        <div class="content_wrapper">
            <NewsOverview
                v-if="viewState === 'overview'"
                :news="filteredNews"
            />
            <NewsFilter
                v-if="viewState === 'filter'"
                :news="news"
                @news-filter="filterNews"
            />
            <NewsCreate
                v-if="viewState === 'create'"
                @news-created="() => {getNews(); viewState = 'overview';}"
            />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import NewsOverview from "./subpages/NewsOverview";
    import NewsFilter from "./subpages/NewsFilter";
    import NewsCreate from "./subpages/NewsCreate";
    import NdxIcon from "../library/NdxIcon";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxPageHeader from "../library/NdxPageHeader.vue";

    export default {
        name: "News",
        components: {NdxPageHeader, NdxButtonLink, NdxIcon, NewsCreate, NewsFilter, NewsOverview},
        data() {
            return {
                news: [],
                viewState: 'overview'
            };
        },
        computed: {
            ...mapState({
                newsFilter: state => state.news.newsFilter
            }),
            ...mapGetters('news', {
                messageCreate: 'messageCreate'
            }),
            filteredNews() {
                let filteredNews = [];
                switch (this.newsFilter) {
                    case 'all':
                        filteredNews = this.news;
                        break;
                    case "-1":
                    case -1:
                        filteredNews = this.news.filter(
                            newsItem => !newsItem.readByLoggedUser || newsItem.replyInfo.unreadCount > 0
                        );
                        break;
                    default:
                        filteredNews = this.news.filter(
                            newsItem => newsItem.type === +this.newsFilter
                        );
                        break;
                }

                return filteredNews;
            },
            showCreateMessageBtn() {
                return this.messageCreate !== 0;
            },
            currentWgc() {
                return this.$store.getters['shop/getWgcsByType']('news')[0];
            },
            breadcrumbs() {
                return [{
                    label: this.currentWgc.label
                }];
            }
        },
        created() {
            this.getNews();
        },
        methods: {
            getNews() {
                this.$store.dispatch('news/findAllRelevant', {
                    offset: 0,
                    limit: null,
                    order: {
                        id: 'DESC'
                    }
                }).then(news => this.news = news);
            },
            filterNews(newsType) {
                this.$store.dispatch('news/setNewsFilter', newsType);
                this.viewState = 'overview';
            },
            toggleFilter() {
                this.viewState = this.viewState === 'filter' ? 'overview' : 'filter';
            }
        }
    };
</script>

<style scoped lang="scss">
    .filter {
        cursor: pointer;

        &:hover {
            color: var(--bs-primary);
        }
    }

    .rounded-icon {
        background-color: var(--bs-gray-light);
        color: var(--bs-gray-dark);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        cursor: pointer;
    }
</style>
