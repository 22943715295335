import { throbberRules } from "@utilities/throbberRules";
import { ndxRpc } from "../utils/ndxRpc";

const service = 'productCategory';

export default {
    getSubtree(rootId, depth, throbberRule = throbberRules.FULLSCREEN) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getSubtree', [rootId, depth], throbberRule)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getSubtreeWithWgc(rootId, depth) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getSubtreeWithWgc', [rootId, depth], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    filterBy(rootId, offset, limit, searchText) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'filterBy', [rootId, offset, limit, searchText], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getParentId(currentCategoryId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getParentId', [currentCategoryId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getParentList(categoryId, rootCategoryId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'getParentList', [+categoryId, +rootCategoryId], throbberRules.FULLSCREEN)
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};
