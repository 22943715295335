<template>
    <NdxPageFrame>
        <BasketFrame />
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import BasketFrame from "../../components/basket/BasketFrame";
    export default {
        name: 'Basket',
        components: {BasketFrame, NdxPageFrame}
    };
</script>
