const RequestCollector = class {
    #apiFunction;
    #colletingDelay;
    #runningRequest;
    #waitingRequest;
    #requestParameterStack;

    constructor(apiFunction, colletingDelay = 100) {
        if (typeof apiFunction !== "function") {
            throw new Error("Constructor parameter has to be a function");
        }

        this.#apiFunction = apiFunction;
        this.#colletingDelay = colletingDelay;
        this.#requestParameterStack = [];
    }

    doRequest(requestParameters) {
        this.#requestParameterStack = this.#requestParameterStack.concat(requestParameters);
        if (!this.#waitingRequest) {
            this.#waitingRequest = new Promise((resolve, reject) => {
                let interval = window.setInterval(() => {
                    if (!this.#runningRequest) {
                        window.clearInterval(interval);
                        this.#runningRequest = this.#apiFunction(this.#requestParameterStack)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((error) => {
                                reject(error);
                            })
                            .finally(() => this.#runningRequest = null);

                        this.#waitingRequest = null;
                        this.#requestParameterStack = [];
                    }
                }, this.#colletingDelay);
            }).finally(() => {
                this.#runningRequest = null;
            });
        }

        return this.#waitingRequest;
    }
};

export default RequestCollector;
