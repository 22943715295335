<template>
    <div
        class="w-100 ndx-form-element ndx-radio"
        :class="{focused: focused, disabled: readonly, error: invalid}"
    >
        <div class="label" v-if="label && label.length > 0">{{ label }}</div>
        <div v-if="description && description.length" class="description">{{ description }}</div>
        <div class="d-flex flex-column flex-md-row align-items-md-center">
            <div
                v-for="option in options"
                :key="option.value"
                class="radioItem d-flex align-items-center"
                :class="{
                    checked: option.value === modelValue,
                    readonly: readonly,
                    focused: focused === option.value
                }"
            >
                <div class="optic flex-shrink-0"></div>
                <input
                    type="radio"
                    :checked="option.value === modelValue"
                    :disabled="readonly"
                    :name="radioName"
                    :value="option.value"
                    @change="() => {$emit('update:modelValue', option.value); }"
                    @focusin="focused = option.value"
                    @focusout="focused = ''"
                />
                <span class="radioItem-label">{{ option.text }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NdxRadio',
        props: {
            label: {
                type: String
            },
            description: {
                type: String
            },
            modelValue: {
                type: [String, Number, undefined],
                required: false
            },
            readonly: {
                type: Boolean,
            },
            invalid: {
                type: Boolean,
            },
            options: {
                type: Array
            }
        },
        emits: ['update:modelValue'],
        data() {
            return {
                focused: '',
                radioName: 'NdxRadio_' + this.ndxUuid
            };
        }
    };
</script>
