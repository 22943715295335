<template>
    <svg
        viewBox="0 0 16 16"
        :width="iconSize"
        :height="iconSize"
        focusable="false"
        role="img"
        aria-label="visible"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi"
        :class="{
            'action-icon': isAction,
            'no-hover': noHover,
            'active': active,
            [sizeClassName]: true
        }"
        v-html="iconPath"
        :style="customStyle"
        :data-icon="icon"
    >
    </svg>
</template>

<script>
import { icons } from "../../plugins/ndx-icons";

export default {
    name: 'NdxIcon',
    props: {
        icon: {
            type: String
        },
        title: {
            type: String
        },
        flipH: {
            type: Boolean
        },
        flipV: {
            type: Boolean
        },
        size: {
            type: String,
            default: 'm',
            validator: function (value) {
                return ['s', 'm', 'l', 'xl'].indexOf(value) !== -1;
            }
        },
        isAction: {
            type: Boolean
        },
        noHover: {
            type: Boolean
        },
        active: {
            type: Boolean
        }
    },
    computed: {
        iconPath: function () {
            if (this.icon && this.icon in icons) {
                return (this.title ? '<title>' + this.title + '</title>' : '') + icons[this.icon];
            }

            return '';
        },
        customStyle() {
            if (this.flipH || this.flipV) {
                return {
                    transform: 'rotate(180deg)'
                };
            }

            return {};
        },
        iconSize() {
            if (this.size === 'xl') {
                return 48;
            }
            if (this.size === 'l') {
                return 32;
            }
            if (this.size === 'm') {
                return 24;
            }
            return 16;
        },
        sizeClassName() {
            return 'size-' + this.size;
        }
    }
};
</script>
