<template>
    <div
        class="w-100 ndx-form-element ndx-file-upload"
        :class="{disabled: readonly, [variant]: true, error: invalid}"
    >
        <div v-if="label && label.length" class="label">{{ label }}</div>
        <div v-if="description && description.length" class="description">{{ description }}</div>
        <div class="file-preview" v-if="variant !== 'link' ">
            <div v-if="modelValue && 'originalFilename' in modelValue" @click="showPreview">
                <NdxIcon icon="export" size="s" is-action class="me-1" @click.stop="downloadFile"/>
                {{ modelValue.originalFilename}}
                <NdxIcon icon="preview" v-if="previewType" size="s"/>
            </div>
            <div v-else class="empty">
                {{ $t('label.noUploadedFile') }}
            </div>
        </div>
        <div class="d-flex gap-2">
            <template v-if="variant === 'minimal'">
                <div class="uploadWrapper" v-if="!readonly">
                    <input ref="fileUpload" type="file" @change="handleFileUpload"/>
                    <NdxButton variant="secondary">
                        <template #icon>
                            <NdxIcon icon="upload" size="m"/>
                        </template>
                        {{ $t('btn.upload') }}
                    </NdxButton>
                </div>
                <NdxButton
                    v-if="!readonly && !hideRemoveBtn"
                    variant="secondary"
                    :disabled="modelValue == null"
                    @click="remove"
                >
                    <template #icon>
                        <NdxIcon icon="trash" size="m"/>
                    </template>
                    {{ $t('btn.remove') }}
                </NdxButton>
            </template>
            <template v-else-if="variant === 'link'">
                <div class="uploadWrapper" v-if="!readonly">
                    <input ref="fileUpload" type="file" @change="handleFileUpload"/>
                    <NdxIcon icon="upload" size="s"/>
                    <span class="primary ms-2">{{ $t('btn.upload') }}</span>
                </div>
            </template>
        </div>
        <NdxNotification
            :model-value="errorMsg !== null && !preventNotification"
            :message="errorMsg"
            :duration="0"
            variant="error"
        />

        <NdxImageOverlay v-model="imagePreviewFile"/>
        <NdxFileOverlay v-model="pdfPreviewFile"/>
    </div>
</template>

<script>
    import NdxNotification from "../NdxNotification";
    import NdxButton from "../NdxButton";
    import NdxIcon from "../NdxIcon";
    import { ndxUploadFile } from "../../../utils/ndxRpc";
    import NdxFileOverlay from "../NdxFileOverlay";
    import NdxImageOverlay from "../NdxImageOverlay";

    export default {
        name: 'NdxFileUpload',
        components: {NdxImageOverlay, NdxFileOverlay, NdxIcon, NdxButton, NdxNotification},
        props: {
            label: {
                type: String
            },
            description: {
                type: String
            },
            modelValue: {
                type: [Object, undefined],
                required: false
            },
            uploadParams: {
                type: Object,
                required: false,
                default: function () {
                    return {};
                }
            },
            serverValidation: {
                type: String,
                default: 'tmpFile'
            },
            uploadUrl: {
                type: String,
                default: '/ndx/file/uploadTmpFile'
            },
            readonly: {
                type: Boolean,
            },
            variant: {
                type: String,
                default: '',
                validator: function (value) {
                    return ['minimal', 'link'].indexOf(value) !== -1;
                }
            },
            invalid: {
                type: Boolean
            },
            hideRemoveBtn: {
                type: Boolean
            },
            preventNotification: {
                type: Boolean
            }
        },
        emits: ['update:modelValue', 'upload-failed'],
        data() {
            return {
                errorMsg: null,
                imagePreviewFile: null,
                pdfPreviewFile: null,
            };
        },
        computed: {
            previewType() {
                const fileEnding = this.modelValue?.originalFilename.substr(
                    this.modelValue?.originalFilename.lastIndexOf('.') + 1
                );
                const file = this.modelValue?.cacheId;
                const imageFormats = ['bmp', 'svg', 'png', 'jpeg', 'jpg', 'gif', 'tif', 'tiff'];
                const pdfFormats = ['pdf'];
                const supportPreviewFormats = imageFormats.concat(pdfFormats);

                if (file && supportPreviewFormats.includes(fileEnding)) {
                    if (pdfFormats.includes(fileEnding)) {
                        return 'pdf';
                    } else if (imageFormats.includes(fileEnding)) {
                        return 'image';
                    }
                }

                return null;
            }
        },
        methods: {
            downloadFile() {
                if (this.modelValue) {
                    const downloadUrl = '/file/download/' + this.modelValue.cacheId;
                    window.open(downloadUrl, '_blank');
                }
            },
            remove() {
                this.$emit('update:modelValue', null);
            },
            handleFileUpload() {
                this.errorMsg = null;

                if (this.$refs.fileUpload.files &&
                    this.$refs.fileUpload.files.length &&
                    this.$refs.fileUpload.files.length === 1
                ) {
                    let file = this.$refs.fileUpload.files[0];

                    let formData = new FormData();
                    formData.append("validation", this.serverValidation);
                    formData.append("upload", file);
                    formData.append("originalName", file.name);

                    for (let key in this.uploadParams) {
                        formData.append(key, this.uploadParams[key]);
                    }

                    return ndxUploadFile(
                        this.uploadUrl,
                        formData
                    )
                        .then((data) => this.onSuccess(data))
                        .catch((error) => this.onError(error));
                }
            },
            onSuccess: function (uploadedData) {
                /**
                 * Gives you <em>cacheId</em> and <em>originalFilename</em> for uploaded file
                 */
                this.$emit('update:modelValue', uploadedData);
            },
            onError: function (error) {
                this.errorMsg = error;
                this.$emit('upload-failed', error);
            },
            showPreview() {
                const file = this.modelValue?.cacheId;

                if (file) {
                    switch (this.previewType) {
                        case "pdf":
                            this.pdfPreviewFile = file;
                            break;
                        case "image":
                            this.imagePreviewFile = {filename: file};
                            break;
                    }
                }
            }
        }
    };
</script>
