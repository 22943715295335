<template>
    <div
        v-for="shipment in shipments"
        :key="shipment.id"
    >
        <template v-if="shipment.trackingUrl.length > 0">
            <a :href="shipment.trackingUrl" target="_blank">{{ shipment.trackingId }}</a>
            ({{ shipment.carrier }})
        </template>
        <template v-else>
            {{ shipment.trackingId }} ({{ shipment.carrier }})
        </template>
    </div>
</template>

<script>
    export default {
        name: 'Shipments',
        props: {
            shipments: {
                type: Array
            }
        }
    };
</script>
