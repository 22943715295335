<template>
    <div
        class="selectionEntry roundedBorder"
        :class="{'selected': selected, 'inactive': disabled, 'error': invalid}"
        @click.stop="onClick"
    >
        <span class="radioFake"></span>
        <span v-html="text"></span>
    </div>

</template>

<script>
    export default {
        name: "NdxRadioCards",
        emits: ['radio-card-selected'],
        props: {
            value: {
                type: [String, Number]
            },
            /**
             * ATTENTION: Be aware of sanatizing the text. It is inserted in DOM without any escaping!!!
             */
            text: {
                type: String
            },
            selected: {
                type: Boolean
            },
            disabled: {
                type: Boolean
            },
            invalid: {
                type: Boolean,
            }
        },
        methods: {
            onClick() {
                if (!this.disabled) {
                    this.$emit('radio-card-selected', this.value);
                }
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../style/variables_ndx";

    .selectionEntry {
        @extend %font-caption;

        padding: 8px;
        cursor: pointer;

        display: flex;
        align-items: center;

        .radioFake {
            display: inline-block;
            width: 16px;
            height: 16px;
            border: 1px solid var(--bs-gray-dark);
            border-radius: 50%;
            background-color: #fff;
            margin-right: 8px;
            flex-shrink: 0;
        }

        &.selected {
            background-color: var(--bs-primary-light);

            .radioFake {
                width: 14px;
                height: 14px;
                border-color: #fff;
                background-color: var(--bs-gray-dark);
                outline: 1px solid var(--bs-gray-dark);
                margin-left: 1px;
                margin-right: 9px;
            }
        }

        & + .selectionEntry {
            margin-top: 8px;
        }

        &.inactive {
            background-color: var(--bs-gray-200);
        }

        &.error {
            border-color: var(--bs-danger-light);
            background-color: var(--bs-danger-light);
        }
    }
</style>
