<template>
    <div
        v-for="(group, grpIdx) in groups"
        :key="'group_' + grpIdx"
        class="myAccount-data-container"
    >
        <div class="groupTitle mb-2">{{ group.name }}</div>
        <div class="groupDesc my-2 mx-0" v-if="group.description">{{ group.description }}</div>
        <div class="myAccount-data mb-3">
            <DataItem
                class="myAccount-data-item"
                :class="{isMandatory: item.mandatory, isInvalid: invalidFieldIds.includes(item.id)}"
                v-for="item of group.entries"
                :key="item.id"
                :item="item"
                type="checkoutAttribute"
                opened
                allow-html-desc
                :model-value="item"
                @update:modelValue="updateValue"
            />
        </div>
    </div>
</template>

<script>
    import {cloneDeep} from "lodash";
    import DataItem from "../../myAccount/dataItem/DataItem";

    export default {
        name: "CustomFields",
        components: {DataItem},
        data() {
            return {
                groups: [],
                updatedFields: {},
                invalidFieldIds: []
            };
        },
        mounted() {
            this.$store.dispatch('checkout/getCustomFields').then((groups) => {
                this.groups = cloneDeep(groups);
                this.validate();
            });
        },
        beforeUnmount() {
            if (Object.keys(this.updatedFields).length) {
                this.$store.dispatch('checkout/saveCustomFields', this.updatedFields).then((groups) => {
                    this.groups = cloneDeep(groups);
                    this.validate();
                });
            }
        },
        methods: {
            validate() {
                let isValid = true;
                this.invalidFieldIds = [];

                const isInvalidValue = (value) => {
                    const _value = typeof value === 'string' ? value.trim() : value;
                    return [null, undefined, ''].includes(_value);
                };
                const checkboxInvalid = (type, value) => {
                    return type === 'checkbox' && !['1', 1].includes(value);
                };

                this.groups.forEach((group) => {
                    group.entries.forEach(entry => {
                        if (entry.mandatory) {
                            let type = entry.attributeDef.valueFormatType;
                            let value = entry.id in this.updatedFields ? this.updatedFields[entry.id].value : null;

                            if (value === null) {
                                value = entry.attributeDef.value;
                                if (value === null) {
                                    value = entry.attributeDef.defaultValue;
                                }
                            }

                            if (isInvalidValue(value) || checkboxInvalid(type, value)) {
                                this.invalidFieldIds.push(entry.id);
                                isValid = false;
                            }
                        }
                    });
                });

                this.$store.dispatch(
                    'checkout/setIsValid',
                    isValid
                );
            },
            updateValue(item) {
                this.updatedFields[item.id] = {
                    type: item.attributeDef.valueFormatType,
                    key: item.attributeDef.key,
                    value: item.value
                };
                this.validate();
            }
        }
    };
</script>

<style scoped lang="scss">
    .isMandatory {
        :deep(.key:after) {
            content: " *"
        }
    }
    .isInvalid {
        background-color: var(--bs-danger-light);
    }
</style>
