<template>
    <div class="fixed-top fixed-bottom bg-light">
        <iframe
            :key="campaignRunId + reinitKey"
            :src="crEditorPath"
            class="w-100 h-100"
            title="campaignRun"
        ></iframe>
        <div
            v-if="isLocked"
            class="blockerOverlay d-flex justify-content-center align-items-center"
        >
            <NdxNotification
                :message="$t('message.campaignrunWasLocked')"
                :model-value="isLocked"
                variant="error"
                :duration="0"
                class="w-50"
            />
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import NdxNotification from "../library/NdxNotification.vue";

    export default {
        name: "ProjectRun",
        components: {NdxNotification},
        emits: ['close', 'done'],
        props: {
            campaignRunId: Number,
            productName: String,
            livePriceCalculation: Boolean,
            campaignRunApproved: Boolean
        },
        data() {
            return {
                localModel: null,
                reinitKey: '',
                crApproved: false,
                editorDialogId: 'projectRunEditor',
                crEditorPath: (process.env.NODE_ENV === 'production' ? '/js/apps/shop/dist/' : '') + 'crEditor.html',


                removeConfigDefaults: {
                    approveRemoveText: this.$t('removeConfig.approveRemoveText'),
                    approveRemoveBtn: this.$t('removeConfig.approveRemoveBtn'),
                    approveRemoveHeadline: this.$t('removeConfig.approveRemoveHeadline'),
                    approveRemoveBtnDesc: this.$t('removeConfig.approveRemoveBtnDesc'),
                }
            };
        },
        computed: {
            ...mapState({
                removeConfig: state => state.shop.removeApproveConfig
            }),
            isLocked() {
                return this.$store.getters['campaignRun/isLocked'](this.campaignRunId);
            }
        },
        created() {
            window.addEventListener('message', this.crFinished);
        },
        mounted() {
            this.crApproved = this.campaignRunApproved;
            this.loadCr();
        },
        beforeUnmount() {
            window.removeEventListener('message', this.crFinished);
        },
        methods: {
            loadCr() {
                window.ndxCrEditor = {
                    ajaxSecret: this.$store.getters["session/getSecret"],
                    crId: this.campaignRunId,
                    productName: this.productName,
                    campaignRunApproved: this.crApproved,
                    livePriceCalculation: this.livePriceCalculation,
                    socketIoPath: this.$store.getters['session/getWebSocketUrl'],
                    productionMode: process.env.NODE_ENV === 'production',
                    brandColor: window.getComputedStyle(window.parent.document.body).getPropertyValue('--bs-primary'),
                    removeConfig: Object.assign({},this.removeConfigDefaults, this.removeConfig)
                };
            },
            crFinished: function (ev) {
                if (ev && ev.data && typeof ev.data === 'string') {
                    try {
                        const data = JSON.parse(ev.data);
                        const url = window.location.protocol + '//' + window.location.hostname +
                            (window.location.port ? ':' + window.location.port : '');

                        if (ev.origin === url && (data.type === 'crFinished' || data.type === 'closeEditor')) {
                            this.$emit('close');
                        }

                        if (ev.origin === url && data.type === 'approved') {
                            this.$emit('done');
                        }

                        if (ev.origin === url && data.type === 'removeApprove') {
                            this.removeApproval();
                        }
                    } catch (e) {
                        // do not fail on other events sending strings that are no valid JSON
                    }
                }
            },
            removeApproval() {
                this.$store.dispatch('basket/removeApproval', this.campaignRunId).then(
                    () => {
                        this.crApproved = false;
                        this.loadCr();
                        this.reinitKey += Math.round(Math.random() * 10);
                    }
                );
            }
        }
    };
</script>

<style scoped lang="scss">
    .blockerOverlay {
        width: 100%;
        background-color: #eeec;
        position: fixed;
        top: 56px;
        bottom: 0;
        left: 0;
        right: 0;

        & > * {
            max-width: 400px;
        }
    }
</style>
