<template>
    <QuickCheckoutFlyIn
        v-if="quickCheckoutData !== null"
        :order-infos="quickCheckoutData"
        @cancel="closeQuickCheckout"
        @set-address="setAddress"
        @order-basket="orderBasket"
    />
    <NdxFlyIn v-if="orderSucceeded" class="limit500">
        <template #default>
            <NdxIcon icon="done-circle" class="success me-2"/>
            {{ $t('message.thanksForOrdering') }}

            <template v-for="orderItem in orderItems" :key="orderItem.id">
                <OrderDetailGroupItemFiles :item="orderItem" :order-id="orderId" with-headline />
            </template>
        </template>
        <template #buttons>
            <NdxButtonLink
                class="btnFlex"
                @click="() => gotoHome()"
            >{{ $t('btn.continueShopping') }}
            </NdxButtonLink>
            <NdxButton class="btnFlex" @click="() => gotoOrder()">
                <div class="d-flex justify-content-between">
                    {{ $t('btn.openOrder') }}
                    <NdxIcon icon="arrow-left" flip-h/>
                </div>
            </NdxButton>
        </template>
    </NdxFlyIn>
    <NdxFlyIn v-if="orderFailed" class="limit500">
        <template #default>
            <NdxIcon icon="error-filled" class="fail me-2"/>
            {{ $t('message.errorsDuringOrdering') }}
        </template>
        <template #buttons>
            <NdxButton class="btnFlex" @click="() => orderFailed = false">
                <div class="d-flex justify-content-between">
                    {{ $t('btn.close') }}
                    <NdxIcon icon="arrow-left" flip-h/>
                </div>
            </NdxButton>
        </template>
    </NdxFlyIn>
</template>

<script>
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxButton from "../library/NdxButton";
    import NdxIcon from "../library/NdxIcon";
    import NdxButtonLink from "../library/NdxButtonLink";
    import QuickCheckoutFlyIn from "./QuickCheckoutFlyIn";
    import { mapState } from "vuex";
    import OrderDetailGroupItemFiles from "../orders/detail/OrderDetailGroupItemFiles.vue";

    export default {
        components: {OrderDetailGroupItemFiles, QuickCheckoutFlyIn, NdxButtonLink, NdxIcon, NdxButton, NdxFlyIn},
        data() {
            return {
                orderSucceeded: false,
                orderFailed: false,
                orderId: null,
                orderItems: []
            };
        },
        computed: {
            ...mapState({
                quickCheckoutData: state => state.basket.quickCheckoutData
            })
        },
        methods: {
            closeQuickCheckout() {
                this.$store.dispatch('basket/resetQuickCheckoutData');
            },
            setAddress(data) {
                if (this.quickCheckoutData
                    && this.quickCheckoutData[data.type + 'Address'].id != data.id
                ) {
                    this.$store.dispatch('basket/setAddress', {
                        addressType: data.type,
                        addressId: data.id
                    }).then(() => {
                        this.$store.dispatch('basket/getQuickCheckoutData');
                    });
                }
            },
            orderBasket() {
                this.closeQuickCheckout();
                this.$store.dispatch('basket/order')
                    .then((orderId) => {
                        this.orderId = orderId;
                        this.orderSucceeded = true;
                        this.$store.dispatch('orders/find', {orderId: orderId})
                            .then(() => {
                                this.orderItems = this.$store.getters['orders/getOrderItems'];
                            });
                    })
                    .catch((error) => {
                        console.error('Order failure', error);
                        this.orderFailed = true;
                        this.$store.dispatch('basket/getBasket');
                    });
            },
            gotoOrder() {
                this.$router.push({
                    name: 'OrderDetails',
                    params: {
                        id: this.$store.getters['shop/getRouteByType']('orders').params.id,
                        orderId: this.orderId
                    }
                });
            },
            gotoHome() {
                const route = this.$store.getters['shop/getRouteByType']('shopHomepage');
                this.$router.push(route);
            }
        }
    };
</script>
