<template>
    <NdxPageFrame>
        <OrdersList />
    </NdxPageFrame>
</template>

<script>
    import NdxPageFrame from "../../components/library/NdxPageFrame";
    import OrdersList from "../../components/orders/OrdersList";
    export default {
        name: 'Orders',
        components: {OrdersList, NdxPageFrame},
    };
</script>
