<template>
    <div
        class="ndx-form-element ndx-checkbox"
        :class="{
            [variant]: true,
            autoWidth: true,
            disabled: readonly
        }"
        @click.stop="click"
        @mouseenter="enter"
        @mouseleave="leave"
    >
        <div class="d-flex align-items-center">
            <div>
                <div class="boxContainer" tabindex="1" @focusin="focusin" @focusout="focusout">
                    <div :class="{circle: true, checked: checked, hover: hover, focus: focus}"></div>
                    <div :class="{box: true, checked: checked, readonly: readonly}">
                        <NdxIcon icon="done" :style="'color: var(--bs-gray-900)'"/>
                    </div>
                </div>
            </div>
            <div class="label" v-if="label && label.length > 0" v-html="label"></div>
        </div>
    </div>
</template>

<script>

import NdxIcon from "../NdxIcon";
export default {
    name: "NdxCheckbox",
    components: {NdxIcon},
    props: {
        variant: {
            type: String,
            default: 'primary',
            validator: function (value) {
                return ['primary', 'secondary'].indexOf(value) !== -1;
            }
        },
        /**
         * Sets the checked state of the form element
         */
        modelValue: {
            type: Boolean,
            default: false
        },
        /**
         * right sided label, other label positions are not supported
         */
        label: {
            type: String,
            required: false
        },
        /**
         * Whether the user can edit the content or not
         */
        readonly: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            checked: this.modelValue,
            hover: false,
            focus: false
        };
    },
    watch: {
        modelValue: function () {
            this.checked = this.modelValue;
        }
    },
    methods: {
        click() {
            if (!this.readonly) {
                this.checked = !this.checked;
                this.$emit('update:modelValue', this.checked);
            }
        },
        enter() {
            if (!this.readonly) {
                this.hover = true;
            }
        },
        leave() {
            this.hover = false;
        },
        focusin() {
            if (!this.readonly) {
                this.focus = true;
            }
        },
        focusout() {
            this.focus = false;
        }
    }
};
</script>

<style scoped lang="scss">
@import "../../../style/variables_ndx";

.ndx-form-element.ndx-checkbox {
    --size: 26px;
    --rectSize: 18px;

    .primary:not(.disabled) {
        margin-top: 12px;
    }

    .boxContainer {
        width: var(--size);
        height: var(--size);
        outline: none;
        position: relative;

        .circle {
            position: absolute;
            top: 0;
            left: 0;
            width: var(--size);
            height: var(--size);
            border-radius: 10%;
            background-color: var(--bs-gray-200);
            opacity: 0;
            transition: all 0.2s;

            &.checked.hover {
                opacity: 0.16;
            }

            &:not(.checked).hover {
                background-color: var(--bs-gray-400);
                opacity: 0.16;
            }

            &.checked.focus {
                opacity: 0.10;
            }

            &:not(.checked).focus {
                background-color: var(--bs-gray-400);
                opacity: 0.10;
            }
        }

        .box {
            position: absolute;
            top: calc((var(--size) - var(--rectSize)) / 2);
            left: calc((var(--size) - var(--rectSize)) / 2);
            width: var(--rectSize);
            height: var(--rectSize);
            line-height: var(--rectSize);
            border-radius: 2px;
            border: 2px solid var(--bs-gray-400);
            background-color: white;
            color: white;

            &.checked .bi {
                transform: translate(-4px, -5px) scale(1.1);
            }

            &:not(.checked) .bi {
                display: none;
            }

            &.readonly {
                border: 2px solid var(--bs-gray-200);
                background-color: white;
            }

            &.checked.readonly {
                border: 2px solid var(--bs-gray-200);
                background-color: var(--bs-gray-200);
            }

            &:not(.readonly) {
                cursor: pointer;
            }
        }
    }

    .label {
        width: 100%;
        margin-left: 8px;
    }

}
</style>
