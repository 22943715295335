<template>
    <NdxFlyIn v-if="showPasswordChange || showPasswordChangeSuccess">
        <template #default>
            <template v-if="showPasswordChange">
                <PasswordChangeCriteria
                    class="d-none d-md-block"
                    :form="form"
                    :all-white="false"
                />
                <PasswordChangeForm
                    ref="passwordChangeForm"
                    v-model:form-validation="form"
                    hide-action-buttons
                    @on-change-success="onPasswordChange"
                />
            </template>
            <template v-if="showPasswordChangeSuccess">
                {{ $t('message.passwordChangeSucceeded') }}
            </template>
        </template>
        <template #buttons>
            <template v-if="showPasswordChange">
                <NdxButtonLink @click="$emit('update:showPasswordChange', false)">
                    {{ $t('btn.cancel') }}
                </NdxButtonLink>
                <NdxButton
                    @click="saveNewPassword"
                    :disabled="saveBtnInactive"
                >
                    {{ $t('btn.save') }}
                </NdxButton>
            </template>
            <template v-if="showPasswordChangeSuccess">
                <NdxButton @click="$emit('update:showPasswordChangeSuccess', false)">
                    {{ $t('btn.close') }}
                </NdxButton>
            </template>
        </template>
    </NdxFlyIn>
</template>

<script>
    import PasswordChangeForm from "../passwordChange/PasswordChange";
    import PasswordChangeCriteria from "../passwordChange/PasswordChangeCriteria";
    import NdxFlyIn from "../library/NdxFlyIn";
    import NdxButtonLink from "../library/NdxButtonLink";
    import NdxButton from "../library/NdxButton";

    export default {
        name: 'UserPassword',
        components: {NdxButton, NdxButtonLink, NdxFlyIn, PasswordChangeForm, PasswordChangeCriteria},
        props: {
            showPasswordChange: {
                type: Boolean
            },
            showPasswordChangeSuccess: {
                type: Boolean
            }
        },
        emits: ['update:showPasswordChange', 'update:showPasswordChangeSuccess'],
        data() {
            return {
                form: {
                    oldPassword: {
                        required: false
                    },
                    newPassword: {
                        required: false,
                        hasAlpha: false,
                        hasNumeric: false,
                        hasSpecialChar: false,
                        hasNoWhiteSpace: true,
                        minLength: false,
                        notSameAs: false
                    },
                    confirmPassword: {
                        required: false,
                        sameAs: true
                    }
                }
            };
        },
        computed: {
            saveBtnInactive() {
                return ('$error' in this.form && this.form.$error === true) ||
                    !('$error' in this.form);
            }
        },
        methods: {
            onPasswordChange() {
                this.$emit('update:showPasswordChange', false);
                this.$emit('update:showPasswordChangeSuccess', true);
            },
            saveNewPassword() {
                this.$refs.passwordChangeForm.submitForm();
            }
        }
    };
</script>
