<template>
    <div class="wrapper-preview">
        <div class="imagePreview">
            <slot name="default"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageDetailDefaultVisual'
    };
</script>

<style scoped lang="scss">
    .wrapper-preview {
        aspect-ratio: 1;

        .imagePreview {
            border: 1px solid var(--bs-gray-middle);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;

            img {
                object-fit: scale-down;
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
